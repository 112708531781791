import React, { useState, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useDropzone } from "react-dropzone";
import MaterialTable, { MTableToolbar } from "material-table";
import Paper from "@material-ui/core/Paper";
import AdminHeader from "../layouts/AdminHeader";
import UserStore from "../stores/UserStore";
import { Delete, Edit } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Autocomplete,
} from "@mui/material";
import AssetStore from "../stores/AssetStore";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import PatchedPagination from "../components/admin/PatchedPagination";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  generateButton: {
    margin: theme.spacing(1),
  },
  generateAllButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  processingAllButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#9e9e9e",
    color: "white",
  },
  allButtonProgress: {
    marginRight: theme.spacing(1),
  },
  statusBar: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  },
  statusCount: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  successText: {
    color: "green",
  },
  errorText: {
    color: "red",
  },
}));

// Configuration for async processing
const CONFIG = {
  // Maximum number of concurrent requests
  MAX_CONCURRENT_REQUESTS: 100,
  // Delay between starting batches (in milliseconds)
  BATCH_DELAY: 500
};

const getStateFromStores = () => {
  return {
    graphics: AssetStore.getGraphics(),
  };
};

const AdminGraphicsPage = () => {
  // State management
  const [data, setData] = useState(getStateFromStores());
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [tags, setTags] = useState("pizapPRO");
  const [type, setType] = useState("Sticker");
  const tableRef = useRef();
  const classes = useStyles();
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(20);
  const [selectedRowsOnPage, setSelectedRowsOnPage] = useState([]);
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const [generateAllProgress, setGenerateAllProgress] = useState({ 
    total: 0, 
    completed: 0, 
    successful: 0, 
    failed: 0 
  });
  
  // Lambda URL for SEO data generation
  const LAMBDA_URL = "https://bx2afikobmevjjenheseebpxze0isnhp.lambda-url.us-west-1.on.aws/";

  // Load graphics on component mount
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (data.graphics === null) {
        AssetActionCreators.loadGraphics();
      }
      AssetStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  // Update state when store changes
  const _onChange = () => {
    setData(getStateFromStores());
  };

  // Update loading state when graphics data changes
  useEffect(() => {
    if (data.graphics) {
      setIsLoading(false);
    } else setIsLoading(true);
  }, [data.graphics]);

  // Helper function to get or create required DOM elements
  const ensureDomElements = (buttonId, statusId) => {
    let buttonEl = document.getElementById(buttonId);
    let statusEl = document.getElementById(statusId);
    
    // If elements don't exist, try to find them by a different strategy
    if (!buttonEl || !statusEl) {
      console.log(`Elements not found by ID, trying alternative methods`);
      
      // Try to find all generate buttons
      const allButtons = document.querySelectorAll('[class*="generateButton"]');
      
      // Create the elements if they still don't exist (fallback)
      if (!buttonEl) {
        // Find existing button with similar class and clone its structure
        if (allButtons.length > 0) {
          const existingButton = allButtons[0];
          buttonEl = existingButton.cloneNode(true);
          buttonEl.id = buttonId;
          buttonEl.textContent = "Generate SEO";
          existingButton.parentNode.appendChild(buttonEl);
          console.log(`Created button element with ID ${buttonId}`);
        }
      }
      
      if (!statusEl) {
        // Create status element
        statusEl = document.createElement("div");
        statusEl.id = statusId;
        if (buttonEl && buttonEl.parentNode) {
          buttonEl.parentNode.appendChild(statusEl);
          console.log(`Created status element with ID ${statusId}`);
        } else if (allButtons.length > 0) {
          // Find an existing button's parent to add the status to
          const existingButton = allButtons[0];
          if (existingButton.parentNode) {
            existingButton.parentNode.appendChild(statusEl);
            console.log(`Created status element with ID ${statusId} (fallback method)`);
          }
        }
      }
    }
    
    return { buttonEl, statusEl };
  };
  
 /**
 * Function to generate SEO data for an asset
 * @param {Object} asset - The asset to generate SEO data for
 * @param {string} buttonId - The ID of the button to update
 * @param {string} statusId - The ID of the status element to update
 * @returns {Object} Result of the operation
 */
const generateSeoData = async (asset, buttonId, statusId) => {
  const thumbnailUrl = asset.thumbnail;
  const fullUrl = asset.url;
  const tags = asset.tags || []; // Get the tags from the asset
  const type = asset.type || "Sticker"; // Get the type from the asset, default to "Sticker"
  
  // Get references to DOM elements using our helper function
  const { buttonEl, statusEl } = ensureDomElements(buttonId, statusId);
  
  if (!buttonEl || !statusEl) {
    console.error("Button or status element not found and couldn't be created", buttonId, statusId);
    return { success: false, error: "DOM elements not found" };
  }
  
  // Store original button styling to restore it later
  const originalButtonStyles = {
    backgroundColor: buttonEl.style.backgroundColor,
    color: buttonEl.style.color,
    disabled: buttonEl.disabled,
    text: buttonEl.textContent
  };
  
  // Update UI to show processing state
  buttonEl.disabled = true;
  buttonEl.textContent = "Processing...";
  buttonEl.style.backgroundColor = "#9e9e9e"; // Gray background for processing
  buttonEl.style.color = "white";
  statusEl.innerHTML = "";
  
  try {
    console.log("Generating SEO data for asset:", fullUrl);
    console.log("Using thumbnail for processing:", thumbnailUrl);
    console.log("Using tags:", tags);
    console.log("Using type:", type);
    
    // Make API call to generate SEO data with both URLs, tags, and type
    const response = await fetch(LAMBDA_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        processingUrl: thumbnailUrl,  // URL for the LLM to process
        storageUrl: fullUrl,          // URL to store in the database
        tags: tags,                   // Tags to include with the asset
        type: type                    // Type of the asset (Sticker or Background)
      })
    });
    
    // Use direct DOM manipulation with a small timeout to ensure the update happens
    if (response.ok) {
      const data = await response.json();
      console.log("SEO data generated successfully:", data);
      
      // Use setTimeout to ensure the DOM update happens after any React re-rendering
      setTimeout(() => {
        const currentButtonEl = document.getElementById(buttonId);
        const currentStatusEl = document.getElementById(statusId);
        
        if (currentButtonEl && currentStatusEl) {
          // Update UI to show success
          currentButtonEl.disabled = false;
          currentButtonEl.textContent = "Generate SEO";
          currentButtonEl.style.backgroundColor = originalButtonStyles.backgroundColor;
          currentButtonEl.style.color = originalButtonStyles.color;
          currentStatusEl.innerHTML = '<span style="color: green; margin-left: 8px;">✓ Success</span>';
          console.log(`Updated button ${buttonId} to success state`);
        } else {
          console.error("Button or status element not found after successful API call", buttonId, statusId);
        }
      }, 100);
      
      return { success: true, data };
    } else {
      console.error("Failed to generate SEO data");
      
      // Use setTimeout to ensure the DOM update happens after any React re-rendering
      setTimeout(() => {
        const currentButtonEl = document.getElementById(buttonId);
        const currentStatusEl = document.getElementById(statusId);
        
        if (currentButtonEl && currentStatusEl) {
          // Update UI to show failure
          currentButtonEl.disabled = false;
          currentButtonEl.textContent = "Generate SEO";
          currentButtonEl.style.backgroundColor = originalButtonStyles.backgroundColor;
          currentButtonEl.style.color = originalButtonStyles.color;
          currentStatusEl.innerHTML = '<span style="color: red; margin-left: 8px;">✗ Failed</span>';
          console.log(`Updated button ${buttonId} to failure state`);
        } else {
          console.error("Button or status element not found after failed API call", buttonId, statusId);
        }
      }, 100);
      
      return { success: false, error: "API Error" };
    }
  } catch (error) {
    console.error("Error generating SEO data:", error);
    
    // Use setTimeout to ensure the DOM update happens after any React re-rendering
    setTimeout(() => {
      const currentButtonEl = document.getElementById(buttonId);
      const currentStatusEl = document.getElementById(statusId);
      
      if (currentButtonEl && currentStatusEl) {
        // Update UI to show error
        currentButtonEl.disabled = false;
        currentButtonEl.textContent = "Generate SEO";
        currentButtonEl.style.backgroundColor = originalButtonStyles.backgroundColor;
        currentButtonEl.style.color = originalButtonStyles.color;
        currentStatusEl.innerHTML = '<span style="color: red; margin-left: 8px;">✗ Error</span>';
        console.log(`Updated button ${buttonId} to error state`);
      } else {
        console.error("Button or status element not found after error", buttonId, statusId);
      }
    }, 100);
    
    return { success: false, error: error.message };
  }
};

  /**
   * Helper function for throttling async requests
   * @param {Function[]} items - Array of functions to execute
   * @param {number} maxConcurrent - Max number of concurrent requests
   * @returns {Promise<Array>} Results of all operations
   */
  const processBatch = async (items, maxConcurrent) => {
    // Process items in batches with maxConcurrent items per batch
    const results = [];
    
    for (let i = 0; i < items.length; i += maxConcurrent) {
      const batch = items.slice(i, i + maxConcurrent);
      const batchPromises = batch.map(item => item());
      
      // Wait for all promises in this batch to resolve
      const batchResults = await Promise.all(batchPromises);
      results.push(...batchResults);
      
      // Short delay before starting the next batch to avoid rate limiting
      if (i + maxConcurrent < items.length) {
        await new Promise(resolve => setTimeout(resolve, CONFIG.BATCH_DELAY));
      }
    }
    
    return results;
  };

/**
 * Function to generate SEO data for all graphics on the current page
 */
const generateAllSeoData = async () => {
  // Prevent multiple simultaneous "Generate All" operations
  if (isGeneratingAll) return;
  
  setIsGeneratingAll(true);
  
  // Get the current visible data from the table ref
  if (!tableRef.current) {
    console.error("Table reference not available");
    setIsGeneratingAll(false);
    return;
  }
  
  // Get the current page data
  // We need to get just the current page, not all data
  const pageSize = tableRef.current.dataManager.pageSize;
  const currentPage = tableRef.current.dataManager.currentPage;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  
  // This gets only the data for the current page
  const currentData = tableRef.current.dataManager.sortedData.slice(startIndex, endIndex);
  
  console.log(`Current page: ${currentPage}, Page size: ${pageSize}`);
  console.log(`Found ${currentData.length} assets on current page (from ${startIndex} to ${endIndex})`);
  
  if (!currentData || currentData.length === 0) {
    console.error("No data found on current page");
    setIsGeneratingAll(false);
    return;
  }
  
  // Initialize progress tracking
  const total = currentData.length;
  setGenerateAllProgress({
    total,
    completed: 0,
    successful: 0,
    failed: 0
  });
  
  // Create an array of functions to execute
  const taskFunctions = currentData.map(asset => {
    return async () => {
      const thumbnailUrl = asset.thumbnail;
      const fullUrl = asset.url;
      const tags = asset.tags || []; // Get tags from the asset
      const type = asset.type || "Sticker"; // Get type from the asset, default to "Sticker"
      
      if (!thumbnailUrl) {
        console.error("No thumbnail URL found for asset:", asset);
        setGenerateAllProgress(prev => ({
          ...prev,
          completed: prev.completed + 1,
          failed: prev.failed + 1
        }));
        return { success: false, error: "No thumbnail URL" };
      }
      
      // Create safe IDs for button and status elements
      const safeId = fullUrl.replace(/[^a-zA-Z0-9]/g, '_');
      const buttonId = `seo-button-${safeId}`;
      const statusId = `seo-status-${safeId}`;
      
      console.log(`Processing asset: ${fullUrl}`);
      console.log(`Looking for button with ID: ${buttonId}`);
      console.log(`Using tags: ${tags}`);
      console.log(`Using type: ${type}`);
      
      // Check if button exists before proceeding
      const buttonCheck = document.getElementById(buttonId);
      if (!buttonCheck) {
        console.error(`Button with ID ${buttonId} not found in DOM`);
        // Try to find the button again with a different strategy
        const allButtons = document.querySelectorAll('button[class*="generateButton"]');
        console.log(`Found ${allButtons.length} buttons with generateButton class`);
        
        // Log all button IDs for debugging
        allButtons.forEach((button, index) => {
          console.log(`Button ${index}: id="${button.id}", text="${button.textContent}"`);
        });
        
        setGenerateAllProgress(prev => ({
          ...prev,
          completed: prev.completed + 1,
          failed: prev.failed + 1
        }));
        return { success: false, error: "Button element not found" };
      }
      
      // Give the DOM a moment to stabilize before sending the request
      await new Promise(resolve => setTimeout(resolve, 50));
      
      // Generate SEO data for this asset
      const result = await generateSeoData({ 
        thumbnail: thumbnailUrl,
        url: fullUrl,
        tags: tags,
        type: type
      }, buttonId, statusId);
      
      // Update progress
      setGenerateAllProgress(prev => ({
        ...prev,
        completed: prev.completed + 1,
        successful: prev.successful + (result.success ? 1 : 0),
        failed: prev.failed + (result.success ? 0 : 1)
      }));
      
      return result;
    };
  });
  
  try {
    // Process all tasks with throttling
    await processBatch(taskFunctions, CONFIG.MAX_CONCURRENT_REQUESTS);
    console.log("All SEO generation tasks completed");
  } catch (error) {
    console.error("Error in batch processing:", error);
  } finally {
    setIsGeneratingAll(false);
  }
};

 /**
 * Component to display SEO status and button for each asset
 * @param {Object} props - Component props
 * @param {Object} props.rowData - The full asset data object
 */
const SeoStatusDisplay = ({ rowData }) => {
  // Get both URLs from the row data
  const thumbnailUrl = rowData.thumbnail;
  const fullUrl = rowData.url;
  const tags = rowData.tags || []; // Get tags from the rowData
  const type = rowData.type || "Sticker"; // Get type from the rowData, default to "Sticker"
  
  // Use the full URL for the ID to ensure uniqueness
  // Replace any special characters with underscores to make valid IDs
  const safeId = fullUrl.replace(/[^a-zA-Z0-9]/g, '_');
  const buttonId = `seo-button-${safeId}`;
  const statusId = `seo-status-${safeId}`;
  
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        id={buttonId}
        variant="contained"
        color="primary"
        size="small"
        className={classes.generateButton}
        onClick={() => generateSeoData({ 
          thumbnail: thumbnailUrl, 
          url: fullUrl,
          tags: tags,
          type: type
        }, buttonId, statusId)}
      >
        Generate SEO
      </Button>
      <div id={statusId}></div>
    </div>
  );
};
  // Handler for tag deletion
  const handleTagDelete = (chipToDelete, tag) => () => {
    data.graphics.forEach((item) => {
      if (item.url === chipToDelete.url) {
        item.tags = item.tags.filter((chip) => chip !== tag);
        fetch(
          `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
            item.url
          )}`,
          {
            method: "PUT",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
              "content-type": "application/json",
            }),
            body: JSON.stringify(item),
          }
        ).then(() => {
          console.log("resolved");
        });
      }
    });
  };
  
  // Handler for feature deletion
  const handleFeatureDelete = (chipToDelete, feature) => () => {
    data.graphics.forEach((item, index) => {
      if (item.url === chipToDelete.url) {
        item.features = item.features.filter((chip) => chip !== feature);
        fetch(
          `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
            item.url
          )}`,
          {
            method: "PUT",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
              "content-type": "application/json",
            }),
            body: JSON.stringify(item),
          }
        ).then((res) => {
          console.log("resolved");
        });
      }
    });
  };

  // File drop handler for uploads
  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    for (let x = 0; x < acceptedFiles.length; x++) {
      formData.append("file" + x, acceptedFiles[x]);
    }

    fetch(`${process.env.REACT_APP_SERVER_API}/pizapAsset/prepare`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${UserStore.getAccessToken()}`,
      }),
      body: formData,
    })
      .then((res) => res.json())
      .then(
        (result) =>
          new Promise((resolve, reject) => {
            AssetStore.setGraphics([...result, ...data.graphics]);
            AssetStore.emitChange();
            resolve();
          })
      );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
  // Handler for delete button
  const handleDeleteRows = (event, rowData) => {
    setOpen(true);
  };

  // Handler for edit button
  const handleEditRows = (event, rowData) => {
    setEditOpen(true);
  };

  // Helper function to update a row
  const updateRow = async (url, newData) => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
        url
      )}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${UserStore.getAccessToken()}`,
          "content-type": "application/json",
        }),
        body: JSON.stringify(newData),
      }
    );
    return res;
  };

  // Handler for editing multiple rows
  const handleEditMultiRows = async () => {
    let _items = data.graphics;
    selectedRowsOnPage.forEach(async (rd) => {
      await Promise.all(
        _items.map(async (t) => {
          if (t.tableData.id === rd.tableData.id) {
            const update = {
              ...t,
              tags: tags.split(","),
              type: type,
            };
            const res = await updateRow(t.url, update);
            if (res.status === 200) {
              return update;
            } else {
              return t;
            }
          } else {
            return t;
          }
        })
      );
    });
    AssetStore.setGraphics(_items);
    AssetStore.emitChange();
    setEditOpen(false);
  };

  // Handler for deleting multiple rows
  const handleClickMultiDelete = () => {
    let _items = data.graphics;
    selectedRowsOnPage.forEach((rd) => {
      setTimeout(() => {
        fetch(
          `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
            rd.url
          )}`,
          {
            method: "DELETE",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
            }),
          }
        )
          .then((res) => {
            if (res.status === 200) {
              _items = _items.filter((t) => t.tableData.id !== rd.tableData.id);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }, 1000);
    });
    AssetStore.setGraphics(_items);
    AssetStore.emitChange();
    setOpen(false);
  };

  // Dialog close handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  // Form input handlers
  const handleEditTagsChange = (e) => {
    e.preventDefault();
    setTags(e.target.value);
  };

  const handleEditTypeChange = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  // Pagination event handlers
  const handlePageChange = (pageIndex, pageSize) => {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    setStartRowIndex(startIndex);
    setEndRowIndex(endIndex);
  };

  // Selection change handler - FIXED the comparison bug
  const handleSelectionChange = (rows) => {
    const currentPageRows = tableRef.current.dataManager.data.slice(
      startRowIndex,
      endRowIndex
    );
    const selectedRowsOnCurrentPage = rows.filter((row) =>
      currentPageRows.find(
        (pageRow) => pageRow.tableData.id === row.tableData.id
      )
    );
    setSelectedRowsOnPage(selectedRowsOnCurrentPage);
  };

  // Render component based on loading state
  return (
    <section className="admin-section banner banner-section">
      <AdminHeader />
      {!isLoading ? (
        <div className="item-b">
          {/* Edit Dialog */}
          <Dialog open={editOpen} onClose={handleClose}>
            <DialogTitle>Edit Selected</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="tags"
                label="Tags"
                type="text"
                value={tags}
                fullWidth
                variant="standard"
                onChange={handleEditTagsChange}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                className="mt-5"
                options={[
                  { label: "Sticker", value: "Sticker" },
                  { label: "Background", value: "Background" },
                ]}
                value={type}
                onSelect={handleEditTypeChange}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Choose a type" />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Cancel</Button>
              <Button onClick={handleEditMultiRows}>Save</Button>
            </DialogActions>
          </Dialog>
          
          {/* Delete Confirmation Dialog */}
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              Are you sure you want to delete these rows?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Once you delete it, you cannot retrieve it.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleClickMultiDelete}>Delete</Button>
            </DialogActions>
          </Dialog>
          
          {/* Generate All SEO Button */}
          <Button
            variant="contained"
            className={isGeneratingAll ? classes.processingAllButton : classes.generateAllButton}
            disabled={isGeneratingAll}
            onClick={generateAllSeoData}
            size="large"
            fullWidth
          >
            {isGeneratingAll && (
              <CircularProgress 
                size={24} 
                className={classes.allButtonProgress} 
                color="inherit" 
              />
            )}
            {isGeneratingAll 
              ? `Processing (${generateAllProgress.completed}/${generateAllProgress.total})` 
              : "Generate SEO for All Items on This Page"
            }
          </Button>
          
          {/* Progress Status Bar */}
          {isGeneratingAll && (
            <div className={classes.statusBar}>
              <div>Generating SEO data for all assets...</div>
              <div className={classes.statusCount}>
                <span className={classes.successText}>
                  Success: {generateAllProgress.successful}
                </span>
                {" / "}
                <span className={classes.errorText}>
                  Failed: {generateAllProgress.failed}
                </span>
                {" / "}
                Total: {generateAllProgress.total}
              </div>
            </div>
          )}
          
          {/* File Upload Dropzone */}
          <Paper {...getRootProps({ className: "dropzone mb-2" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </Paper>
          
          {/* Assets Table */}
          {data.graphics && (
            <MaterialTable
              tableRef={tableRef}
              title="Pizap Assets"
              columns={[
                {
                  title: "Thumb",
                  field: "url",
                  render: (rowData) => (
                    <img
                      className="thumbIcon"
                      src={rowData.thumbnail}
                      alt="icon"
                    />
                  ),
                },
                {
                  title: "Type",
                  field: "type",
                  lookup: { Sticker: "Sticker", Background: "Background" },
                },
                { title: "Sequence", field: "sequence", type: "numeric" },
                {
                  title: "Tags",
                  field: "tags",
                  render: (rowData) => (
                    <>
                      {rowData.tags ? (
                        rowData.tags.map((tag) => {
                          let icon;
                          return (
                            <Chip
                              key={tag}
                              icon={icon}
                              label={tag}
                              onDelete={handleTagDelete(rowData, tag)}
                              className={classes.chip}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                },
                {
                  title: "Features",
                  field: "features",
                  render: (rowData) => (
                    <>
                      {rowData.features ? (
                        rowData.features.map((feature) => {
                          let icon;
                          return (
                            <Chip
                              key={feature}
                              icon={icon}
                              label={feature}
                              onDelete={handleFeatureDelete(rowData, feature)}
                              className={classes.chip}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                },
                {
                  title: "SEO Data",
                  field: "seo",
                  sorting: false,
                  filtering: false,
                  render: (rowData) => (
                    <SeoStatusDisplay rowData={rowData} />
                  ),
                },
              ]}
              data={data.graphics}
              options={{
                filtering: true,
                pageSizeOptions: [5, 10, 20, 50, 100],
                pageSize: 20,
                selection: true,
                paging: true,
                showTextRowsSelected: false,
                selectionProps: (rowData) => ({
                  checked: selectedRowsOnPage.some(
                    (row) => row.tableData.id === rowData.tableData.id  // FIXED: was comparing row.tableData.id === row.tableData.id
                  ),
                }),
              }}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={(pageSize) => {
                const startIndex = 0;
                const endIndex = pageSize;
                setStartRowIndex(startIndex);
                setEndRowIndex(endIndex);
              }}
              onSelectionChange={handleSelectionChange}
              actions={[
                {
                  icon: () => <Delete />,
                  tooltip: "Delete Rows",
                  onClick: handleDeleteRows,
                },
                {
                  icon: () => <Edit />,
                  tooltip: "Edit Rows",
                  onClick: handleEditRows,
                },
              ]}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (
                        newData.tags !== null &&
                        newData.tags !== "" &&
                        typeof newData.tags === "string"
                      ) {
                        newData.tags = newData.tags.split(",");
                      }
                      if (
                        newData.features !== null &&
                        newData.features !== "" &&
                        typeof newData.features === "string"
                      ) {
                        newData.features = newData.features.split(",");
                      }
                      fetch(`${process.env.REACT_APP_SERVER_API}/pizapAsset`, {
                        method: "POST",
                        headers: new Headers({
                          Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          "content-type": "application/json",
                        }),
                        body: JSON.stringify(newData),
                      })
                        .then((res) => {
                          if (res.status === 200) {
                            AssetStore.setGraphics([...data.graphics, newData]);
                            AssetStore.emitChange();
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...data.graphics];
                      const index = oldData.tableData.id;
                      if (
                        newData.tags !== null &&
                        newData.tags !== "" &&
                        typeof newData.tags === "string"
                      ) {
                        newData.tags = newData.tags.split(",");
                      }
                      if (
                        newData.features !== null &&
                        newData.features !== "" &&
                        typeof newData.features === "string"
                      ) {
                        newData.features = newData.features.split(",");
                      }
                      dataUpdate[index] = newData;
                      fetch(
                        `${
                          process.env.REACT_APP_SERVER_API
                        }/pizapAsset/${encodeURIComponent(newData.url)}`,
                        {
                          method: "PUT",
                          headers: new Headers({
                            Authorization: `Bearer ${UserStore.getAccessToken()}`,
                            "content-type": "application/json",
                          }),
                          body: JSON.stringify(newData),
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            AssetStore.setGraphics([...dataUpdate]);
                            AssetStore.emitChange();
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...data.graphics];
                      const index = oldData.tableData.id;
                      const record = dataDelete[index];
                      dataDelete.splice(index, 1);
                      fetch(
                        `${
                          process.env.REACT_APP_SERVER_API
                        }/pizapAsset/${encodeURIComponent(record.url)}`,
                        {
                          method: "DELETE",
                          headers: new Headers({
                            Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          }),
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            // FIXED: Removed duplicate resolve() call
                            AssetStore.setGraphics([...dataDelete]);
                            AssetStore.emitChange();
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject();
                        });
                    }, 1000);
                  }),
              }}
              components={{
                Pagination: PatchedPagination,
                Toolbar: (props) => (
                  <div>
                    <h6 className="MuiTypography-root MuiTypography-h6">
                      Selected Rows: {selectedRowsOnPage.length}
                    </h6>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
            />
          )}
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </section>
  );
};

export default AdminGraphicsPage;