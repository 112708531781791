import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserStore from "../stores/UserStore";
import MaterialTable, { MTableToolbar } from "material-table";
import AdminHeader from "../layouts/AdminHeader";
import PatchedPagination from "../components/admin/PatchedPagination";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  generateButton: {
    margin: theme.spacing(1),
  },
  generateAllButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  processingAllButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#9e9e9e",
    color: "white",
  },
  allButtonProgress: {
    marginRight: theme.spacing(1),
  },
  statusBar: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  },
  statusCount: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  successText: {
    color: "green",
  },
  errorText: {
    color: "red",
  },
}));

// Configuration for async processing
const CONFIG = {
  // Maximum number of concurrent requests
  MAX_CONCURRENT_REQUESTS: 100,
  // Delay between starting batches (in milliseconds)
  BATCH_DELAY: 500
};

const AdminTemplatesPage = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const classes = useStyles();
  const tableRef = useRef(null);
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const [generateAllProgress, setGenerateAllProgress] = useState({ 
    total: 0, 
    completed: 0, 
    successful: 0, 
    failed: 0 
  });
  
  const LAMBDA_URL = "https://bx2afikobmevjjenheseebpxze0isnhp.lambda-url.us-west-1.on.aws/";

  // Fetch templates directly using fetch API instead of the store
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_API}/pizapTemplate`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${UserStore.getAccessToken()}`,
        'Content-Type': 'application/json'
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        // Check if result.data exists, otherwise use the result directly
        const templatesData = result.data || result;
        setTemplates(templatesData);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }, []);

// Function to generate SEO data for a template
const generateSeoData = async (template, buttonId, statusId) => {
  const thumbnailUrl = template.thumbnail;
  const fullUrl = template.url || template.thumbnail; // In case url is not available, fallback to thumbnail
  const tags = template.tags || []; // Get the tags from the template
  const type = template.type || "template"; // Default type is "template" for the templates page
  
  // Get references to DOM elements
  const buttonEl = document.getElementById(buttonId);
  const statusEl = document.getElementById(statusId);
  
  if (!buttonEl || !statusEl) {
    console.error("Button or status element not found", buttonId, statusId);
    return { success: false, error: "DOM elements not found" };
  }
  
  // Update UI to show processing
  buttonEl.disabled = true;
  buttonEl.textContent = "Processing...";
  buttonEl.style.backgroundColor = "#9e9e9e"; // Gray background for processing
  buttonEl.style.color = "white";
  statusEl.innerHTML = "";
  
  try {
    console.log("Generating SEO data for", thumbnailUrl);
    
    const response = await fetch(LAMBDA_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        processingUrl: thumbnailUrl,
        storageUrl: fullUrl,
        tags: tags, // Include the tags in the request
        type: type   // Include the type in the request
      })
    });
    
    if (response.ok) {
      const data = await response.json();
      console.log("SEO data generated:", data);
      
      // Update UI to show success
      buttonEl.disabled = false;
      buttonEl.textContent = "Generate SEO";
      buttonEl.style.backgroundColor = ""; // Reset to default Material-UI color
      buttonEl.style.color = "";
      statusEl.innerHTML = '<span style="color: green; margin-left: 8px;">✓ Success</span>';
      
      return { success: true, data };
    } else {
      console.error("Failed to generate SEO data");
      
      // Update UI to show failure
      buttonEl.disabled = false;
      buttonEl.textContent = "Generate SEO";
      buttonEl.style.backgroundColor = ""; // Reset to default Material-UI color
      buttonEl.style.color = "";
      statusEl.innerHTML = '<span style="color: red; margin-left: 8px;">✗ Failed</span>';
      
      return { success: false, error: "API Error" };
    }
  } catch (error) {
    console.error("Error generating SEO data:", error);
    
    // Update UI to show error
    buttonEl.disabled = false;
    buttonEl.textContent = "Generate SEO";
    buttonEl.style.backgroundColor = ""; // Reset to default Material-UI color
    buttonEl.style.color = "";
    statusEl.innerHTML = '<span style="color: red; margin-left: 8px;">✗ Error</span>';
    
    return { success: false, error: error.message };
  }
};

  // Helper function for throttling async requests
  const processBatch = async (items, maxConcurrent) => {
    // Process items in batches with maxConcurrent items per batch
    const results = [];
    
    for (let i = 0; i < items.length; i += maxConcurrent) {
      const batch = items.slice(i, i + maxConcurrent);
      const batchPromises = batch.map(item => item());
      
      // Wait for all promises in this batch to resolve
      const batchResults = await Promise.all(batchPromises);
      results.push(...batchResults);
      
      // Short delay before starting the next batch to avoid rate limiting
      if (i + maxConcurrent < items.length) {
        await new Promise(resolve => setTimeout(resolve, CONFIG.BATCH_DELAY));
      }
    }
    
    return results;
  };

  // Function to generate SEO data for all templates on the current page
const generateAllSeoData = async () => {
  // Prevent multiple simultaneous "Generate All" operations
  if (isGeneratingAll) return;
  
  setIsGeneratingAll(true);
  
  // Get the current visible data from the table ref
  if (!tableRef.current) {
    console.error("Table reference not available");
    setIsGeneratingAll(false);
    return;
  }
  
  // Get the current page data
  // We need to get just the current page, not all data
  const pageSize = tableRef.current.dataManager.pageSize;
  const currentPage = tableRef.current.dataManager.currentPage;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  
  // This gets only the data for the current page
  const currentData = tableRef.current.dataManager.sortedData.slice(startIndex, endIndex);
  
  console.log(`Current page: ${currentPage}, Page size: ${pageSize}`);
  console.log(`Found ${currentData.length} templates on current page (from ${startIndex} to ${endIndex})`);
  
  if (!currentData || currentData.length === 0) {
    console.error("No data found on current page");
    setIsGeneratingAll(false);
    return;
  }
  
  // Initialize progress tracking
  const total = currentData.length;
  setGenerateAllProgress({
    total,
    completed: 0,
    successful: 0,
    failed: 0
  });
  
  // Create an array of functions to execute
const taskFunctions = currentData.map(template => {
  return async () => {
    const thumbnailUrl = template.thumbnail;
    const fullUrl = template.url || template.thumbnail; // Fallback to thumbnail if url not available
    const tags = template.tags || []; // Get the tags from the template
    const type = "template"; // For templates page, we set the type as "template"
    
    if (!thumbnailUrl) {
      console.error("No thumbnail found for template:", template);
      setGenerateAllProgress(prev => ({
        ...prev,
        completed: prev.completed + 1,
        failed: prev.failed + 1
      }));
      return { success: false, error: "No thumbnail URL" };
    }
    
    // Create safe IDs for button and status elements
    const safeId = (fullUrl || thumbnailUrl).replace(/[^a-zA-Z0-9]/g, '_');
    const buttonId = `seo-button-${safeId}`;
    const statusId = `seo-status-${safeId}`;
    
    // Generate SEO data for this template
    const result = await generateSeoData({ 
      thumbnail: thumbnailUrl,
      url: fullUrl,
      tags: tags, // Pass the tags to generateSeoData
      type: type  // Pass the type to generateSeoData
    }, buttonId, statusId);
    
    // Update progress
    setGenerateAllProgress(prev => ({
      ...prev,
      completed: prev.completed + 1,
      successful: prev.successful + (result.success ? 1 : 0),
      failed: prev.failed + (result.success ? 0 : 1)
    }));
    
    return result;
  };
});
  
  try {
    // Process all tasks with throttling
    await processBatch(taskFunctions, CONFIG.MAX_CONCURRENT_REQUESTS);
    console.log("All SEO generation tasks completed");
  } catch (error) {
    console.error("Error in batch processing:", error);
  } finally {
    setIsGeneratingAll(false);
  }
};

// SEO status display component
const SeoStatusDisplay = ({ rowData }) => {
  const thumbnailUrl = rowData.thumbnail;
  const fullUrl = rowData.url || rowData.thumbnail; // Fallback to thumbnail if url is not available
  const tags = rowData.tags || []; // Get the tags from the rowData
  const type = "template"; // For templates page, we set the type as "template"
  
  // Use the full URL (or thumbnail if full URL not available) for the ID
  const safeId = (fullUrl || thumbnailUrl).replace(/[^a-zA-Z0-9]/g, '_');
  const buttonId = `seo-button-${safeId}`;
  const statusId = `seo-status-${safeId}`;
  
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        id={buttonId}
        variant="contained"
        color="primary"
        size="small"
        className={classes.generateButton}
        onClick={() => generateSeoData({ 
          thumbnail: thumbnailUrl,
          url: fullUrl,
          tags: tags, // Pass the tags to generateSeoData
          type: type  // Pass the type to generateSeoData
        }, buttonId, statusId)}
      >
        Generate SEO
      </Button>
      <div id={statusId}></div>
    </div>
  );
};

  let [columns] = useState([
    {
      title: "Thumb",
      field: "thumbnail",
      render: (rowData) => (
        <img className="thumbIcon" src={rowData.thumbnail} alt="icon" />
      ),
    },
    { title: "Age", field: "age" },
    { title: "Caption", field: "caption" },
    { title: "Sequence", field: "sequence", type: "numeric" },
    {
      title: "Tags",
      field: "tags",
      render: (rowData) => (
        <>
          {rowData.tags && rowData.tags.map((tag) => {
            let icon;
            return (
              <Chip
                key={tag}
                icon={icon}
                label={tag}
                onDelete={handleTagDelete(rowData, tag)}
                className={classes.chip}
              />
            );
          })}
        </>
      ),
    },
    {
      title: "SEO Data",
      field: "seo",
      sorting: false,
      filtering: false,
      render: (rowData) => <SeoStatusDisplay rowData={rowData} />,
    },
  ]);

// Simplified tag deletion function - only sends API command
const handleTagDelete = (rowData, tag) => () => {
  // Just make the API call to delete the tag without updating UI state
  fetch(
    `${process.env.REACT_APP_SERVER_API}/projectTag/${encodeURIComponent(
      rowData.imageName
    )}/${encodeURIComponent(tag)}`,
    {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${UserStore.getAccessToken()}`,
        "content-type": "application/json",
      }),
    }
  )
  .then(res => {
    if (!res.ok) {
      console.error("Failed to delete tag");
    } else {
      console.log("Tag deleted successfully");
      // Optionally, you could force a refresh of just this row if needed
      // But we're not updating the entire state to avoid UI issues
    }
  })
  .catch(error => {
    console.error("Error deleting tag:", error);
  });
};

  // Use the same loading/error pattern as the working version
  if (error) {
    return (
      <section className="admin-section banner banner-section">
        <AdminHeader />
        <div>Error: {error.message}</div>
      </section>
    );
  } else if (!isLoaded) {
    return (
      <section className="admin-section banner banner-section">
        <AdminHeader />
        <div className="loading"></div>
      </section>
    );
  } else {
    return (
      <section className="admin-section banner banner-section">
        <AdminHeader />
        <div className="item-b">
          <Button
            variant="contained"
            className={isGeneratingAll ? classes.processingAllButton : classes.generateAllButton}
            disabled={isGeneratingAll}
            onClick={generateAllSeoData}
            size="large"
            fullWidth
          >
            {isGeneratingAll && (
              <CircularProgress 
                size={24} 
                className={classes.allButtonProgress} 
                color="inherit" 
              />
            )}
            {isGeneratingAll 
              ? `Processing (${generateAllProgress.completed}/${generateAllProgress.total})` 
              : "Generate SEO for All Items on This Page"
            }
          </Button>
          
          {isGeneratingAll && (
            <div className={classes.statusBar}>
              <div>Generating SEO data for all templates...</div>
              <div className={classes.statusCount}>
                <span className={classes.successText}>
                  Success: {generateAllProgress.successful}
                </span>
                {" / "}
                <span className={classes.errorText}>
                  Failed: {generateAllProgress.failed}
                </span>
                {" / "}
                Total: {generateAllProgress.total}
              </div>
            </div>
          )}
          
          <MaterialTable
            tableRef={tableRef}
            title="Pizap Templates"
            columns={columns}
            data={templates}
            options={{
              filtering: true,
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 100],
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...templates];
                    const index = oldData.tableData.id;
                    if (
                      newData.tags !== null &&
                      newData.tags !== "" &&
                      typeof newData.tags === "string"
                    ) {
                      newData.tags = newData.tags.split(",");
                    }
                    dataUpdate[index] = newData;
                    
                    fetch(
                      `${process.env.REACT_APP_SERVER_API}/pizapTemplate/${encodeURIComponent(newData.imageName)}`,
                      {
                        method: "PUT",
                        headers: new Headers({
                          Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          "content-type": "application/json",
                        }),
                        body: JSON.stringify(newData),
                      }
                    )
                      .then((res) => {
                        if (res.status === 200) {
                          setTemplates(dataUpdate);
                          resolve();
                        } else {
                          reject();
                        }
                      })
                      .catch((e) => {
                        reject(e);
                      });
                  }, 1000);
                }),
            }}
            components={{
              Pagination: PatchedPagination,
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
          />
        </div>
      </section>
    );
  }
};

export default AdminTemplatesPage;