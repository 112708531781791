import EditorDispatcher from "../dispatcher/EditorDispatcher";
import ActionTypes from "../constants/EditorConstants";
import UserStore from "../stores/UserStore";
import AssetStore from "../stores/AssetStore";
import { EventEmitter } from "events";
import assign from "object-assign";
import * as Filters from "pixi-filters";
import WebFont from "webfontloader";
import Lockr from "lockr";
import * as PIXI from "pixi.js";
import JSONUtils from "../utils/JSONUtils";
import SaveAs from "file-saver";
import utils from "../editor/utils";
import PhotoAsset from "../editor/PhotoAsset";
import EditorActionCreators from "../actions/EditorActionCreators";
import LeftMenuStore from "./LeftMenuStore";
import { decimalToHex } from "../utils/utils";

EventEmitter.defaultMaxListeners = 30;
let CHANGE_EVENT = "change";
let _collageHighlighted;
let _saving = false;
let _selectedObject;
let _propertiesVisible = false;
let _propertiesMinified = false;
let _executed = [];
let _unexecuted = [];
let _touchupexecuted = [];
let _touchupunexecuted = [];
let _paintColor = "#FFFFFF";
let _paintMode = "paint";
let _paintAlpha = 1;
let _paintBlur = 0;
let _brushSize = 16;
let _cutoutMode = "crop";
let _cutoutView = "show";
let _editorMode = "view";
let _editorType = "edit";
let _showFilePicker = false;
let _showCropPicker = false;
let _showDesignPicker = false;
let _showCollagePicker = false;
let _showPropertiesCollage = false;
let _showCustomSize = false;
let _filePickerTool = null;
let _showShareDialog = false;
let _pickerState = null;
let _savedCloudKey = null;
let _selectedCollageHotspot = null;
let _selectedActiveColalgeHotspot = null;
let _showEmbed = false;
let _showEmail = false;
let _showLink = false;
let _showSpinner = false;
let _showProjectDel = false;
let _deleteObj = null;
let _showStickerLoadError = false;
let _showUsageLimit = false;
let _showProjectLoad = false;
let _showSaveQuality = false;
let _showCutoutInstructions = false;
let _showFeedback = false;
let _showCutoutStyle = false;
let _showGraphicCutoutStyle = false;
let _showCutoutTrace = false;
let _showCutoutZoom = false;
let _showCutoutRefine = false;
let _showStart = false;
let _customBorder = false;
let _customWidth = 1;
let _propManualPosition = false;
let _customColor = 0xffffff;
let _designBackground = null;
let _currentEffect = null;
let _bg_effect = null;
let _graphicEffect = null;
let _collageHotspotEffect = null;
let _imageSaved = false;
let _touchupTool = null;
let _touchupBrushSize = 12;
let _touchupBrushHardness = 4;
let _touchupFade = 0;
let _touchupColor;
let _projectId;
let selectedCropType = null;
let _colorSelectorOpen = false;
let _projectTitle = "Untitled";
let _statusMessage = "";
let _projectText = "";
let _showColorPicker = false;
let _colorPickerColorCode;
let _colorPickerCallback;
let _colorPickerOriginalColor;
let _showDoubleArrow;
let _gptLoaded = false;
let _blankColor = 0xffffff;
let savedUrl = "";
let _aierror = false;
let _contextMenu = false;
let _showDeleteAccount = false;
const minCount = UserStore.isPro() ? 1 : 2;
let _imageSettings = {
  temperature: 0,
  brightness: 0,
  contrast: 0.8,
  saturation: 0,
  hue: 0,
  red: 1,
  green: 1,
  blue: 1,
  noise: 0,
  bloom: 0,
  emboss: 0,
  triangleBlur: 0,
  denoise: 50,
  sharpen: 0,
  blur: 0,
  straighten: 0,
};

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}


var EditorStore = assign({}, EventEmitter.prototype, {
  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.setMaxListeners(20);
    this.on(CHANGE_EVENT, callback);
  },
  getProjectId: function () {
    return _projectId;
  },
  setProjectId: function (id) {
    _projectId = id;
  },
  getProjectText: function () {
    return _projectText;
  },
  setProjectText: function (text) {
    _projectText = text;
  },
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  setCollageHighlighted: function (spot) {
    _collageHighlighted = spot;
    this.emitChange();
  },
  setSavedUrl: function (url) {
    savedUrl = url;
    this.emitChange();
  },
  getSavedUrl: function () {
    return savedUrl;
  },
  setGptLoaded: function (val) {
    _gptLoaded = val;
    this.emitChange();
  },
  getGptLoaded: function () {
    return _gptLoaded;
  },
  getCollageHighlighted: function () {
    return _collageHighlighted;
  },
  getSelectedObject: function () {
    return _selectedObject;
  },
  getPropertiesVisible: function () {
    return _propertiesVisible;
  },
  getPropertiesMinified: function () {
    return _propertiesMinified;
  },
  getExecuted: function () {
    return _executed;
  },
  getUnexecuted: function () {
    return _unexecuted;
  },
  getTouchupExecuted: function () {
    return _touchupexecuted;
  },
  getTouchupUnexecuted: function () {
    return _touchupunexecuted;
  },
  getPaintColor: function () {
    return _paintColor;
  },
  getPaintMode: function () {
    return _paintMode;
  },
  getPaintAlpha: function () {
    return _paintAlpha;
  },
  getPaintBlur: function () {
    return _paintBlur;
  },
  getBrushSize: function () {
    return _brushSize;
  },
  getCustomBorder: function () {
    return _customBorder;
  },
  getCustomWidth: function () {
    return _customWidth;
  },
  getCustomColor: function () {
    return _customColor;
  },
  getPropManualPosition: function () {
    return _propManualPosition;
  },
  getShowDesignPicker: function () {
    return _showDesignPicker;
  },
  getShowCollagePicker: function () {
    return _showCollagePicker;
  },
  setPropManualPosition: function (value) {
    _propManualPosition = value;
  },
  getShowPropertiesCollage: function () {
    return _showPropertiesCollage;
  },
  getSelectedActiveCollageHotspot: function () {
    return _selectedActiveColalgeHotspot;
  },
  getSelectedCollageHotspot: function () {
    return _selectedCollageHotspot;
  },
  getTouchupTool: function () {
    return _touchupTool;
  },
  getSelectedCropType: function () {
    return selectedCropType;
  },
  setSelectedCropType: function (item) {
    selectedCropType = item;
  },
  getColorSelectorOpen: function () {
    return _colorSelectorOpen;
  },
  setColorSelectorOpen: function (value) {
    _colorSelectorOpen = value;
  },
  getColorPickerCallback: function () {
    return _colorPickerCallback;
  },
  getColorPickerColorCode: function () {
    return _colorPickerColorCode;
  },
  getColorPickerOriginalColor: function () {
    return _colorPickerOriginalColor;
  },
  setColorPickerColorCode: function (color) {
    _colorPickerColorCode = color;
    EditorStore.emitChange();
  },
  getShowColorPicker: function () {
    return _showColorPicker;
  },
  getBlankColor: function () {
    return _blankColor;
  },
  setShowColorPicker: function (val) {
    _showColorPicker = val;
    EditorStore.emitChange();
  },
  isElectron: function () {
    if (process.versions.hasOwnProperty("electron")) {
      return true;
    }
    return false;
  },
  getFontSize: function () {
    var object = _selectedObject;
    var fs;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.style) {
      if (Lockr.get("font")) {
        var style = Lockr.get("font");
        if (style._fontSize) {
          fs = style._fontSize;
          if (typeof fs.replace === "function") {
            fs = fs.replace("px", "");
            fs = parseInt(fs, 10);
          }
          return fs;
        }
      }

      return null;
    }
    fs = object.style.fontSize;
    if (typeof fs.replace === "function") {
      fs = fs.replace("px", "");
      fs = parseInt(fs, 10);
    }
    return fs;
  },
  getLetterSpacing: function () {
    var object = _selectedObject;
    var fs;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.style) {
      if (Lockr.get("font")) {
        var style = Lockr.get("font");
        if (style._letterSpacing) {
          fs = style._letterSpacing;
          if (typeof fs.replace === "function") {
            fs = fs.replace("px", "");
            fs = parseInt(fs, 10);
          }
          return fs;
        }
      }

      return null;
    }
    fs = object.style.letterSpacing;
    if (typeof fs.replace === "function") {
      fs = fs.replace("px", "");
      fs = parseInt(fs, 10);
    }

    return fs;
  },
  getLineHeight: function () {
    var object = _selectedObject;
    var fs;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.style) {
      if (Lockr.get("font")) {
        var style = Lockr.get("font");
        if (style._lineHeight) {
          fs = style._lineHeight;
          if (fs.replace && typeof fs.replace === "function") {
            fs = fs.replace("px", "");
            fs = parseInt(fs, 10);
          }
          return fs;
        }
      }
      return 12;
    }
    fs = object.style.lineHeight || 12;
    if (fs && fs.replace && typeof fs.replace === "function") {
      fs = fs.replace("px", "");
      fs = parseInt(fs, 10);
    }
    return fs;
  },
  getFont: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.style) {
      if (Lockr.get("font")) {
        var style = Lockr.get("font");
        if (style._fontFamily) {
          return style._fontFamily;
        }
      }
      return "Impact";
    }
    return object.style.fontFamily;
  },
  getGlowSliderVal: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.glowSliderVal) return 2;
    return object.glowSliderVal;
  },
  getOutlineSliderVal: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.style) return 2;
    return object.style.strokeThickness;
  },
  getTextCurve: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.curve) return 0;
    return object.curve;
  },
  getBackgroundColor: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    return object?.backgroundColor || 0xffffff;
  },
  getGlowColor: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (object) return object.glowColor;
    else return 0xff00ff;
  },
  getOutlineColor: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    const oldStyle = Lockr.get("font");
    let outlineColor = null;
    if (oldStyle) {
      outlineColor = oldStyle._stroke;
    } else if (object && object.style)
      outlineColor = `#${decimalToHex(object.outlineColor)}`;
    else outlineColor = `#${decimalToHex(0x000000)}`;
    return outlineColor;
  },
  getDropShadowColor: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.dropShadowColor) return 0;
    return object.dropShadowColor;
  },
  getDropShadowDistance: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.dropShadowDistance) return 5;
    return object.dropShadowDistance;
  },
  getDropShadowAlpha: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.dropShadowAlpha) return 0.5;
    return object.dropShadowAlpha;
  },
  getDropShadowBlur: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.dropShadowBlur) return 0.5;
    return object.dropShadowBlur;
  },
  getDropShadowAngle: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.dropShadowRotation) return 45;
    return object.dropShadowRotation;
  },
  getFontColor: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.style) {
      if (Lockr.get("font")) {
        var style = Lockr.get("font");
        if (style._fill) {
          return style._fill;
        }
      }
      return null;
    }
    return object.style.fill;
  },
  getText: function () {
    var object = _selectedObject;
    if (_selectedObject) {
      if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
        object = window.editor.getStickerById(_selectedObject.childId);
      }
    }
    if (!object || !object.text) return "";
    if (object.text === " ") return "";
    if (object.textVal) return object.textVal;
    return object.text;
  },
  getLocalFonts: function () {
    return []; //window.LocalFonts;
  },
  getCutoutMode: function () {
    return _cutoutMode;
  },
  getCutoutView: function () {
    return _cutoutView;
  },
  getEditorMode: function () {
    return _editorMode;
  },
  getShowDoubleArrow: function () {
    return _showDoubleArrow;
  },
  setShowDoubleArrow: function (value) {
    _showDoubleArrow = value;
    if (value) {
      setTimeout(function () {
        EditorStore.setShowDoubleArrow(false);
        EditorStore.emitChange();
      }, 4000);
    }
    EditorStore.emitChange();
  },
  getImageSettings: function () {
    if (_selectedObject && _selectedObject.imageSettings) {
      return _selectedObject.imageSettings;
    }
    return _imageSettings;
  },
  getGraphicImageSettings: function () {
    if (_selectedObject && _selectedObject.imageSettings) {
      return _selectedObject.imageSettings;
    }
    return _imageSettings;
  },
  getMainImageSettings: function () {
    return _imageSettings;
  },
  setImageSettings: function (settings) {
    if (_selectedObject) {
      _selectedObject.imageSettings = settings;
    }
    _imageSettings = settings;
  },
  getActiveHotspotSettings: function () {
    if (
      _selectedActiveColalgeHotspot &&
      _selectedActiveColalgeHotspot.imageSettings
    ) {
      return _selectedActiveColalgeHotspot.imageSettings;
    }
    return {
      temperature: 0,
      brightness: 0,
      contrast: 0.8,
      saturation: 0,
      hue: 0,
      red: 1,
      green: 1,
      blue: 1,
      noise: 0,
      bloom: 0,
      emboss: 0,
      triangleBlur: 0,
      denoise: 50,
      sharpen: 0,
      straighten: 0,
      blur: 0,
    };
  },
  getHotspotSettings: function () {
    if (_selectedCollageHotspot && _selectedCollageHotspot.imageSettings) {
      return _selectedCollageHotspot.imageSettings;
    }
    return {
      temperature: 0,
      brightness: 0,
      contrast: 0.8,
      saturation: 0,
      hue: 0,
      red: 1,
      green: 1,
      blue: 1,
      noise: 0,
      bloom: 0,
      emboss: 0,
      triangleBlur: 0,
      denoise: 50,
      sharpen: 0,
      straighten: 0,
      blur: 0,
    };
  },
  setActiveHotspotSettings: function (settings) {
    if (_selectedActiveColalgeHotspot) {
      _selectedActiveColalgeHotspot.imageSettings = settings;
    }
  },
  setHotspotSettings: function (settings) {
    if (_selectedCollageHotspot) {
      _selectedCollageHotspot.imageSettings = settings;
    }
  },
  getSaving: function () {
    return _saving;
  },
  showFilePicker: function () {
    return _showFilePicker;
  },
  showCropPicker: function () {
    return _showCropPicker;
  },
  showShareDialog: function () {
    return _showShareDialog;
  },
  pickerState: function () {
    if (_showFilePicker) return _pickerState;
    return null;
  },
  getFilePickerTool: function () {
    return _filePickerTool;
  },
  setFilePickerTool: function (tool) {
    _filePickerTool = tool;
  },
  showEmbed: function () {
    return _showEmbed;
  },
  showEmail: function () {
    return _showEmail;
  },
  showLink: function () {
    return _showLink;
  },
  showSpinner: function () {
    return _showSpinner;
  },
  showProjectDel: function () {
    return _showProjectDel;
  },
  getDeleteObj: function () {
    return _deleteObj;
  },
  showSticker: function () {
    return _showStickerLoadError;
  },
  showUsageLimit: function () {
    return _showUsageLimit;
  },
  showProjectLoad: function () {
    return _showProjectLoad;
  },
  showStartMenu: function () {
    return _showStart;
  },
  getSavedCloudKey: function () {
    return _savedCloudKey;
  },
  showSaveQuality: function () {
    return _showSaveQuality;
  },
  showCustomSize: function () {
    return _showCustomSize;
  },
  showCutoutInstructions: function () {
    return _showCutoutInstructions;
  },
  showCutoutStyle: function () {
    return _showCutoutStyle;
  },
  getGraphicCutoutStyle: function () {
    return _showGraphicCutoutStyle;
  },
  setGraphicCutoutStyle: function (value) {
    _showGraphicCutoutStyle = value;
    EditorStore.emitChange();
  },
  showCutoutTrace: function () {
    return _showCutoutTrace;
  },
  showCutoutRefine: function () {
    return _showCutoutRefine;
  },
  showCutoutZoom: function () {
    return _showCutoutZoom;
  },
  setShowSpinner: function (val) {
    _showSpinner = val;
    EditorStore.emitChange();
  },
  setShowProjectDel: function (val, delObj = null) {
    _showProjectDel = val;
    _deleteObj = delObj;
    EditorStore.emitChange();
  },
  setShowStickerLoadError: function (val) {
    _showStickerLoadError = val;
    EditorStore.emitChange();
  },
  setShowUsageLimit: function (val) {
    _showUsageLimit = val;
    EditorStore.emitChange();
  },
  setShowProjectLoad: function (val) {
    // _showFilePicker = false;
    _showProjectLoad = val;
    EditorStore.emitChange();
  },
  setShowCustomSize: function (val) {
    _showCustomSize = val;
    EditorStore.emitChange();
  },
  setShowDesignPicker: function (val) {
    _showDesignPicker = val;
    EditorStore.emitChange();
  },
  setShowCollagePicker: function (val) {
    _showCollagePicker = val;
    EditorStore.emitChange();
  },
  setSelectedObject: function (object) {
    _selectedObject = object;
    EditorStore.emitChange();
  },
  setSelectedCollageHotspot: function (object) {
    _selectedCollageHotspot = object;
    EditorStore.emitChange();
  },
  setCustomBorder: function (object) {
    _customBorder = object;
    if (!object) {
      _customWidth = 1;
    }
    EditorStore.emitChange();
  },
  showFeedback: function () {
    return _showFeedback;
  },
  setEditorMode: function (mode) {
    _editorMode = mode;
    EditorStore.emitChange();
  },
  setShowFilePicker: function (val) {
    _showFilePicker = val;
    EditorStore.emitChange();
  },
  setShowCropPicker: function (val) {
    _showCropPicker = val;
    EditorStore.emitChange();
  },
  getEditorType: function () {
    return _editorType;
  },
  getProjectTitle: function () {
    return _projectTitle;
  },
  setProjectTitle: function (title) {
    _projectTitle = title;
  },
  setStatusMessage: function (message) {
    _statusMessage = message;
    EditorStore.emitChange();
  },
  getStatusMessage: function () {
    return _statusMessage;
  },
  setEditorType: function (type) {
    if (type === "clear") {
      _showDesignPicker = false;
      _showStart = false;
      _showCollagePicker = false;
      _editorType = "edit";
      EditorStore.emitChange();
    } else {
      _editorType = type;
      if (type === "design") {
        _showDesignPicker = true;
        _showStart = false;
        EditorStore.emitChange();
      } else if (type === "edit") {
        _showFilePicker = false;
        _showDesignPicker = false;
        _showStart = false;
        EditorStore.emitChange();
      } else if (type === "collage") {
        _showCollagePicker = true;
        _showStart = false;
        EditorStore.emitChange();
      } else if (type === "start") {
        _showCollagePicker = false;
        _showStart = true;
        _editorType = "edit";
        EditorStore.emitChange();
      } else if (type === "eraser") {
        _editorType = "design";
        _showDesignPicker = false;
        _showStart = false;
        var p = {
          key: "Custom",
          name: "Custom",
          icon: "",
          item: {
            Name: "Custom",
            Width: 2500,
            Height: 2500,
            AllowCustom: false,
          },
        };
        window.editor.addDesign(p, () => {
          this.setShowSpinner(false);
        });

        var baseObject = window.editor.getBaseObject();
        baseObject.clear();
        baseObject.beginFill(0xffffff, 0.05);
        baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
        baseObject.endFill();
        baseObject.transparent = true;
        EditorActionCreators.ShowCutoutInstructions(true);
        EditorStore.emitChange();
      } else if (type === "sticker") {
        _editorType = "design";
        _showDesignPicker = false;
        _showStart = false;
        var p = {
          key: "Custom",
          name: "Custom",
          icon: "",
          item: {
            Name: "Custom",
            Width: 2500,
            Height: 2500,
            AllowCustom: false,
          },
        };
        window.editor.addDesign(p, () => {
          this.setShowSpinner(false);
        });
        var baseObject = window.editor.getBaseObject();
        baseObject.clear();
        baseObject.beginFill(0xffffff, 0.05);
        baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
        baseObject.endFill();
        baseObject.transparent = true;
        EditorStore.emitChange();
      }
    }
  },
  getDesignBackground: function () {
    return _designBackground;
  },
  setDesignBackground: function (background) {
    _designBackground = background;
    EditorStore.emitChange();
  },
  getBackgroundEffect: function () {
    return _bg_effect;
  },
  setBackgroundEffect: function (item) {
    _bg_effect = item;
    EditorStore.emitChange();
  },
  getCurrentEffect: function () {
    return _currentEffect;
  },
  setCurrentEffect: function (item) {
    _currentEffect = item;
    EditorStore.emitChange();
  },
  getGraphicEffect: function () {
    return _graphicEffect;
  },
  setGraphicEffect: function (item) {
    _graphicEffect = item;
    EditorStore.emitChange();
  },
  getCollageHotspotEffect: function () {
    return _collageHotspotEffect;
  },
  setCollageHotspotEffect: function (item) {
    _collageHotspotEffect = item;
    EditorStore.emitChange();
  },
  getImageSaved: function () {
    return _imageSaved;
  },
  getTouchupBrushSize: function () {
    return _touchupBrushSize;
  },
  getTouchupBrushHardness: function () {
    return _touchupBrushHardness;
  },
  getTouchupFade: function () {
    return _touchupFade;
  },
  setTouchupTool: function (tool) {
    _touchupTool = tool;
    EditorStore.emitChange();
  },
  getTouchupColor: function () {
    return _touchupColor;
  },
  getAIError: function () {
    return _aierror;
  },
  getContextMenu: function () {
    return _contextMenu;
  },
  setContextMenu: function (value) {
    _contextMenu = value;
    EditorStore.emitChange();
  },
  showDeleteAccount: function () {
    return _showDeleteAccount;
  },
});

EditorStore.dispatchToken = EditorDispatcher.register(function (action) {
  var x,
    baseObject,
    background,
    style,
    sticker,
    object,
    z,
    found,
    i,
    ar,
    f,
    size,
    spacing,
    lineHeight;
  switch (action.type) {
    case ActionTypes.EDITOR_OBJECT_SELECTED:
      _selectedObject = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_PROPERTIES_MINIFIED:
      _propertiesMinified = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_INIT_PHOTO:
      _editorType = "PHOTO";
      window.editor.initPhoto(action.value);
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_REMOVE_STICKER:
      _selectedObject = null;
      window.editor.removeSticker(action.value);
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_COPY_STICKER:
      _selectedObject = null;
      _contextMenu = false;
      window.editor.copySticker(action.value);
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_MOVE_FRONT:
      action.value.parent.setChildIndex(
        action.value,
        action.value.parent.children.length - minCount
      );
      break;
    case ActionTypes.EDITOR_MOVE_FORWARD:
      if (
        action.value.parent.children.length - minCount >
        action.value.parent.getChildIndex(action.value)
      ) {
        action.value.parent.setChildIndex(
          action.value,
          action.value.parent.getChildIndex(action.value) + 1
        );
      }
      break;
    case ActionTypes.EDITOR_MOVE_BACK:
      action.value.parent.setChildIndex(action.value, 0);
      break;
    case ActionTypes.EDITOR_MOVE_BACKWARD:
      if (action.value.parent.getChildIndex(action.value) > 0) {
        action.value.parent.setChildIndex(
          action.value,
          action.value.parent.getChildIndex(action.value) - 1
        );
      }
      break;
    case ActionTypes.PAINT_CHANGE_COLOR:
      _paintColor = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.PAINT_ALPHA:
      _paintAlpha = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.PAINT_BLUR:
      _paintBlur = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.PAINT_MODE:
      _paintMode = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.PAINT_BRUSH_SIZE:
      _brushSize = action.value;
      const bSize = parseInt(_brushSize, 10);
      const canvas = document.createElement("canvas");
      canvas.width = bSize;
      canvas.height = bSize;

      const ctx = canvas.getContext("2d");

      ctx.beginPath();
      ctx.arc(
        canvas.width / 2,
        canvas.width / 2,
        bSize / 2,
        0,
        2 * Math.PI,
        false
      );
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#FFFFFF";
      ctx.stroke();

      const brushImage = canvas.toDataURL("image/png");
      const root = document.querySelector(":root");
      root.style.setProperty(
        "--custom-cursor",
        "url(" + brushImage + "), auto"
      );
      // window.editor.getStage().interactive = true;
      // window.editor.getStage().cursor = "url(" + brushImage + "), auto";
      // window.editor.getStage().defaultCursor = "url(" + brushImage + "), auto";

      // window.editor.getBaseObject().interactive = true;
      // window.editor.getBaseObject().cursor = "url(" + brushImage + "), auto";
      // window.editor.getBaseObject().defaultCursor =
      //   "url(" + brushImage + "), auto";
      EditorStore.emitChange();
      break;
    case ActionTypes.TOUCHUP_BRUSH_SIZE:
      _touchupBrushSize = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.TOUCHUP_FADE:
      _touchupFade = action.value;

      EditorStore.emitChange();
      break;
    case ActionTypes.TOUCHUP_HARDNESS:
      _touchupBrushHardness = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.TEXT_FONT:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;
      if (action.value.value) {
        WebFont.load({
          custom: {
            families: [action.value.value],
            urls: ["/app/css/fonts.css"],
          },
          fontactive: function (familyName, fvd) {
            object.font = action.value.value;
            style = object.style;
            style.fontFamily = action.value.value;
            style.padding = Math.ceil(EditorStore.getFontSize() * 0.25);
            object.style = style;
            Lockr.set("font", style);
            if (_selectedObject) {
              if (_selectedObject.childId) {
                window.editor.resizeText(_selectedObject);
              }
              if (_selectedObject.childId) {
                sticker = window.editor.getStickerById(_selectedObject.childId);
                if (sticker && sticker.textBackground) {
                  sticker.position.x = _selectedObject.position.x;
                  sticker.position.y = _selectedObject.position.y;
                  sticker.clear();
                  sticker.beginFill(_selectedObject.backgroundColor, 1);
                  sticker.drawRect(
                    0 - _selectedObject.style.wordWrapWidth / 2,
                    0 - _selectedObject.height / 2,
                    _selectedObject.style.wordWrapWidth,
                    _selectedObject.height
                  );
                  sticker.endFill();
                }
              }
            }
            if (action.value.curve) {
              window.editor.applyCurve(object, action.value.curve);
            }
            window.editor.stickerSelector.updateSelector();
            EditorStore.emitChange();
          },
        });
      } else {
        object.font = action.value;
        style = object.style;
        style.fontFamily = action.value;
        object.style = style;
        Lockr.set("font", style);
        window.editor.stickerSelector.updateSelector();
        EditorStore.emitChange();
      }
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_FONT_COLOR:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      object.fontColor = action.value;
      style = object.style;
      style.fill = action.value;
      object.style = style;
      Lockr.set("font", style);

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      window.editor.autoSave();
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      break;
    case ActionTypes.TEXT_ALIGN:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      object.align = action.value;
      style = object.style;
      style.align = action.value;
      object.style = style;
      Lockr.set("font", style);

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (action.value === "left") {
        object.anchor.set(0, 0.5);
        object.pivot.set(object.style.wordWrapWidth / 2, 0);
      }
      if (action.value === "right") {
        object.anchor.set(1, 0.5);
        object.pivot.set(0 - object.style.wordWrapWidth / 2, 0);
      }
      if (action.value === "center") {
        object.anchor.set(0.5);
        object.pivot.set(0);
      }
      window.editor.stickerSelector.updateSelector();
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_BOLD:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;
      object.bold = !object.bold;
      style = object.style;
      style.fontWeight = object.bold ? "bold" : "normal";
      object.style = style;
      Lockr.set("font", style);
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_ITALIC:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      object.italic = !object.italic;
      style = object.style;
      style.fontStyle = object.italic ? "italic" : "normal";
      object.style = style;
      Lockr.set("font", style);

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_GLOW_COLOR:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      object.glowColor = action.value;
      if (object.filters === null) object.filters = [];
      found = false;
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.GlowFilter) {
            object.filters[z].color = action.value;
            found = true;
          }
        }
      }
      if (!found) {
        object.filters.push(
          new Filters.GlowFilter(10, 2, 0, action.value, 0.5)
        );
      }
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
    case ActionTypes.GRAPHIC_GLOW_COLOR:
      object = _selectedObject;
      if (!object) return null;
      object.glowColor = action.value;
      if (object.filters === null) object.filters = [];
      found = false;
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.GlowFilter) {
            object.filters[z].color = action.value;
            found = true;
          }
        }
      }
      if (!found) {
        object.filters.push(
          new Filters.GlowFilter(10, 2, 0, action.value, 0.5)
        );
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_GLOW_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      // object.glowSliderVal = action.value;
      if (object.filters === null) object.filters = [];
      found = false;
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.GlowFilter) {
            object.filters[z].outerStrength = action.value;
            found = true;
          }
        }
      }
      if (!found) {
        object.filters.push(
          new Filters.GlowFilter(10, action.value, 0, object.glowColor, 0.5)
        );
      }
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;
    case ActionTypes.GRAPHIC_GLOW_SLIDER_VAL:
      object = _selectedObject;
      object.glowSliderVal = action.value;
      if (object.filters === null) object.filters = [];
      found = false;
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.GlowFilter) {
            object.filters[z].outerStrength = action.value;
            found = true;
          }
        }
      }
      if (!found) {
        object.filters.push(
          new Filters.GlowFilter(10, action.value, 0, object.glowColor, 0.5)
        );
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_DROPSHADOW_DISTANCE_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      if (action.value) {
        object.dropShadowDistance = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].distance = action.value;
          }
        }
      }
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;
    case ActionTypes.GRAPHIC_DROPSHADOW_DISTANCE_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;
      if (action.value) {
        object.dropShadowDistance = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].distance = action.value;
          }
        }
      }
      // window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
      break;

    case ActionTypes.TEXT_DROPSHADOW_ALPHA_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      if (action.value) {
        object.dropShadowAlpha = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].alpha = action.value;
          }
        }
      }

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.GRAPHIC_DROPSHADOW_ALPHA_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;
      if (action.value) {
        object.dropShadowAlpha = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].alpha = action.value;
          }
        }
      }
      // window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_DROPSHADOW_ANGLE_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      if (action.value) {
        object.dropShadowRotation = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].rotation = action.value;
          }
        }
      }
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.GRAPHIC_DROPSHADOW_ANGLE_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;

      if (action.value) {
        object.dropShadowRotation = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].rotation = action.value;
          }
        }
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_DROPSHADOW_BLUR_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      if (action.value) {
        object.dropShadowBlur = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].blur = action.value;
          }
        }
      }

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.GRAPHIC_DROPSHADOW_BLUR_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;

      if (action.value) {
        object.dropShadowBlur = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].blur = action.value;
          }
        }
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_OUTLINE_SLIDER_VAL:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      style = object.style;
      if (action.value) {
        style.strokeThickness = action.value;
        Lockr.set("font", style);
      }
      object.style = style;
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;
    case ActionTypes.GLOW_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      if (object.filters === null) object.filters = [];

      if (action.value) {
        f = new Filters.GlowFilter(10, 2, 0, object.glowColor, 0.5);
        f.padding = 20;
        object.filters = [f].concat(object.filters);
      } else {
        if (object.filters !== null) {
          ar = [];
          for (z = 0; z < object.filters.length; z++) {
            if (object.filters[z] instanceof Filters.GlowFilter) {
            } else {
              ar.push(object.filters[z]);
            }
          }
          object.filters = ar;
        }
      }
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;

    case ActionTypes.GRAPHIC_GLOW_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;

      if (object.filters === null) object.filters = [];

      if (action.value) {
        f = new Filters.GlowFilter(10, 2, 0, object.glowColor, 0.5);
        object.filters = object.filters.concat([f]);
      } else {
        if (object.filters !== null) {
          ar = [];
          for (z = 0; z < object.filters.length; z++) {
            if (object.filters[z] instanceof Filters.GlowFilter) {
            } else {
              ar.push(object.filters[z]);
            }
          }
          object.filters = ar;
        }
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.OUTLINE_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      style = object.style;
      if (action.value) {
        style.strokeThickness = style.fontSize / 20;
        style.stroke = object.outlineColor;
      } else {
        style.strokeThickness = 0;
      }
      object.style = style;
      Lockr.set("font", style);
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;

    case ActionTypes.GRAPHIC_OUTLINE_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.BACKGROUND_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;
      object.backgroundEnabled = action.value;
      if (action.value) {
        const id = JSONUtils.guid();
        background = new PIXI.Graphics();
        background.beginFill(object.backgroundColor, 1);
        background.position.x = object.position.x;
        background.position.y = object.position.y;
        background.drawRect(
          0 - object.style.wordWrapWidth / 2,
          0 - object.height / 2,
          object.style.wordWrapWidth,
          object.height
        );
        background.endFill();
        background.childId = object.id;
        background.parentId = object.id;
        background.id = id;
        background.rotation = object.rotation;
        background.textBackground = true;
        window.editor.getStickerContainer().addChildAt(background, 0);
        object.childId = id;
      } else {
        if (object.textBackground) {
          window.editor.getStickerContainer().removeChild(object);
          var textObj = window.editor.getStickerById(object.childId);
          textObj.childId = null;
        } else {
          background = window.editor.getStickerById(object.childId);
          window.editor.getStickerContainer().removeChild(background);
          object.childId = null;
        }
      }
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.DROPSHADOW_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;
      if (object.filters === null) object.filters = [];

      if (action.value) {
        f = new Filters.DropShadowFilter({
          alpha: object.dropShadowAlpha,
          rotation: object.dropShadowRotation,
          distance: object.dropShadowDistance,
          color: object.dropShadowColor,
          blur: object.dropShadowBlur,
          quality: 20,
        });
        object.filters = object.filters.concat([f]);
      } else {
        if (object.filters !== null) {
          ar = [];
          for (z = 0; z < object.filters.length; z++) {
            if (object.filters[z] instanceof Filters.DropShadowFilter) {
            } else {
              ar.push(object.filters[z]);
            }
          }
          object.filters = ar;
        }
      }

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;

    case ActionTypes.GRAPHIC_DROPSHADOW_ENABLED:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;
      if (object.filters === null) object.filters = [];
      if (action.value) {
        f = new Filters.DropShadowFilter({
          alpha: object.dropShadowAlpha ? object.dropShadowAlpha : 0.5,
          rotation: object.dropShadowRotation ? object.dropShadowRotation : 45,
          distance: object.dropShadowDistance ? object.dropShadowDistance : 5,
          color: object.dropShadowColor ? object.dropShadowColor : 0,
          blur: object.dropShadowBlur ? object.dropShadowBlur : 0.5,
          quality: 20,
        });
        object.filters = object.filters.concat([f]);
      } else {
        if (object.filters !== null) {
          ar = [];
          for (z = 0; z < object.filters.length; z++) {
            if (object.filters[z] instanceof Filters.DropShadowFilter) {
            } else {
              ar.push(object.filters[z]);
            }
          }
          object.filters = ar;
        }
      }
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_OUTLINE_COLOR:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      object.outlineColor = action.value;
      style = object.style;
      style.stroke = action.value;
      object.style = style;
      Lockr.set("font", style);

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_BACKGROUND_COLOR:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;

      object.backgroundColor = action.value;

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }

      window.editor.autoSave();
      break;

    case ActionTypes.TEXT_DROPSHADOW_COLOR:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object || !object.style) return null;

      if (action.value) {
        object.dropShadowColor = action.value;
      }

      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].color = action.value;
          }
        }
      }

      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.GRAPHIC_DROPSHADOW_COLOR:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      if (!object) return null;
      if (action.value) {
        object.dropShadowColor = action.value;
      }
      if (object.filters !== null) {
        for (z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            object.filters[z].color = action.value;
          }
        }
      }
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
      break;

    case ActionTypes.TEXT_FONT_SIZE:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      size = parseInt(action.value, 10);
      if (!object || !object.style) return null;
      object.fontSize = size;
      style = object.style.clone();
      var originalWidth = style.fontSize;
      style.fontSize = size;
      style.padding = Math.ceil(action.value * 0.25);
      if (style.strokeThickness !== 0) {
        style.strokeThickness = style.strokeThickness; //object.fontSize / 20;
      }
      object.style = style;
      if (
        !action.isManual &&
        object.width > window.editor.getBaseObject().width - 40
      ) {
        style.fontSize = originalWidth;
        style.padding = Math.ceil(originalWidth * 0.25);
        if (style.strokeThickness !== 0) {
          style.strokeThickness = originalWidth / 20;
        }
        object.style = style;
      }
      if (object.width > style.wordWrapWidth) {
        style.wordWrapWidth = object.width;
        object.style = style;
      }
      object.scale.y = 1;
      object._height = 0;
      object._width = 0;
      style.letterSpacing = 0;
      object.style = style;
      object.updateText(false);
      Lockr.set("font", style);
      window.editor.stickerSelector.updateSelector();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_SPACING:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      spacing = parseInt(action.value, 10);
      if (!object || !object.style) return null;
      object.letterSpacing = spacing;
      style = object.style.clone();
      style.letterSpacing = spacing;
      object.style = style;
      if (object.width > style.wordWrapWidth) {
        style.wordWrapWidth = object.width;
        object.style = style;
      }
      object.scale.y = 1;
      object._height = 0;
      object.updateText(false);
      Lockr.set("font", style);
      window.editor.stickerSelector.updateSelector();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }

      EditorStore.emitChange();
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }

      window.editor.autoSave();
      break;
    case ActionTypes.TEXT_CURVE:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
          if (object.curveSprite) {
            window.editor.getStickerContainer().removeChild(object.curveSprite);
            object.curveSprite = null;
          } else {
            return;
          }
        }
      }
      const curve = parseInt(action.value, 10);
      window.editor.applyCurve(object, curve);
      break;
    case ActionTypes.TEXT_LINE_HEIGHT:
      object = _selectedObject;
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
        }
      }
      lineHeight = parseInt(action.value, 10);
      if (!object || !object.style) return null;
      object.lineHeight = lineHeight;
      style = object.style.clone();
      style.lineHeight = lineHeight;
      object.style = style;
      object.scale.y = 1;
      object._height = 0;
      object.updateText(false);
      Lockr.set("font", style);
      window.editor.stickerSelector.updateSelector();
      if (_selectedObject.childId) {
        sticker = window.editor.getStickerById(_selectedObject.childId);
        if (sticker && sticker.textBackground) {
          sticker.position.x = _selectedObject.position.x;
          sticker.position.y = _selectedObject.position.y;
          sticker.clear();
          sticker.beginFill(_selectedObject.backgroundColor, 1);
          sticker.drawRect(
            0 - _selectedObject.style.wordWrapWidth / 2,
            0 - _selectedObject.height / 2,
            _selectedObject.style.wordWrapWidth,
            _selectedObject.height
          );
          sticker.endFill();
        }
      }
      EditorStore.emitChange();
      window.editor.autoSave();
      break;

    case ActionTypes.TEXT_CHANGE:
      object = _selectedObject;
      const oldStyle = Lockr.get("font");
      window.editor.setOriginalText(action.value);
      if (_selectedObject) {
        if (_selectedObject.childId && !_selectedObject.backgroundEnabled) {
          object = window.editor.getStickerById(_selectedObject.childId);
          object.textVal = action.value;
          if (action.value.endsWith("\n")) {
            object.text = action.value.replace(/\n$/, "");
          } else {
            object.text = action.value;
          }
          window.editor.resizeText(_selectedObject);
        } else {
          if (_selectedObject instanceof PIXI.Text) {
            object.textVal = action.value;
            object.bold = oldStyle._fontWeight === "bold" ? true : false;
            object.italic = oldStyle._fontStyle === "italic" ? true : false;
            object.strokeThickness =
              oldStyle._strokeThickness !== 0 ? oldStyle._strokeThickness : 0;
            object.stroke = oldStyle.outlineColor;
            if (action.value.endsWith("\n")) {
              object.text = action.value.replace(/\n$/, "");
            } else {
              object.text = action.value;
            }

            // var longestWord = 0;
            // var words = object.text.split("\n");
            // for (let i = 0; i < words.length; i++) {
            //   var wordLength = object.context.measureText(words[i]).width;
            //   if (wordLength > longestWord) longestWord = wordLength;
            // }
            style = object.style;
            style._wordWrap = true;
            // if (longestWord > style.wordWrapWidth) {
            //   style.wordWrapWidth = longestWord;
            // }

            if (
              style.wordWrapWidth >
              window.editor.getBaseObject().width * 0.8
            ) {
              style.wordWrapWidth = window.editor.getBaseObject().width * 0.8;
            }
            object._height = 0;
            style.letterSpacing = 0;
            object.style = style;
            if (_selectedObject.childId) {
              sticker = window.editor.getStickerById(_selectedObject.childId);
              if (sticker && sticker.textBackground) {
                sticker.position.x = _selectedObject.position.x;
                sticker.position.y = _selectedObject.position.y;
                sticker.clear();
                sticker.beginFill(_selectedObject.backgroundColor, 1);
                sticker.drawRect(
                  0 - _selectedObject.style.wordWrapWidth / 2,
                  0 - _selectedObject.height / 2,
                  _selectedObject.style.wordWrapWidth,
                  _selectedObject.height
                );
                sticker.endFill();
              }
            }
          } else {
            window.editor.addText(action.value, null);
          }
        }
      } else {
        window.editor.addText(
          action.value,
          null,
          null,
          null,
          null,
          null,
          function (txt) {
            txt.style = new PIXI.TextStyle({
              align: oldStyle?._align ? oldStyle?._align : "left",
              breakWords: oldStyle?._breakWords ? oldStyle?._breakWords : false,
              dropShadow: oldStyle?._dropShadow ? oldStyle?._dropShadow : false,
              dropShadowAlpha: oldStyle?._align.dropShadowAlpha
                ? oldStyle?._align.dropShadowAlpha
                : 1,
              dropShadowAngle: oldStyle?._dropShadowAngle
                ? oldStyle?._dropShadowAngle
                : Math.PI / 6,
              dropShadowBlur: oldStyle?._dropShadowBlur
                ? oldStyle?._dropShadowBlur
                : 0,
              dropShadowColor: oldStyle?._dropShadowColor
                ? oldStyle?._dropShadowColor
                : "black",
              dropShadowDistance: oldStyle?._dropShadowDistance
                ? oldStyle?._dropShadowDistance
                : 5,
              fill: oldStyle?._fill ? oldStyle?._fill : "black",
              fillGradientType: oldStyle?._fillGradientType
                ? oldStyle?._fillGradientType
                : PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
              fillGradientStops: oldStyle?._fillGradientStops,
              fontFamily: oldStyle?._fontFamily
                ? oldStyle?._fontFamily
                : "Arial",
              fontSize: Lockr.get("fontSize"),
              fontStyle: oldStyle?._fontStyle ? oldStyle?._fontStyle : "normal",
              fontVariant: oldStyle?._fontVariant
                ? oldStyle?._fontVariant
                : "normal",
              fontWeight: oldStyle?._fontWeight
                ? oldStyle?._fontWeight
                : "normal",
              letterSpacing: oldStyle?._letterSpacing
                ? oldStyle?._letterSpacing
                : 0,
              lineHeight:
                oldStyle?._lineHeight && oldStyle?._lineHeight > 0
                  ? oldStyle?._lineHeight
                  : null,
              lineJoin: "round",
              miterLimit: oldStyle?._miterLimit ? oldStyle?._miterLimit : 10,
              padding: oldStyle?._padding ? oldStyle?._padding : 0,
              stroke: oldStyle?._stroke ? oldStyle?._stroke : "black",
              strokeThickness: oldStyle?._strokeThickness
                ? oldStyle?._strokeThickness
                : 0,
              textBaseline: oldStyle?._textBaseline
                ? oldStyle?._textBaseline
                : "alphabetic",
              trim: oldStyle?._trim ? oldStyle?._trim : false,
              whiteSpace: oldStyle?._whiteSpace ? oldStyle?._whiteSpace : "pre",
              wordWrap: oldStyle?._wordWrap ? oldStyle?._wordWrap : false,
              wordWrapWidth: window.editor.getBaseObject().width * 0.8,
              leading: oldStyle?._leading ? oldStyle?._leading : 0,
            });
          },
          null
        );
      }
      if (!object || !object.style) return null;
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      if (object && object.curve) {
        window.editor.applyCurve(object, object.curve);
      }
      window.editor.autoSave();
      break;
    case ActionTypes.LOCAL_FONTS:
      EditorStore.emitChange();
      break;
    case ActionTypes.CUTOUT_SAVE:
      _showSpinner = true;
      _cutoutMode = "crop";
      EditorStore.setStatusMessage("saving cutout ...");
      PhotoAsset.createAsset(action.item, function (url, finalUrl) {
        EditorStore.setStatusMessage("cutout saved...");
        if (_showGraphicCutoutStyle) {
          if (!_selectedObject) {
            _selectedObject = window.editor.getTempSelectedObject();
          }
          _selectedObject.sourceUrl = finalUrl;
          _selectedObject.svg = true;
          const img = new Image();
          img.onload = function () {
            window.editor.redrawSvg(
              _selectedObject,
              _selectedObject.width,
              (_selectedObject.width * img.naturalHeight) / img.naturalWidth,
              () => {
                EditorStore.setShowSpinner(false);
              }
            );
          };
          img.src = finalUrl;
        } else {
          window.editor.addSticker(finalUrl, null, null, function (sticker) {
            sticker.texture.assetUrl = finalUrl;
            EditorStore.setShowSpinner(false);
          });
        }
      });
      window.editor.autoSave();
      break;
    case ActionTypes.CUTOUT_MODE:
      _cutoutMode = action.value;
      if (action.value === "point") {
        _showCutoutInstructions = false;
        _showCutoutZoom = false;
        _showCutoutTrace = true;
        window.editor.cropCutoutCancel();
        window.editor.getCutoutStage().defaultCursor = "crosshair";
      }
      if (action.value === "move") {
        _showCutoutInstructions = false;
        _showCutoutZoom = false;
        _showCutoutTrace = false;
        _showCutoutRefine = true;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.CUTOUT_VIEW:
      _cutoutView = action.value;
      EditorStore.emitChange();
      if (action.value === "show") {
        window.editor.cutoutShow();
      } else {
        window.editor.cutoutPreview();
      }
      break;
    case ActionTypes.CUSTOM_BORDER_COLOR:
      _customColor = parseInt(action.data.replace("#", "0x"), 16);
      _customBorder = true;
      window.editor.customBorder(_customWidth, _customColor);
      EditorStore.emitChange();
      break;
    case ActionTypes.CUSTOM_BORDER_WIDTH:
      _customWidth = parseInt(action.data, 10);
      _customBorder = true;
      window.editor.getBorderContainer().removeChildren();
      window.editor.customBorder(_customWidth, _customColor);
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_MODE:
      _editorMode = action.value;
      if (action.value === "crop") {
        if (!selectedCropType) {
          selectedCropType = AssetStore.get().Crop.Categories[0].items[0];
        }
        if (LeftMenuStore.get() === "edit_graphic") {
          window.editor.initGraphicCrop(EditorStore.getSelectedObject());
        } else {
          window.editor.initCrop();
        }
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.EDITOR_IMAGE_SETTINGS:
      if (action.value && action.value.contrast) {
        if (_selectedObject) {
          _selectedObject.imageSettings = action.value;
          EditorStore.emitChange();
          window.editor.applyStickerEffect({ Effect: [] });
        } else {
          _imageSettings = action.value;
          EditorStore.emitChange();
          window.editor.realApplyEffect({ Effect: [] });
        }
      }
      break;
    case ActionTypes.EDITOR_HOTSPOT_SETTINGS:
      if (_selectedActiveColalgeHotspot) {
        _selectedActiveColalgeHotspot.imageSettings = action.value;
        EditorStore.emitChange();
        window.editor.applyHotspotEffect({ Effect: [] });
      }
      break;
    case ActionTypes.SAVE:
      if (action.extension === "pizap") {
        _showSaveQuality = false;
        _imageSaved = true;
        EditorStore.emitChange();
        var proj = window.editor.export(action);
        var b = new Blob([JSON.stringify(proj, null, 2)], {
          type: "application/json",
        });

        SaveAs.saveAs(b, action.name + "." + action.extension);
        return;
      }
      _showSaveQuality = false;
      _showSpinner = true;
      _imageSaved = true;
      EditorStore.emitChange();

      //_showShareDialog = true;
      _saving = true;
      var renderer = window.editor.getRenderer();
      var stage = window.editor.getStage();
      stage.scale.x = 1;
      stage.position.x = 0;
      stage.position.y = 0;
      var rendererSize = {
        width: renderer.view.width,
        height: renderer.view.height,
      };
      var originalScale = stage.scale.x;
      size = window.editor.getOriginalSize();
      if (action.width && action.height) {
        size = { width: action.width, height: action.height };
      }
      var saveSize = { width: size.width, height: size.height };
      var tmpScale;
      if (size.width + size.height > 8360) {
        tmpScale = 8360 / (size.height + size.width);
        saveSize = {
          width: size.width * tmpScale,
          height: size.height * tmpScale,
        };
      }
      if (action.value === "standard" && size.width + size.height > 2048) {
        tmpScale = 2048 / (size.height + size.width);
        saveSize = {
          width: size.width * tmpScale,
          height: size.height * tmpScale,
        };
      }
      var offsets = utils.scaleSave(
        saveSize.width,
        saveSize.height,
        size.width,
        size.height
      );

      stage.scale.set(offsets.width / rendererSize.width);
      renderer.resize(
        Math.ceil(
          (rendererSize.width / window.editor.getStartingDevicePixelRatio()) *
            stage.scale.x
        ),
        Math.ceil(
          (rendererSize.height / window.editor.getStartingDevicePixelRatio()) *
            stage.scale.x
        )
      );

      var isSafari =
        Object.prototype.toString
          .call(window.HTMLElement)
          .indexOf("Constructor") > 0 ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || window.safari.pushNotification);
      if (
        isSafari &&
        EditorStore.getEditorType() !== "collage" &&
        EditorStore.getEditorType() !== "design"
      ) {
        stage.scale.y *= -1;
        stage.position.y = 0 - stage.height;
      }
      if (window.editor.getBaseObject().transparent) {
        window.editor.getBaseObject().alpha = 0;
      }
      baseObject = window.editor.getBaseObject();
      for (x = baseObject.children.length - 1; x >= 0; x--) {
        if (baseObject.children[x].blueBorder) {
          baseObject.removeChild(baseObject.children[x]);
        }
      }

      renderer.render(stage);
      window.editor.prepareSave(function () {
        renderer.render(stage);
        renderer.view.toBlob(
          function (blob) {
            SaveAs.saveAs(blob, action.name + "." + action.extension);
            renderer.resize(
              rendererSize.width / window.editor.getStartingDevicePixelRatio(),
              rendererSize.height / window.editor.getStartingDevicePixelRatio()
            );
            stage.scale.set(originalScale);
            window.editor.postSave();

            _saving = false;
            _showSpinner = false;
            if (!EditorStore.isElectron()) _showShareDialog = true;

            EditorStore.emitChange();
            if (
              isSafari &&
              EditorStore.getEditorType() !== "collage" &&
              EditorStore.getEditorType() !== "design"
            ) {
              //stage.scale.y *= -1;
              stage.position.y = 0;
            }
          },
          action.extension === "jpg" ? "image/jpeg" : "image/png",
          1.0
        );
        EditorStore.emitChange();
      }, offsets);

      break;
    case ActionTypes.LEFT_MENU_SELECTED:
      var menu = action.menu;
      if (menu === "main") {
        _editorMode = "view";
        window.editor.cropGraphicCancel();
        window.editor.cropCancel();
        EditorStore.emitChange();
      } else {
        window.editor.cropCancel();
        window.editor.cropGraphicCancel();
        _editorMode = "view";
        EditorStore.emitChange();
      }

      if (menu === "border_custom") {
        if (EditorStore.getCustomBorder() !== true) {
          _customWidth = 10;
          _customBorder = true;
          window.editor.getBorderContainer().removeChildren();
          window.editor.customBorder(_customWidth, _customColor);
          EditorStore.emitChange();
        }
      }
      if (menu !== "collagehotspot") {
        _selectedActiveColalgeHotspot = null;
        baseObject = window.editor.getBaseObject();
        if (baseObject) {
          for (x = baseObject.children.length - 1; x >= 0; x--) {
            if (baseObject.children[x].blueBorder) {
              baseObject.removeChild(baseObject.children[x]);
            }
          }
        }
        EditorStore.emitChange();
      }
      break;
    case ActionTypes.SHOW_FILE_PICKER:
      _showFilePicker = action.value;
      _showCutoutInstructions = false;
      _showCutoutStyle = false;
      _showStart = false;
      if (action.value === false) {
        _pickerState = null;
      }
      if (action.tool) {
        _filePickerTool = action.tool;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CROP_PICKER:
      _showCropPicker = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_SHARE_DIALOG:
      _showSaveQuality = false;
      _showShareDialog = action.data;
      if (!_showShareDialog) {
        _showFeedback = true;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_FEEDBACK:
      _showFeedback = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_DESIGN_PICKER:
      _showDesignPicker = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_COLLAGE_PICKER:
      _showCollagePicker = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_SAVE_QUALITY:
      _showSaveQuality = action.data;
      _showShareDialog = false;
      if (!_showSaveQuality) {
        _showFeedback = true;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CUTOUT_INSTRUCTIONS:
      _showFilePicker = false;
      _pickerState = null;
      _showCutoutInstructions = action.data;
      _cutoutMode = "crop";
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CUTOUT_STYLE:
      _showCutoutStyle = action.data;
      if (action.data) {
        _showCutoutInstructions = false;
        _showCutoutZoom = false;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_GRAPHIC_CUTOUT_STYLE:
      _showGraphicCutoutStyle = action.data;
      _showCutoutStyle = action.data;
      if (action.data) {
        _showCutoutInstructions = false;
        _showCutoutZoom = false;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CUSTOM_SIZE:
      _showCustomSize = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_PROPERTIES_COLLAGE:
      _showPropertiesCollage = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.COLLAGE_HOTSPOT_SELECTED:
      _selectedCollageHotspot = action.hotspot;
      EditorStore.emitChange();
      break;
    case ActionTypes.COLLAGE_HOTSPOT_ACTIVE_SELECTED:
      _selectedActiveColalgeHotspot = action.hotspot;
      baseObject = window.editor.getBaseObject();
      window.editor.showOutline(false);

      for (x = baseObject.children.length - 1; x >= 0; x--) {
        if (baseObject?.children[x].blueBorder) {
          baseObject.removeChild(baseObject.children[x]);
        }
      }
      if (
        _selectedActiveColalgeHotspot &&
        _selectedActiveColalgeHotspot.mask instanceof PIXI.Graphics
      ) {
        var border = new PIXI.Graphics();
        border.lineStyle(4, 0x006dff, 1);
        if (_selectedActiveColalgeHotspot !== null) {
          if (_selectedActiveColalgeHotspot.parent.isRectangle) {
            var rect = _selectedActiveColalgeHotspot.mask.getBounds();
            border.drawRoundedRect(
              rect.x,
              rect.y,
              rect.width,
              rect.height,
              parseInt(baseObject.corner, 10) + 1
            );
          } else {
            border.drawPolygon(
              _selectedActiveColalgeHotspot.mask.currentPath.shape.points
            );
          }
          border.blueBorder = true;
          baseObject.addChild(border);
        }
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CUTOUT_TRACE:
      _showCutoutTrace = action.data;
      if (action.data) {
        _showCutoutStyle = false;
        _showCutoutInstructions = false;
        _showCutoutZoom = false;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CUTOUT_REFINE:
      _showCutoutRefine = action.data;
      if (action.data) {
        _showCutoutTrace = false;
        _showCutoutStyle = false;
        _showCutoutInstructions = false;
        _showCutoutZoom = false;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CUTOUT_ZOOM:
      _showCutoutZoom = action.data;
      if (action.data) {
        _showCutoutInstructions = false;
        _showCutoutStyle = false;
      }
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_EMBED:
      _showEmbed = action.data;
      _savedCloudKey = action.key;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_EMAIL:
      _showEmail = action.data;
      _savedCloudKey = action.key;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_SPINNER:
      _showSpinner = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_STICKER_ERROR:
      _showStickerLoadError = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_USAGE_LIMIT:
      _showUsageLimit = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_LINK:
      _showLink = action.data;
      _savedCloudKey = action.key;
      EditorStore.emitChange();
      break;
    case ActionTypes.PICKER_STATE:
      _showFilePicker = true;
      _pickerState = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.TOUCHUP_TOOL:
      _touchupTool = action.data;
      _touchupColor = null;
      _touchupBrushSize = action.data.item.brushSize;
      _touchupBrushHardness = action.data.item.brushBlur;
      if (action.data.item.fade) {
        _touchupFade = action.data.item.fade;
      }
      if (_touchupTool.colors && _touchupTool.colors.length > 0)
        _touchupColor = _touchupTool.colors[0];
      else _touchupColor = "#FFFFFF";
      EditorStore.emitChange();
      break;
    case ActionTypes.TOUCHUP_COLOR:
      _touchupColor = action.value;

      EditorStore.emitChange();
      break;
    case ActionTypes.PROJECT_TITLE_CHANGE:
      _projectTitle = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.PROJECT_SAVE:
      _statusMessage = "saving ...";
      EditorStore.emitChange();
      let actionsCompleted = 0;
      const project = window.editor.export({
        name: "pizap",
        extension: "pizap",
      });
      window.editor.getThumbnail((blobThumb, thumbWidth, thumbHeight) => {
        fetch(
          `${
            process.env.REACT_APP_SERVER_API
          }/projects/createThumb/${EditorStore.getProjectId()}`,
          {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          }
        )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          return response.json();
        })
        .then((thumbpolicy) => {
          if (!thumbpolicy || !thumbpolicy.policy) {
            throw new Error("Invalid thumbnail policy response");
          }
          // Store thumbnail in bucket
          var fdThumb = new FormData();
          fdThumb.append("x-amz-storage-class", "REDUCED_REDUNDANCY");
          fdThumb.append("policy", thumbpolicy.policy);
          fdThumb.append("signature", thumbpolicy.signature);
          fdThumb.append("acl", "public-read");
          fdThumb.append("key", thumbpolicy.filename);
          fdThumb.append("AWSAccessKeyId", thumbpolicy.access_key);
          fdThumb.append("Content-Type", "image/jpeg");
          fdThumb.append("success_action_status", "201");
          fdThumb.append("file", blobThumb, thumbpolicy.filename);
          var xhrThumb = new XMLHttpRequest();
          xhrThumb.addEventListener(
            "load",
            function (evt) {
              // thumbnail created
              actionsCompleted++;
              if (action.data && actionsCompleted === 2)
                window.location.href = action.data;
            },
            false
          );
          xhrThumb.addEventListener(
            "error",
            function (evt) {
              console.log("thumb upload Failed");
            },
            false
          );
          xhrThumb.addEventListener(
            "abort",
            function (evt) {
              console.log("thumb upload cancelled");
            },
            false
          );
          xhrThumb.open(
            "POST",
            "https://s3-us-west-1.amazonaws.com/thumbnailsw.pizap.com/",
            true
          ); // MUST BE LAST LINE BEFORE YOU SEND
          xhrThumb.send(fdThumb);

          // --- START PREVIEW IMAGE CODE ---
          const currentUser = UserStore.getUser();
          const isSpecialUser = currentUser.FBUserId === "503391935";

          if (isSpecialUser) {
            console.log("PROJECT_SAVE: Starting Special Preview Thumbnail Processing using getPreview");
            window.editor.getPreview((blobPreview, previewWidth, previewHeight) => {
              console.log("PROJECT_SAVE: Preview blob generated, dimensions:", { previewWidth, previewHeight, size: blobPreview.size });

              // Fetch preview policy for project thumbnail upload
              fetch(`${process.env.REACT_APP_SERVER_API}/projects/createPreview/${EditorStore.getProjectId()}`, {
                method: "GET",
                headers: new Headers({
                  Authorization: "Bearer " + UserStore.getAccessToken(),
                  "Content-Type": "application/json",
                }),
              })
                .then(response => {
                  if (!response.ok) {
                    throw new Error("Failed to fetch preview policy");
                  }
                  return response.json();
                })
                .then(previewPolicy => {
                  console.log("PROJECT_SAVE: Preview policy received:", previewPolicy);
                  // Build FormData for preview upload
                  const fdPreview = new FormData();
                  fdPreview.append("x-amz-storage-class", "REDUCED_REDUNDANCY");
                  fdPreview.append("policy", previewPolicy.policy);
                  fdPreview.append("signature", previewPolicy.signature);
                  fdPreview.append("acl", "public-read");
                  fdPreview.append("key", previewPolicy.filename);
                  fdPreview.append("AWSAccessKeyId", previewPolicy.access_key);
                  fdPreview.append("Content-Type", "image/jpeg");
                  fdPreview.append("success_action_status", "201");
                  fdPreview.append("file", blobPreview, previewPolicy.filename);

                  console.log("PROJECT_SAVE: Uploading preview thumbnail to:", "https://s3-us-west-1.amazonaws.com/previews.pizap.com/");
                  const xhrPreview = new XMLHttpRequest();
                  xhrPreview.addEventListener("load", function (evt) {
                    console.log("PROJECT_SAVE: Preview thumbnail upload complete");
                  });
                  xhrPreview.addEventListener("error", function (evt) {
                    console.error("PROJECT_SAVE: Preview thumbnail upload failed");
                  });
                  xhrPreview.open("POST", "https://s3-us-west-1.amazonaws.com/previews.pizap.com/", true);
                  xhrPreview.send(fdPreview);
                })
                .catch(err => {
                  console.error("PROJECT_SAVE: Error uploading preview thumbnail:", err);
                });
            });
          }
          // --- End Special Preview Thumbnail Processing for Project Thumbnail ---


        })
        .catch((err) => {
          console.log("Error fetching thumbnail policy:", err);
        });
        //Store project record for user
        fetch(`${process.env.REACT_APP_SERVER_API}/projects`, {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            imageName: EditorStore.getProjectId(),
            thumbWidth: thumbWidth,
            thumbHeight: thumbHeight,
            imageSize: 0,
            imageWidth: project ? project.renderer.width : 0,
            imageHeight: project ? project.renderer.height : 0,
            project: project,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((newProject) => {
            EditorStore.setSavedUrl(newProject.src);
            _statusMessage = "save complete";
            setTimeout(() => {
              if (_statusMessage === "save complete")
                EditorStore.setStatusMessage("");
              EditorStore.emitChange();
            }, 1000);
            actionsCompleted++;
            if (action.data && actionsCompleted === 2)
              window.location.href = action.data;
            EditorStore.emitChange();
          })
          .catch((e) => console.log(e));
      });
      // _showSaveQuality = false;
      _imageSaved = true;
      EditorStore.emitChange();

      break;
    case ActionTypes.COLOR_PICKER_PREVIEW:
      _showColorPicker = _colorPickerCallback !== action.colorCode;
      if (_showColorPicker) {
        _colorPickerOriginalColor = action.colorCode;
      }
      _colorPickerCallback = action.callback;
      _colorPickerColorCode = action.colorCode;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_COLOR_PICKER:
      _showColorPicker = action.value;
      EditorStore.emitChange();
      break;
    case ActionTypes.PRINT:
      _showSpinner = true;
      EditorStore.emitChange();

      window.editor.pizapSave(function (cloudUrl) {
        window.location.href = "/print/index.html?url=" + cloudUrl;
      }, true);
      break;
    case ActionTypes.REMOVE_BACKGROUND:
      _showGraphicCutoutStyle = false;
      EditorStore.emitChange();
      if (action.data && action.data.startsWith("http")) {
        fetch(action.data)
          .then(function (response) {
            return response.blob();
          })
          .then(function (blob) {
            window.editor.removeBackground(blob);
          });
      } else {
        window.editor.removeBackground(dataURItoBlob(action.data));
      }
      break;
    case ActionTypes.UPDATE_BLACK_COLOR:
      _blankColor = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_DELETE_ACCOUNT:
      _showDeleteAccount = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_AI_ERROR:
      _aierror = action.data;
      EditorStore.emitChange();
      break;
    case ActionTypes.SHOW_CONTEXT_MENU:
      _contextMenu = action.data;
      EditorStore.emitChange();
      break;
    default:
      break;
  }
});

export default EditorStore;
