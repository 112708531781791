import * as PIXI from "pixi.js";
import DenoiseFilter from "../filters/DenoiseFilter";
import Dexie from "dexie";
import CropSelector from "./CropSelector";
import CropCutoutSelector from "./CropCutoutSelector";
import StickerCutoutSelector from "./StickerCutoutSelector";
import UserActionCreators from "../actions/UserActionCreators";
import ProjectAsset from "./ProjectAsset";
import EXIF from "exif-js";
import firstBy from "thenby";
import JSONUtils from "../utils/JSONUtils";
import Lockr from "lockr";
import WebFont from "webfontloader";
import SVGPath from "../utils/SVGPath";
import EditorStore from "../stores/EditorStore";
import LeftMenuStore from "../stores/LeftMenuStore";
import UserStore from "../stores/UserStore";
import AssetStore from "../stores/AssetStore";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
const Filters = require("pixi-filters");
import AddCollageIcon from "../images/editor/add_collage_icon.png";
import AddCollageIconMouseOver from "../images/editor/add_collage_icon_mouseover.png";
import RotateControlImg from "../images/editor/rotate_control.png";
import WhiteCircleImg from "../images/editor/white_circle.png";
import WhitePillImg from "../images/editor/white_pill.png";
import WhitePill2Img from "../images/editor/white_pill_2.png";
import { checkImageTransparency } from "../utils/utils";

var stage;
var cutoutStage = new PIXI.Container();
var baseObject;
var imgObject;
var originalSize;
var baseOffsets;
var cutoutSize;
var renderer;
var cutoutRenderer;
var stickerContainer;
var borderContainer;
var effectBorderContainer;
var memeContainer;
var backgroundContainer;
var selectorContainer;
var collageBackgroundContainer;
var paintContainer;
var cutoutContainer;
var tempSelObject;
var cutoutMask;
var collageSpotSelected;
var cropCutoutContainer;
var topMemeText;
var topMemeSize = 50;
var topMemeColor = "white";
var bottomMemeText;
var bottomMemeSize = 50;
var bottomMemeColor = "white";
var startingDevicePixelRatio = window.devicePixelRatio;
var memeWordWrapWidth;
var selectedObject;
var cropCutoutObject;
var origCutoutScale;
var origCutoutStageScale;
var tmpFilters;
var lastFilter;
var isTransparent = false;
var vertAlign = null;
var horizontalAlign = null;
var gridLines = null;
var straightenGrid = null;
var resizeTimer;
var autoSaveTimer;
var loaderCount = 0;
var _disableSave = false;
var processGoogle = false;
let colorWindowShow = false;
let originalText;
const canvasHeightOffset = 56;
const canvasWidthOffset = 56;

const params = new URLSearchParams(window.location.search);
const imageUrl = params.get("img");
const stickerUrl = params.get("sticker");


let isDragging = false;
let deltaSize = {
  width: 0,
  height: 0,
};
let oldSize = {
  width: 0,
  height: 0,
};
let initialSize = {
  width: 0,
  height: 0,
};
let rotateIconPos = "middleTop";

window.editor = {
  setProcessGoogle: function (val) {
    processGoogle = val;
  },
  googleLogin: function (googleUser) {
    if (processGoogle) {
      processGoogle = false;
      var auth = googleUser.getAuthResponse();
      var myData = JSON.stringify({ googleToken: auth.id_token });
      UserActionCreators.logGoogleIn(myData);
    }
  },
  saveProject: function (navigateLocation) {
    EditorStore.setStatusMessage("Saving ...");
    EditorStore.emitChange();
    UserActionCreators.saveProject(navigateLocation);
  },
  autoSave: function () {
    if (
      EditorStore.getEditorMode() !== "cutout" &&
      EditorStore.getEditorMode() !== "paint" &&
      LeftMenuStore.get() !== "cutout"
    ) {
      window.clearTimeout(autoSaveTimer);
      if (_disableSave === true) return;
      autoSaveTimer = setTimeout(function () {
        window.editor.saveProject();
      }, 3000);
    }
  },
  import: async function (data) {
    EditorStore.setProjectText(data);
    _disableSave = true;
    var self = this;
    const project = JSON.parse(data);
    var newScale = 1;
    if (baseObject != null) {
      var newSize = window.editor.scaleToFit(
        baseObject.width,
        baseObject.height,
        project.renderer.width,
        project.renderer.height
      );
      newScale = newSize.width / project.stage.children[0].width;
      self.setupProjectItems(project, newScale, true);
      return;
    }
    if (project.createdBy !== UserStore.getUser().UserName) {
      project.createdBy = UserStore.getUser().UserName;
      project.id = JSONUtils.guid();
    }
    EditorStore.setShowProjectLoad(true);
    EditorStore.setEditorType(project.mode);
    if (project.title) EditorStore.setProjectTitle(project.title);
    if (project.mode === "edit" || project.mode === "touchup") {
      this.initPhoto(project.stage.children[0].dataUrl, function () {
        renderer.render(stage);

        baseObject.rotate = project.stage.children[0].rotate;
        if (project.stage.children[0].textureFrame) {
          baseObject.texture.frame = project.stage.children[0].textureFrame;
          baseObject.texture._updateUvs();
          var rect = project.stage.children[0].textureFrame;
          var content = document.getElementById("container");
          var canvasWidth = content.clientWidth - 374;
          var canvasHeight = content.clientHeight - 46;
          var offsets = window.editor.scaleToFit(
            canvasWidth - canvasWidthOffset,
            canvasHeight - canvasHeightOffset,
            rect.width,
            rect.height
          );
          baseObject.scale.set(offsets.width / baseObject.texture.width);
          originalSize = {
            width: baseObject.texture.width,
            height: baseObject.texture.height,
          };
          baseOffsets = offsets;
          baseObject.position.set(baseObject.width / 2, baseObject.height / 2);
          renderer.resize(offsets.width, offsets.height);
          renderer.view.style.height = offsets.height + "px";
          renderer.view.style.width = offsets.width + "px";
          renderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

          memeWordWrapWidth = baseObject.width * 0.9;
          var s = topMemeText.style;
          s.wordWrapWidth = memeWordWrapWidth;
          topMemeText.style = s;
          topMemeText.position.x = offsets.width / 2;
          topMemeText.position.y = offsets.height / 16;
          s = bottomMemeText.style;
          s.wordWrapWidth = memeWordWrapWidth;
          bottomMemeText.style = s;
          bottomMemeText.position.x = offsets.width / 2;
          bottomMemeText.position.y = offsets.height - offsets.height / 16;
        }
        if (baseObject.rotate) {
          baseObject.rotation = project.stage.children[0].rotation - 1.5708;
          window.editor.rotate(baseObject);
        }
        if (project.stage.children[0].straightenScale) {
          baseObject.rotation = project.stage.children[0].rotation;
          baseObject.scale.set(
            baseObject.scale.x * project.stage.children[0].straightenScale,
            baseObject.scale.x * project.stage.children[0].straightenScale
          );
          baseObject.straightenScale =
            project.stage.children[0].straightenScale;
        }
        if (project.stage.children[0].mirrored) {
          baseObject.scale.x *= -1;
          baseObject.mirrored = true;
        }
        renderer.render(stage);
        newScale = baseObject.width / project.stage.children[0].width;

        if (project.appliedEffect) {
          EditorStore.setCurrentEffect(project.appliedEffect);
          var effect = JSON.parse(JSON.stringify(project.appliedEffect));

          if (effect.zoom !== 1) {
            for (var x = 0; x < effect.Effect.length; x++) {
              switch (effect.Effect[x].filter) {
                case "contrast":
                  effect.Effect[x].value =
                    0.8 - (0.8 - effect.Effect[x].value) * effect.zoom;
                  break;
                case "denoise":
                  effect.Effect[x].value =
                    50 - (50 - effect.Effect[x].value) * effect.zoom;
                  break;
                case "rgb":
                  effect.Effect[x].value =
                    1 - (1 - effect.Effect[x].value) * effect.zoom;
                  effect.Effect[x].value2 =
                    1 - (1 - effect.Effect[x].value2) * effect.zoom;
                  effect.Effect[x].value3 =
                    1 - (1 - effect.Effect[x].value3) * effect.zoom;
                  break;
                default:
                  if (!isNaN(effect.Effect[x].value))
                    effect.Effect[x].value =
                      effect.Effect[x].value * effect.zoom;
              }
            }
            window.editor.realApplyEffect(effect);
          } else {
            window.editor.realApplyEffect(project.appliedEffect);
          }
        }
        EditorActionCreators.ModifyImageSettings(project.imageSettings);
        self.setupProjectItems(project, newScale, true);
      });
    }
    if (project.mode === "collage") {
      this.initCollage(project.stage.children[0].collageItem, function () {
        if (project.collageBackground) {
          EditorStore.setFilePickerTool("collageBackground");
          window.editor.addPicture(project.collageBackground, function (cb) {
            self.importCollage(project);
          });
        } else {
          self.importCollage(project);
        }
      });
    }
    if (project.mode === "design") {
      initialSize.width = project.stage.children[0].designItem.item.Width;
      initialSize.height = project.stage.children[0].designItem.item.Height;
      await this.addDesign(project.stage.children[0].designItem, function () {
        newScale = baseObject.width / project.stage.children[0].width;
        var newScaleWidth = baseObject.width / project.stage.children[0].width;
        var newScaleHeight =
          baseObject.height / project.stage.children[0].height;
        project.stage.children.forEach((item) => {
          if (
            item.type === "Container" &&
            item.knownObject === "BackgroundContainer"
          ) {
            item.children.forEach(async (s) => {
              if (s.DesignBackground) {
                await window.editor.loadTextureFromUrl(
                  s.dataUrl,
                  (texture) => {
                    var bg = self.addDesignBackground(texture, s.id);
                    if (s.alpha) bg.alpha = s.alpha;
                    bg.height = s.height * newScaleHeight;
                    bg.width = s.width * newScaleWidth;
                    bg.position.set(s.x * newScaleWidth, s.y * newScaleHeight);
                    EditorActionCreators.ModifyImageSettings(
                      project.imageSettings
                    );
                    bg.rotation = s.rotation;
                  },
                  7000
                );
              }
            });
          }
        });
        baseObject.clear();
        baseObject.beginFill(
          project.stage.children[0].transparent
            ? 0xffffff
            : project.stage.children[0].fillColor,
          project.stage.children[0].transparent ? 0.05 : 1
        );
        EditorActionCreators.updateBlankColor(
          project.stage.children[0].transparent
            ? 0xffffff
            : project.stage.children[0].fillColor
        );
        baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
        baseObject.transparent = project.stage.children[0].transparent;

        if (project.appliedEffect) {
          EditorStore.setBackgroundEffect(project.appliedEffect);
          var effect = JSON.parse(JSON.stringify(project.appliedEffect));

          if (effect.zoom !== 1) {
            if (effect.Effect) {
              for (var x = 0; x < effect.Effect.length; x++) {
                switch (effect.Effect[x].filter) {
                  case "contrast":
                    effect.Effect[x].value =
                      0.8 - (0.8 - effect.Effect[x].value) * effect.zoom;
                    break;
                  case "denoise":
                    effect.Effect[x].value =
                      50 - (50 - effect.Effect[x].value) * effect.zoom;
                    break;
                  case "rgb":
                    effect.Effect[x].value =
                      1 - (1 - effect.Effect[x].value) * effect.zoom;
                    effect.Effect[x].value2 =
                      1 - (1 - effect.Effect[x].value2) * effect.zoom;
                    effect.Effect[x].value3 =
                      1 - (1 - effect.Effect[x].value3) * effect.zoom;
                    break;
                  default:
                    if (!isNaN(effect.Effect[x].value))
                      effect.Effect[x].value =
                        effect.Effect[x].value * effect.zoom;
                }
              }
            }
            window.editor.realApplyEffect(effect);
          } else {
            window.editor.realApplyEffect(project.appliedEffect);
          }
        }
        self.setupProjectItems(project, newScale, true);
        EditorStore.setShowSpinner(false);
        
        if (stickerUrl) {
          window.editor.addSticker(
            stickerUrl, // Sticker URL from the parameter
            null,       // let addSticker position it (or pass coordinates if desired)
            null,
            function (stickerSprite) {
              // Use baseObject's width as reference; fallback if not available.
              let canvasSize = baseObject ? baseObject.width : 500;
              
              // Calculate the sticker's greatest dimension.
              let stickerGreatestDimension = Math.max(
                stickerSprite.texture.width,
                stickerSprite.texture.height
              );
              
              // Compute the scale factor so that the sticker's largest side becomes 65% of the canvas.
              let scaleFactor = (canvasSize * 0.65) / stickerGreatestDimension;
              
              // Apply scaling and center the sticker.
              stickerSprite.scale.set(scaleFactor);
              stickerSprite.x = canvasSize / 2;
              stickerSprite.y = canvasSize / 2;

               // Automatically select the sticker.
        window.editor.setSelectedObject(stickerSprite);
              
              // Simulate pressing the transparent toggle:
              let mainContent = document.getElementById("mainContent");
              if (mainContent) {
                let canvases = mainContent.getElementsByTagName("canvas");
                if (canvases.length > 0) {
                  canvases[0].className = "transparent";
                }
              }
              
              // Force the checkbox toggle AFTER a short delay,
              // in case later code resets it.
              setTimeout(() => {
                const checkbox = document.querySelector('.transparent input[type="checkbox"]');
                if (checkbox && !checkbox.checked) {
                  checkbox.checked = true;
                  const changeEvent = new Event('change', { bubbles: true });
                  checkbox.dispatchEvent(changeEvent);
                }
                // Also, ensure the EditorStore state reflects transparency.
                if (typeof EditorStore.setTransparent === "function") {
                  EditorStore.setTransparent(true);
                }
              }, 1500); // adjust the delay as needed
              
              console.log("Sticker added, scaled, and transparency toggle forced on with scale factor:", scaleFactor);
              EditorStore.setShowSpinner(false);
            },
            null,
            null,
            null,
            null,
            null,
            [] // features or options as an empty array
          );
        } else {
          EditorStore.setShowSpinner(false);
        }
      });
    }
  },
  importCollage: function (project) {
    var newScale = baseObject.width / project.stage.children[0].width;
    if (project.hotspots) {
      project.hotspots.forEach((item) => {
        if (item.dataUrl) loaderCount++;
        window.editor.loadTextureFromUrl(
          item.dataUrl,
          function (texture, isTrans) {
            loaderCount--;
            if (loaderCount <= 0) {
              EditorStore.setShowProjectLoad(false);
              EditorStore.emitChange();
              _disableSave = false;
            }
            var hs = window.editor.getBaseObject().children[item.hotspot];
            window.editor.addCollageBackground(
              texture,
              item.id,
              hs,
              function (hsSticker) {
                hsSticker.width = item.width * newScale;
                hsSticker.height = item.height * newScale;
                hsSticker.position.x = item.x * newScale;
                hsSticker.position.y = item.y * newScale;
                hsSticker.parent.imageSettings = item.imageSettings;
                EditorStore.setSelectedCollageHotspot(hsSticker.parent);
                window.editor.applyHotspotEffect({ Effect: [] });
                EditorStore.setSelectedCollageHotspot(null);
              }
            );
          }
        );
      });
    }
    if (
      project.stage.children[0].spacing ||
      window.editor.getBaseObject().isRectangle
    ) {
      var spacing = 0;
      if (project.stage.children[0].spacing)
        spacing = project.stage.children[0].spacing;
      window.editor.getBaseObject().corner = project.stage.children[0].corner;
      window.editor.getBaseObject().spacing = spacing * newScale;
      var mask;
      var spacer = parseFloat(spacing) * newScale;
      for (var x = 0; x < window.editor.getBaseObject().children.length; x++) {
        if (window.editor.getBaseObject().children[x].mask) {
          if (window.editor.getBaseObject().isRectangle) {
            mask = new PIXI.Graphics();
            var rect = window.editor.getBaseObject().children[x].initialRect;
            var rectX = rect.x;
            var rectY = rect.y;
            var rectWidth = rect.width;
            var rectHeight = rect.height;

            if (rect.x === 0) {
              rectX += spacer;
              rectWidth -= spacer * 1.5;
            } else {
              rectX += spacer / 2;
              rectWidth -= spacer;
            }
            if (rect.y === 0) {
              rectY += spacer;
              rectHeight -= spacer * 1.5;
            } else {
              rectY += spacer / 2;
              rectHeight -= spacer;
            }
            if (
              rect.y + rect.height + spacer + 20 >=
              window.editor.getBaseObject().height
            ) {
              rectHeight -= spacer / 2;
            }
            if (
              rect.x + rect.width + spacer + 20 >=
              window.editor.getBaseObject().width
            ) {
              rectWidth -= spacer / 2;
            }
            mask.beginFill(0, 1);
            mask.drawRoundedRect(
              rectX,
              rectY,
              rectWidth,
              rectHeight,
              parseInt(window.editor.getBaseObject().corner, 10)
            );
            mask.endFill();
            window.editor.getBaseObject().children[x].mask = null;
            window.editor.getBaseObject().children[x].mask = mask;
          } else {
            mask = window.editor.getBaseObject().children[x].mask;
            window.editor.getBaseObject().children[x].mask = null;
            window.editor.getBaseObject().children[x].mask = mask;
          }
        }
      }
    }
    if (project.stage.children[0].color) {
      baseObject.color = project.stage.children[0].color;
      baseObject.beginFill(project.stage.children[0].color, 1);
      baseObject.drawRect(0, 0, baseObject.width, baseObject.height);
      baseObject.endFill();
    }
    if (project.stage.children[0].color && !project.collageBackground) {
      window.editor.deleteDesignBackground();
    }
    if (project.appliedEffect) {
      EditorStore.setCurrentEffect(project.appliedEffect);
      var effect = JSON.parse(JSON.stringify(project.appliedEffect));

      if (effect.zoom !== 1) {
        for (x = 0; x < effect.Effect.length; x++) {
          switch (effect.Effect[x].filter) {
            case "contrast":
              effect.Effect[x].value =
                0.8 - (0.8 - effect.Effect[x].value) * effect.zoom;
              break;
            case "denoise":
              effect.Effect[x].value =
                50 - (50 - effect.Effect[x].value) * effect.zoom;
              break;
            case "rgb":
              effect.Effect[x].value =
                1 - (1 - effect.Effect[x].value) * effect.zoom;
              effect.Effect[x].value2 =
                1 - (1 - effect.Effect[x].value2) * effect.zoom;
              effect.Effect[x].value3 =
                1 - (1 - effect.Effect[x].value3) * effect.zoom;
              break;
            default:
              if (!isNaN(effect.Effect[x].value))
                effect.Effect[x].value = effect.Effect[x].value * effect.zoom;
          }
        }
        window.editor.realApplyEffect(effect);
      } else {
        window.editor.realApplyEffect(project.appliedEffect);
      }
    }

    this.setupProjectItems(project, newScale, true);
  },
  findByChildId: function (id, project) {
    for (var x = 0; x < project.stage.children.length; x++) {
      var child = project.stage.children[x];
      if (
        child &&
        child.type === "Container" &&
        child.knownObject === "StickerContainer"
      ) {
        for (var y = 0; y < child.children.length; y++) {
          if (child.children[y].childId === id) {
            return child.children[y];
          }
        }
      }
    }
    return null;
  },
  pizapSave: function (callback, isPrint) {
    window.editor.setSelectedObject(null);
    var cloudKey;
    JSONUtils.getJSON(
      "https://api.pizap.com/image/post?access_token=" +
        encodeURIComponent(UserStore.getAccessToken()),
      "GET",
      null,
      function (response) {
        window.editor.saveToBlob(
          0,
          0,
          function (blob) {
            var fd = new FormData();

            cloudKey = response.ImagePrefix + ".jpg";

            fd.append("x-amz-storage-class", "REDUCED_REDUNDANCY");
            fd.append("policy", response.Policy);
            fd.append("signature", response.Signature);
            fd.append("acl", "public-read");
            fd.append("key", cloudKey);
            fd.append("AWSAccessKeyId", response.AccessKey);
            fd.append("Content-Type", "image/jpeg");
            fd.append("success_action_status", "201");
            fd.append("file", blob, cloudKey);
            var xhr = new XMLHttpRequest();

            xhr.addEventListener(
              "load",
              function (evt) {
                var xmlDoc = evt.target.responseXML;
                var cloudUrl =
                  xmlDoc.getElementsByTagName("Location")[0].textContent;
                cloudUrl = "https://imagesw.pizap.com/" + cloudKey;
                var caption = "";
                JSONUtils.getJSON(
                  "https://api.pizap.com/image/post?publicvalue=no&posttofacebook=no&imageNamePreset=" +
                    cloudKey +
                    "&title=" +
                    encodeURIComponent(caption) +
                    "&posttotwitter=no&posttotumblr=no&access_token=" +
                    encodeURIComponent(UserStore.getAccessToken()),
                  "GET",
                  null,
                  function (response) {
                    if (callback) callback(cloudUrl);
                  }
                );
              },
              false
            );
            xhr.addEventListener(
              "error",
              function (evt) {
                console.log("upload Failed");
              },
              false
            );
            xhr.addEventListener(
              "abort",
              function (evt) {
                console.log("upload cancelled");
              },
              false
            );
            xhr.open(
              "POST",
              "https://s3-us-west-1.amazonaws.com/imagesw.pizap.com/",
              true
            ); //MUST BE LAST LINE BEFORE YOU SEND
            xhr.send(fd);
          },
          isPrint
        );
      },
      function () {
        console.log("save failed");
      }
    );
  },
  saveToBlob: function (width, height, callback, isPrint) {
    var renderer = window.editor.getRenderer();
    var stage = window.editor.getStage();
    stage.scale.x = 1;
    stage.position.x = 0;
    stage.position.y = 0;
    var rendererSize = {
      width: renderer.view.width,
      height: renderer.view.height,
    };
    var originalScale = stage.scale.x;
    var size = window.editor.getOriginalSize();
    var saveSize = { width: size.width, height: size.height };
    var tmpScale;
    if (size.width + size.height > 8360) {
      tmpScale = 8200 / (size.height + size.width);
      saveSize = {
        width: size.width * tmpScale,
        height: size.height * tmpScale,
      };
    }
    if (!UserStore.isPro() && size.width + size.height > 2048 && !isPrint) {
      tmpScale = 2048 / (size.height + size.width);
      saveSize = {
        width: size.width * tmpScale,
        height: size.height * tmpScale,
      };
    }
    var offsets = window.editor.scaleSave(
      saveSize.width,
      saveSize.height,
      size.width,
      size.height
    );

    stage.scale.set(offsets.width / rendererSize.width);
    var bo = window.editor.getBaseObject();
    if (bo.initialSpacing) {
      renderer.resize(
        Math.floor(
          (rendererSize.width / window.editor.getStartingDevicePixelRatio()) *
            stage.scale.x
        ) -
          (bo.initialSpacing - parseInt(bo.spacing, 10)),
        Math.floor(
          (rendererSize.height / window.editor.getStartingDevicePixelRatio()) *
            stage.scale.x
        ) -
          (bo.initialSpacing - parseInt(bo.spacing, 10))
      );
    } else {
      renderer.resize(
        Math.floor(
          (rendererSize.width / window.editor.getStartingDevicePixelRatio()) *
            stage.scale.x
        ),
        Math.floor(
          (rendererSize.height / window.editor.getStartingDevicePixelRatio()) *
            stage.scale.x
        )
      );
    }
    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || window.safari.pushNotification);
    if (isSafari) {
      stage.scale.y *= -1;
      stage.position.y = 0 - stage.height;
    }
    if (window.editor.getBaseObject().transparent) {
      window.editor.getBaseObject().alpha = 0;
    }
    var baseObject = window.editor.getBaseObject();
    for (var x = baseObject.children.length - 1; x >= 0; x--) {
      if (baseObject.children[x].blueBorder) {
        baseObject.removeChild(baseObject.children[x]);
      }
    }
    var cb = callback;

    renderer.render(stage);
    window.editor.prepareSave(function () {
      renderer.render(stage);
      renderer.view.toBlob(function (blob) {
        renderer.resize(
          rendererSize.width / window.editor.getStartingDevicePixelRatio(),
          rendererSize.height / window.editor.getStartingDevicePixelRatio()
        );
        stage.scale.set(originalScale);
        window.editor.postSave();

        if (isSafari) {
          //stage.scale.y *= -1;
          stage.position.y = 0;
        }
        if (cb) cb(blob);
      }, "image/jpeg");
    }, offsets);
  },

  setupProjectItems: function (project, newScale, isProjectId) {
    const self = this;
    if (isProjectId) EditorStore.setProjectId(project.id);
    const loopFunction = (item) => {
      let projectStickers = child;
      let zOffset = zOrder;
      if (item.id) {
      } else {
        item.id = JSONUtils.guid();
      }
      let svgWidth = 0;
      let svgHeight = 0;
      if (item.fillColor) {
        svgHeight = item.height;
        svgWidth = item.width;
      }
      if (item.type === "Sprite" && !item.childId) {
        loaderCount++;
        self.addSticker(
          item.dataUrl,
          item.x * newScale,
          item.y * newScale,
          function (newSticker) {
            const blur = new PIXI.filters.BlurFilter();
            blur.blur = 0;
            blur.padding = 40;
            blur.resolution = 2;
            blur.quality = 1;
            if (!isProjectId) {
              newSticker.position.x = baseOffsets.width / 2;
            }
            newSticker.filters = [blur];
            if (item.dropShadowEnabled) {
              newSticker.filters = newSticker.filters.concat([
                new Filters.DropShadowFilter({
                  alpha: item.dropShadowAlpha ? item.dropShadowAlpha : 0.5,
                  rotation: item.dropShadowRotation
                    ? item.dropShadowRotation
                    : 45,
                  distance: item.dropShadowDistance
                    ? item.dropShadowDistance
                    : 5,
                  color: item.dropShadowColor ? item.dropShadowColor : 0,
                  blur: item.dropShadowBlur ? item.dropShadowBlur : 0.5,
                  quality: 20,
                }),
              ]);
            }
            if (item.glowEnabled) {
              newSticker.filters = newSticker.filters.concat([
                new Filters.GlowFilter(10, 2, 0, item.glowColor, 0.5),
              ]);
            }
            newSticker.id = item.id;
            newSticker.rotation = item.rotation;
            newSticker.width = item.width * newScale;
            newSticker.height = item.height * newScale;
            newSticker.alpha = item.alpha;
            newSticker.blendMode = item.blendMode;
            newSticker.fillColor = item.fillColor;
            newSticker.glowSliderVal = item.glowSliderVal;
            newSticker.dropShadowAlpha = item.dropShadowAlpha;
            newSticker.dropShadowBlur = item.dropShadowBlur;
            newSticker.dropShadowColor = item.dropShadowColor;
            newSticker.dropShadowRotation = item.dropShadowRotation;
            newSticker.dropShadowDistance = item.dropShadowDistance;
            newSticker.glowColor = item.glowColor;
            newSticker.dropShadowEnabled = item.dropShadowEnabled;
            newSticker.glowEnabled = item.glowEnabled;
            if (item.locked) {
              newSticker.locked = item.locked;
            }
            if (item.permLocked) {
              newSticker.interactive = false;
              newSticker.permLocked = item.permLocked;
            }
            if (item.imageSettings) {
              newSticker.imageSettings = item.imageSettings;
              EditorActionCreators.ModifyImageSettings(item.imageSettings);
              // window.editor.applyStickerEffect({ Effect: [] }, newSticker);
            }
            newSticker._selectedBlendMode = item._selectedBlendMode;
            if (item.flipHorizontal) newSticker.scale.x *= -1;
            if (item.flipVertical) newSticker.scale.y *= -1;
            if (item.hue && item.hue !== 0) {
              var colorMatrix = new PIXI.filters.ColorMatrixFilter();
              colorMatrix.resolution =
                window.editor.getStartingDevicePixelRatio();
              colorMatrix.hue(1 + parseFloat(item.hue), true);
              switch (item._selectedBlendMode) {
                case "NORMAL":
                  colorMatrix.blendMode = PIXI.BLEND_MODES.NORMAL;
                  break;
                case "ADD":
                  colorMatrix.blendMode = PIXI.BLEND_MODES.ADD;
                  break;
                case "MULTIPLY":
                  colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
                  break;
                case "SCREEN":
                  colorMatrix.blendMode = PIXI.BLEND_MODES.SCREEN;
                  break;
                default:
              }
              newSticker.blendMode = PIXI.BLEND_MODES.NORMAL;
              newSticker.pluginName = "sprite";
              newSticker.filters = [colorMatrix];
              newSticker.hue = item.hue;
            } else {
              switch (item._selectedBlendMode) {
                case "NORMAL":
                  newSticker.blendMode = PIXI.BLEND_MODES.NORMAL;
                  break;
                case "ADD":
                  newSticker.blendMode = PIXI.BLEND_MODES.ADD;
                  break;
                case "MULTIPLY":
                  newSticker.blendMode = PIXI.BLEND_MODES.MULTIPLY;
                  break;
                case "SCREEN":
                  newSticker.blendMode = PIXI.BLEND_MODES.SCREEN;
                  break;
                default:
              }
            }
            self.setSelectedObject(null);
            loaderCount--;
            if (loaderCount <= 0) {
              EditorStore.setShowProjectLoad(false);
              EditorStore.emitChange();
              self.arrangeStickers(projectStickers);
              _disableSave = false;
            }
            if (newSticker.fillColor) {
              newSticker.originalUrl = newSticker.sourceUrl;
              if (newSticker.sourceUrl.startsWith("http")) {
                JSONUtils.getXML(
                  newSticker.sourceUrl,
                  "GET",
                  null,
                  function (response) {
                    var style =
                      response.documentElement.getElementsByTagName("style");
                    style[0].innerHTML =
                      "path{fill:#" +
                      newSticker.fillColor.toString(16).padStart(6, "0") +
                      ";stroke:#" +
                      newSticker.fillColor.toString(16).padStart(6, "0") +
                      ";}";

                    newSticker.sourceUrl =
                      "data:image/svg+xml;utf8," +
                      encodeURIComponent(
                        new XMLSerializer().serializeToString(response)
                      );
                    window.editor.redrawSvg(
                      newSticker,
                      svgWidth * newScale,
                      svgHeight * newScale
                    );
                  }
                );
              }
            }
            self.arrangeStickers(projectStickers);
            EditorStore.setShowSpinner(false);
            setTimeout(() => {
              let b_isMask = false;
              for (let x = 0; x < stickerContainer.children.length; x++) {
                if (stickerContainer.children[x]?.isMask) {
                  b_isMask = true;
                }
                if (stickerContainer.children[x]?.isShape && b_isMask) {
                  EditorActionCreators.MoveBack(stickerContainer.children[x]);
                }
              }
            }, 1500);
          },
          item.width * newScale,
          item.height * newScale,
          item.id,
          zOffset,
          item.fillColor
        );
      }
      if (item.type === "Text") {
        if (item.childId) {
          var bubble;
          for (var z = 0; z < child.children.length; z++) {
            if (
              child.children[z].id === item.childId &&
              child.children[z].type === "Sprite"
            ) {
              bubble = child.children[z];
            }
          }
        }
        if (bubble) {
          loaderCount++;
          window.editor.addText(
            item.text,
            bubble.dataUrl,
            bubble.width * newScale,
            bubble.height * newScale,
            0.5,
            0.5,
            function (txt) {
              self.arrangeStickers(projectStickers);
              txt.id = item.id;
              txt.position.x = item.x * newScale;
              txt.position.y = item.y * newScale;
              item.style.wordWrapWidth = txt.width;
              txt.style = item.style;

              txt.style = new PIXI.TextStyle({
                align: item.style._align ? item.style._align : "left",
                breakWords: item.style._breakWords
                  ? item.style._breakWords
                  : false,
                dropShadow: item.style._dropShadow
                  ? item.style._dropShadow
                  : false,
                dropShadowAlpha: item.style._align.dropShadowAlpha
                  ? item.style._align.dropShadowAlpha
                  : 1,
                dropShadowAngle: item.style._dropShadowAngle
                  ? item.style._dropShadowAngle
                  : Math.PI / 6,
                dropShadowBlur: item.style._dropShadowBlur
                  ? item.style._dropShadowBlur
                  : 0,
                dropShadowColor: item.style._dropShadowColor
                  ? item.style._dropShadowColor
                  : "black",
                dropShadowDistance: item.style._dropShadowDistance
                  ? item.style._dropShadowDistance
                  : 5,
                fill: item.style._fill ? item.style._fill : "black",
                fillGradientType: item.style._fillGradientType
                  ? item.style._fillGradientType
                  : PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
                fillGradientStops: item.style._fillGradientStops,
                fontFamily: item.style._fontFamily
                  ? item.style._fontFamily
                  : "Arial",
                fontSize: item.style._fontSize
                  ? item.style._fontSize * newScale
                  : 26,
                fontStyle: item.style._fontStyle
                  ? item.style._fontStyle
                  : "normal",
                fontVariant: item.style._fontVariant
                  ? item.style._fontVariant
                  : "normal",
                fontWeight: item.style._fontWeight
                  ? item.style._fontWeight
                  : "normal",
                letterSpacing: item.style._letterSpacing
                  ? item.style._letterSpacing * newScale
                  : 0,
                lineHeight:
                  item.style._lineHeight && item.style._lineHeight > 0
                    ? item.style._lineHeight * newScale
                    : null,
                lineJoin: "round",
                miterLimit: item.style._miterLimit
                  ? item.style._miterLimit
                  : 10,
                padding: item.style._padding ? item.style._padding : 0,
                stroke: item.style._stroke ? item.style._stroke : "black",
                strokeThickness: item.style._strokeThickness
                  ? item.style._strokeThickness
                  : 0,
                textBaseline: item.style._textBaseline
                  ? item.style._textBaseline
                  : "alphabetic",
                trim: item.style._trim ? item.style._trim : false,
                whiteSpace: item.style._whiteSpace
                  ? item.style._whiteSpace
                  : "pre",
                wordWrap: item.style._wordWrap ? item.style._wordWrap : false,
                wordWrapWidth: item.style._wordWrapWidth
                  ? item.style._wordWrapWidth * newScale
                  : item.width * newScale,
                leading: item.style._leading ? item.style._leading : 0,
              });

              txt.wrapPercent = item.wrapPercent;
              txt.glowColor = item.glowColor;
              txt.backgroundColor = item.backgroundColor;
              txt.outlineColor = item.outlineColor;
              txt.dropShadowColor = item.dropShadowColor;
              txt.glowSliderVal = item.glowSliderVal;
              txt.dropShadowDistance = item.dropShadowDistance;
              txt.dropShadowAlpha = item.dropShadowAlpha;
              txt.dropShadowBlur = item.dropShadowBlur;
              txt.dropShadowRotation = item.dropShadowRotation;
              txt.alpha = item.alpha;
              if (item.locked) {
                txt.locked = item.locked;
              }
              if (item.permLocked) {
                txt.permLocked = item.permLocked;
              }
              txt.updateText(false);
              if (item.glowEnabled) {
                if (txt.filters === null) txt.filters = [];
                var f = new Filters.GlowFilter(10, 2, 0, txt.glowColor, 0.5);
                txt.filters = [f].concat(txt.filters);
              }
              var childText = window.editor.getStickerById(txt.childId);
              txt.childId = bubble.id;
              if (projectStickers) {
                childText.id = bubble.id;
                childText.width = bubble.width * newScale;
                childText.height = bubble.height * newScale;
                childText.x = bubble.x * newScale;
                childText.y = bubble.y * newScale;
                if (bubble.flipHorizontal) childText.scale.x *= -1;
                if (bubble.flipVertical) childText.scale.y *= -1;

                if (bubble.locked) {
                  childText.locked = bubble.locked;
                }
                if (bubble.permLocked) {
                  childText.permLocked = bubble.permLocked;
                }
                var curIndex = stickerContainer.getChildIndex(childText);
                if (curIndex > zOffset - 1) {
                  stickerContainer.setChildIndex(childText, zOffset - 1);
                }
              }
              if (txt.style.fontFamily) {
                WebFont.load({
                  custom: {
                    families: [txt.style.fontFamily],
                    urls: ["/app/css/fonts.css"],
                  },
                  timeout: 1000,
                  fontinactive: function () {
                    txt.updateText(false);
                    self.resizeText(child);
                  },
                  fontactive: function () {
                    txt.updateText(false);
                    self.resizeText(child);
                  },
                });
              }
              loaderCount--;
              if (loaderCount <= 0) {
                EditorStore.setShowProjectLoad(false);
                EditorStore.emitChange();
                self.arrangeStickers(projectStickers);
                _disableSave = false;
              }
              self.setSelectedObject(null);
            },
            item.id
          );
        } else {
          loaderCount++;
          window.editor.addText(
            item.text,
            null,
            null,
            null,
            null,
            null,
            function (txt) {
              self.arrangeStickers(projectStickers);
              txt.id = item.id;
              txt.position.x = item.x * newScale;
              txt.position.y = item.y * newScale;
              if (!isProjectId) {
                txt.position.x = baseOffsets.width / 2;
              }
              if (
                item.style &&
                item.style._fill &&
                !item.style._fill.startsWith("#")
              ) {
                item.style._fill =
                  "#" +
                  parseInt(item.style._fill, 10).toString(16).padStart(6, "0");
              }
              txt.style = new PIXI.TextStyle({
                align: item.style._align ? item.style._align : "left",
                breakWords: item.style._breakWords
                  ? item.style._breakWords
                  : false,
                dropShadow: item.style._dropShadow
                  ? item.style._dropShadow
                  : false,
                dropShadowAlpha: item.style._align.dropShadowAlpha
                  ? item.style._align.dropShadowAlpha
                  : 1,
                dropShadowAngle: item.style._dropShadowAngle
                  ? item.style._dropShadowAngle
                  : Math.PI / 6,
                dropShadowBlur: item.style._dropShadowBlur
                  ? item.style._dropShadowBlur
                  : 0,
                dropShadowColor: item.style._dropShadowColor
                  ? item.style._dropShadowColor
                  : "black",
                dropShadowDistance: item.style._dropShadowDistance
                  ? item.style._dropShadowDistance
                  : 5,
                fill: item.style._fill ? item.style._fill : "black",
                fillGradientType: item.style._fillGradientType
                  ? item.style._fillGradientType
                  : PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
                fillGradientStops: item.style._fillGradientStops,
                fontFamily: item.style._fontFamily
                  ? item.style._fontFamily
                  : "Arial",
                fontSize: item.style._fontSize
                  ? item.style._fontSize * newScale
                  : 26,
                fontStyle: item.style._fontStyle
                  ? item.style._fontStyle
                  : "normal",
                fontVariant: item.style._fontVariant
                  ? item.style._fontVariant
                  : "normal",
                fontWeight: item.style._fontWeight
                  ? item.style._fontWeight
                  : "normal",
                letterSpacing:
                  item.style._letterSpacing && item.style._letterSpacing > 0
                    ? item.style._letterSpacing * newScale
                    : 0,
                lineHeight:
                  item.style._lineHeight && item.style._lineHeight > 0
                    ? item.style._lineHeight * newScale
                    : null,
                lineJoin: "round",
                miterLimit: item.style._miterLimit
                  ? item.style._miterLimit
                  : 10,
                padding: item.style._padding ? item.style._padding : 0,
                stroke: item.style._stroke ? item.style._stroke : "black",
                strokeThickness:
                  item.style._strokeThickness && item.style._strokeThickness > 0
                    ? item.style._strokeThickness
                    : 0,
                textBaseline: item.style._textBaseline
                  ? item.style._textBaseline
                  : "alphabetic",
                trim: item.style._trim ? item.style._trim : false,
                whiteSpace: item.style._whiteSpace
                  ? item.style._whiteSpace
                  : "pre",
                wordWrap: item.style._wordWrap ? item.style._wordWrap : true,
                wordWrapWidth: item.style._wordWrapWidth
                  ? item.style._wordWrapWidth * newScale
                  : item.width * newScale,
                leading: item.style._leading ? item.style._leading : 0,
              });
              txt.rotation = item.rotation;

              txt.glowColor = item.glowColor;
              txt.backgroundColor = item.backgroundColor;
              txt.outlineColor = item.outlineColor;
              txt.dropShadowColor = item.dropShadowColor;
              txt.glowSliderVal = item.glowSliderVal;
              txt.dropShadowDistance = item.dropShadowDistance * newScale;
              txt.dropShadowAlpha = item.dropShadowAlpha;
              txt.dropShadowBlur = item.dropShadowBlur;
              txt.dropShadowRotation = item.dropShadowRotation;
              txt.backgroundEnabled = item.backgroundEnabled;
              txt.alpha = item.alpha;
              if (item.style._align === "left") {
                txt.anchor.set(0, 0.5);
                txt.pivot.set(txt.style.wordWrapWidth / 2, 0);
              }
              if (item.style._align === "right") {
                txt.anchor.set(1, 0.5);
                txt.pivot.set(0 - txt.style.wordWrapWidth / 2, 0);
              }
              if (item.style._align === "center") {
                txt.anchor.set(0.5);
                txt.pivot.set(0);
              }
              txt.height = item.height * newScale;
              txt.updateText(false);
              if (item.glowEnabled) {
                if (txt.filters === null) txt.filters = [];
                var f = new Filters.GlowFilter(10, 2, 0, txt.glowColor, 0.5);
                txt.filters = [f].concat(txt.filters);
              }
              if (item.locked) {
                txt.locked = item.locked;
              }
              if (item.permLocked) {
                txt.permLocked = item.permLocked;
              }
              txt.childId = item.childId;
              if (item.dropShadowEnabled) {
                if (txt.filters === null) txt.filters = [];

                f = new Filters.DropShadowFilter({
                  alpha: txt.dropShadowAlpha,
                  rotation: txt.dropShadowRotation,
                  distance: txt.dropShadowDistance,
                  color: txt.dropShadowColor,
                  blur: txt.dropShadowBlur,
                  quality: 20,
                });
                txt.filters = txt.filters.concat([f]);
              }
              if (item.curveTextAngle) {
                window.editor.applyCurve(txt, item.curveTextAngle);
              }

              if (txt.style.fontFamily) {
                WebFont.load({
                  custom: {
                    families: [txt.style.fontFamily],
                    urls: ["/app/css/fonts.css"],
                  },
                  timeout: 1000,
                  fontinactive: function () {
                    txt.updateText(false);
                    selectedObject = txt;
                    self.stickerSelector.updateSelector();
                    selectedObject = null;
                    self.stickerSelector.updateSelector();
                    if (item.curveTextAngle) {
                      window.editor.applyCurve(txt, item.curveTextAngle);
                    }
                  },
                  fontactive: function () {
                    txt.updateText(false);
                    selectedObject = txt;
                    self.stickerSelector.updateSelector();
                    selectedObject = null;
                    self.stickerSelector.updateSelector();
                    if (item.curveTextAngle) {
                      window.editor.applyCurve(txt, item.curveTextAngle);
                    }
                  },
                });
              }
              loaderCount--;
              if (loaderCount <= 0) {
                EditorStore.setShowProjectLoad(false);
                EditorStore.emitChange();
                self.arrangeStickers(projectStickers);
                _disableSave = false;
              }
              self.setSelectedObject(null);
            },
            item.id
          );
        }
      }
      if (item.type === "Graphics") {
        var parentObj = window.editor.findByChildId(item.id, project);
        if (parentObj && parentObj.backgroundEnabled) {
          //add text Background
          var background = new PIXI.Graphics();
          background.position.x = item.x * newScale;
          background.position.y = item.y * newScale;
          background.beginFill(parentObj.backgroundColor, 1);
          background.drawRect(
            0 - (item.width * newScale) / 2,
            0 - (item.height * newScale) / 2,
            item.width * newScale,
            item.height * newScale
          );
          background.endFill();
          background.childId = parentObj.id;
          background.parentId = parentObj.id;
          background.id = item.id;
          background.rotation = parentObj.rotation;
          background.textBackground = true;
          window.editor.getStickerContainer().addChildAt(background, 0);
        } else {
          //add Paint object
          var g = new PIXI.Graphics();
          g.id = item.id;

          g.lineStyle(item.lineWidth, item.lineColor, 1);
          if (item.lineBlur > 0) {
            g.filters = [
              new PIXI.filters.BlurFilter(
                item.lineBlur,
                1,
                startingDevicePixelRatio,
                5
              ),
            ];
          } else {
            g.filters = null;
          }
          g.alpha = item.lineAlpha;

          g.beginFill(item.lineColor);
          if (item.points) {
            for (var i = 0; i < item.points.length; i++) {
              g.drawCircle(
                item.points[i].x * newScale,
                item.points[i].y * newScale,
                (item.lineWidth * newScale) / stage.scale.x / 36
              );
            }
          }
          g.points = item.points;
          g.lineBlur = item.lineBlur;
          g.endFill();
          stickerContainer.addChild(g);
        }
        self.arrangeStickers(projectStickers);
      }
      if (item.type === "Hotspot") {
        var img = new Image();
        img.width = item.mask.width;
        img.height = item.mask.height;
        img.onload = function () {
          var hotspot = new PIXI.Sprite(new PIXI.Texture.fromCanvas(img));
          hotspot.id = item.mask.id;
          hotspot.svg = true;
          hotspot.isMask = true;
          hotspot.anchor.set(0.5);
          hotspot.scale.set(1);
          hotspot.position.x = item.mask.x * newScale;
          hotspot.rotation = item.mask.rotation;
          hotspot.position.y = item.mask.y * newScale;
          hotspot.width = item.mask.width * newScale;
          hotspot.height = item.mask.height * newScale;
          hotspot.texture.dataUrl = item.mask.dataUrl;
          hotspot.sourceUrl = item.mask.dataUrl;
          window.editor.getStickerContainer().addChild(hotspot);
          var hs = window.editor.setupStickerHotspot(
            item.mask.dataUrl,
            hotspot,
            hotspot.getBounds()
          );
          hs.id = item.id;
          if (item.image) {
            window.editor.loadTextureFromUrl(
              item.image.dataUrl,
              function (texture, isTrans) {
                window.editor.addCollageBackground(
                  texture,
                  item.id,
                  hs,
                  function (hsSticker) {
                    hsSticker.width = item.image.width * newScale;
                    hsSticker.height = item.image.height * newScale;
                    hsSticker.position.x = item.image.x * newScale;
                    hsSticker.position.y = item.image.y * newScale;
                    hsSticker.parent.imageSettings = item.imageSettings;
                    EditorStore.setSelectedCollageHotspot(hsSticker.parent);
                    window.editor.applyHotspotEffect({ Effect: [] });
                    EditorStore.setSelectedCollageHotspot(null);
                  }
                );
              }
            );
          }
        };
        img.src = item.mask.dataUrl;
      }
      zOrder++;
    };
    for (var x = 0; x < project.stage.children.length; x++) {
      var child = project.stage.children[x];
      if (
        child &&
        child.type === "Container" &&
        child.knownObject === "StickerContainer"
      ) {
        var zOrder = 0;
        child.children.forEach(loopFunction);
        setTimeout(function () {
          for (var x = 0; x < project.stage.children.length; x++) {
            var child = project.stage.children[x];
            if (
              child &&
              child.type === "Container" &&
              child.knownObject === "StickerContainer"
            ) {
              self.arrangeStickers(child);
            }
          }
          EditorStore.setShowProjectLoad(false);
          EditorStore.emitChange();
          _disableSave = false;
        }, 2000);
      }
      if (
        child &&
        child.type === "Container" &&
        child.knownObject === "BorderContainer"
      ) {
        for (var z = 0; z < child.children.length; z++) {
          var stkr = child.children[z];
          window.editor.addBorder(stkr.dataUrl, stkr.effect);
        }
      }
    }
    if (project.meme) {
      self.meme.top.setSize(project.meme.top.size * newScale);
      self.meme.top.setColor(project.meme.top.color);
      if (project.meme.top.text) self.meme.top.setText(project.meme.top.text);
      if (project.meme.top.x)
        topMemeText.position.x = project.meme.top.x * newScale;
      if (project.meme.top.y)
        topMemeText.position.y = project.meme.top.y * newScale;
      self.meme.bottom.setSize(project.meme.bottom.size * newScale);
      self.meme.bottom.setColor(project.meme.bottom.color);
      if (project.meme.bottom.text)
        self.meme.bottom.setText(project.meme.bottom.text);
      if (project.meme.bottom.x)
        bottomMemeText.position.x = project.meme.bottom.x * newScale;
      if (project.meme.bottom.y)
        bottomMemeText.position.y = project.meme.bottom.y * newScale;

      WebFont.load({
        custom: {
          families: [topMemeText.style.fontFamily],
          urls: ["/app/css/fonts.css"],
        },
        timeout: 1000,
        fontinactive: function () {
          topMemeText.updateText(false);
          selectedObject = topMemeText;
          self.stickerSelector.updateSelector();
          bottomMemeText.updateText(false);
          selectedObject = bottomMemeText;
          self.stickerSelector.updateSelector();
          selectedObject = null;
          self.stickerSelector.updateSelector();
        },
        fontactive: function () {
          topMemeText.updateText(false);
          selectedObject = topMemeText;
          self.stickerSelector.updateSelector();
          bottomMemeText.updateText(false);
          selectedObject = bottomMemeText;
          self.stickerSelector.updateSelector();
          selectedObject = null;
          self.stickerSelector.updateSelector();
        },
      });
    }
    if (project.customBorder && project.customBorder.enabled) {
      EditorStore.setCustomBorder(true);
      self.customBorder(
        project.customBorder.width * newScale,
        project.customBorder.color
      );
    }
    window.editor.setSelectedObject(null);
    if (project.mode === "collage") {
      LeftMenuStore.setMenuSelected("collage");
    }
    if (project.mode === "design") {
      LeftMenuStore.setMenuSelected("design");
    }
    if (project.mode === "edit" || project.mode === "touchup") {
      LeftMenuStore.setMenuSelected("main");
    }
    if (loaderCount === 0) {
      EditorStore.setShowProjectLoad(false);
      _disableSave = false;
    }

    EditorStore.emitChange();
  },
  arrangeStickers: function (projectItems) {
    for (
      var containerOffset = 0;
      containerOffset < stickerContainer.children.length;
      containerOffset++
    ) {
      for (
        var projectOffset = 0;
        projectOffset < projectItems.children.length;
        projectOffset++
      ) {
        if (
          stickerContainer.children[containerOffset].id ===
          projectItems.children[projectOffset].id
        ) {
          if (projectOffset < containerOffset) {
            stickerContainer.setChildIndex(
              stickerContainer.children[containerOffset],
              projectOffset
            );
          } else {
            if (
              projectOffset > containerOffset &&
              stickerContainer.children.length > projectOffset
            ) {
              stickerContainer.setChildIndex(
                stickerContainer.children[containerOffset],
                projectOffset
              );
            }
          }
        }
      }
    }
  },
  export: function (action) {
    try {
      var project = {
        mode: EditorStore.getEditorType(),
        id: EditorStore.getProjectId(),
        created: Date.now(),
        createdBy: UserStore.getUser() ? UserStore.getUser().UserName : null,
        renderer: {
          width: renderer.width,
          height: renderer.height,
          devicePixelRatio: window.devicePixelRatio,
        },
        stage: this.exportContainer(stage),
        imageSettings: EditorStore.getMainImageSettings(),
        appliedEffect: EditorStore.getCurrentEffect(),
        title: EditorStore.getProjectTitle(),
        meme: {
          top: {
            text: window.editor.meme.top.getText(),
            size: window.editor.meme.top.getSize(),
            color: window.editor.meme.top.getColor(),
            x: topMemeText.position.x,
            y: topMemeText.position.y,
          },
          bottom: {
            text: window.editor.meme.bottom.getText(),
            size: window.editor.meme.bottom.getSize(),
            color: window.editor.meme.bottom.getColor(),
            x: bottomMemeText.position.x,
            y: bottomMemeText.position.y,
          },
        },
        customBorder: {
          enabled: EditorStore.getCustomBorder(),
          color: EditorStore.getCustomColor(),
          width: EditorStore.getCustomWidth(),
        },
      };
      if (EditorStore.getEditorType() === "collage") {
        project.hotspots = [];
        for (
          var x = 0;
          x < window.editor.getBaseObject().children.length;
          x++
        ) {
          for (
            var a = 0;
            a < window.editor.getBaseObject().children[x].children.length;
            a++
          ) {
            if (
              window.editor.getBaseObject().children[x].children[a]
                .CollageBackground
            ) {
              var hs = this.exportSprite(
                window.editor.getBaseObject().children[x].children[a]
              );
              hs.hotspot = x;
              hs.imageSettings = baseObject.children[x].imageSettings;
              project.hotspots.push(hs);
            }
          }
        }
        if (
          collageBackgroundContainer !== null &&
          collageBackgroundContainer.children.length > 0
        ) {
          project.collageBackground =
            collageBackgroundContainer.children[0].texture.assetUrl;
        }
      }

      if (!action.name) action.name = "pizap";
      if (action.name === "latestProject") {
        project.id = "latestProject";
        var db = new Dexie("pizapProjects");
        db.version(1).stores({
          projects: "id,created,createdBy, mode",
        });
        db.open();
        db.projects
          .clear()
          .then(function () {
            window.editor.getThumbnail(function (
              blobThumb,
              thumbWidth,
              thumbHeight
            ) {
              var reader = new FileReader();
              reader.addEventListener(
                "load",
                function () {
                  project.thumb = reader.result;
                  project.thumbnailWidth = thumbWidth;
                  project.thumbnailHeight = thumbHeight;
                  db.projects.put(project);
                  console.log("autosave completed");
                  EditorStore.setStatusMessage("");
                  EditorStore.emitChange();
                },
                false
              );
              reader.readAsDataURL(blobThumb);
            });
          })
          .catch((err) => {
            console.log("Error: " + (err.stack || err));
          });
      } else {
        return project;
      }
    } catch (e) {}
  },
  exportChild: function (parent) {
    if (parent.maskId) {
      return this.exportStickerHotspot(parent);
    } else if (parent.isMask) {
    } else {
      if (parent instanceof PIXI.Text) return this.exportText(parent);
      if (parent instanceof PIXI.Sprite) return this.exportSprite(parent);
      if (parent instanceof PIXI.Graphics) return this.exportGraphics(parent);
      if (parent instanceof PIXI.Container) return this.exportContainer(parent);
    }
    return null;
  },
  exportStickerHotspot(obj) {
    var maskObj = this.getStickerById(obj.maskId);
    var img = null;
    for (var x = 0; x < obj.children.length; x++) {
      if (obj.children[x].CollageBackground) {
        var hs = this.exportSprite(obj.children[x]);
        hs.imageSettings = obj.imageSettings;
        img = hs;
      }
    }
    return {
      type: "Hotspot",
      id: obj.id,
      x: obj.position.x,
      y: obj.position.y,
      width: obj.width,
      height: obj.height,
      maskId: obj.maskId,
      mask: this.exportSprite(maskObj),
      image: img,
    };
  },
  exportContainer: function (obj) {
    var knownObject = null;
    if (obj === borderContainer) knownObject = "BorderContainer";
    if (obj === stickerContainer) knownObject = "StickerContainer";
    if (obj === collageBackgroundContainer)
      knownObject = "CollageBackgroundContainer";
    if (obj === backgroundContainer) knownObject = "BackgroundContainer";
    if (obj === stage) knownObject = "Stage";
    if (knownObject === null) return null;
    var children = [];
    for (var x = 0; x < obj.children.length; x++) {
      var child = this.exportChild(obj.children[x]);
      if (child !== null) children.push(child);
    }
    return {
      type: "Container",
      knownObject: knownObject,
      width: obj.width,
      height: obj.height,
      x: obj.position.x,
      y: obj.position.y,
      children: children,
    };
  },
  exportSprite: function (obj) {
    let isDropShadowEnabled = false;
    let isGlowEnabled = false;
    if (obj.filters !== null) {
      for (let z = 0; z < obj.filters.length; z++) {
        if (obj.filters[z] instanceof Filters.DropShadowFilter) {
          isDropShadowEnabled = true;
        }
        if (obj.filters[z] instanceof Filters.GlowFilter) {
          isGlowEnabled = true;
        }
      }
    }
    let dataUrl;
    if (obj && obj.curveSprite) return null;
    if (obj.texture.assetUrl) {
      dataUrl = obj.texture.assetUrl;
    } else {
      if (obj.sourceUrl) dataUrl = obj.sourceUrl;
      if (obj.texture.dataUrl && obj.texture.dataUrl.startsWith("http"))
        dataUrl = obj.texture.dataUrl;
    }
    if (obj.originalUrl) dataUrl = obj.originalUrl;
    if (!dataUrl) {
      console.log("Sprite without a source");
    }
    return {
      type: "Sprite",
      id: obj.id,
      x: obj.position.x,
      y: obj.position.y,
      width: obj.width,
      height: obj.height,
      anchor: { x: obj.anchor.x, y: obj.anchor.y },
      isTransparent: obj.isTransparent,
      initialScaleX: obj.initialScaleX,
      initialScaleY: obj.initialScaleY,
      rotation: obj.rotation,
      dataUrl: dataUrl,
      // effect: obj.effect,
      alpha: obj.alpha,
      textureFrame: obj.texture.frame,
      straightenScale: obj.straightenScale,
      rotate: obj.rotate,
      blendMode: obj.blendMode,
      DesignBackground: obj.DesignBackground,
      childId: obj.childId,
      mirrored: obj.mirrored,
      hue: obj.hue,
      _selectedBlendMode: obj._selectedBlendMode,
      flipVertical: obj.scale.y < 0,
      flipHorizontal: obj.scale.x < 0,
      locked: obj.locked,
      isMask: obj.isMask,
      permLocked: obj.permLocked,
      fillColor: obj.fillColor,
      imageSettings: obj.imageSettings,
      dropShadowAlpha: obj.dropShadowAlpha,
      dropShadowBlur: obj.dropShadowBlur,
      dropShadowColor: obj.dropShadowColor,
      dropShadowRotation: obj.dropShadowRotation,
      dropShadowDistance: obj.dropShadowDistance,
      glowColor: obj.glowColor,
      glowEnabled: isGlowEnabled,
      dropShadowEnabled: isDropShadowEnabled,
      // glowSliderVal: obj.glowSliderVal,
    };
  },
  exportText: function (obj) {
    var isDropShadowEnabled = false;
    var isGlowEnabled = false;
    if (obj.filters !== null) {
      for (var z = 0; z < obj.filters.length; z++) {
        if (obj.filters[z] instanceof Filters.DropShadowFilter) {
          isDropShadowEnabled = true;
        }
        if (obj.filters[z] instanceof Filters.GlowFilter) {
          isGlowEnabled = true;
        }
      }
    }

    return {
      type: "Text",
      id: obj.id,
      x: obj.position.x,
      y: obj.position.y,
      width: obj.width,
      height: obj.height,
      rotation: obj.rotation,
      alpha: obj.alpha,
      text: obj.text,
      style: obj.style,
      glowEnabled: isGlowEnabled,
      dropShadowEnabled: isDropShadowEnabled,
      glowColor: obj.glowColor,
      backgroundColor: obj.backgroundColor,
      outlineColor: obj.outlineColor,
      dropShadowColor: obj.dropShadowColor,
      glowSliderVal: obj.glowSliderVal,
      dropShadowDistance: obj.dropShadowDistance,
      dropShadowAlpha: obj.dropShadowAlpha,
      dropShadowBlur: obj.dropShadowBlur,
      dropShadowRotation: obj.dropShadowRotation,
      fontSize: obj.style.fontSize,
      childId: obj.childId,
      backgroundEnabled: obj.backgroundEnabled,
      wrapPercent: obj.wrapPercent,
      locked: obj.locked,
      permLocked: obj.permLocked,
      curveTextAngle: obj.curve,
    };
  },
  exportGraphics: function (obj) {
    if (obj.height === 0 && obj.width === 0) return null;
    return {
      type: "Graphics",
      id: obj.id,
      x: obj.position.x,
      y: obj.position.y,
      width: obj.width,
      height: obj.height,
      points: obj.points,
      lineAlpha: obj.lineAlpha,
      lineColor: obj.lineColor,
      lineWidth: obj.lineWidth,
      lineBlur: obj.lineBlur,
      collageItem: obj.collageItem,
      designItem: obj.designItem,
      transparent: obj.transparent,
      fillColor: obj.fillColor,
      spacing: obj.spacing,
      corner: obj.corner,
      color: obj.color,
      maskId: obj.maskId,
      textBackground: obj.textBackground,
    };
  },
  getIsTransparent: function () {
    return isTransparent;
  },
  displayAds: function () {
    var showAds = UserStore.showAds();
    if (showAds) {
      if (EditorStore.isElectron()) {
      } else {
        if (
          window.googletag &&
          typeof window.googletag.destroySlots === "function"
        )
          window.googletag.destroySlots();

        window.googletag.cmd.push(function () {
          var slot2 = window.googletag.defineSlot(
            "/10683603/pizap.com_editor_160x600_af_2",
            [160, 600],
            "right-ad"
          );
          //                    var slot3 = window.googletag.defineSlot('/10683603/pizap.com_editor_728x90_af', [728, 90], 'bottom-ad');
          slot2.addService(window.googletag.pubads());
          //                    slot3.addService(window.googletag.pubads());
          window.googletag.enableServices();
          window.onload = function () {
            setInterval(function () {
              if (
                EditorStore.getEditorMode() !== "cutout" &&
                EditorStore.getEditorMode() !== "paint" &&
                LeftMenuStore.get() !== "cutout"
              ) {
                window.googletag.pubads().refresh([slot2]);
                console.log("ads reloading");
              }
            }, 60000);
          };
        });
        //                window.googletag.cmd.push(function () {
        //                    window.googletag.display('bottom-ad');
        //                });
        window.googletag.cmd.push(function () {
          window.googletag.display("right-ad");
        });
      }
    }
  },
  getStartingDevicePixelRatio: function () {
    return startingDevicePixelRatio;
  },
  getScale: function () {
    if (stage) return stage.scale.x;
    else {
      return 1;
    }
  },
  setupCursors: function (rend) {
    rend.plugins.interaction.cursorStyles["nw-resize"] = "nw-resize";
    rend.plugins.interaction.cursorStyles["ne-resize"] = "ne-resize";
    rend.plugins.interaction.cursorStyles["sw-resize"] = "sw-resize";
    rend.plugins.interaction.cursorStyles["se-resize"] = "se-resize";
    rend.plugins.interaction.cursorStyles["n-resize"] = "n-resize";
    rend.plugins.interaction.cursorStyles["s-resize"] = "s-resize";
    rend.plugins.interaction.cursorStyles["e-resize"] = "e-resize";
    rend.plugins.interaction.cursorStyles["w-resize"] = "w-resize";
    rend.plugins.interaction.cursorStyles["crosshair"] = "crosshair";
    rend.plugins.interaction.cursorStyles["move"] = "move";
    rend.plugins.interaction.cursorStyles["alias"] = "alias";
    rend.plugins.interaction.cursorStyles["color"] =
      "url(https://static.pizap.com/html5/resources/editor/color-dropper-icon.png) 1 23, auto;";
  },
  removeBackground: function (blob) {
    var fd = new FormData();
    fd.append("file", blob);
    fetch(
      `${process.env.REACT_APP_SERVER_API}/projectAssets/removeBackground`,
      {
        method: "post",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
        }),
        body: fd,
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (newCutout) {
        window.editor.addPicture(newCutout.src, () => {
          fetch(`${process.env.REACT_APP_SERVER_API}/user/cutouts`, {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (resJson) {
              UserStore.setCutouts(resJson);
            });
        });
      })
      .catch((e) => {
        EditorStore.setShowSpinner(false);
      });
  },
  loadTextureFromUrl: async function (
    url,
    callback,
    maxSize,
    origUrl,
    isTouchup
  ) {
    const self = this;
    if (url && url.startsWith("http")) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = async () => {
          await self.loadTextureFromUrl(reader.result, callback, maxSize, url);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.log(error);
      }
    } else {
      let orientation = 1;
      const img = document.createElement("IMG");
      img.onload = function () {
        EXIF.getData(img, function (i) {
          const index = url.indexOf("png");
          let isTrans = false;
          if (index >= 0 && index < 1000) isTrans = true;
          orientation = EXIF.getTag(this, "Orientation");
          let t;
          if (img.naturalWidth + img.naturalHeight > maxSize) {
            var tmpScale = maxSize / (img.naturalHeight + img.naturalWidth);
            var tmpData = url;
            if (orientation && orientation > 1) {
              var rotCanvas = window.editor.rotateImage(img, orientation);
              tmpData = rotCanvas.toDataURL("image/png");
              window.editor.resizeImage(tmpData, tmpScale, function (canvas) {
                t = PIXI.Texture.fromCanvas(canvas, PIXI.SCALE_MODES.NEAREST);
                t.dataUrl = url;
                if (origUrl && origUrl.indexOf("/proxy/") === -1) {
                  t.assetUrl = origUrl;
                  callback(t, isTrans);
                } else {
                  ProjectAsset.createAsset(t, isTouchup, callback);
                }
              });
            } else {
              // just resize do not rotate.  since image already send in image so no reconversion
              window.editor.resizeImage(img, tmpScale, function (canvas) {
                t = PIXI.Texture.fromCanvas(canvas, PIXI.SCALE_MODES.NEAREST);
                t.dataUrl = url;
                if (origUrl && origUrl.indexOf("/proxy/") === -1) {
                  t.assetUrl = origUrl;
                  callback(t, isTrans);
                } else {
                  ProjectAsset.createAsset(t, isTouchup, callback);
                }
              });
            }
          } else {
            if (orientation && orientation > 1) {
              const tmpCanvas = window.editor.rotateImage(img, orientation);
              t = PIXI.Texture.fromCanvas(tmpCanvas, PIXI.SCALE_MODES.NEAREST);
              t.dataUrl = url;
              if (origUrl && origUrl.indexOf("/proxy/") === -1) {
                t.assetUrl = origUrl;
                callback(t, isTrans);
              } else {
                ProjectAsset.createAsset(t, isTouchup, callback);
              }
            } else {
              var noResizeCanvas = document.createElement("canvas");
              const noResizectx = noResizeCanvas.getContext("2d");
              noResizeCanvas.width = img.naturalWidth;
              noResizeCanvas.height = img.naturalHeight;
              noResizectx.drawImage(img, 0, 0);

              t = PIXI.Texture.fromCanvas(
                noResizeCanvas,
                PIXI.SCALE_MODES.NEAREST
              );
              t.dataUrl = url;
              if (origUrl && origUrl.indexOf("/proxy/") === -1) {
                t.assetUrl = origUrl;
                callback(t, isTrans);
              } else {
                ProjectAsset.createAsset(t, isTouchup, callback);
              }
            }
          }
        });
      };
      img.src = url;
    }
  },
  getBase64Image: function (img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
  },
  rgb2hex: function (rgb) {
    rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);

    function hex(x) {
      return ("0" + parseInt(x, 10).toString(16)).slice(-2);
    }

    return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
  },
  scaleSave: function (maxWidth, maxHeight, imgWidth, imgHeight) {
    var scale_width = maxWidth / imgWidth;
    var scale_height = maxHeight / imgHeight;

    var scale = Math.min(scale_width, scale_height);
    if (scale > 1) {
      scale = 1;
    }
    var width = imgWidth * scale; // 608
    var height = imgHeight * scale; // 550

    return { width: width, height: height };
  },
  scaleToFit: function (maxWidth, maxHeight, imgWidth, imgHeight) {
    var scale_width = maxWidth / imgWidth;
    var scale_height = maxHeight / imgHeight;

    var scale = Math.min(scale_width, scale_height);

    var width = imgWidth * scale; // 608
    var height = imgHeight * scale; // 550

    var x = (maxWidth - width) / 2; // 52
    var y = (maxHeight - height) / 2;
    return { x: x, y: y, width: Math.ceil(width), height: Math.ceil(height) };
  },
  scaleToFill: function (maxWidth, maxHeight, imgWidth, imgHeight) {
    var scale_width = maxWidth / imgWidth;
    var scale_height = maxHeight / imgHeight;

    var scale = Math.max(scale_width, scale_height);
    return scale;
  },
  setCollageSpotSelected: function (spot) {
    collageSpotSelected = spot;
  },
  getCollageSpotSelected: function () {
    return collageSpotSelected;
  },
  initPhoto: function (url, callback) {
    EditorStore.setShowSpinner(true);
    EditorStore.setProjectId(JSONUtils.guid());
    var menuParam =
      decodeURIComponent(
        (new RegExp("[?|&]menu=([^&;]+?)(&|#|;|$)").exec(
          window.location.search
        ) || [null, ""])[1].replace(/\+/g, "%20")
      ) || null;
    if (menuParam) {
      LeftMenuActionCreators.MenuSelected(menuParam);
    } else {
      LeftMenuActionCreators.MenuSelected("main");
    }
    window.editor.loadTextureFromUrl(
      url,
      function (texture, isTrans) {
        var content = document.getElementById("container");
        var container = document.getElementById("mainContent");
        var canvasWidth = content.clientWidth - 374;
        var canvasHeight = content.clientHeight - 46;
        isTransparent = isTrans;
        var offsets;
        originalSize = { width: texture.width, height: texture.height };
        offsets = window.editor.scaleToFit(
          canvasWidth - canvasWidthOffset,
          canvasHeight - canvasHeightOffset,
          texture.width,
          texture.height
        );
        baseOffsets = offsets;
        renderer = new PIXI.WebGLRenderer(offsets.width, offsets.height, {
          transparent: isTransparent,
          antialias: true,
          autoresize: true,
          resolution: startingDevicePixelRatio,
          preserveDrawingBuffer: true,
        });
        window.editor.setupCursors(renderer);
        container.appendChild(renderer.view);
        renderer.view.style.height = offsets.height + "px";
        renderer.view.style.width = offsets.width + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
        stage = new PIXI.Container();
        var alphaFilter = new PIXI.filters.AlphaFilter();
        alphaFilter.resolution = startingDevicePixelRatio;
        stage.filters = [alphaFilter];
        baseObject = new PIXI.Sprite(texture);
        baseObject.width = offsets.width;
        baseObject.height = offsets.height;
        baseObject.anchor.set(0.5, 0.5);
        baseObject.position.set(baseObject.width / 2, baseObject.height / 2);
        baseObject.interactive = true;
        baseObject.on("click", window.editor.baseClick);
        baseObject.isTransparent = isTransparent;
        baseObject.initialScaleX = baseObject.scale.x;
        baseObject.initialScaleY = baseObject.scale.y;
        stage.addChild(baseObject);

        borderContainer = new PIXI.Container();
        effectBorderContainer = new PIXI.Container();
        memeContainer = new PIXI.Container();
        memeWordWrapWidth = offsets.width * 0.9;

        topMemeSize = offsets.height / 16;
        bottomMemeSize = offsets.height / 16;

        topMemeText = new PIXI.Text("", {
          fontFamily: "Impact",
          fontSize: topMemeSize,
          fill: topMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: topMemeSize / 20,
        });
        topMemeText.anchor.set(0.5, 0);
        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;
        topMemeText.resolution = startingDevicePixelRatio;
        topMemeText.interactive = true;
        topMemeText.on("click", function () {
          LeftMenuActionCreators.MenuSelected("meme");
        });
        topMemeText.id = "MemeTop";
        topMemeText.Meme = true;
        topMemeText.buttonMode = true;
        topMemeText
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);

        memeContainer.addChild(topMemeText);

        bottomMemeText = new PIXI.Text("", {
          fontFamily: "Impact",
          fontSize: bottomMemeSize,
          fill: bottomMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: bottomMemeSize / 20,
        });
        bottomMemeText.anchor.set(0.5, 1);
        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;
        bottomMemeText.resolution = startingDevicePixelRatio;
        bottomMemeText.interactive = true;
        bottomMemeText.on("click", function () {
          LeftMenuActionCreators.MenuSelected("meme");
        });
        bottomMemeText.id = "MemeBottom";
        bottomMemeText.Meme = true;
        bottomMemeText.buttonMode = true;
        bottomMemeText
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);
        memeContainer.addChild(bottomMemeText);

        stickerContainer = new PIXI.Container();

        paintContainer = new PIXI.Graphics();

        stage.addChild(effectBorderContainer);
        stage.addChild(borderContainer);
        stage.addChild(stickerContainer);
        stage.addChild(paintContainer);
        stage.addChild(memeContainer);

        window.addEventListener("resize", function () {
          if (EditorStore.getSaving() === false) {
            var canvasWidth = content.clientWidth - 374;
            var canvasHeight = content.clientHeight - 46;
            var offsets = window.editor.scaleToFit(
              canvasWidth - canvasWidthOffset,
              canvasHeight - canvasHeightOffset,
              originalSize.width,
              originalSize.height
            );
            renderer.view.style.height = offsets.height + "px";
            renderer.view.style.width = offsets.width + "px";
            if (EditorStore.isElectron()) {
              renderer.view.style.top =
                (canvasHeight - offsets.height) / 2 + "px";
            } else {
              renderer.view.style.top =
                (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
            }
            baseObject.initialScaleX = baseObject.scale.x;
            baseObject.initialScaleY = baseObject.scale.y;
            window.clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
              var canvasWidth = content.clientWidth - 374;
              var canvasHeight = content.clientHeight - 46;
              var offsets = window.editor.scaleToFit(
                canvasWidth - canvasWidthOffset,
                canvasHeight - canvasHeightOffset,
                originalSize.width,
                originalSize.height
              );
              renderer.view.style.height = offsets.height + "px";
              renderer.view.style.width = offsets.width + "px";
              renderer.view.style.top =
                (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

              var newScale =
                offsets.width / (renderer.width / startingDevicePixelRatio);
              renderer.resize(offsets.width, offsets.height);

              stage.scale.set(1);
              if (
                baseObject.rotation === 1.5708 ||
                baseObject.rotation === 1.5708 * 3
              ) {
                baseObject.width = offsets.height;
                baseObject.height = offsets.width;
                baseObject.position.set(
                  baseObject.height / 2,
                  baseObject.width / 2
                );
                baseObject.initialScaleX = baseObject.scale.x;
                baseObject.initialScaleY = baseObject.scale.y;
                if (baseObject.straightenScale) {
                  baseObject.scale.set(
                    baseObject.scale.x * baseObject.straightenScale,
                    baseObject.scale.y * baseObject.straightenScale
                  );
                }
              } else {
                baseObject.width = offsets.width;
                baseObject.height = offsets.height;
                baseObject.position.set(
                  baseObject.width / 2,
                  baseObject.height / 2
                );
                baseObject.initialScaleX = baseObject.scale.x;
                baseObject.initialScaleY = baseObject.scale.y;
                if (baseObject.straightenScale) {
                  baseObject.scale.set(
                    baseObject.scale.x * baseObject.straightenScale,
                    baseObject.scale.y * baseObject.straightenScale
                  );
                }
              }

              baseOffsets = offsets;

              ///////  Update MEME
              topMemeText.position.x = offsets.width / 2;
              topMemeText.position.y = offsets.height / 16;
              window.editor.meme.top.setSize(
                Math.floor(window.editor.meme.top.getSize() * newScale)
              );

              var style = topMemeText.style;
              style.wordWrapWidth = offsets.width * 0.9;
              topMemeText.style = style;
              topMemeText.updateText(false);

              bottomMemeText.position.x = offsets.width / 2;
              bottomMemeText.position.y = offsets.height - offsets.height / 16;
              window.editor.meme.bottom.setSize(
                Math.floor(window.editor.meme.bottom.getSize() * newScale)
              );

              style = bottomMemeText.style;
              style.wordWrapWidth = offsets.width * 0.9;
              bottomMemeText.style = style;
              bottomMemeText.updateText(false);

              //////// Update Borders
              for (var x = 0; x < borderContainer.children.length; x++) {
                borderContainer.children[x].width = offsets.width;
                borderContainer.children[x].height = offsets.height;
              }

              //////// Update Stickers
              var z = 0;
              for (var y = 0; y < stickerContainer.children.length; y++) {
                stickerContainer.children[y].position.x =
                  stickerContainer.children[y].position.x * newScale;
                stickerContainer.children[y].position.y =
                  stickerContainer.children[y].position.y * newScale;
                if (stickerContainer.children[y] instanceof PIXI.Text) {
                  style = stickerContainer.children[y].style;
                  style.fontSize = style.fontSize * newScale;
                  style.wordWrapWidth = style.wordWrapWidth * newScale;
                  if (style.letterSpacing && style.letterSpacing > 0)
                    style.letterSpacing *= newScale;
                  if (style.lineHeight && style.lineHeight > 0)
                    style.lineHeight *= newScale;
                  stickerContainer.children[y].style = style;
                  stickerContainer.children[y]._height = 0;
                  stickerContainer.children[y]._width = 0;
                  stickerContainer.children[y].updateText(false);

                  if (stickerContainer.children[y].filters !== null) {
                    for (
                      z = 0;
                      z < stickerContainer.children[y].filters.length;
                      z++
                    ) {
                      if (
                        stickerContainer.children[y].filters[z] instanceof
                        Filters.DropShadowFilter
                      ) {
                        stickerContainer.children[y].filters[z].distance =
                          stickerContainer.children[y].filters[z].distance *
                          newScale;
                      }
                    }
                  }
                  if (stickerContainer.children[y].style.align === "left") {
                    stickerContainer.children[y].pivot.set(
                      stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                  }
                  if (stickerContainer.children[y].style.align === "right") {
                    stickerContainer.children[y].pivot.set(
                      0 - stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                  }
                } else {
                  stickerContainer.children[y].width =
                    stickerContainer.children[y].width * newScale;
                  stickerContainer.children[y].height =
                    stickerContainer.children[y].height * newScale;
                }
              }

              for (z = 0; z < effectBorderContainer.children.length; z++) {
                effectBorderContainer.children[z].width =
                  EditorStore.getCustomBorder()
                    ? offsets.width - baseObject.x
                    : offsets.width;
                effectBorderContainer.children[z].height =
                  EditorStore.getCustomBorder()
                    ? offsets.height - baseObject.y
                    : offsets.height;
                effectBorderContainer.children[z].position.x =
                  EditorStore.getCustomBorder() ? baseObject.x : 0;
                effectBorderContainer.children[z].position.y =
                  EditorStore.getCustomBorder() ? baseObject.y : 0;
              }

              window.editor.setSelectedObject(
                window.editor.getSelectedObject()
              );

              console.log(
                "resize timer finished " +
                  baseObject.scale.x +
                  " stage: " +
                  stage.scale.x
              );
            }, 750);
          }
        });

        renderer.view.addEventListener(
          "dragover",
          window.editor.onDragOver,
          false
        );
        renderer.view.addEventListener("drop", window.editor.onDrop, false);
        renderer.view.addEventListener(
          "mousedown",
          window.editor.onPaintStart,
          false
        );
        renderer.view.addEventListener(
          "mousemove",
          window.editor.onPaintMove,
          false
        );
        renderer.view.addEventListener(
          "mouseup",
          window.editor.onPaintEnd,
          false
        );
        renderer.view.addEventListener(
          "touchstart",
          window.editor.onPaintStart,
          false
        );
        renderer.view.addEventListener(
          "touchmove",
          window.editor.onPaintMove,
          true
        );
        renderer.view.addEventListener(
          "touchend",
          window.editor.onPaintEnd,
          false
        );
        requestAnimationFrame(window.editor.animate);
        window.editor.stickerSelector.init();
        stage.addChild(selectorContainer);
        baseObject.mirrored = false;
        baseObject.texture.orientation = 1;
        EditorStore.setShowSpinner(false);
        window.editor.showOutline(true);
        if (callback) callback();
      },
      7000
    );
  },
  initCollage: function (item, callback) {
    var x, y, z;
    EditorStore.setShowSpinner(true);
    EditorStore.setProjectId(JSONUtils.guid());

    EditorStore.setShowCollagePicker(false);
    LeftMenuActionCreators.MenuSelected("collage");
    JSONUtils.getXML(item.src, "GET", null, function (response) {
      var bo;
      var path, rect, graphicsPath;
      var viewbox = response.documentElement.getAttribute("viewBox").split(" ");
      var width = parseFloat(viewbox[2]);
      var height = parseFloat(viewbox[3]);
      var paths = response.documentElement.getElementsByTagName("path");
      var textures = [];
      var settings = [];
      var filters = [];
      var content = document.getElementById("container");
      var container = document.getElementById("mainContent");
      var canvasWidth = content.clientWidth - 374;
      var canvasHeight = content.clientHeight - 46;
      var offsets = window.editor.scaleToFit(
        canvasWidth - canvasWidthOffset,
        canvasHeight - canvasHeightOffset,
        width,
        height
      );
      baseOffsets = offsets;
      var tmpScale = 2048 / (height + width);
      originalSize = {
        width: Math.ceil(width * tmpScale),
        height: Math.ceil(height * tmpScale),
      };
      tmpScale = 6000 / (height + width);
      originalSize = {
        width: Math.ceil(width * tmpScale),
        height: Math.ceil(height * tmpScale),
      };

      if (renderer) {
        bo = window.editor.getBaseObject();
        for (y = 0; y < bo.children.length; y++) {
          if (bo.children[y].collagePlaceholder) {
            for (z = 0; z < bo.children[y].children.length; z++) {
              if (bo.children[y].children[z].CollageBackground) {
                textures.push(bo.children[y].children[z].texture);
                if (bo.children[y].imageSettings) {
                  settings.push(bo.children[y].imageSettings);
                } else {
                  settings.push(null);
                }
                filters.push(bo.children[y].children[z].filters);
              }
            }
          }
        }

        for (y = bo.children.length - 1; y >= 0; y--) {
          if (bo.children[y].collagePlaceholder) {
            bo.removeChildAt(y);
          }
        }
        // if (
        //   collageBackgroundContainer !== null &&
        //   collageBackgroundContainer.children.length &&
        //   collageBackgroundContainer.children.length > 0
        // ) {
        //   collageBackgroundContainer.removeChildren();
        // }

        renderer.resize(offsets.width, offsets.height);
        renderer.view.style.height = Math.floor(offsets.height) + "px";
        renderer.view.style.width = Math.floor(offsets.width) + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

        baseObject.originalWidth = width;
        baseObject.orignalHeight = height;
        baseObject.interactive = true;
        baseObject.color = 0xffffff;
        baseObject.corner = 0;
        baseObject.overlayCollage = false;

        memeWordWrapWidth = offsets.width * 0.9;

        topMemeSize = offsets.height / 16;
        bottomMemeSize = offsets.height / 16;
        topMemeText.anchor.set(0.5, 0);
        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;
        topMemeText.resolution = startingDevicePixelRatio;
        bottomMemeText.anchor.set(0.5, 1);
        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;
        bottomMemeText.resolution = startingDevicePixelRatio;
      } else {
        renderer = new PIXI.WebGLRenderer(
          Math.floor(offsets.width),
          Math.floor(offsets.height),
          {
            transparent: true,
            antialias: true,
            autoresize: true,
            resolution: startingDevicePixelRatio,
            preserveDrawingBuffer: true,
          }
        );
        renderer.resize(Math.floor(offsets.width), Math.floor(offsets.height));
        renderer.view.style.height = Math.floor(offsets.height) + "px";
        renderer.view.style.width = Math.floor(offsets.width) + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

        container.appendChild(renderer.view);

        stage = new PIXI.Container();
        var alphaFilter = new PIXI.filters.AlphaFilter();
        alphaFilter.resolution = startingDevicePixelRatio;
        stage.filters = [alphaFilter];

        baseObject = new PIXI.Graphics();
        baseObject.width = Math.floor(offsets.width);
        baseObject.height = Math.floor(offsets.height);
        baseObject.originalWidth = width;
        baseObject.orignalHeight = height;
        baseObject.overlayCollage = false;
        baseObject.interactive = true;
        baseObject.on("click", window.editor.baseClick);
        stage.addChild(baseObject);
        baseObject.color = 0xffffff;
        baseObject.corner = 0;

        collageBackgroundContainer = new PIXI.Container();
        if (item.HasBackground) {
        } else {
          baseObject.addChild(collageBackgroundContainer);
        }
        borderContainer = new PIXI.Container();
        effectBorderContainer = new PIXI.Container();
        memeContainer = new PIXI.Container();
        memeWordWrapWidth = offsets.width * 0.9;

        topMemeSize = offsets.height / 16;
        bottomMemeSize = offsets.height / 16;

        topMemeText = new PIXI.Text("", {
          fontFamily: "Impact",
          fontSize: topMemeSize,
          fill: topMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: topMemeSize / 20,
        });
        topMemeText.anchor.set(0.5, 0);
        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;
        topMemeText.resolution = startingDevicePixelRatio;
        topMemeText.interactive = true;
        topMemeText.on("click", function () {
          LeftMenuActionCreators.MenuSelected("meme");
        });
        topMemeText.id = "MemeTop";
        topMemeText.Meme = true;
        topMemeText.buttonMode = true;
        topMemeText
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);
        memeContainer.addChild(topMemeText);

        bottomMemeText = new PIXI.Text("", {
          fontFamily: "Impact",
          fontSize: bottomMemeSize,
          fill: bottomMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: bottomMemeSize / 20,
        });
        bottomMemeText.anchor.set(0.5, 1);
        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;
        bottomMemeText.resolution = startingDevicePixelRatio;
        bottomMemeText.interactive = true;
        bottomMemeText.on("click", function () {
          LeftMenuActionCreators.MenuSelected("meme");
        });
        bottomMemeText.id = "MemeBottom";
        bottomMemeText.Meme = true;
        bottomMemeText.buttonMode = true;
        bottomMemeText
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);
        memeContainer.addChild(bottomMemeText);

        stickerContainer = new PIXI.Container();
        backgroundContainer = new PIXI.Container();
        paintContainer = new PIXI.Graphics();
        stage.addChild(backgroundContainer);
        stage.addChild(effectBorderContainer);
        stage.addChild(borderContainer);
        stage.addChild(stickerContainer);
        stage.addChild(paintContainer);
        stage.addChild(memeContainer);

        window.addEventListener("resize", function () {
          var z = 0;
          if (EditorStore.getSaving() === false) {
            var canvasWidth = content.clientWidth - 374;
            var canvasHeight = content.clientHeight - 46;
            var offsets = window.editor.scaleToFit(
              canvasWidth - canvasWidthOffset,
              canvasHeight - canvasHeightOffset,
              originalSize.width,
              originalSize.height
            );
            baseOffsets = offsets;
            renderer.view.style.height = offsets.height + "px";
            renderer.view.style.width = offsets.width + "px";
            renderer.view.style.top =
              (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
            window.clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
              var canvasWidth = content.clientWidth - 374;
              var canvasHeight = content.clientHeight - 46;
              var offsets = window.editor.scaleToFit(
                canvasWidth - canvasWidthOffset,
                canvasHeight - canvasHeightOffset,
                originalSize.width,
                originalSize.height
              );
              renderer.view.style.height = offsets.height + "px";
              renderer.view.style.width = offsets.width + "px";
              renderer.view.style.top =
                (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

              var newScale =
                offsets.width / (renderer.width / startingDevicePixelRatio);
              renderer.resize(offsets.width, offsets.height);

              stage.scale.set(1);

              var tmpScale =
                6000 / (baseObject.orignalHeight + baseObject.originalWidth);
              originalSize = {
                width: Math.ceil(baseObject.originalWidth * tmpScale),
                height: Math.ceil(baseObject.orignalHeight * tmpScale),
              };

              baseOffsets = offsets;
              baseObject.clear();
              baseObject.beginFill(baseObject.color, 1);
              baseObject.drawRect(0, 0, baseOffsets.width, baseOffsets.height);
              baseObject.endFill();
              baseObject.HasBackground = item.HasBackground;

              ///////  Update MEME
              topMemeText.position.x = offsets.width / 2;
              topMemeText.position.y = offsets.height / 16;
              window.editor.meme.top.setSize(
                Math.ceil(window.editor.meme.top.getSize() * newScale)
              );

              var style = topMemeText.style;
              style.wordWrapWidth = offsets.width * 0.9;
              style.fontSize = offsets.height / 16;
              style.wordWrapWidth = style.wordWrapWidth * newScale;
              style.padding = style.padding * newScale;
              style.strokeThickness = style.fontSize / 20;
              topMemeText.scale.set(1 / stage.scale.x);
              topMemeText.style = style;
              topMemeText.updateText(false);

              bottomMemeText.position.x = offsets.width / 2;
              bottomMemeText.position.y = offsets.height - offsets.height / 16;
              window.editor.meme.bottom.setSize(
                Math.ceil(window.editor.meme.bottom.getSize() * newScale)
              );

              style = bottomMemeText.style;
              style.wordWrapWidth = offsets.width * 0.9;
              style.fontSize = offsets.height / 16;
              style.wordWrapWidth = style.wordWrapWidth * newScale;
              style.padding = style.padding * newScale;
              style.strokeThickness = style.fontSize / 20;
              bottomMemeText.scale.set(1 / stage.scale.x);
              bottomMemeText.style = style;
              bottomMemeText.updateText(false);

              //////// Update Borders
              var x = 0;
              for (x = 0; x < borderContainer.children.length; x++) {
                borderContainer.children[x].width = offsets.width;
                borderContainer.children[x].height = offsets.height;
              }

              ////////  Update backgrounds
              for (x = 0; x < backgroundContainer.children.length; x++) {
                backgroundContainer.children[x].width = offsets.width;
                backgroundContainer.children[x].height = offsets.height;
              }

              for (x = 0; x < collageBackgroundContainer.children.length; x++) {
                collageBackgroundContainer.children[x].width =
                  collageBackgroundContainer.children[x].width * newScale;
                collageBackgroundContainer.children[x].height =
                  collageBackgroundContainer.children[x].height * newScale;
                if (collageBackgroundContainer.children[x].anchor.x !== 0) {
                  collageBackgroundContainer.children[x].position.x =
                    collageBackgroundContainer.children[x].position.x *
                    newScale;
                  collageBackgroundContainer.children[x].position.y =
                    collageBackgroundContainer.children[x].position.y *
                    newScale;
                }
              }
              var onloadFunction = function () {
                var hotspot = new PIXI.Sprite(new PIXI.Texture.fromCanvas(img));
                hotspot.id = JSONUtils.guid();
                hotspot.svg = true;
                hotspot.isMask = true;
                hotspot.anchor.set(0.5);
                hotspot.scale.set(1);
                hotspot.position.x = stickerContainer.children[y].position.x;
                hotspot.position.y = stickerContainer.children[y].position.y;
                hotspot.width = stickerContainer.children[y].width;
                hotspot.height = stickerContainer.children[y].height;
                hotspot.texture.dataUrl = stickerContainer.children[y].svgPath;
                hotspot.sourceUrl = stickerContainer.children[y].svgPath;
                stickerContainer.children[y].mask = null;
                stickerContainer.children[y].mask = hotspot;
                stickerContainer.children[y].initialRect = hotspot.getBounds();
              };
              //////// Update Stickers
              for (var y = 0; y < stickerContainer.children.length; y++) {
                stickerContainer.children[y].position.x =
                  stickerContainer.children[y].position.x * newScale;
                stickerContainer.children[y].position.y =
                  stickerContainer.children[y].position.y * newScale;
                if (stickerContainer.children[y] instanceof PIXI.Text) {
                  style = stickerContainer.children[y].style;
                  style.fontSize = style.fontSize * newScale;
                  style.wordWrapWidth = style.wordWrapWidth * newScale;
                  if (style.letterSpacing && style.letterSpacing > 0)
                    style.letterSpacing *= newScale;
                  if (style.lineHeight && style.lineHeight > 0)
                    style.lineHeight *= newScale;
                  stickerContainer.children[y].style = style;
                  stickerContainer.children[y]._height = 0;
                  stickerContainer.children[y]._width = 0;
                  stickerContainer.children[y].updateText(false);
                  if (stickerContainer.children[y].filters !== null) {
                    for (
                      z = 0;
                      z < stickerContainer.children[y].filters.length;
                      z++
                    ) {
                      if (
                        stickerContainer.children[y].filters[z] instanceof
                        Filters.DropShadowFilter
                      ) {
                        stickerContainer.children[y].filters[z].distance =
                          stickerContainer.children[y].filters[z].distance *
                          newScale;
                      }
                    }
                  }
                  if (stickerContainer.children[y].style.align === "left") {
                    stickerContainer.children[y].pivot.set(
                      stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                  }
                  if (stickerContainer.children[y].style.align === "right") {
                    stickerContainer.children[y].pivot.set(
                      0 - stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                  }
                } else {
                  if (stickerContainer.children[y].collagePlaceholder) {
                    var img = new Image();
                    img.crossOrigin = "";
                    img.width = stickerContainer.children[y].width;
                    img.height = stickerContainer.children[y].height;
                    img.onload = onloadFunction;
                    img.onerror = function (event, txt) {
                      console.log("Something went wrong.");
                    };
                    img.src = stickerContainer.children[y].svgPath;
                    if (stickerContainer.children[y].children.length > 2) {
                      var obj = stickerContainer.children[y].children[2];
                      obj.position.x = obj.position.x * newScale;
                      obj.position.y = obj.position.y * newScale;
                      obj.width = obj.width * newScale;
                      obj.height = obj.height * newScale;
                      obj.initialZoom = obj.scale.x;
                    }
                  } else {
                    stickerContainer.children[y].width =
                      stickerContainer.children[y].width * newScale;
                    stickerContainer.children[y].height =
                      stickerContainer.children[y].height * newScale;
                  }
                }
              }

              for (z = 0; z < effectBorderContainer.children.length; z++) {
                effectBorderContainer.children[z].width =
                  EditorStore.getCustomBorder()
                    ? offsets.width - baseObject.x
                    : offsets.width;
                effectBorderContainer.children[z].height =
                  EditorStore.getCustomBorder()
                    ? offsets.height - baseObject.y
                    : offsets.height;
                effectBorderContainer.children[z].position.x =
                  EditorStore.getCustomBorder() ? baseObject.x : 0;
                effectBorderContainer.children[z].position.y =
                  EditorStore.getCustomBorder() ? baseObject.y : 0;
              }

              ////////  Fix Collage spots
              var gutterWidth = -1;
              var scale = offsets.width / baseObject.originalWidth;
              for (
                x = 0;
                x < window.editor.getBaseObject().children.length;
                x++
              ) {
                if (window.editor.getBaseObject().children[x].mask) {
                  graphicsPath = SVGPath.init(
                    window.editor.getBaseObject().children[x].svgpath,
                    scale
                  );
                  if (baseObject.isRectangle) {
                    rect = graphicsPath.getBounds();
                    if (gutterWidth > rect.x || gutterWidth < 0) {
                      gutterWidth = rect.x;
                    }
                  }
                }
              }
              if (gutterWidth < 0) gutterWidth = 0;
              var spacer =
                parseFloat(window.editor.getBaseObject().spacing) * newScale;
              window.editor.getBaseObject().spacing = spacer;
              for (
                x = 0;
                x < window.editor.getBaseObject().children.length;
                x++
              ) {
                if (window.editor.getBaseObject().children[x].mask) {
                  graphicsPath = SVGPath.init(
                    window.editor.getBaseObject().children[x].svgpath,
                    scale
                  );
                  rect = graphicsPath.getBounds();
                  if (baseObject.isRectangle) {
                    if (Math.ceil(rect.x) === Math.ceil(gutterWidth)) {
                      rect.x = 0;
                      rect.width += gutterWidth;
                    } else {
                      rect.x -= gutterWidth / 2;
                      rect.width += gutterWidth / 2;
                    }
                    if (Math.ceil(rect.y) === Math.ceil(gutterWidth)) {
                      rect.y = 0;
                      rect.height += gutterWidth;
                    } else {
                      rect.y -= gutterWidth / 2;
                      rect.height += gutterWidth / 2;
                    }
                    if (
                      rect.x + rect.width + gutterWidth + 20 >=
                      offsets.width
                    ) {
                      rect.width = offsets.width - rect.x;
                    } else {
                      rect.width += gutterWidth / 2;
                    }
                    if (
                      rect.y + rect.height + gutterWidth + 20 >=
                      offsets.height
                    ) {
                      rect.height = offsets.height - rect.y;
                    } else {
                      rect.height += gutterWidth / 2;
                    }

                    var rectX = rect.x;
                    var rectY = rect.y;
                    var rectWidth = rect.width;
                    var rectHeight = rect.height;

                    if (rect.x === 0) {
                      rectX += spacer;
                      rectWidth -= spacer * 1.5;
                    } else {
                      rectX += spacer / 2;
                      rectWidth -= spacer;
                    }
                    if (rect.y === 0) {
                      rectY += spacer;
                      rectHeight -= spacer * 1.5;
                    } else {
                      rectY += spacer / 2;
                      rectHeight -= spacer;
                    }
                    if (rect.y + rect.height + spacer + 40 >= offsets.height) {
                      if (rect.y === 0) {
                        rectHeight = offsets.height - rect.y - spacer * 2;
                      } else {
                        rectHeight = offsets.height - rect.y - spacer * 1.5;
                      }
                    }
                    if (rect.x + rect.width + spacer + 40 >= offsets.width) {
                      if (rect.x === 0) {
                        rectWidth = offsets.width - rect.x - spacer * 2;
                      } else {
                        rectWidth = offsets.width - rect.x - spacer * 1.5;
                      }
                    }

                    window.editor.getBaseObject().children[x].clear();
                    window.editor
                      .getBaseObject()
                      .children[x].beginFill(0x252a32, 1);
                    window.editor
                      .getBaseObject()
                      .children[x].drawRect(0, 0, rect.width, rect.height);
                    window.editor.getBaseObject().children[x].endFill();
                    window.editor.getBaseObject().children[x].position.x =
                      rect.x;
                    window.editor.getBaseObject().children[x].position.y =
                      rect.y;

                    var mask = new PIXI.Graphics();
                    mask.beginFill(0, 1);
                    mask.drawRoundedRect(
                      rectX,
                      rectY,
                      rectWidth,
                      rectHeight,
                      parseInt(window.editor.getBaseObject().corner * scale, 10)
                    );
                    mask.endFill();
                    window.editor.getBaseObject().children[x].mask = null;
                    window.editor.getBaseObject().children[x].mask = mask;
                    window.editor.getBaseObject().children[x].initialRect =
                      rect;
                  } else {
                    window.editor.getBaseObject().children[x].clear();
                    window.editor
                      .getBaseObject()
                      .children[x].beginFill(0x252a32, 1);
                    window.editor
                      .getBaseObject()
                      .children[x].drawRect(0, 0, rect.width, rect.height);
                    window.editor.getBaseObject().children[x].endFill();
                    window.editor.getBaseObject().children[x].position.x =
                      rect.x;
                    window.editor.getBaseObject().children[x].position.y =
                      rect.y;

                    window.editor.getBaseObject().children[x].mask = null;
                    window.editor.getBaseObject().children[x].mask =
                      graphicsPath;
                    window.editor.getBaseObject().children[x].initialRect =
                      rect;
                  }
                  var fontSize = Math.min(26, parseInt(rect.height / 7, 10));

                  window.editor.getBaseObject().children[x].children[0].x =
                    rect.width / 2;
                  window.editor.getBaseObject().children[x].children[0].y =
                    rect.height / 2 + fontSize;

                  var imgSize = Math.min(40, parseInt(rect.height / 5, 10));
                  window.editor.getBaseObject().children[x].children[1].height =
                    imgSize;
                  window.editor.getBaseObject().children[x].children[1].width =
                    parseInt(imgSize * 0.836728837876614, 10);
                  window.editor.getBaseObject().children[
                    x
                  ].children[1].position.x = rect.width / 2;
                  window.editor.getBaseObject().children[
                    x
                  ].children[1].position.y = rect.height / 2 - fontSize;
                  if (
                    window.editor.getBaseObject().children[x].children.length >
                    2
                  ) {
                    obj = window.editor.getBaseObject().children[x].children[2];
                    obj.position.x = obj.position.x * newScale;
                    obj.position.y = obj.position.y * newScale;
                    obj.width = obj.width * newScale;
                    obj.height = obj.height * newScale;
                    obj.initialZoom = obj.scale.x;
                  }
                }
              }

              window.editor.setSelectedObject(
                window.editor.getSelectedObject()
              );
            }, 1250);
          }
        });
        renderer.view.addEventListener(
          "dragover",
          window.editor.onDragOver,
          false
        );
        renderer.view.addEventListener("drop", window.editor.onDrop, false);
        renderer.view.addEventListener(
          "mousedown",
          window.editor.onPaintStart,
          false
        );
        renderer.view.addEventListener(
          "mousemove",
          window.editor.onPaintMove,
          false
        );
        renderer.view.addEventListener(
          "mouseup",
          window.editor.onPaintEnd,
          false
        );
        renderer.view.addEventListener(
          "touchstart",
          window.editor.onPaintStart,
          false
        );
        renderer.view.addEventListener(
          "touchmove",
          window.editor.onPaintMove,
          true
        );
        renderer.view.addEventListener(
          "touchend",
          window.editor.onPaintEnd,
          false
        );

        requestAnimationFrame(window.editor.animate);
        window.editor.stickerSelector.init();
      }
      baseObject.beginFill(0xffffff, 1);
      baseObject.drawRect(0, 0, baseOffsets.width, baseOffsets.height);
      baseObject.endFill();
      baseObject.HasBackground = item.HasBackground;
      baseObject.collageItem = item;

      var scale = offsets.width / width;
      stage.addChild(selectorContainer);
      var roundRect;
      if (item.isRectangle) {
        var rects = [];
        var roundRects = [];
        for (x = 0; x < paths.length; x++) {
          path = paths[x].getAttribute("d");
          var extraPaths = path.split(" M");
          if (extraPaths.length > 1) {
            for (y = 0; y < extraPaths.length; y++) {
              if (extraPaths[y] !== "") {
                graphicsPath = SVGPath.init(" M" + extraPaths[y], scale);
                rect = graphicsPath.getBounds();
                if (rect.height > 20 && rect.width > 20) {
                  rects.push(rect);
                  roundRect = rect.clone();
                  roundRect.x = Math.ceil(roundRect.x);
                  roundRect.y = Math.ceil(roundRect.y);
                  rect.svgpath = graphicsPath.svgpath;
                  roundRects.push(roundRect);
                }
              }
            }
          } else {
            graphicsPath = SVGPath.init(path, scale);
            rect = graphicsPath.getBounds();
            if (rect.height > 20 && rect.width > 20) {
              rect.svgpath = graphicsPath.svgpath;
              rects.push(rect);
              roundRect = rect.clone();
              roundRect.x = Math.ceil(roundRect.x);
              roundRect.y = Math.ceil(roundRect.y);
              roundRects.push(roundRect);
            }
          }
        }

        roundRects.sort(firstBy("y").thenBy("x"));
        var gutterWidth = roundRects[0].x;
        baseObject.spacing = gutterWidth;
        baseObject.initialSpacing = gutterWidth;
        baseObject.initialZoom = scale;
        baseObject.corner = "0";
        baseObject.isRectangle = item.isRectangle;
        for (x = 0; x < rects.length; x++) {
          rect = rects[x];
          if (Math.ceil(rect.x) === Math.ceil(gutterWidth)) {
            rect.x = 0;
            rect.width += gutterWidth;
          } else {
            rect.x -= gutterWidth / 2;
            rect.width += gutterWidth / 2;
          }
          if (Math.ceil(rect.y) === Math.ceil(gutterWidth)) {
            rect.y = 0;
            rect.height += gutterWidth;
          } else {
            rect.y -= gutterWidth / 2;
            rect.height += gutterWidth / 2;
          }
          if (rect.x + rect.width + gutterWidth + 20 >= baseObject.width) {
            rect.width = baseObject.width - rect.x;
          } else {
            rect.width += gutterWidth / 2;
          }
          if (rect.y + rect.height + gutterWidth + 20 >= baseObject.height) {
            rect.height = baseObject.height - rect.y;
          } else {
            rect.height += gutterWidth / 2;
          }
          var mask = new PIXI.Graphics();
          var rectX = rect.x;
          var rectY = rect.y;
          var rectWidth = rect.width;
          var rectHeight = rect.height;

          if (rect.x === 0) {
            rectX += gutterWidth;
            rectWidth -= gutterWidth * 1.5;
          } else {
            rectX += gutterWidth / 2;
            rectWidth -= gutterWidth;
          }
          if (rect.y === 0) {
            rectY += gutterWidth;
            rectHeight -= gutterWidth * 1.5;
          } else {
            rectY += gutterWidth / 2;
            rectHeight -= gutterWidth;
          }
          if (
            rect.y + rect.height + gutterWidth + 20 >=
            window.editor.getBaseObject().height
          ) {
            rectHeight -= gutterWidth / 2;
          }
          if (
            rect.x + rect.width + gutterWidth + 20 >=
            window.editor.getBaseObject().width
          ) {
            rectWidth -= gutterWidth / 2;
          }
          mask.beginFill(0, 1);
          mask.drawRoundedRect(rectX, rectY, rectWidth, rectHeight, 0);
          mask.endFill();
          window.editor.setupCollageHotspot(false, rect.svgpath, mask, rect);
        }
        baseObject.rects = rects;
        // add borders
      } else {
        baseObject.spacing = 0;
        baseObject.initialSpacing = 0;
        baseObject.isRectangle = null;
        var parsedPaths = [];
        for (x = 0; x < paths.length; x++) {
          path = paths[x].getAttribute("d");
          extraPaths = path.split(" M");
          if (extraPaths.length > 1) {
            for (y = 0; y < extraPaths.length; y++) {
              if (extraPaths[y] !== "") {
                parsedPaths.push(" M" + extraPaths[y]);
              }
            }
          } else {
            parsedPaths.push(path);
          }
        }
        paths = parsedPaths;
        var loadTextureFunc = function (texture) {
          var backImg = new PIXI.Sprite(texture);

          backImg.width = offsets.width;
          backImg.height = offsets.height;
          collageBackgroundContainer.addChild(backImg);
        };
        for (x = 0; x < paths.length; x++) {
          path = paths[x];
          graphicsPath = SVGPath.init(path, scale);
          rect = graphicsPath.getBounds();
          if (rect.height > 20 && rect.width > 20) {
            window.editor.setupCollageHotspot(false, path, graphicsPath, rect);
          }
        }
        if (item.HasBackground) {
          var background = item.src.replace(".svg", ".png");
          baseObject.overlayCollage = true;
          window.editor.loadTextureFromUrl(background, loadTextureFunc);
        }
      }
      if (item.HasBackground) {
        baseObject.addChild(collageBackgroundContainer);
      }

      EditorStore.setShowSpinner(false);
      bo = window.editor.getBaseObject();
      for (y = 0; y < bo.children.length; y++) {
        if (bo.children[y].collagePlaceholder) {
          if (textures.length > 0) {
            var texture = textures.pop();
            var imageSettings = settings.pop();
            filters.pop();
            if (texture) {
              var newAdd = window.editor.addCollageBackground(
                texture,
                JSONUtils.guid(),
                bo.children[y]
              );
              if (newAdd) {
                if (imageSettings) bo.children[y].imageSettings = imageSettings;
              }
            }
          }
        }
      }
      window.editor.showOutline(true);
      EditorStore.emitChange();
      if (callback) callback();
    });
  },
  setupCollageHotspot: function (isRectangle, svgPath, mask, rect) {
    var sprite = new PIXI.Graphics();
    sprite.mask = mask;
    sprite.initialRect = rect;
    sprite.svgpath = svgPath;
    sprite.beginFill(0x252a32, 1);
    sprite.drawRect(0, 0, rect.width, rect.height);
    sprite.endFill();
    sprite.x = rect.x;
    sprite.y = rect.y;
    sprite.collagePlaceholder = true;
    sprite.buttonMode = true;
    sprite.interactive = true;
    sprite.cursor = "pointer";
    baseObject.addChild(sprite);
  
    const fontSize = Math.min(26, parseInt(rect.height / 14, 10));
    const imgSize = Math.min(40, parseInt(rect.height / 5, 10));
  
    // Normal icon
    let s = PIXI.Sprite.fromImage(AddCollageIcon);
    s.height = imgSize;
    s.width = imgSize;
    s.anchor.set(0.5);
    s.position.x = rect.width / 2;
    s.position.y = rect.height / 2 - fontSize;
    s.alpha = 1;
  
    // Hover icon
    let overS = PIXI.Sprite.fromImage(AddCollageIconMouseOver);
    overS.height = imgSize;
    overS.width = imgSize;
    overS.anchor.set(0.5);
    overS.position.x = s.position.x;
    overS.position.y = s.position.y;
    overS.alpha = 0;
  
    // ----- ADD THE TEXT OBJECTS -----
    // Normal text (white)
    let normalStyle = new PIXI.TextStyle({
      fill: 0xffffff,
      fontFamily: "Arial",
      fontSize: fontSize,
      align: "center",
    });
    let textNormal = new PIXI.Text("Click\nto add image", normalStyle);
    textNormal.anchor.set(0.5, 0);
    textNormal.x = rect.width / 2;
    // Position the text below the icon
    textNormal.y = s.position.y + imgSize / 2 + 4; 
    textNormal.alpha = 1;
  
    // Hover text (blue)
    let hoverStyle = new PIXI.TextStyle({
      fill: 0x006dff, // or whatever "blue" color you want
      fontFamily: "Arial",
      fontSize: fontSize,
      align: "center",
    });
    let textHover = new PIXI.Text("Click\nto add image", hoverStyle);
    textHover.anchor.set(0.5, 0);
    textHover.x = textNormal.x;
    textHover.y = textNormal.y;
    textHover.alpha = 0; // hidden until hover
  
    // Add all objects to the sprite
    sprite.addChild(overS);
    sprite.addChild(s);
    sprite.addChild(textHover);
    sprite.addChild(textNormal);
  
    // ---------- EVENTS (unchanged except for toggling text) ----------
    sprite.on("click", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        for (let a = 0; a < sprite.children.length; a++) {
          if (sprite.children[a].CollageBackground) {
            window.editor.setSelectedObject(null);
            return;
          }
        }
        if (EditorStore.getColorSelectorOpen()) return;
        EditorActionCreators.ShowFilePicker(true, "collage");
        window.editor.setSelectedObject(null);
        window.editor.setCollageSpotSelected(event.currentTarget);
      }
    });
  
    sprite.on("mousedown", (event) => {
      if (LeftMenuStore.get() !== "paint") {
        for (let a = 0; a < sprite.children.length; a++) {
          if (sprite.children[a].CollageBackground) {
            EditorActionCreators.CollageHotspotActiveSelected(sprite);
            window.editor.setSelectedObject(sprite.children[a]);
            EditorStore.setCollageHighlighted(event.currentTarget);
          }
        }
      }
    });
  
    sprite.on("mousemove", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        if (
          event.currentTarget?.mask.containsPoint(
            new PIXI.Point(event.data.global.x, event.data.global.y)
          )
        ) {
          EditorStore.setCollageHighlighted(event.currentTarget);
        }
        if (event.currentTarget !== EditorStore.getSelectedCollageHotspot()) {
          sprite.interactiveChildren = false;
        } else {
          sprite.interactiveChildren = true;
        }
      }
    });
  
    sprite.on("mouseout", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        overS.alpha = 0;    // normal icon
        s.alpha = 1;
        if (EditorStore.getCollageHighlighted() === sprite) {
          EditorStore.setCollageHighlighted(null);
        }
        if (event.currentTarget !== EditorStore.getSelectedCollageHotspot()) {
          sprite.interactiveChildren = false;
        } else {
          sprite.interactiveChildren = true;
        }
        textHover.alpha = 0;  // hide the blue text
        textNormal.alpha = 1; // show the white text
      }
    });
  
    sprite.on("mouseover", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        for (let a = 0; a < sprite.children.length; a++) {
          if (sprite.children[a].CollageBackground) {
            EditorActionCreators.CollageHotspotSelected(sprite);
          }
        }
        if (event.currentTarget !== EditorStore.getSelectedCollageHotspot()) {
          sprite.interactiveChildren = false;
        } else {
          sprite.interactiveChildren = true;
        }
        overS.alpha = 1;     // show hover icon
        s.alpha = 0;
        textHover.alpha = 1;   // show blue text
        textNormal.alpha = 0;  // hide white text
      }
    });
  },
  deleteStickerHotspot: function (so) {
    var mask = so.mask;
    so.removeChildren();
    window.editor.getStickerContainer().removeChild(so);

    window.editor.addSticker(
      mask.texture.dataUrl,
      mask.position.x,
      mask.position.y,
      function (sticker) {
        sticker.id = mask.id;
        sticker.height = mask.height;
        sticker.width = mask.width;
        sticker.rotation = mask.rotation;
        sticker.isShape = true;
        sticker.fillColor = "#FFFFFF";
        EditorStore.setShowSpinner(false);
      }
    );
  },
  createStickerHotspot: function (so) {
    let sprite = null;
    JSONUtils.getXML(
      so.sourceUrl,
      "GET",
      null,
      function (response) {
        // var style = response.documentElement.getElementsByTagName("style");
        // style[0].innerHTML = "path{fill:#FFF;stroke:#FFF;}";
        var svgSrc =
          "data:image/svg+xml;utf8," +
          new XMLSerializer().serializeToString(response).replace("#", "%23");
        var img = new Image();
        img.crossOrigin = "";
        img.width = so.width;
        img.height = so.height;
        img.onload = function () {
          var hotspot = new PIXI.Sprite(new PIXI.Texture.fromCanvas(img));
          hotspot.id = JSONUtils.guid();
          hotspot.svg = true;
          hotspot.isMask = true;
          hotspot.anchor.set(0.5);
          hotspot.scale.set(1);
          hotspot.position.x = so.position.x;
          hotspot.position.y = so.position.y;
          hotspot.width = so.width;
          hotspot.height = so.height;
          hotspot.texture.dataUrl = svgSrc;
          hotspot.rotation = so.rotation;
          hotspot.sourceUrl = svgSrc;
          window.editor.getStickerContainer().addChild(hotspot);
          sprite = window.editor.setupStickerHotspot(
            svgSrc,
            hotspot,
            hotspot.getBounds()
          );
          window.editor.setCollageSpotSelected(sprite);
          window.editor.getStickerContainer().removeChild(so);
        };
        img.onerror = function (event, txt) {
          console.log(event);
        };
        img.src = svgSrc;
        window.editor.autoSave();
      },
      function () {}
    );
  },
  setupStickerHotspot: function (svgPath, mask, rect) {
    var sprite = new PIXI.Graphics();
    sprite.mask = mask;
    sprite.initialRect = rect;
    sprite.svgpath = svgPath;
    sprite.beginFill(0x252a32, 1);
    sprite.drawRect(0, 0, rect.width, rect.height);
    sprite.endFill();
    sprite.x = rect.x;
    sprite.y = rect.y;
    sprite.maskId = mask.id;
    sprite.collagePlaceholder = true;
    sprite.buttonMode = true;
    sprite.interactive = true;
    sprite.cursor = "pointer";
    stickerContainer.addChild(sprite);
  
    const fontSize = Math.min(26, parseInt(rect.height / 14, 10));
    const imgSize = Math.min(40, parseInt(rect.height / 5, 10));
  
    // Normal icon
    let s = PIXI.Sprite.fromImage(AddCollageIcon);
    s.height = imgSize;
    s.width = imgSize;
    s.anchor.set(0.5);
    s.position.x = rect.width / 2;
    s.position.y = rect.height / 2 - fontSize;
    s.alpha = 1;
  
    // Hover icon
    let overS = PIXI.Sprite.fromImage(AddCollageIconMouseOver);
    overS.height = imgSize;
    overS.width = imgSize;
    overS.anchor.set(0.5);
    overS.position.x = rect.width / 2;
    overS.position.y = rect.height / 2 - fontSize;
    overS.alpha = 0;
  
    // ----- ADD THE TEXT OBJECTS -----
    // Normal text (white)
    let normalStyle = new PIXI.TextStyle({
      fill: 0xffffff,
      fontFamily: "Arial",
      fontSize: fontSize,
      align: "center",
    });
    let textNormal = new PIXI.Text("Click\nto add image", normalStyle);
    textNormal.anchor.set(0.5, 0);
    textNormal.x = rect.width / 2;
    textNormal.y = s.position.y + imgSize / 2 + 4;
    textNormal.alpha = 1;
  
    // Hover text (blue)
    let hoverStyle = new PIXI.TextStyle({
      fill: 0x006dff,
      fontFamily: "Arial",
      fontSize: fontSize,
      align: "center",
    });
    let textHover = new PIXI.Text("Click\nto add image", hoverStyle);
    textHover.anchor.set(0.5, 0);
    textHover.x = textNormal.x;
    textHover.y = textNormal.y;
    textHover.alpha = 0; // hidden until hover
  
    // Add all objects to the sprite
    sprite.addChild(overS);
    sprite.addChild(s);
    sprite.addChild(textHover);
    sprite.addChild(textNormal);
  
    // ---------- EVENTS (unchanged except for toggling text) ----------
    sprite.on("click", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        for (let a = 0; a < sprite.children.length; a++) {
          if (sprite.children[a].CollageBackground) {
            return;
          }
        }
        if (EditorStore.getColorSelectorOpen()) return;
        EditorActionCreators.ShowFilePicker(true, "collage");
        window.editor.setSelectedObject(null);
        window.editor.setCollageSpotSelected(event.currentTarget);
      }
    });
  
    sprite.on("mousedown", (event) => {
      if (LeftMenuStore.get() !== "paint") {
        for (let a = 0; a < sprite.children.length; a++) {
          if (sprite.children[a].CollageBackground) {
            EditorActionCreators.CollageHotspotActiveSelected(sprite);
            window.editor.setCollageSpotSelected(sprite);
            window.editor.setSelectedObject(sprite.children[a]);
            EditorStore.setCollageHighlighted(event.currentTarget);
          }
        }
      }
    });
  
    sprite.on("mousemove", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        if (
          event.currentTarget?.mask.containsPoint(
            new PIXI.Point(event.data.global.x, event.data.global.y)
          )
        ) {
          EditorStore.setCollageHighlighted(event.currentTarget);
        }
        if (event.currentTarget !== EditorStore.getSelectedCollageHotspot()) {
          sprite.interactiveChildren = false;
        } else {
          sprite.interactiveChildren = true;
        }
      }
    });
  
    sprite.on("mouseout", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        overS.alpha = 0;    // normal icon
        s.alpha = 1;
        if (EditorStore.getCollageHighlighted() === sprite) {
          EditorStore.setCollageHighlighted(null);
        }
        if (event.currentTarget !== EditorStore.getSelectedCollageHotspot()) {
          sprite.interactiveChildren = false;
        } else {
          sprite.interactiveChildren = true;
        }
        textHover.alpha = 0;  // hide the blue text
        textNormal.alpha = 1; // show the white text
      }
    });
  
    sprite.on("mouseover", function (event) {
      if (LeftMenuStore.get() !== "paint") {
        for (let a = 0; a < sprite.children.length; a++) {
          if (sprite.children[a].CollageBackground) {
            EditorActionCreators.CollageHotspotSelected(sprite);
          }
        }
        if (event.currentTarget !== EditorStore.getSelectedCollageHotspot()) {
          sprite.interactiveChildren = false;
        } else {
          sprite.interactiveChildren = true;
        }
        overS.alpha = 1;     // show hover icon
        s.alpha = 0;
        textHover.alpha = 1;   // show blue text
        textNormal.alpha = 0;  // hide white text
      }
    });
    return sprite;
  },

  addDesign: async function (item, callback) {
    EditorStore.setProjectId(JSONUtils.guid());
    var scale, i;
    if (item.item.AllowCustom) {
      EditorActionCreators.showCustomSize(true);
    } else {
      EditorStore.setShowSpinner(true);
      EditorStore.setShowCustomSize(false);
      var content = document.getElementById("container");
      var container = document.getElementById("mainContent");
      var canvasWidth = content.clientWidth - 374;
      var canvasHeight = content.clientHeight - 46;
      var offsets = window.editor.scaleToFit(
        canvasWidth - canvasWidthOffset,
        canvasHeight - canvasHeightOffset,
        item.item.Width,
        item.item.Height
      );
      baseOffsets = offsets;
      originalSize = { width: item.item.Width, height: item.item.Height };

      if (renderer) {
        oldSize = {
          width: renderer.width,
          height: renderer.height,
        };
        renderer.resize(offsets.width, offsets.height);
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
        renderer.view.style.height = offsets.height + "px";
        renderer.view.style.width = offsets.width + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
        baseObject.width = offsets.width;
        baseObject.height = offsets.height;
        baseObject.interactive = true;

        memeWordWrapWidth = offsets.width * 0.9;

        topMemeSize = offsets.height / 16;
        bottomMemeSize = offsets.height / 16;

        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;

        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;

        if (
          stickerContainer &&
          stickerContainer.children.length &&
          EditorStore.getSaving() === false
        ) {
          for (let x = 0; x < stickerContainer.children.length; x++) {
            stickerContainer.children[x].position.x =
              stickerContainer.children[x].position.x - deltaSize.width;
            stickerContainer.children[x].position.y =
              stickerContainer.children[x].position.y - deltaSize.height;
          }
          let scale = 1;
          if (initialSize.width === initialSize.height) {
            scale =
              Math.min(renderer.width, renderer.height) /
              Math.min(oldSize.width, oldSize.height);
          } else {
            if (initialSize.width < initialSize.height) {
              scale = renderer.height / oldSize.height;
            } else if (initialSize.width > initialSize.height) {
              scale = renderer.width / oldSize.width;
            }
          }

          const xScale = renderer.width / oldSize.width;
          const yScale = renderer.height / oldSize.height;

          if (renderer.width > renderer.height) {
            deltaSize.width =
              (renderer.width - renderer.height) /
              2 /
              window.editor.getStartingDevicePixelRatio();
            deltaSize.height = 0;
          } else {
            deltaSize.height =
              (renderer.height - renderer.width) /
              2 /
              window.editor.getStartingDevicePixelRatio();
            deltaSize.width = 0;
          }

          for (let y = 0; y < stickerContainer.children.length; y++) {
            if (initialSize.width === initialSize.height) {
              stickerContainer.children[y].scale.x =
                stickerContainer.children[y].scale.x * scale;
              stickerContainer.children[y].scale.y =
                stickerContainer.children[y].scale.y * scale;
              stickerContainer.children[y].position.x =
                stickerContainer.children[y].position.x * scale +
                deltaSize.width;
              stickerContainer.children[y].position.y =
                stickerContainer.children[y].position.y * scale +
                deltaSize.height;
            } else if (initialSize.width < initialSize.height) {
              if (renderer.width > renderer.height) {
                deltaSize.width = 0;
              } else {
                deltaSize.height = 0;
              }
              stickerContainer.children[y].scale.x =
                stickerContainer.children[y].scale.x * scale;
              stickerContainer.children[y].scale.y =
                stickerContainer.children[y].scale.y * scale;
              stickerContainer.children[y].position.y =
                stickerContainer.children[y].position.y * yScale +
                deltaSize.height;
              if (stickerContainer.children[y].mask instanceof PIXI.Sprite) {
                const diffX =
                  (stickerContainer.children[y].width -
                    stickerContainer.children[y].mask.width) /
                  2;
                stickerContainer.children[y].position.x =
                  stickerContainer.children[y].mask.position.x -
                  diffX +
                  deltaSize.width;
              } else {
                stickerContainer.children[y].position.x =
                  stickerContainer.children[y].position.x * xScale +
                  deltaSize.width;
              }
            } else {
              if (renderer.width > renderer.height) {
                deltaSize.width = 0;
              } else {
                deltaSize.height = 0;
              }
              stickerContainer.children[y].scale.x =
                stickerContainer.children[y].scale.x * scale;
              stickerContainer.children[y].scale.y =
                stickerContainer.children[y].scale.y * scale;
              stickerContainer.children[y].position.x =
                stickerContainer.children[y].position.x * xScale +
                deltaSize.width;
              if (stickerContainer.children[y].mask instanceof PIXI.Sprite) {
                const diffY =
                  (stickerContainer.children[y].height -
                    stickerContainer.children[y].mask.height) /
                  2;
                stickerContainer.children[y].position.y =
                  stickerContainer.children[y].mask.position.y -
                  diffY +
                  deltaSize.height;
              } else {
                stickerContainer.children[y].position.y =
                  stickerContainer.children[y].position.y * yScale +
                  deltaSize.height;
              }
            }
          }
        }

        if (EditorStore.getDesignBackground()) {
          var sticker = EditorStore.getDesignBackground();
          sticker.position.x = baseOffsets.width / 2;
          sticker.position.y = baseOffsets.height / 2;
          sticker.anchor.set(0.5);
          sticker.scale.set(1 / window.editor.getStartingDevicePixelRatio());
          offsets = window.editor.scaleToFit(
            baseOffsets.width,
            baseOffsets.height,
            sticker.texture.width,
            sticker.texture.height
          );
          if (offsets.height < baseOffsets.height) {
            scale = baseOffsets.height / sticker.texture.height;
            sticker.scale.x = scale;
            sticker.scale.y = scale;
          } else {
            scale = baseOffsets.width / sticker.texture.width;
            sticker.scale.x = scale;
            sticker.scale.y = scale;
          }
          sticker.initialZoom = sticker.scale.x;
        }
        if (
          effectBorderContainer &&
          effectBorderContainer.children &&
          effectBorderContainer.children.length > 0
        ) {
          for (i = 0; i < effectBorderContainer.children.length; i++) {
            effectBorderContainer.children[i].width = baseOffsets.width;
            effectBorderContainer.children[i].height = baseOffsets.height;
          }
        }
        if (
          borderContainer &&
          borderContainer.children &&
          borderContainer.children.length > 0
        ) {
          for (i = 0; i < borderContainer.children.length; i++) {
            borderContainer.children[i].width = baseOffsets.width;
            borderContainer.children[i].height = baseOffsets.height;
          }
        }
      } else {
        renderer = new PIXI.WebGLRenderer(offsets.width, offsets.height, {
          transparent: true,
          antialias: true,
          autoresize: true,
          resolution: startingDevicePixelRatio,
          preserveDrawingBuffer: true,
        });
        window.editor.setupCursors(renderer);

        renderer.resize(offsets.width, offsets.height);
        renderer.view.style.height = offsets.height + "px";
        renderer.view.style.width = offsets.width + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

        container.appendChild(renderer.view);
        renderer.view.style.height = offsets.height + "px";
        renderer.view.style.width = offsets.width + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
        stage = new PIXI.Container();
        var alphaFilter = new PIXI.filters.AlphaFilter();
        alphaFilter.resolution = startingDevicePixelRatio;
        stage.filters = [alphaFilter];

        baseObject = new PIXI.Graphics();
        baseObject.width = offsets.width;
        baseObject.height = offsets.height;
        baseObject.interactive = true;
        baseObject.on("click", window.editor.baseClick);
        stage.addChild(baseObject);

        baseObject.beginFill(0xffffff, 1);
        baseObject.drawRect(0, 0, offsets.width, offsets.height);
        baseObject.endFill();
        baseObject.transparent = false;
        borderContainer = new PIXI.Container();
        effectBorderContainer = new PIXI.Container();
        memeContainer = new PIXI.Container();
        memeWordWrapWidth = offsets.width * 0.9;

        topMemeSize = offsets.height / 16;
        bottomMemeSize = offsets.height / 16;

        topMemeText = new PIXI.Text("", {
          fontFamily: "Impact",
          fontSize: topMemeSize,
          fill: topMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: topMemeSize / 20,
        });
        topMemeText.anchor.set(0.5, 0);
        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;
        topMemeText.resolution = startingDevicePixelRatio;
        topMemeText.interactive = true;
        topMemeText.on("click", function () {
          LeftMenuActionCreators.MenuSelected("meme");
        });
        topMemeText.id = "MemeTop";
        topMemeText.Meme = true;
        topMemeText.buttonMode = true;
        topMemeText
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);
        memeContainer.addChild(topMemeText);

        bottomMemeText = new PIXI.Text("", {
          fontFamily: "Impact",
          fontSize: bottomMemeSize,
          fill: bottomMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: bottomMemeSize / 20,
        });
        bottomMemeText.anchor.set(0.5, 1);
        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;
        bottomMemeText.resolution = startingDevicePixelRatio;
        bottomMemeText.interactive = true;
        bottomMemeText.on("click", function () {
          LeftMenuActionCreators.MenuSelected("meme");
        });
        bottomMemeText.id = "MemeBottom";
        bottomMemeText.Meme = true;
        bottomMemeText.buttonMode = true;
        bottomMemeText
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);
        memeContainer.addChild(bottomMemeText);

        stickerContainer = new PIXI.Container();
        backgroundContainer = new PIXI.Container();
        paintContainer = new PIXI.Graphics();

        stage.addChild(backgroundContainer);
        stage.addChild(effectBorderContainer);
        stage.addChild(borderContainer);
        stage.addChild(stickerContainer);
        stage.addChild(paintContainer);
        stage.addChild(memeContainer);

        window.addEventListener("resize", function () {
          if (EditorStore.getSaving() === false) {
            var canvasWidth = content.clientWidth - 374;
            var canvasHeight = content.clientHeight - 46;
            var offsets = window.editor.scaleToFit(
              canvasWidth - canvasWidthOffset,
              canvasHeight - canvasHeightOffset,
              originalSize.width,
              originalSize.height
            );
            renderer.view.style.height = offsets.height + "px";
            renderer.view.style.width = offsets.width + "px";
            renderer.view.style.top =
              (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
            baseObject.initialScaleX = baseObject.scale.x;
            baseObject.initialScaleY = baseObject.scale.y;
            window.clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
              var canvasWidth = content.clientWidth - 374;
              var canvasHeight = content.clientHeight - 46;
              var offsets = window.editor.scaleToFit(
                canvasWidth - canvasWidthOffset,
                canvasHeight - canvasHeightOffset,
                originalSize.width,
                originalSize.height
              );
              renderer.view.style.height = offsets.height + "px";
              renderer.view.style.width = offsets.width + "px";
              renderer.view.style.top =
                (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

              var newScale =
                offsets.width / (renderer.width / startingDevicePixelRatio);
              renderer.resize(offsets.width, offsets.height);

              stage.scale.set(1);
              baseOffsets = offsets;
              baseObject.width = offsets.width;
              baseObject.height = offsets.height;

              ///////  Update MEME
              topMemeText.position.x = offsets.width / 2;
              topMemeText.position.y = offsets.height / 16;
              window.editor.meme.top.setSize(
                Math.ceil(window.editor.meme.top.getSize() * newScale)
              );

              var style = topMemeText.style;
              style.wordWrapWidth = offsets.width * 0.9;
              style.fontSize = offsets.height / 16;
              style.padding = style.padding * newScale;
              style.strokeThickness = style.fontSize / 20;
              topMemeText.style = style;
              topMemeText.updateText(false);

              bottomMemeText.position.x = offsets.width / 2;
              bottomMemeText.position.y = offsets.height - offsets.height / 16;
              window.editor.meme.bottom.setSize(
                Math.ceil(window.editor.meme.bottom.getSize() * newScale)
              );

              style = bottomMemeText.style;
              style.fontSize = offsets.height / 16;
              style.wordWrapWidth = offsets.width * 0.9;
              style.padding = style.padding * newScale;
              style.strokeThickness = style.fontSize / 20;
              bottomMemeText.style = style;
              bottomMemeText.updateText(false);

              //////// Update Borders
              for (var x = 0; x < borderContainer.children.length; x++) {
                if (borderContainer.children[x].DesignBackground) {
                } else {
                  borderContainer.children[x].width = offsets.width;
                  borderContainer.children[x].height = offsets.height;
                }
              }

              for (x = 0; x < backgroundContainer.children.length; x++) {
                if (backgroundContainer.children[x].DesignBackground) {
                  if (
                    backgroundContainer.children[x].rotation === 1.5708 ||
                    baseObject.rotation === 1.5708 * 3
                  ) {
                    backgroundContainer.children[x].width *= newScale;
                    backgroundContainer.children[x].height *= newScale;
                    backgroundContainer.children[x].initialScaleX =
                      baseObject.scale.x;
                    backgroundContainer.children[x].initialScaleY =
                      baseObject.scale.y;
                    if (backgroundContainer.children[x].straightenScale) {
                      backgroundContainer.children[x].scale.set(
                        backgroundContainer.children[x].scale.x *
                          backgroundContainer.children[x].straightenScale,
                        backgroundContainer.children[x].scale.y *
                          backgroundContainer.children[x].straightenScale
                      );
                    }
                  } else {
                    backgroundContainer.children[x].width *= newScale;
                    backgroundContainer.children[x].height *= newScale;
                    backgroundContainer.children[x].initialScaleX =
                      baseObject.scale.x;
                    backgroundContainer.children[x].initialScaleY =
                      baseObject.scale.y;
                    if (backgroundContainer.children[x].straightenScale) {
                      backgroundContainer.children[x].scale.set(
                        backgroundContainer.children[x].scale.x *
                          backgroundContainer.children[x].straightenScale,
                        backgroundContainer.children[x].scale.y *
                          backgroundContainer.children[x].straightenScale
                      );
                    }
                  }
                  backgroundContainer.children[x].position.x *= newScale;
                  backgroundContainer.children[x].position.y *= newScale;
                } else {
                  var scale = window.editor.scaleToFill(
                    offsets.width,
                    offsets.height,
                    backgroundContainer.children[x].texture.width,
                    backgroundContainer.children[x].texture.height
                  );
                  if (
                    backgroundContainer.children[x].rotation === 1.5708 ||
                    backgroundContainer.children[x].rotation === 1.5708 * 3
                  ) {
                    scale = window.editor.scaleToFill(
                      offsets.width,
                      offsets.height,
                      backgroundContainer.children[x].texture.height,
                      backgroundContainer.children[x].texture.width
                    );
                  }
                  backgroundContainer.children[x].scale.x = scale;
                  backgroundContainer.children[x].scale.y = scale;
                  backgroundContainer.children[x].initialZoom =
                    backgroundContainer.children[x].scale.x;

                  if (backgroundContainer.children[x].anchor.x !== 0) {
                    backgroundContainer.children[x].position.x =
                      backgroundContainer.children[x].position.x * newScale;
                    backgroundContainer.children[x].position.y =
                      backgroundContainer.children[x].position.y * newScale;
                  }
                }
              }

              //////// Update Stickers
              var z = 0;
              for (var y = 0; y < stickerContainer.children.length; y++) {
                stickerContainer.children[y].position.x =
                  stickerContainer.children[y].position.x * newScale;
                stickerContainer.children[y].position.y =
                  stickerContainer.children[y].position.y * newScale;

                if (stickerContainer.children[y] instanceof PIXI.Text) {
                  style = stickerContainer.children[y].style;
                  style.fontSize = style.fontSize * newScale;
                  style.wordWrapWidth = style.wordWrapWidth * newScale;
                  if (style.letterSpacing && style.letterSpacing > 0)
                    style.letterSpacing *= newScale;
                  if (style.lineHeight && style.lineHeight > 0)
                    style.lineHeight *= newScale;
                  stickerContainer.children[y].style = style;
                  stickerContainer.children[y]._height = 0;
                  stickerContainer.children[y]._width = 0;
                  stickerContainer.children[y].updateText(false);
                  if (stickerContainer.children[y].filters !== null) {
                    for (
                      z = 0;
                      z < stickerContainer.children[y].filters.length;
                      z++
                    ) {
                      if (
                        stickerContainer.children[y].filters[z] instanceof
                        Filters.DropShadowFilter
                      ) {
                        stickerContainer.children[y].filters[z].distance =
                          stickerContainer.children[y].filters[z].distance *
                          newScale;
                      }
                    }
                  }
                  if (stickerContainer.children[y].style.align === "left") {
                    stickerContainer.children[y].pivot.set(
                      stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                  }
                  if (stickerContainer.children[y].style.align === "right") {
                    stickerContainer.children[y].pivot.set(
                      0 - stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                  }
                } else {
                  if (stickerContainer.children[y].collagePlaceholder) {
                    var img = new Image();
                    img.crossOrigin = "";
                    img.width = stickerContainer.children[y].width;
                    img.height = stickerContainer.children[y].height;
                    img.onload = function () {
                      var hotspot = new PIXI.Sprite(
                        new PIXI.Texture.fromCanvas(img)
                      );
                      hotspot.id = JSONUtils.guid();
                      hotspot.svg = true;
                      hotspot.isMask = true;
                      hotspot.anchor.set(0.5);
                      hotspot.scale.set(1);
                      hotspot.position.x =
                        stickerContainer.children[y].position.x;
                      hotspot.position.y =
                        stickerContainer.children[y].position.y;
                      hotspot.width = stickerContainer.children[y].width;
                      hotspot.height = stickerContainer.children[y].height;
                      hotspot.texture.dataUrl =
                        stickerContainer.children[y].svgPath;
                      hotspot.sourceUrl = stickerContainer.children[y].svgPath;
                      stickerContainer.children[y].mask = null;
                      stickerContainer.children[y].mask = hotspot;
                      stickerContainer.children[y].initialRect =
                        hotspot.getBounds();
                    };
                    img.onerror = function (event, txt) {
                      console.log("Something went wrong.");
                    };
                    img.src = stickerContainer.children[y].svgPath;
                    if (stickerContainer.children[y].children.length > 2) {
                      var obj = stickerContainer.children[y].children[2];
                      obj.position.x = obj.position.x * newScale;
                      obj.position.y = obj.position.y * newScale;
                      obj.width = obj.width * newScale;
                      obj.height = obj.height * newScale;
                      obj.initialZoom = obj.scale.x;
                    }
                  } else {
                    stickerContainer.children[y].width =
                      stickerContainer.children[y].width * newScale;
                    stickerContainer.children[y].height =
                      stickerContainer.children[y].height * newScale;
                  }
                }
              }

              for (z = 0; z < effectBorderContainer.children.length; z++) {
                effectBorderContainer.children[z].width =
                  EditorStore.getCustomBorder()
                    ? offsets.width - baseObject.x
                    : offsets.width;
                effectBorderContainer.children[z].height =
                  EditorStore.getCustomBorder()
                    ? offsets.height - baseObject.y
                    : offsets.height;
                effectBorderContainer.children[z].position.x =
                  EditorStore.getCustomBorder() ? baseObject.x : 0;
                effectBorderContainer.children[z].position.y =
                  EditorStore.getCustomBorder() ? baseObject.y : 0;
              }

              window.editor.setSelectedObject(
                window.editor.getSelectedObject()
              );
            }, 750);
          }
        });
        renderer.view.addEventListener(
          "dragover",
          window.editor.onDragOver,
          false
        );
        renderer.view.addEventListener("drop", window.editor.onDrop, false);
        renderer.view.addEventListener(
          "mousedown",
          window.editor.onPaintStart,
          false
        );
        renderer.view.addEventListener(
          "mousemove",
          window.editor.onPaintMove,
          false
        );
        renderer.view.addEventListener(
          "mouseup",
          window.editor.onPaintEnd,
          false
        );
        renderer.view.addEventListener(
          "touchstart",
          window.editor.onPaintStart,
          false
        );
        renderer.view.addEventListener(
          "touchmove",
          window.editor.onPaintMove,
          true
        );
        renderer.view.addEventListener(
          "touchend",
          window.editor.onPaintEnd,
          false
        );

        requestAnimationFrame(window.editor.animate);
        window.editor.stickerSelector.init();
        stage.addChild(selectorContainer);
      }
      baseObject.mirrored = false;
      baseObject.designItem = item;
      baseObject.fillColor = 0xffffff;
      EditorStore.setShowDesignPicker(false);
      LeftMenuActionCreators.MenuSelected("design");
      if (callback) {
        callback();
      }
    }
  },
  baseClick: function (event) {
    if (event.data.originalEvent.target.nodeName === "CANVAS") {
      if (
        LeftMenuStore.get() !== "paint" &&
        stage.interactive === false &&
        !EditorStore.getShowColorPicker()
      ) {
        if (LeftMenuStore.get() === "text_props") {
          LeftMenuStore.setMenuSelected("text");
        } else if (LeftMenuStore.get() === "edit_graphic") {
          AssetStore.setRememberPhotos(true);
          LeftMenuStore.setMenuSelected("photos");
        } else if (LeftMenuStore.get() === "edit_graphic_svg") {
          AssetStore.setRememberStickers(true);
          LeftMenuStore.setMenuSelected("sticker");
        }
        window.editor.setSelectedObject(null);
        window.editor.stickerSelector.cancelSelector();
      }
    }
  },
  customBorder: function (width, color) {
    baseObject.width =
      window.editor.getRenderer().width / startingDevicePixelRatio - width * 2;
    baseObject.scale.y = baseObject.scale.x;
    if (baseObject.straightenScale) {
      baseObject.scale.set(
        baseObject.scale.x * baseObject.straightenScale,
        baseObject.scale.y * baseObject.straightenScale
      );
    }

    renderer.backgroundColor = color;
    if (
      effectBorderContainer &&
      effectBorderContainer.children &&
      effectBorderContainer.children.length > 0
    ) {
      for (var x = 0; x < effectBorderContainer.children.length; x++) {
        effectBorderContainer.children[x].width = baseObject.width;
        effectBorderContainer.children[x].height = baseObject.height;
        effectBorderContainer.children[x].position.x = width;
        effectBorderContainer.children[x].position.y = width;
      }
    }
  },
  customBorderRemove: function () {
    baseObject.width =
      window.editor.getRenderer().width / startingDevicePixelRatio;
    baseObject.height =
      window.editor.getRenderer().height / startingDevicePixelRatio;
    if (baseObject.straightenScale) {
      baseObject.scale.set(
        baseObject.scale.x * baseObject.straightenScale,
        baseObject.scale.y * baseObject.straightenScale
      );
    }
    EditorStore.setCustomBorder(false);
    if (
      effectBorderContainer &&
      effectBorderContainer.children &&
      effectBorderContainer.children.length > 0
    ) {
      for (var x = 0; x < effectBorderContainer.children.length; x++) {
        effectBorderContainer.children[x].width = baseObject.width;
        effectBorderContainer.children[x].height = baseObject.height;
        effectBorderContainer.children[x].position.x = 0;
        effectBorderContainer.children[x].position.y = 0;
      }
    }
  },
  drawStraightenGrid: function () {
    if (!stage) return;
    if (straightenGrid && straightenGrid.parent === null) {
      stage.addChild(straightenGrid);
      return;
    }
    straightenGrid = new PIXI.Graphics();
    stage.addChild(straightenGrid);
    var maxWidth = Math.min(stage.width, 5000);
    var maxHeight = Math.min(stage.height, 5000);
    for (var x = 26; x < maxWidth; x += 26) {
      straightenGrid.lineStyle(1, 0xffffff, 0.3);
      straightenGrid.moveTo(x, 0);
      straightenGrid.lineTo(x, stage.height);
      straightenGrid.lineStyle(1, 0, 0.3);
      straightenGrid.moveTo(x + 1, 0);
      straightenGrid.lineTo(x + 1, stage.height);
    }
    for (var y = 26; y < maxHeight; y += 26) {
      straightenGrid.lineStyle(1, 0xffffff, 0.3);
      straightenGrid.moveTo(0, y);
      straightenGrid.lineTo(stage.width, y);
      straightenGrid.lineStyle(1, 0, 0.3);
      straightenGrid.moveTo(0, y + 1);
      straightenGrid.lineTo(stage.width, y + 1);
    }
  },
  hideStraightenGrid: function () {
    if (straightenGrid) {
      stage.removeChild(straightenGrid);
    }
  },
  initGraphicCrop: function (object) {
    EditorStore.setShowSpinner(true);
    window.editor.loadTextureFromUrl(
      object.sourceUrl,
      function (texture) {
        const content = document.getElementById("container");
        const container = document.getElementById("mainContent");
        const mainCanvas = renderer.view;

        mainCanvas.style.display = "none";
        const offsets = window.editor.scaleToFit(
          content.clientWidth - 410,
          content.clientHeight - 112,
          texture.width,
          texture.height
        );
        cutoutRenderer = new PIXI.WebGLRenderer(offsets.width, offsets.height, {
          antialias: true,
          transparent: true,
          autoresize: true,
          preserveDrawingBuffer: true,
        });
        window.editor.setupCursors(cutoutRenderer);
        const canvasHeight = content.clientHeight - 46;

        cutoutRenderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

        cutoutRenderer.view.style.display = "block";
        container.appendChild(cutoutRenderer.view);
        requestAnimationFrame(window.editor.animateCutout);

        imgObject = new PIXI.Sprite(texture);
        texture.dataUrl = object.sourceUrl;
        cutoutSize = { width: texture.width, height: texture.height };

        cutoutStage.addChild(imgObject);

        cutoutContainer = new PIXI.Graphics();
        cutoutStage.addChild(cutoutContainer);

        cutoutMask = new PIXI.Graphics();
        cutoutMask.renderable = false;

        imgObject.addChild(cutoutMask);

        cutoutStage.width = offsets.width;
        cutoutStage.height = offsets.height;

        window.addEventListener("resize", function () {
          if (cutoutRenderer) {
            var canvasWidth = content.clientWidth - 374;
            var canvasHeight = content.clientHeight - 46;
            var offsets = window.editor.scaleToFit(
              canvasWidth - canvasWidthOffset,
              canvasHeight - canvasHeightOffset,
              cutoutSize.width,
              cutoutSize.height
            );
            cutoutRenderer.view.style.height = offsets.height + "px";
            cutoutRenderer.view.style.width = offsets.width + "px";
            cutoutRenderer.view.style.top =
              (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
          }
        });
        cutoutRenderer.view.addEventListener(
          "mousedown",
          window.editor.onCutoutStart,
          false
        );
        cutoutRenderer.view.addEventListener(
          "mousemove",
          window.editor.onCutoutMove,
          false
        );
        cutoutRenderer.view.addEventListener(
          "mouseup",
          window.editor.onCutoutEnd,
          false
        );
        cutoutRenderer.view.addEventListener(
          "touchstart",
          window.editor.onCutoutStart,
          false
        );
        cutoutRenderer.view.addEventListener(
          "touchmove",
          window.editor.onCutoutMove,
          true
        );
        cutoutRenderer.view.addEventListener(
          "touchend",
          window.editor.onCutoutEnd,
          false
        );
        self.cutoutInitialZoom = cutoutStage.scale.x;
        cutoutStage.interactive = true;
        cutoutStage.buttonMode = true;
        window.editor.initCutoutCrop();
        StickerCutoutSelector.init();
        StickerCutoutSelector.setCropCutoutObject(imgObject);
        origCutoutScale = imgObject.scale.x;
        origCutoutStageScale = cutoutStage.scale.x;
        EditorStore.setShowSpinner(false);
        window.editor.autoSave();
      },
      7000
    );
  },
  cropGraphicFinish: function () {
    cutoutStage.removeChild(CropCutoutSelector.getCropCutoutContainer());
    var scale = 1 / cutoutStage.scale.x;
    var rect = new PIXI.Rectangle(
      cropCutoutObject.x,
      cropCutoutObject.y,
      cropCutoutObject.width,
      cropCutoutObject.height
    );
    if (rect.width / scale < 50 || rect.height / scale < 50) {
      cutoutStage.scale.set(1 / scale);
      return;
    }
    if (rect.x - 10 * scale > 0) {
      rect.x = rect.x - 10 * scale;
    }
    if (rect.y - 10 * scale > 0) {
      rect.y = rect.y - 10 * scale;
    }
    if (rect.height + rect.y + 20 * scale < imgObject.height) {
      rect.height = rect.height + 20 * scale;
    }
    if (rect.width + rect.x + 20 * scale < imgObject.width) {
      rect.width = rect.width + 20 * scale;
    }
    cutoutRenderer.autoresize = false;

    imgObject.mask = null;
    imgObject.texture = new PIXI.Texture(imgObject.texture, rect);
    var content = document.getElementById("container");
    var canvasWidth = content.clientWidth - 374;
    var canvasHeight = content.clientHeight - 46;
    var offsets = window.editor.scaleToFit(
      canvasWidth - 24,
      canvasHeight - canvasHeightOffset,
      rect.width,
      rect.height
    );
    imgObject.scale.set(offsets.width / imgObject.texture.width);
    imgObject.parent.scale.set(1);
    cutoutRenderer.resize(offsets.width, offsets.height);
    cutoutRenderer.view.style.height = offsets.height + "px";
    cutoutRenderer.view.style.width = offsets.width + "px";
    cutoutRenderer.view.style.top =
      (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
    cutoutRenderer.render(cutoutStage);
    const url = cutoutRenderer.view.toDataURL();
    LeftMenuActionCreators.saveCutout(url);
    window.editor.removeCutout();
  },
  cropGraphicCancel: function () {
    if (renderer) {
      var mainCanvas = renderer.view;
      mainCanvas.style.display = "block";
      if (cutoutRenderer) {
        try {
          cutoutRenderer.destroy(true);
        } catch (e) {}
        cutoutRenderer = null;
        cutoutContainer.destroy();
        cutoutContainer = null;
        cutoutMask.destroy();
        cutoutMask = null;
        imgObject = null;
        cutoutStage.removeChildren();
      }
    }
  },
  initCrop: function () {
    if (!stage.children[0].cropBackground) {
      var dUrl = baseObject.texture.dataUrl;
      baseObject.texture = new PIXI.Texture(baseObject.texture);
      baseObject.texture.dataUrl = dUrl;
      CropSelector.fixedAspect = false;
      var content = document.getElementById("container");
      var canvasWidth = content.clientWidth - 374;
      var canvasHeight = content.clientHeight - 46;

      var offsets = window.editor.scaleToFit(
        canvasWidth - canvasWidthOffset,
        canvasHeight - canvasHeightOffset,
        baseObject.texture.width,
        baseObject.texture.height
      );
      if (
        baseObject.rotation === 1.5708 ||
        baseObject.rotation === 1.5708 * 3
      ) {
        offsets = window.editor.scaleToFit(
          canvasWidth - 24,
          canvasHeight - 52,
          baseObject.texture.height,
          baseObject.texture.width
        );
        baseObject.scale.set(offsets.width / baseObject.texture.height);
      } else {
        baseObject.scale.set(offsets.width / baseObject.texture.width);
      }

      originalSize = { width: offsets.width, height: offsets.height };

      renderer.resize(offsets.width, offsets.height);
      renderer.view.style.height = offsets.height + "px";
      renderer.view.style.width = offsets.width + "px";
      renderer.view.style.top =
        (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

      var origImage = new PIXI.Sprite(baseObject.texture);
      origImage.width = baseObject.width;
      origImage.height = baseObject.height;
      origImage.alpha = 0.5;
      origImage.cropBackground = true;
      origImage.anchor = baseObject.anchor;
      origImage.position.set(origImage.width / 2, origImage.height / 2);
      origImage.rotation = baseObject.rotation;
      if (baseObject.straightenScale) {
        console.log("straighten scale used");
        origImage.scale.set(origImage.scale.x * baseObject.straightenScale);
        baseObject.scale.set(baseObject.scale.x * baseObject.straightenScale);
      }
      stage.addChildAt(origImage, 0);
      baseObject.position.set(baseObject.width / 2, baseObject.height / 2);

      var mask = new PIXI.Graphics();

      mask.beginFill(0xffffff, 1);
      mask.drawRect(
        offsets.width / 2 - offsets.width * 0.375,
        offsets.height / 2 - offsets.height * 0.375,
        offsets.width * 0.75,
        offsets.height * 0.75
      );
      mask.endFill();
      baseObject.mask = mask;

      var sticker = PIXI.Sprite.fromImage(
        "https://static.pizap.com/html5/resources/editor/clear.png"
      );
      sticker.interactive = true;
      sticker.buttonMode = true;
      sticker.position.x = offsets.width / 2;
      sticker.position.y = offsets.height / 2;
      sticker.anchor.set(0.5);
      sticker.scale.set(1);
      sticker.id = JSONUtils.guid();
      sticker.width = offsets.width * 0.75;
      sticker.height = offsets.height * 0.75;
      sticker.crop = true;
      stickerContainer.addChild(sticker);

      sticker
        .on("mousedown", window.editor.onCropStart)
        .on("touchstart", window.editor.onCropStart)
        .on("mouseup", window.editor.onCropEnd)
        .on("mouseupoutside", window.editor.onCropEnd)
        .on("touchend", window.editor.onCropEnd)
        .on("touchendoutside", window.editor.onCropEnd)
        .on("mousemove", window.editor.onCropMove)
        .on("touchmove", window.editor.onCropMove);

      CropSelector.setCropObject(sticker);
      CropSelector.init();
      CropSelector.updateSelector();
      tmpFilters = baseObject.filters;
      baseObject.filters = null;
      stage.addChild(CropSelector.getCropContainer());
    }
  },
  setGraphicCrop: function () {
    if (!cutoutStage.children[0].cropBackground) {
      CropCutoutSelector.fixedAspect = false;
      var mask = new PIXI.Graphics();
      mask.beginFill(0xffffff, 1);
      mask.drawRect(
        imgObject.width * 0.125 * imgObject.parent.scale.x,
        imgObject.height * 0.125 * imgObject.parent.scale.y,
        imgObject.width * 0.75 * imgObject.parent.scale.x,
        imgObject.height * 0.75 * imgObject.parent.scale.y
      );
      mask.endFill();
      imgObject.mask = mask;

      var origImage = new PIXI.Sprite(imgObject.texture);
      origImage.width = imgObject.width;
      origImage.height = imgObject.height;
      origImage.alpha = 0.5;
      origImage.cropBackground = true;
      origImage.anchor = imgObject.anchor;
      origImage.rotation = imgObject.rotation;
      cutoutStage.addChildAt(origImage, 0);
      var sticker = PIXI.Sprite.fromImage(
        "https://static.pizap.com/html5/resources/editor/clear.png"
      );
      sticker.interactive = true;
      sticker.buttonMode = true;
      sticker.position.x = imgObject.width * 0.125;
      sticker.position.y = imgObject.height * 0.125;
      sticker.scale.set(1);
      sticker.id = JSONUtils.guid();
      sticker.width = imgObject.width * 0.75;
      sticker.height = imgObject.height * 0.75;
      sticker.crop = true;
      sticker.cursor = "move";
      cutoutStage.addChild(sticker);

      sticker
        .on("mousedown", window.editor.onCutoutCropStart)
        .on("touchstart", window.editor.onCutoutCropStart)
        .on("mouseup", window.editor.onCutoutCropEnd)
        .on("mouseupoutside", window.editor.onCutoutCropEnd)
        .on("touchend", window.editor.onCutoutCropEnd)
        .on("touchendoutside", window.editor.onCutoutCropEnd)
        .on("mousemove", window.editor.onCutoutCropMove)
        .on("touchmove", window.editor.onCutoutCropMove);

      cropCutoutObject = sticker;
      CropCutoutSelector.init();
      cutoutStage.addChild(CropCutoutSelector.getCropCutoutContainer());
    }
  },
  initCutoutCrop: function () {
    if (!cutoutStage.children[0].cropBackground) {
      CropCutoutSelector.fixedAspect = false;
      var mask = new PIXI.Graphics();
      mask.beginFill(0xffffff, 1);
      mask.drawRect(
        imgObject.width * 0.125 * imgObject.parent.scale.x,
        imgObject.height * 0.125 * imgObject.parent.scale.y,
        imgObject.width * 0.75 * imgObject.parent.scale.x,
        imgObject.height * 0.75 * imgObject.parent.scale.y
      );
      mask.endFill();
      imgObject.mask = mask;

      var origImage = new PIXI.Sprite(imgObject.texture);
      origImage.width = imgObject.width;
      origImage.height = imgObject.height;
      origImage.alpha = 0.5;
      origImage.cropBackground = true;
      origImage.anchor = imgObject.anchor;
      origImage.rotation = imgObject.rotation;
      cutoutStage.addChildAt(origImage, 0);

      var sticker = PIXI.Sprite.fromImage(
        "https://static.pizap.com/html5/resources/editor/clear.png"
      );
      sticker.interactive = true;
      sticker.buttonMode = true;
      sticker.position.x = imgObject.width * 0.125;
      sticker.position.y = imgObject.height * 0.125;
      sticker.scale.set(1);
      sticker.id = JSONUtils.guid();
      sticker.width = imgObject.width * 0.75;
      sticker.height = imgObject.height * 0.75;
      sticker.crop = true;
      sticker.cursor = "move";
      cutoutStage.addChild(sticker);

      sticker
        .on("mousedown", window.editor.onCutoutCropStart)
        .on("touchstart", window.editor.onCutoutCropStart)
        .on("mouseup", window.editor.onCutoutCropEnd)
        .on("mouseupoutside", window.editor.onCutoutCropEnd)
        .on("touchend", window.editor.onCutoutCropEnd)
        .on("touchendoutside", window.editor.onCutoutCropEnd)
        .on("mousemove", window.editor.onCutoutCropMove)
        .on("touchmove", window.editor.onCutoutCropMove);

      cropCutoutObject = sticker;
      CropCutoutSelector.init();
      cutoutStage.addChild(CropCutoutSelector.getCropCutoutContainer());
    }
  },
  updateCropRect: function (width, height) {
    var cropper = this.getCropSelector();

    cropper.width = width;
    cropper.height = height;

    var mask = new PIXI.Graphics();
    mask.beginFill(0xffffff, 1);
    mask.drawRect(
      cropper.x - cropper.width / 2,
      cropper.y - cropper.height / 2,
      cropper.width,
      cropper.height
    );
    mask.endFill();
    baseObject.mask = mask;
    CropSelector.setCropObject(cropper);
  },
  cropCancel: function () {
    if (stage && stage.children && stage.children[0].cropBackground) {
      stage.removeChildAt(0);
      baseObject.mask = null;
      var cropSelector = this.getCropSelector();
      cropSelector.parent.removeChild(cropSelector);
      baseObject.filters = tmpFilters;
      stage.removeChild(CropSelector.getCropContainer());
      EditorStore.setEditorMode("");
      baseObject.initialScaleX = baseObject.scale.x;
      baseObject.initialScaleY = baseObject.scale.y;
    }
  },
  cropCutoutCancel: function () {
    if (
      cutoutStage &&
      cutoutStage.children &&
      cutoutStage.children[0]?.cropBackground
    ) {
      cutoutStage.removeChildAt(0);
      imgObject.mask = null;

      window.editor.getCutoutStage().removeChild(cropCutoutObject);
      window.editor.getCutoutStage().removeChild(cropCutoutContainer);
    }
  },
  cropFinish: function () {
    var cropSelector = this.getCropSelector();
    this.CommandManager.execute({
      execute: function () {
        var dUrl = baseObject.texture.dataUrl;
        baseObject.texture = new PIXI.Texture(baseObject.texture);
        var rect = new PIXI.Rectangle(
          Math.floor(
            (cropSelector.x - cropSelector.width / 2) / baseObject.scale.x
          ),
          Math.floor(
            (cropSelector.y - cropSelector.height / 2) / baseObject.scale.y
          ),
          Math.floor(cropSelector.width / baseObject.scale.x),
          Math.floor(cropSelector.height / baseObject.scale.y)
        );
        if (rect.x < 0) rect.x = 0;
        if (rect.y < 0) rect.y = 0;
        if (
          baseObject.rotation === 1.5708 ||
          baseObject.rotation === 1.5708 * 3
        ) {
          rect = new PIXI.Rectangle(rect.y, rect.x, rect.height, rect.width);
        }
        if (rect.x + rect.width > baseObject.texture.width) {
          rect.width = baseObject.texture.width - rect.x;
        }
        if (rect.y + rect.height > baseObject.texture.height) {
          rect.height = baseObject.texture.height - rect.y;
        }

        if (baseObject.anchor.x === 1) {
          rect.x = baseObject.texture.width - rect.x - rect.width;
        }
        if (baseObject.anchor.y === 1) {
          rect.y = baseObject.texture.height - rect.y - rect.height;
        }
        var texture2 = new PIXI.Texture(baseObject.texture, rect);

        baseObject.mask = null;
        baseObject.texture = texture2;
        baseObject.texture.dataUrl = dUrl;
        ProjectAsset.createAsset(baseObject.texture);
        var content = document.getElementById("container");
        var canvasWidth = content.clientWidth - 374;
        var canvasHeight = content.clientHeight - 46;
        var offsets = window.editor.scaleToFit(
          canvasWidth - canvasWidthOffset,
          canvasHeight - canvasHeightOffset,
          rect.width,
          rect.height
        );
        if (
          baseObject.rotation === 1.5708 ||
          baseObject.rotation === 1.5708 * 3
        ) {
          offsets = window.editor.scaleToFit(
            canvasWidth - 24,
            canvasHeight - 52,
            rect.height,
            rect.width
          );
          baseObject.scale.set(offsets.width / baseObject.texture.height);
          originalSize = { width: texture2.height, height: texture2.width };
          baseOffsets = offsets;
        } else {
          baseObject.scale.set(offsets.width / baseObject.texture.width);
          originalSize = { width: texture2.width, height: texture2.height };
          baseOffsets = offsets;
        }
        baseObject.position.set(baseObject.width / 2, baseObject.height / 2);
        renderer.resize(offsets.width, offsets.height);
        renderer.view.style.height = offsets.height + "px";
        renderer.view.style.width = offsets.width + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
        memeWordWrapWidth = baseObject.width * 0.9;
        var s = topMemeText.style;
        s.wordWrapWidth = memeWordWrapWidth;
        topMemeText.style = s;
        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;
        s = bottomMemeText.style;
        s.wordWrapWidth = memeWordWrapWidth;
        bottomMemeText.style = s;
        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;

        if (
          effectBorderContainer &&
          effectBorderContainer.children &&
          effectBorderContainer.children.length > 0
        ) {
          for (var i = 0; i < effectBorderContainer.children.length; i++) {
            effectBorderContainer.children[i].width = stage.width;
            effectBorderContainer.children[i].height = stage.height;
          }
        }
        if (baseObject.straightenScale) {
          baseObject.scale.set(baseObject.scale.x * baseObject.straightenScale);
        }

        window.editor.cropCancel();
      },
      unexecute: function () {
        baseObject.texture = new PIXI.Texture(baseObject.texture);
        var content = document.getElementById("container");
        var canvasWidth = content.clientWidth - 374;
        var canvasHeight = content.clientHeight - 46;

        var offsets = window.editor.scaleToFit(
          canvasWidth - canvasWidthOffset,
          canvasHeight - canvasHeightOffset,
          baseObject.texture.width,
          baseObject.texture.height
        );
        if (
          baseObject.rotation === 1.5708 ||
          baseObject.rotation === 1.5708 * 3
        ) {
          offsets = window.editor.scaleToFit(
            canvasWidth - 24,
            canvasHeight - 52,
            baseObject.texture.height,
            baseObject.texture.width
          );
          baseObject.scale.set(offsets.width / baseObject.texture.height);
        } else {
          baseObject.scale.set(offsets.width / baseObject.texture.width);
        }
        originalSize = { width: offsets.width, height: offsets.height };

        renderer.resize(offsets.width, offsets.height);
        renderer.view.style.height = offsets.height + "px";
        renderer.view.style.width = offsets.width + "px";
        renderer.view.style.top =
          (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

        memeWordWrapWidth = baseObject.width * 0.9;
        var s = topMemeText.style;
        s.wordWrapWidth = memeWordWrapWidth;
        topMemeText.style = s;
        topMemeText.position.x = offsets.width / 2;
        topMemeText.position.y = offsets.height / 16;
        s = bottomMemeText.style;
        s.wordWrapWidth = memeWordWrapWidth;
        bottomMemeText.style = s;
        bottomMemeText.position.x = offsets.width / 2;
        bottomMemeText.position.y = offsets.height - offsets.height / 16;
      },
    });
  },
  rotate: function () {
    var content = document.getElementById("container");
    var canvasWidth = content.clientWidth - 374;
    var canvasHeight = content.clientHeight - 46;
    var rotation = baseObject.rotation;
    var oldSize = { width: originalSize.width, height: originalSize.height };
    var oldHeight = baseObject.height;
    var oldWidth = baseObject.width;
    var rendererHeight = renderer.view.style.height;
    var rendererWidth = renderer.view.style.width;
    var rendererTop = renderer.view.style.top;

    this.CommandManager.execute({
      execute: function () {
        var offsets;
        baseObject.rotation += 1.5708;
        if (baseObject.rotation === 1.5708 + 1.5708 + 1.5708 + 1.5708) {
          originalSize = {
            width: baseObject.texture.width,
            height: baseObject.texture.height,
          };
          baseObject.rotation = 0;
          offsets = window.editor.scaleToFit(
            canvasWidth - canvasWidthOffset,
            canvasHeight - canvasHeightOffset,
            originalSize.width,
            originalSize.height
          );
          baseOffsets = offsets;
          renderer.view.style.height = offsets.height + "px";
          renderer.view.style.width = offsets.width + "px";
          renderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
          renderer.resize(offsets.width, offsets.height);
          baseObject.width = offsets.width;
          baseObject.height = offsets.height;
          baseObject.position.set(baseObject.width / 2, baseObject.height / 2);

          topMemeText.position.x = offsets.width / 2;
          topMemeText.position.y = offsets.height / 16;
          bottomMemeText.position.x = offsets.width / 2;
          bottomMemeText.position.y = offsets.height - offsets.height / 16;
        }
        if (baseObject.rotation === 1.5708) {
          offsets = window.editor.scaleToFit(
            canvasWidth - 24,
            canvasHeight - 52,
            originalSize.height,
            originalSize.width
          );
          baseOffsets = offsets;
          renderer.view.style.height = offsets.height + "px";
          renderer.view.style.width = offsets.width + "px";
          renderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
          renderer.resize(offsets.width, offsets.height);
          baseObject.width = offsets.height;
          baseObject.height = offsets.width;
          originalSize = {
            width: baseObject.texture.height ? baseObject.texture.height : 576,
            height: baseObject.texture.width ? baseObject.texture.width : 576,
          };
          baseObject.position.set(baseObject.height / 2, baseObject.width / 2);

          topMemeText.position.x = offsets.width / 2;
          topMemeText.position.y = offsets.height / 16;
          bottomMemeText.position.x = offsets.width / 2;
          bottomMemeText.position.y = offsets.height - offsets.height / 16;
        }
        if (baseObject.rotation === 1.5708 * 2) {
          originalSize = {
            width: baseObject.texture.width,
            height: baseObject.texture.height,
          };
          offsets = window.editor.scaleToFit(
            canvasWidth - 24,
            canvasHeight - 52,
            originalSize.width,
            originalSize.height
          );
          baseOffsets = offsets;
          renderer.view.style.height = offsets.height + "px";
          renderer.view.style.width = offsets.width + "px";
          renderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
          renderer.resize(offsets.width, offsets.height);
          baseObject.width = offsets.width;
          baseObject.height = offsets.height;
          baseObject.position.set(baseObject.width / 2, baseObject.height / 2);

          topMemeText.position.x = offsets.width / 2;
          topMemeText.position.y = offsets.height / 16;
          bottomMemeText.position.x = offsets.width / 2;
          bottomMemeText.position.y = offsets.height - offsets.height / 16;
        }
        if (baseObject.rotation === 1.5708 * 3) {
          offsets = window.editor.scaleToFit(
            canvasWidth - 24,
            canvasHeight - 52,
            originalSize.height,
            originalSize.width
          );
          baseOffsets = offsets;
          renderer.view.style.height = offsets.height + "px";
          renderer.view.style.width = offsets.width + "px";
          renderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
          renderer.resize(offsets.width, offsets.height);
          baseObject.width = offsets.height;
          baseObject.height = offsets.width;
          baseObject.position.set(baseObject.height / 2, baseObject.width / 2);

          originalSize = {
            width: baseObject.texture.height,
            height: baseObject.texture.width,
          };
          topMemeText.position.x = offsets.width / 2;
          topMemeText.position.y = offsets.height / 16;
          bottomMemeText.position.x = offsets.width / 2;
          bottomMemeText.position.y = offsets.height - offsets.height / 16;
        }
        baseObject.rotate = baseObject.rotation;
        baseObject.initialScaleX = baseObject.scale.x;
        baseObject.initialScaleY = baseObject.scale.y;
        stage.removeChild(straightenGrid);
        straightenGrid = null;
      },
      unexecute: function () {
        baseObject.rotation = rotation;
        renderer.view.style.height = rendererHeight;
        renderer.view.style.width = rendererWidth;
        renderer.view.style.top = rendererTop;
        renderer.resize(oldWidth, oldHeight);
        if (
          baseObject.rotation === 1.5708 * 3 ||
          baseObject.rotation === 1.5708
        ) {
          baseObject.height = oldWidth;
          baseObject.width = oldHeight;
        } else {
          baseObject.height = oldHeight;
          baseObject.width = oldWidth;
        }
        originalSize = oldSize;
      },
    });
  },
  rotateHotspot: function (obj) {
    var rotation = obj.rotation;
    var anchorX = obj.anchor.x;
    var anchorY = obj.anchor.y;
    var oldHeight = obj.height;
    var oldWidth = obj.width;

    this.CommandManager.execute({
      execute: function () {
        const rect = obj.parent.initialRect;
        obj.rotation += 1.5708;
        if (
          obj.rotation === 1.5708 + 1.5708 + 1.5708 + 1.5708 ||
          obj.rotation === 1.5708 * 2
        ) {
          if (obj.rotation === 1.5708 + 1.5708 + 1.5708 + 1.5708) {
            obj.rotation = 0;
          }
          var scale = window.editor.scaleToFill(
            rect.width,
            rect.height,
            obj.texture.width,
            obj.texture.height
          );
          obj.scale.x = scale;
          obj.scale.y = scale;
        }
        if (obj.rotation === 1.5708 || obj.rotation === 1.5708 * 3) {
          scale = window.editor.scaleToFill(
            rect.width,
            rect.height,
            obj.texture.height,
            obj.texture.width
          );
          obj.scale.x = scale;
          obj.scale.y = scale;
        }
        obj.initialZoom = obj.scale.x;

        obj.position.x = rect.width / 2;
        obj.position.y = rect.height / 2;
      },
      unexecute: function () {
        obj.rotation = rotation;
        obj.anchor.set(anchorX, anchorY);
        if (obj.rotation === 1.5708 * 3 || obj.rotation === 1.5708) {
          obj.height = oldWidth;
          obj.width = oldHeight;
        } else {
          obj.height = oldHeight;
          obj.width = oldWidth;
        }
      },
    });
  },
  getSaveScale: function () {
    var offsets = window.editor.scaleSave(
      5016,
      5016,
      originalSize.width,
      originalSize.height
    );
    return offsets.width / baseOffsets.width;
  },
  getOriginalSize: function () {
    return originalSize;
  },
  getBaseObject: function () {
    return baseObject;
  },
  getStickerContainer: function () {
    return stickerContainer;
  },
  animate: function () {
    requestAnimationFrame(window.editor.animate);
    try {
      renderer.render(stage);
    } catch (error) {
      console.log("Please Refresh the Web Browser.");
    }
  },
  animateCutout: function () {
    if (cutoutRenderer && cutoutRenderer.view.style.display === "block") {
      requestAnimationFrame(window.editor.animateCutout);
      cutoutRenderer.render(cutoutStage);
    }
  },
  resizeImage: function (img, scale, callback) {
    var canvas = document.createElement("canvas");
    var i;
    if (typeof img === "string" || img instanceof String) {
      i = document.createElement("img");
      i.onload = function () {
        canvas.width = i.width * scale;
        canvas.height = i.height * scale;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(i, 0, 0, canvas.width, canvas.height);
        callback(canvas);
      };
      i.src = img;
    } else {
      i = img;
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(i, 0, 0, canvas.width, canvas.height);
      callback(canvas);
    }
  },


  // Helper function to create a checkerboard pattern
  createCheckerboardPattern: function(ctx, cellSize) {
    const patternCanvas = document.createElement('canvas');
    patternCanvas.width = cellSize * 2;
    patternCanvas.height = cellSize * 2;
    const pctx = patternCanvas.getContext('2d');
  
    // Draw white squares
    pctx.fillStyle = "#ffffff";
    pctx.fillRect(0, 0, cellSize, cellSize);
    pctx.fillRect(cellSize, cellSize, cellSize, cellSize);
  
    // Draw light grey squares
    pctx.fillStyle = "#c0c0c0"; // adjust to your preferred grey tone
    pctx.fillRect(cellSize, 0, cellSize, cellSize);
    pctx.fillRect(0, cellSize, cellSize, cellSize);
  
    return ctx.createPattern(patternCanvas, "repeat");
  },


  getThumbnail: function (callback) {
    let selItem = null;
    try {
      if (
        LeftMenuStore.get() === "edit_graphic" ||
        LeftMenuStore.get() === "text_props" ||
        LeftMenuStore.get() !== null
      ) {
        selItem = window.editor.getSelectedObject();
      } else {
        selItem = window.editor.getSelectedObject();
        window.editor.setSelectedObject(selItem);
      }
      renderer.render(stage);
      var canvas = document.createElement("canvas");
      var origWidth = renderer.width;
      var origHeight = renderer.height;
      var targetWidth, targetHeight;
      // If the original width is greater than or equal to the height, fix width to 512.
      if (origWidth >= origHeight) {
        targetWidth = 512;
        targetHeight = Math.round(origHeight * (512 / origWidth));
      } else {
        // Otherwise, fix height to 512.
        targetHeight = 512;
        targetWidth = Math.round(origWidth * (512 / origHeight));
      }
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      var ctx = canvas.getContext("2d");

      // Add white background
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, targetWidth, targetHeight);

      ctx.drawImage(renderer.view, 0, 0, targetWidth, targetHeight);
      canvas.toBlob(function (blob) {
        if (LeftMenuStore.get() !== "text_props") {
          window.editor.setSelectedObject(selItem);
        }
        callback(blob, targetWidth, targetHeight);
      }, "image/jpeg", 0.6);
    } catch (error) {
      console.log(error);
    }
  },



getPreview: function (callback) {
  let selItem = null;
  try {
    if (
      LeftMenuStore.get() === "edit_graphic" ||
      LeftMenuStore.get() === "text_props" ||
      LeftMenuStore.get() !== null
    ) {
      selItem = window.editor.getSelectedObject();
    } else {
      selItem = window.editor.getSelectedObject();
      window.editor.setSelectedObject(selItem);
    }
    renderer.render(stage);

    // Get the main canvas element (from the renderer)
    var canvas = window.editor.getRenderer().view;
    var origWidth = canvas.width;
    var origHeight = canvas.height;
    var targetWidth, targetHeight;

    // Determine new dimensions based on 2048 max dimension logic
    if (origWidth >= origHeight) {
      targetWidth = 1024;
      targetHeight = Math.round(origHeight * (1024 / origWidth));
    } else {
      targetHeight = 1024;
      targetWidth = Math.round(origWidth * (1024 / origHeight));
    }

    // Create an offscreen canvas with the new dimensions.
    var previewCanvas = document.createElement("canvas");
    previewCanvas.width = targetWidth;
    previewCanvas.height = targetHeight;
    var ctx = previewCanvas.getContext("2d");

    // Instead of a solid white background, fill with a checkerboard pattern.
    var pattern = this.createCheckerboardPattern(ctx, 30); // 30px cell size, adjust as needed
    ctx.fillStyle = pattern;
    ctx.fillRect(0, 0, targetWidth, targetHeight);

    // Draw the main canvas over the pattern. Transparent areas will show the checkerboard.
    ctx.drawImage(canvas, 0, 0, targetWidth, targetHeight);

    // Convert the preview canvas to a blob and pass it to the callback.
    previewCanvas.toBlob(function (blobPreview) {
      callback(blobPreview, targetWidth, targetHeight);
    }, "image/jpeg", 0.6);
  } catch (error) {
    console.log(error);
  }
},


  rotateImage: function (img, srcOrientation) {
    var width = img.width,
      height = img.height,
      canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }
    ctx.drawImage(img, 0, 0);
    return canvas;
  },

  getRenderer: function () {
    return renderer;
  },

  getStage: function () {
    return stage;
  },
  getPaintContainer: function () {
    return paintContainer;
  },
  getCutoutStage: function () {
    return cutoutStage;
  },
  applyCurve: function (object, curve) {
    if (!object || !object.style) return;
    if (curve === 0) {
      object.curve = curve;
      if (object.curveSprite) {
        window.editor.getStickerContainer().removeChild(object.curveSprite);
      }
      object.visible = true;
      window.editor.setSelectedObject(object);
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
    } else {
      object.curve = curve;
      if (
        object.context.measureText(object.text).width * 1.3 >
        object.style.wordWrapWidth
      ) {
        const style = object.style.clone();
        object.style = {
          ...style,
          wordWrapWidth: object.context.measureText(object.text).width * 1.3,
        };
        object.updateText(true);
      }
      const curveRadian = Math.abs(curve) * (Math.PI / 180);
      const circumference = (360 / Math.abs(curve)) * object.width;
      const radius = circumference / 2 / Math.PI;
      const startDegree = (curve > 0 ? Math.PI : 2 * Math.PI) - curveRadian / 2;
      const degreeIncrement = curveRadian / 100;
      let points = [];

      for (let i = 0; i <= curveRadian; i += degreeIncrement) {
        let degree = startDegree + i;
        const radian = degree + Math.PI / 2;
        if (degree > 360) degree -= 360;
        points.push(
          new PIXI.Point(Math.cos(radian) * radius, Math.sin(radian) * radius)
        );
      }
      if (curve < 0) points = points.reverse();
      const container = new PIXI.Container();
      const rope = new PIXI.mesh.Rope(object.texture, points);
      container.addChild(rope);

      const bounds = container.getLocalBounds();
      const matrix = new PIXI.Matrix();
      matrix.tx = -bounds.x;
      matrix.ty = -bounds.y;

      const renderTexture = PIXI.RenderTexture.create(
        bounds.width,
        bounds.height
      );
      window.editor
        .getRenderer()
        .render(container, renderTexture, true, matrix, false);

      const sprite = PIXI.Sprite.from(renderTexture);
      sprite.position.set(object.x, object.y);
      if (object.curveSprite)
        window.editor.getStickerContainer().removeChild(object.curveSprite);
      sprite.id = JSONUtils.guid();
      sprite.childId = object.id;
      sprite.curveSprite = true;
      sprite.interactive = true;
      sprite.buttonMode = true;
      sprite.visible = true;
      sprite.anchor.set(0.5);
      if (object?.filters?.length > 0) {
        sprite.filters = object.filters;
      }
      sprite
        .on("mousedown", window.editor.onDragStart)
        .on("touchstart", window.editor.onDragStart)
        .on("mouseup", window.editor.onDragEnd)
        .on("mouseupoutside", window.editor.onDragEnd)
        .on("touchend", window.editor.onDragEnd)
        .on("touchendoutside", window.editor.onDragEnd)
        .on("mousemove", window.editor.onDragMove)
        .on("touchmove", window.editor.onDragMove);
      object.curveSprite = sprite;
      window.editor.getStickerContainer().addChild(sprite);
      object.visible = false;
      container.destroy(true);
      window.editor.setSelectedObject(sprite);
      window.editor.stickerSelector.updateSelector();
    }
  },
  zoom: function zoom(x, y, isZoomIn) {
    var direction = isZoomIn ? 1 : -1;
    var factor = direction * 0.05;
    if (stage.scale.x + factor >= 1 && stage.scale.x + factor < 8) {
      // Technically code below is not required, but helps to zoom on mouse
      // cursor, instead center of graphGraphics coordinates
      var point = new PIXI.Point();
      PIXI.interaction.InteractionData.prototype.getLocalPosition(
        stage,
        point,
        new PIXI.Point(x, y)
      );
      var w = stage.width;
      var h = stage.height;
      stage.scale.x += factor;
      stage.scale.y += factor;
      var newW = stage.width;
      var newH = stage.height;
      var mainCanvas = renderer.view;
      var offSetX = (x - mainCanvas.offsetLeft) / mainCanvas.clientWidth;
      var offSetY = (y - mainCanvas.offsetTop) / mainCanvas.clientHeight;

      stage.position.x += (w - newW) * offSetX;
      stage.position.y += (h - newH) * offSetY;

      if (stage.width + stage.position.x <= renderer.view.width / 1)
        stage.position.x = renderer.view.width / 1 - stage.width;
      if (stage.height + stage.position.y <= renderer.view.height / 1)
        stage.position.y = renderer.view.height / 1 - stage.height;

      if (stage.position.x > 0) stage.position.x = 0;
      if (stage.position.y > 0) stage.position.y = 0;

      if (stage.scale.x === 1) {
        stage.position.x = 0;
        stage.position.y = 0;
        mainCanvas.style.cursor = "default";
        EditorActionCreators.EditorMode("view");
      } else {
        mainCanvas.style.cursor = "move";
        EditorActionCreators.EditorMode("pan");
      }
    }
  },
  CommandManager: {
    execute: function (cmd) {
      cmd.execute();
      lastFilter = null;
      EditorStore.getExecuted().push(cmd);
      EditorStore.emitChange();
    },
    filter: function (cmd) {
      if (lastFilter) {
        cmd.execute();
        lastFilter = cmd;
        EditorStore.emitChange();
      } else {
        lastFilter = cmd;
        cmd.execute();
        EditorStore.getExecuted().push(lastFilter);
        EditorStore.emitChange();
      }
    },
    undo: function () {
      var cmd1 = EditorStore.getExecuted().pop();
      if (cmd1 !== undefined) {
        if (cmd1.unexecute !== undefined) {
          cmd1.unexecute();
        }
        EditorStore.getUnexecuted().push(cmd1);
        lastFilter = null;
        EditorStore.emitChange();
        window.editor.setSelectedObject(null);
      }
    },
    redo: function () {
      var cmd2 = EditorStore.getUnexecuted().pop();

      if (cmd2 !== undefined) {
        cmd2.execute();
        EditorStore.getExecuted().push(cmd2);
      }
      EditorStore.emitChange();
      window.editor.setSelectedObject(null);
    },
  },
  addSticker: async function (
    url,
    dropX,
    dropY,
    callback,
    addWidth,
    addHeight,
    id,
    zOrder,
    fillColor,
    features
  ) {
    var self = this;
    if (!id) id = JSONUtils.guid();

    self.CommandManager.execute({
      execute: function () {
        EditorStore.setShowSpinner(true);
        var img = new Image();
        if (url && !url.startsWith("data")) {
          img.setAttribute("crossOrigin", "anonymous");
        }
        img.onload = function () {
          var sticker;
          img.resolution = window.devicePixelRatio;
          if (url.indexOf("svg") >= 1) {
            if (img.width === 0) {
              JSONUtils.getXML(url, "GET", null, function (response) {
                var viewbox = response.documentElement
                  .getAttribute("viewBox")
                  .split(" ");
                img.width = parseFloat(viewbox[2]);
                img.height = parseFloat(viewbox[3]);
                if (addWidth) {
                  img.width =
                    addWidth *
                    window.editor.getSaveScale() *
                    startingDevicePixelRatio;
                } else {
                  img.width =
                    (img.width / stage.scale.x) *
                    window.editor.getSaveScale() *
                    startingDevicePixelRatio;
                }
                if (addHeight) {
                  img.height =
                    addHeight *
                    window.editor.getSaveScale() *
                    startingDevicePixelRatio;
                } else {
                  img.height =
                    (img.height / stage.scale.y) *
                    window.editor.getSaveScale() *
                    startingDevicePixelRatio;
                }
                if (fillColor) {
                  img.style.path =
                    "fill:" + fillColor + ";stroke:" + fillColor + ";";
                }

                sticker = new PIXI.Sprite(new PIXI.Texture.fromCanvas(img));
                sticker.svg = true;
                if (fillColor) {
                  sticker.isShape = true;
                  sticker.fillColor = fillColor;
                }

                self.setupSticker(
                  sticker,
                  id,
                  url,
                  img,
                  dropX,
                  dropY,
                  callback,
                  zOrder,
                  features
                );
              });
            } else {
              if (addWidth) {
                img.width =
                  addWidth *
                  window.editor.getSaveScale() *
                  startingDevicePixelRatio;
              } else {
                img.width =
                  (img.width / stage.scale.x) *
                  window.editor.getSaveScale() *
                  startingDevicePixelRatio;
              }
              if (addHeight) {
                img.height =
                  addHeight *
                  window.editor.getSaveScale() *
                  startingDevicePixelRatio;
              } else {
                img.height =
                  (img.height / stage.scale.y) *
                  window.editor.getSaveScale() *
                  startingDevicePixelRatio;
              }

              sticker = new PIXI.Sprite(new PIXI.Texture.fromCanvas(img));
              sticker.svg = true;
              if (fillColor) {
                sticker.isShape = true;
                sticker.fillColor = fillColor;
              }
              self.setupSticker(
                sticker,
                id,
                url,
                img,
                dropX,
                dropY,
                callback,
                zOrder,
                features
              );
            }
          } else {
            window.editor.loadTextureFromUrl(url, function (texture) {
              sticker = new PIXI.Sprite(texture);
              sticker.svg = false;
              self.setupSticker(
                sticker,
                id,
                url,
                img,
                dropX,
                dropY,
                callback,
                zOrder,
                features
              );
            });
          }
        };
        img.onerror = function () {
          EditorStore.setShowSpinner(false);
          // EditorStore.setShowStickerLoadError(true);
        };
        img.src = url;
      },
      unexecute: function () {
        for (var x = 0; x < stickerContainer.children.length; x++) {
          if (
            stickerContainer.children[x].id &&
            stickerContainer.children[x].id === id
          ) {
            stickerContainer.removeChildAt(x);
          }
        }
      },
    });
  },
  storedFilters: null,
  compare: function () {
    if (EditorStore.getDesignBackground()) {
      window.editor.storedFilters = EditorStore.getDesignBackground().filters;
      EditorStore.getDesignBackground().filters = null;
    } else {
      window.editor.storedFilters = baseObject.filters;
      baseObject.filters = null;
    }
    effectBorderContainer.renderable = false;
  },
  compareEnd: function () {
    if (EditorStore.getDesignBackground()) {
      EditorStore.getDesignBackground().filters = window.editor.storedFilters;
    } else {
      baseObject.filters = window.editor.storedFilters;
    }
    window.editor.storedFilters = null;
    effectBorderContainer.renderable = true;
  },
  prepareSave: function (callback, offsets) {
    var rect, graphicsPath, x;
    var count = 1;
    var foundText = false;
    var families = ["Impact"];
    for (x = 0; x < stickerContainer.children.length; x++) {
      if (stickerContainer.children[x] instanceof PIXI.Text) {
        if (stickerContainer.children[x].style) {
          count++;
          foundText = true;
          families.push(stickerContainer.children[x].style._fontFamily);
        }
      }
    }

    if (gridLines) {
      gridLines.clear();
    }

    if (EditorStore.getEditorType() === "collage") {
      var gutterWidth = -1;
      var currentScale = baseObject.width / baseObject.originalWidth;
      var scale =
        offsets.width / startingDevicePixelRatio / baseObject.originalWidth;
      for (x = 0; x < window.editor.getBaseObject().children.length; x++) {
        if (window.editor.getBaseObject().children[x].mask) {
          graphicsPath = SVGPath.init(
            window.editor.getBaseObject().children[x].svgpath,
            scale
          );
          if (baseObject.isRectangle) {
            rect = graphicsPath.getBounds();
            if (gutterWidth > rect.x || gutterWidth < 0) {
              gutterWidth = rect.x;
            }
          }
        }
      }
      if (gutterWidth < 0) gutterWidth = 0;
      var spacer = Math.ceil(
        parseInt(window.editor.getBaseObject().spacing / currentScale, 10) *
          scale
      );
      for (x = 0; x < window.editor.getBaseObject().children.length; x++) {
        if (window.editor.getBaseObject().children[x].mask) {
          window.editor.getBaseObject().children[x].saveMask =
            window.editor.getBaseObject().children[x].mask;

          graphicsPath = SVGPath.init(
            window.editor.getBaseObject().children[x].svgpath,
            scale
          );
          if (baseObject.isRectangle) {
            rect = graphicsPath.getBounds();
            if (Math.ceil(rect.x) === Math.ceil(gutterWidth)) {
              rect.x = 0;
              rect.width += gutterWidth;
            } else {
              rect.x -= gutterWidth / 2;
              rect.width += gutterWidth / 2;
            }
            if (Math.ceil(rect.y) === Math.ceil(gutterWidth)) {
              rect.y = 0;
              rect.height += gutterWidth;
            } else {
              rect.y -= gutterWidth / 2;
              rect.height += gutterWidth / 2;
            }
            if (rect.x + rect.width + gutterWidth + 20 >= offsets.width) {
              rect.width = offsets.width - rect.x;
            } else {
              rect.width += gutterWidth / 2;
            }
            if (rect.y + rect.height + gutterWidth + 20 >= offsets.height) {
              rect.height = offsets.height - rect.y;
            } else {
              rect.height += gutterWidth / 2;
            }

            var rectX = rect.x;
            var rectY = rect.y;
            var rectWidth = rect.width;
            var rectHeight = rect.height;

            if (rect.x === 0) {
              rectX += spacer;
              rectWidth -= spacer * 1.5;
            } else {
              rectX += spacer / 2;
              rectWidth -= spacer;
            }
            if (rect.y === 0) {
              rectY += spacer;
              rectHeight -= spacer * 1.5;
            } else {
              rectY += spacer / 2;
              rectHeight -= spacer;
            }
            if (
              rect.y + rect.height + spacer + 40 >=
              offsets.height / startingDevicePixelRatio
            ) {
              if (rect.y === 0) {
                rectHeight =
                  offsets.height / startingDevicePixelRatio -
                  rect.y -
                  spacer * 2;
              } else {
                rectHeight =
                  offsets.height / startingDevicePixelRatio -
                  rect.y -
                  spacer * 1.5;
              }
            }
            if (
              rect.x + rect.width + spacer + 40 >=
              offsets.width / startingDevicePixelRatio
            ) {
              if (rect.x === 0) {
                rectWidth =
                  offsets.width / startingDevicePixelRatio -
                  rect.x -
                  spacer * 2;
              } else {
                rectWidth =
                  offsets.width / startingDevicePixelRatio -
                  rect.x -
                  spacer * 1.5;
              }
            }
            var mask = new PIXI.Graphics();
            mask.beginFill(0, 1);
            mask.drawRoundedRect(
              rectX,
              rectY,
              rectWidth,
              rectHeight,
              parseInt(window.editor.getBaseObject().corner * scale, 10)
            );
            mask.endFill();
            window.editor.getBaseObject().children[x].mask = null;
            window.editor.getBaseObject().children[x].mask = mask;
          } else {
            window.editor.getBaseObject().children[x].mask = null;
            window.editor.getBaseObject().children[x].mask = graphicsPath;
          }
        }
      }
    }

    if (
      window.editor.meme.top.getText() !== "" ||
      window.editor.meme.top.getText() !== ""
    ) {
      foundText = true;
    }

    if (!foundText) {
      callback();
    } else {
      WebFont.load({
        custom: {
          families: families,
          urls: ["/app/css/fonts.css"],
        },
        timeout: 1000,
        fontinactive: function () {
          var style, fs;
          count--;
          if (count === 0) {
            if (topMemeText && topMemeText.style) {
              style = topMemeText.style;
              fs = style.fontSize;
              if (typeof fs.replace === "function") {
                fs = fs.replace("px", "");
                fs = parseInt(fs, 10);
              }
              topMemeText.fontSize = fs;
              style.fontSize = Math.floor(topMemeSize * stage.scale.x);
              topMemeText.wordWrapWidth = style.wordWrapWidth;
              topMemeText.padding = style.padding;
              style.wordWrapWidth = style.wordWrapWidth * stage.scale.x;
              style.padding = style.padding * stage.scale.x;
              style.strokeThickness = style.fontSize / 20;
              topMemeText.scale.set(1 / stage.scale.x);
              topMemeText.style = style;
              topMemeText.updateText(false);
            }
            if (bottomMemeText && bottomMemeText.style) {
              style = bottomMemeText.style;
              fs = style.fontSize;
              if (typeof fs.replace === "function") {
                fs = fs.replace("px", "");
                fs = parseInt(fs, 10);
              }
              bottomMemeText.fontSize = fs;
              style.fontSize = Math.floor(bottomMemeSize * stage.scale.x);
              bottomMemeText.wordWrapWidth = style.wordWrapWidth;
              bottomMemeText.padding = style.padding;
              style.wordWrapWidth = style.wordWrapWidth * stage.scale.x;
              style.padding = style.padding * stage.scale.x;
              style.strokeThickness = style.fontSize / 20;
              bottomMemeText.scale.set(1 / stage.scale.x);
              bottomMemeText.style = style;
              bottomMemeText.updateText(false);
            }

            for (var y = 0; y < stickerContainer.children.length; y++) {
              if (stickerContainer.children[y] instanceof PIXI.Text) {
                if (stickerContainer.children[y].style) {
                  style = stickerContainer.children[y].style;
                  fs = style.fontSize;
                  if (typeof fs.replace === "function") {
                    fs = fs.replace("px", "");
                    fs = parseInt(fs, 10);
                  }
                  stickerContainer.children[y].fontSize = fs;
                  style.fontSize = Math.floor(fs * stage.scale.x);
                  stickerContainer.children[y].wordWrapWidth =
                    style.wordWrapWidth;
                  stickerContainer.children[y].padding = style.padding;
                  style.wordWrapWidth = style.wordWrapWidth * stage.scale.x;
                  style.padding = style.padding * stage.scale.x;
                  if (style.letterSpacing && style.letterSpacing > 0) {
                    stickerContainer.children[y].letterSpacing =
                      style.letterSpacing;
                    style.letterSpacing *= stage.scale.x;
                  }
                  if (style.lineHeight && style.lineHeight > 0) {
                    stickerContainer.children[y].lineHeight = style.lineHeight;
                    style.lineHeight *= stage.scale.x;
                  }
                  if (style.strokeThickness && style.strokeThickness > 0) {
                    stickerContainer.children[y].strokeThickness =
                      style.strokeThickness;
                    style.strokeThickness = Math.floor(
                      style.strokeThickness * stage.scale.x
                    );
                  }
                  if (
                    stickerContainer.children[y].filters !== null &&
                    stickerContainer.children[y].filters.length > 0
                  ) {
                    for (
                      var x = 0;
                      x < stickerContainer.children[y].filters.length;
                      x++
                    ) {
                      if (
                        stickerContainer.children[y].filters[x] instanceof
                        Filters.DropShadowFilter
                      ) {
                        stickerContainer.children[y].filters[x].distance =
                          stickerContainer.children[y].filters[x].distance *
                          stage.scale.x;
                      }
                    }
                  }
                  stickerContainer.children[y].scale.set(1 / stage.scale.x);
                  stickerContainer.children[y].style = style;
                  var offset;
                  if (stickerContainer.children[y].style.align === "left") {
                    stickerContainer.children[y].pivot.set(
                      stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                    offset =
                      stickerContainer.children[y].pivot.x -
                      stickerContainer.children[y].style.wordWrapWidth / 2;
                    stickerContainer.children[y].position.x -= offset;
                  }
                  if (stickerContainer.children[y].style.align === "right") {
                    stickerContainer.children[y].pivot.set(
                      0 - stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                    offset =
                      stickerContainer.children[y].pivot.x +
                      stickerContainer.children[y].style.wordWrapWidth / 2;
                    stickerContainer.children[y].position.x -= offset;
                  }

                  stickerContainer.children[y].updateText(false);
                }
              }
            }
            callback();
          }
        },
        fontactive: function () {
          var style;
          count--;
          if (count === 0) {
            if (topMemeText && topMemeText.style) {
              style = topMemeText.style;
              style.fontSize = topMemeSize * stage.scale.x;
              topMemeText.wordWrapWidth = style.wordWrapWidth;
              topMemeText.padding = style.padding;
              style.wordWrapWidth = style.wordWrapWidth * stage.scale.x;
              style.padding = style.padding * stage.scale.x;
              style.strokeThickness = style.fontSize / 20;
              topMemeText.style = style;
              topMemeText.scale.set(1 / stage.scale.x);
              topMemeText.updateText(false);
            }
            if (bottomMemeText && bottomMemeText.style) {
              style = bottomMemeText.style;
              style.fontSize = bottomMemeSize * stage.scale.x;
              bottomMemeText.wordWrapWidth = style.wordWrapWidth;
              bottomMemeText.padding = style.padding;
              style.wordWrapWidth = style.wordWrapWidth * stage.scale.x;
              style.padding = style.padding * stage.scale.x;
              style.strokeThickness = style.fontSize / 20;
              bottomMemeText.style = style;
              bottomMemeText.scale.set(1 / stage.scale.x);
              bottomMemeText.updateText(false);
            }
            for (var y = 0; y < stickerContainer.children.length; y++) {
              if (stickerContainer.children[y] instanceof PIXI.Text) {
                if (stickerContainer.children[y].style) {
                  style = stickerContainer.children[y].style;
                  var fs = style.fontSize;
                  if (typeof fs.replace === "function") {
                    fs = fs.replace("px", "");
                    fs = parseInt(fs, 10);
                  }
                  stickerContainer.children[y].fontSize = fs;
                  style.fontSize = Math.floor(fs * stage.scale.x);
                  stickerContainer.children[y].wordWrapWidth =
                    style.wordWrapWidth;
                  stickerContainer.children[y].padding = style.padding;
                  style.wordWrapWidth = style.wordWrapWidth * stage.scale.x;
                  style.padding = style.padding * stage.scale.x;
                  if (style.letterSpacing && style.letterSpacing > 0) {
                    stickerContainer.children[y].letterSpacing =
                      style.letterSpacing;
                    style.letterSpacing *= stage.scale.x;
                  }
                  if (style.lineHeight && style.lineHeight > 0) {
                    stickerContainer.children[y].lineHeight = style.lineHeight;
                    style.lineHeight *= stage.scale.x;
                  }
                  if (style.strokeThickness && style.strokeThickness > 0) {
                    stickerContainer.children[y].strokeThickness =
                      style.strokeThickness;
                    style.strokeThickness = Math.floor(
                      style.strokeThickness * stage.scale.x
                    );
                  }
                  if (
                    stickerContainer.children[y].filters !== null &&
                    stickerContainer.children[y].filters.length > 0
                  ) {
                    for (
                      var x = 0;
                      x < stickerContainer.children[y].filters.length;
                      x++
                    ) {
                      if (
                        stickerContainer.children[y].filters[x] instanceof
                        Filters.DropShadowFilter
                      ) {
                        stickerContainer.children[y].filters[x].distance =
                          stickerContainer.children[y].filters[x].distance *
                          stage.scale.x;
                      }
                    }
                  }
                  stickerContainer.children[y].scale.set(1 / stage.scale.x);
                  stickerContainer.children[y].style = style;

                  var offset;
                  if (stickerContainer.children[y].style.align === "left") {
                    stickerContainer.children[y].pivot.set(
                      stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                    offset =
                      stickerContainer.children[y].pivot.x -
                      stickerContainer.children[y].style.wordWrapWidth / 2;
                    stickerContainer.children[y].position.x -= offset;
                  }
                  if (stickerContainer.children[y].style.align === "right") {
                    stickerContainer.children[y].pivot.set(
                      0 - stickerContainer.children[y].style.wordWrapWidth / 2,
                      0
                    );
                    offset =
                      stickerContainer.children[y].pivot.x +
                      stickerContainer.children[y].style.wordWrapWidth / 2;
                    stickerContainer.children[y].position.x -= offset;
                  }

                  stickerContainer.children[y].updateText(false);
                }
              }
            }
            callback();
          }
        },
      });
    }
  },
  postSave: function () {
    var style, x;
    if (baseObject.transparent) {
      window.editor.getBaseObject().alpha = 1;
    }
    if (EditorStore.getEditorType() === "collage") {
      for (x = 0; x < window.editor.getBaseObject().children.length; x++) {
        if (window.editor.getBaseObject().children[x].mask) {
          window.editor.getBaseObject().children[x].mask = null;
          window.editor.getBaseObject().children[x].mask =
            window.editor.getBaseObject().children[x].saveMask;
        }
      }
    }
    if (topMemeText && topMemeText.style) {
      style = topMemeText.style;
      style.fontSize = topMemeSize;
      style.wordWrapWidth = topMemeText.wordWrapWidth;
      style.padding = topMemeText.padding;
      style.strokeThickness = style.fontSize / 20;
      topMemeText.style = style;
      topMemeText.scale.set(1);
    }
    if (bottomMemeText && bottomMemeText.style) {
      style = bottomMemeText.style;
      style.fontSize = bottomMemeSize;
      style.wordWrapWidth = bottomMemeText.wordWrapWidth;
      style.padding = bottomMemeText.padding;
      style.strokeThickness = style.fontSize / 20;
      bottomMemeText.style = style;
      bottomMemeText.scale.set(1);
    }
    for (x = 0; x < stickerContainer.children.length; x++) {
      if (stickerContainer.children[x] instanceof PIXI.Text) {
        if (stickerContainer.children[x].style) {
          style = stickerContainer.children[x].style;
          style.fontSize = stickerContainer.children[x].fontSize;
          style.wordWrapWidth = stickerContainer.children[x].wordWrapWidth;
          style.padding = stickerContainer.children[x].padding;
          if (stickerContainer.children[x].letterSpacing)
            style.letterSpacing = stickerContainer.children[x].letterSpacing;
          if (stickerContainer.children[x].lineHeight)
            style.lineHeight = stickerContainer.children[x].lineHeight;
          if (stickerContainer.children[x].strokeThickness) {
            style.strokeThickness =
              stickerContainer.children[x].strokeThickness;
            stickerContainer.children[x].strokeThickness = null;
          }
          if (
            stickerContainer.children[x].filters !== null &&
            stickerContainer.children[x].filters.length > 0
          ) {
            for (
              var y = 0;
              y < stickerContainer.children[x].filters.length;
              y++
            ) {
              if (
                stickerContainer.children[x].filters[y] instanceof
                Filters.DropShadowFilter
              ) {
                stickerContainer.children[x].filters[y].distance =
                  stickerContainer.children[x].dropShadowDistance;
              }
            }
          }

          stickerContainer.children[x].style = style;
          stickerContainer.children[x].scale.set(1);
          var offset;
          if (stickerContainer.children[x].style.align === "left") {
            stickerContainer.children[x].pivot.set(
              stickerContainer.children[x].style.wordWrapWidth / 2,
              0
            );
            offset =
              stickerContainer.children[x].pivot.x -
              stickerContainer.children[x].style.wordWrapWidth / 2;
            stickerContainer.children[x].position.x -= offset;
          }
          if (stickerContainer.children[x].style.align === "right") {
            stickerContainer.children[x].pivot.set(
              0 - stickerContainer.children[x].style.wordWrapWidth / 2,
              0
            );
            offset =
              stickerContainer.children[x].pivot.x +
              stickerContainer.children[x].style.wordWrapWidth / 2;
            stickerContainer.children[x].position.x -= offset;
          }
        }
      }
    }
  },
  setupSticker: function (
    sticker,
    id,
    url,
    img,
    dropX,
    dropY,
    callback,
    zOrder,
    features
  ) {
    sticker.id = id;
    if (sticker.permLocked) {
      sticker.interactive = false;
    } else {
      sticker.interactive = true;
    }
    sticker.buttonMode = true;
    sticker.anchor.set(0.5);
    sticker.scale.set(1);
    if (baseObject.scale.x < 0) {
      sticker.scale.x = -1;
    }
    if (dropX && dropY) {
      sticker.position.x = dropX;
      sticker.position.y = dropY;
    } else {
      sticker.position.x = Math.floor(baseOffsets.width / 2);
      sticker.position.y = Math.floor(baseOffsets.height / 2);
    }
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    sticker.filters = [blur];
    sticker.glowColor = 0xff00ff;
    sticker.glowSliderVal = 2;
    sticker.dropShadowAlpha = 0.5;
    sticker.dropShadowBlur = 0.5;
    sticker.dropShadowRotation = 45;
    sticker.dropShadowDistance = 5;
    sticker.dropShadowColor = 0;
    sticker.width = (img.width * baseObject.scale.x) / startingDevicePixelRatio;
    sticker.height =
      (img.height * baseObject.scale.y) / startingDevicePixelRatio;
    sticker.sourceUrl = url;
    sticker.cursor = "move";
    sticker.locked = false;
    sticker.permLocked = false;
    sticker.imageSettings = {
      temperature: 0,
      brightness: 0,
      contrast: 0.8,
      saturation: 0,
      hue: 0,
      red: 1,
      green: 1,
      blue: 1,
      noise: 0,
      bloom: 0,
      emboss: 0,
      triangleBlur: 0,
      denoise: 50,
      sharpen: 0,
      blur: 0,
    };

    if (sticker.width > baseOffsets.width * 0.2) {
      sticker.height = window.editor.stickerSelector.calculateGetScaledHeight(
        img.width,
        img.height,
        baseOffsets.width * 0.2
      );
      sticker.width = baseOffsets.width * 0.2;
    } else {
      if (sticker.height > baseOffsets.height * 0.2) {
        var newWidth = window.editor.stickerSelector.calculateGetScaledWidth(
          img.width,
          img.height,
          baseOffsets.height * 0.2
        );
        sticker.height = baseOffsets.height * 0.2;
        sticker.width = newWidth;
      }
    }
    // this.setSelectedObject(sticker);
    stickerContainer.addChild(sticker);

    sticker
      .on("mousedown", window.editor.onDragStart)
      .on("touchstart", window.editor.onDragStart)
      .on("mouseup", window.editor.onDragEnd)
      .on("mouseupoutside", window.editor.onDragEnd)
      .on("touchend", window.editor.onDragEnd)
      .on("touchendoutside", window.editor.onDragEnd)
      .on("mousemove", window.editor.onDragMove)
      .on("touchmove", window.editor.onDragMove);
    window.editor.positionEdp(sticker);
    sticker.texture.dataUrl = url;
    if (url.indexOf("shapes") > 0) {
      sticker.isShape = true;
      if (!sticker.fillColor) sticker.fillColor = "#FFFFFF";
    }
    if (features) {
      features.forEach(function (item) {
        if (item === "color") {
          sticker.isShape = true;
          if (!sticker.fillColor) sticker.fillColor = "#FFFFFF";
        }
      });
    }
    if (callback) callback(sticker);
  },
  addShape: function (src) {
    var origImage = new PIXI.Sprite(imgObject.texture);
    origImage.width = imgObject.width;
    origImage.height = imgObject.height;
    origImage.alpha = 0.25;
    origImage.cropBackground = true;
    origImage.anchor = imgObject.anchor;
    origImage.rotation = imgObject.rotation;
    cutoutStage.addChildAt(origImage, 0);
    cutoutStage.addChild(StickerCutoutSelector.getSelectorCutoutContainer());
    var self = this;
    var id = JSONUtils.guid();
    EditorStore.setShowSpinner(true);
    window.editor.loadTextureFromUrl(src, function (texture) {
      if (texture.width === 0) {
        JSONUtils.getXML(src, "GET", null, function (response) {
          var viewbox = response.documentElement
            .getAttribute("viewBox")
            .split(" ");
          var img = new Image();
          img.width = parseFloat(viewbox[2]);
          img.height = parseFloat(viewbox[3]);
          img.height = window.editor.stickerSelector.calculateGetScaledHeight(
            img.width,
            img.height,
            imgObject.width / 2
          );
          img.width = imgObject.width / 2;
          var sticker = new PIXI.Sprite(new PIXI.Texture.fromCanvas(img));
          sticker.id = id;
          sticker.interactive = true;
          sticker.buttonMode = true;
          sticker.anchor.set(0.5);
          sticker.scale.set(1);
          sticker.width = img.width / 2;
          sticker.height = img.height / 2;
          sticker.position.x = imgObject.width / 2;
          sticker.position.y = imgObject.height / 2;
          sticker.sourceUrl = src;
          sticker.cursor = "move";
          sticker.svg = true;
          sticker.shape = true;

          imgObject.mask = sticker;
          imgObject.addChild(sticker);
          sticker
            .on("mousedown", window.editor.onCutoutDragStart)
            .on("touchstart", window.editor.onCutoutDragStart)
            .on("mouseup", window.editor.onCutoutDragEnd)
            .on("mouseupoutside", window.editor.onCutoutDragEnd)
            .on("touchend", window.editor.onCutoutDragEnd)
            .on("touchendoutside", window.editor.onCutoutDragEnd)
            .on("mousemove", window.editor.onCutoutDragMove)
            .on("touchmove", window.editor.onCutoutDragMove);
          self.setSelectedObject(sticker);
          StickerCutoutSelector.updateSelector();
          EditorStore.setShowSpinner(false);
          cutoutStage.interactive = false;
          cutoutStage.buttonMode = false;
        });
      } else {
        var sticker = new PIXI.Sprite(texture);
        sticker.id = id;
        sticker.interactive = true;
        sticker.buttonMode = true;
        sticker.anchor.set(0.5);
        sticker.scale.set(1);
        sticker.width = imgObject.width / 2;
        sticker.height = window.editor.stickerSelector.calculateGetScaledHeight(
          texture.width,
          texture.height,
          imgObject.width / 2
        );
        sticker.position.x = imgObject.width / 2;
        sticker.position.y = imgObject.height / 2;
        sticker.sourceUrl = src;
        sticker.cursor = "move";
        sticker.svg = true;
        sticker.shape = true;
        imgObject.mask = sticker;
        imgObject.addChild(sticker);
        sticker
          .on("mousedown", window.editor.onCutoutDragStart)
          .on("touchstart", window.editor.onCutoutDragStart)
          .on("mouseup", window.editor.onCutoutDragEnd)
          .on("mouseupoutside", window.editor.onCutoutDragEnd)
          .on("touchend", window.editor.onCutoutDragEnd)
          .on("touchendoutside", window.editor.onCutoutDragEnd)
          .on("mousemove", window.editor.onCutoutDragMove)
          .on("touchmove", window.editor.onCutoutDragMove);
        window.editor.setSelectedObject(sticker);

        StickerCutoutSelector.updateSelector();
        EditorStore.setShowSpinner(false);
        cutoutStage.interactive = false;
        cutoutStage.buttonMode = false;
      }
      var finishwidth = sticker.width;
      var finishheight = sticker.height;
      if (sticker && sticker.svg === true) {
        var saveScale = cutoutStage.scale.x;
        var xmlText = sticker.sourceUrl;
        var source = new Image();
        source.setAttribute("crossOrigin", "anonymous");
        source.width = finishwidth * saveScale;
        source.height = finishheight * saveScale;
        source.onload = function () {
          var newsticker = new PIXI.Sprite(new PIXI.Texture.fromCanvas(source));
          newsticker.id = sticker.id;
          newsticker.interactive = true;
          newsticker.buttonMode = true;
          newsticker.position = sticker.position;
          newsticker.anchor.set(0.5);
          newsticker.scale.set(1);
          newsticker.sourceUrl = sticker.sourceUrl;
          newsticker.svg = true;
          newsticker.width = finishwidth;
          newsticker.height = finishheight;
          newsticker.cursor = "move";
          newsticker.rotation = sticker.rotation;
          imgObject.removeChildren(1);
          imgObject.addChild(newsticker);
          imgObject.mask = newsticker;
          window.editor.setSelectedObject(newsticker);
          newsticker
            .on("mousedown", window.editor.onCutoutDragStart)
            .on("touchstart", window.editor.onCutoutDragStart)
            .on("mouseup", window.editor.onCutoutDragEnd)
            .on("mouseupoutside", window.editor.onCutoutDragEnd)
            .on("touchend", window.editor.onCutoutDragEnd)
            .on("touchendoutside", window.editor.onCutoutDragEnd)
            .on("mousemove", window.editor.onCutoutDragMove)
            .on("touchmove", window.editor.onCutoutDragMove);
        };
        source.src = xmlText;
      } else {
        sticker.width = finishwidth;
        sticker.height = finishheight;
      }
    });
  },
  resetShape: function () {
    imgObject.mask = null;
    imgObject.removeChildren(1);
    cutoutStage.removeChildAt(0);
    this.setSelectedObject(null);
    StickerCutoutSelector.updateSelector();
  },
  addText: function (
    text,
    url,
    width,
    height,
    anchorY,
    wrapPercent,
    callback,
    id
  ) {
    var self = this;
    if (!id) id = JSONUtils.guid();
    var childId = JSONUtils.guid();

    var fontFamily = "Arial";
    var fontSize = 50;
    var fill = url ? "#000000" : "#000000";
    var align = "center";
    var wordWrap = true;

    if (Lockr.get("font")) {
      var style = Lockr.get("font");
      if (style._fontFamily) {
        fontFamily = style._fontFamily;
      }
      if (style._fontSize) {
        fontSize = style._fontSize;
        if (typeof fontSize.replace === "function") {
          fontSize = fontSize.replace("px", "");
          fontSize = Math.abs(parseInt(fontSize, 10));
        }
        if (fontSize < 0) fontSize = 50;
      }
      if (style._fill) {
        fill = style._fill;
      }
      if (style._align) {
        align = style._align;
      }
      if (style._wordWrap) {
        wordWrap = style._wordWrap;
      }
    }
    self.CommandManager.execute({
      execute: function () {
        if (url) {
          EditorStore.setShowSpinner(true);
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = function () {
            var newHeight =
              window.editor.stickerSelector.calculateGetScaledHeight(
                width,
                height,
                baseOffsets.width * 0.4
              );

            var canvas = document.createElement("canvas");
            canvas.width = baseOffsets.width * 0.4;
            canvas.height = newHeight;
            canvas
              .getContext("2d")
              .drawImage(img, 0, 0, canvas.width, canvas.height);

            var sticker = new PIXI.Sprite(
              new PIXI.Texture(new PIXI.BaseTexture(canvas))
            );
            sticker.id = childId;
            sticker.childId = id;
            sticker.interactive = true;
            sticker.buttonMode = true;
            sticker.parentId = id;
            sticker.position.x = baseOffsets.width / 2;
            sticker.position.y = baseOffsets.height / 2;
            sticker.anchor.set(0.5);
            sticker.sourceUrl = url;
            sticker.cursor = "move";
            sticker.texture.dataUrl = url;
            sticker.locked = false;
            sticker.permLocked = false;
            stickerContainer.addChild(sticker);
            self.setSelectedObject(sticker);

            sticker
              .on("mousedown", window.editor.onDragStart)
              .on("touchstart", window.editor.onDragStart)
              .on("mouseup", window.editor.onDragEnd)
              .on("mouseupoutside", window.editor.onDragEnd)
              .on("touchend", window.editor.onDragEnd)
              .on("touchendoutside", window.editor.onDragEnd)
              .on("mousemove", window.editor.onDragMove)
              .on("touchmove", window.editor.onDragMove);

            var wordWrapWidth = canvas.width * 0.6;

            var txt = new PIXI.Text(text, {
              fontFamily: fontFamily,
              fontSize: fontSize,
              fill: "#000000",
              align: align,
              wordWrap: wordWrap,
              wordWrapWidth: wordWrapWidth,
              padding: Math.ceil(fontSize * 0.25),
            });
            txt.id = id;
            txt.childId = childId;
            txt.anchorY = anchorY;
            txt.wrapPercent = wrapPercent;
            txt.resolution = 4;
            txt.locked = false;
            txt.permLocked = false;
            if (anchorY !== 0.5) {
              txt.position.y = sticker.position.y - newHeight * anchorY;
            } else {
              txt.position.y = baseOffsets.height / 2;
            }
            txt.position.x = baseOffsets.width / 2;
            txt.anchor.set(0.5);

            stickerContainer.addChild(txt);
            txt.glowColor = 0xff00ff;
            txt.outlineColor = 0x000000;
            txt.align = align;
            txt.bold = false;
            txt.italic = false;
            txt.dropShadowColor = 0x0;
            txt.dropShadowAlpha = 0.5;
            txt.dropShadowRotation = 45;
            txt.dropShadowBlur = 0.5;
            txt.dropShadowDistance = 5;
            txt.backgroundEnabled = false;
            txt.backgroundColor = 0xffffff;

            if (align === "left") {
              txt.anchor.set(0, 0.5);
              txt.pivot.set(txt.style.wordWrapWidth / 2, 0);
            }
            if (align === "right") {
              txt.anchor.set(1, 0.5);
              txt.pivot.set(0 - txt.style.wordWrapWidth / 2, 0);
            }
            if (align === "center") {
              txt.anchor.set(0.5);
              txt.pivot.set(0);
            }

            EditorStore.setShowSpinner(false);
            window.editor.stickerSelector.updateSelector();
            window.editor.positionEdp(sticker);
            if (callback) {
              callback(txt);
            }
          };
          img.src = url;
        } else {
          var wordWrapWidth = baseOffsets.width * 0.3;
          var txt = new PIXI.Text(text, {
            fontFamily: fontFamily,
            fontSize: fontSize,
            fill: fill,
            align: align,
            wordWrap: wordWrap,
            wordWrapWidth: wordWrapWidth,
            padding: Math.ceil(fontSize * 0.3),
            dropShadowBlur: 0.5,
            dropShadowAlpha: 0.5,
          });
          txt.id = id;
          txt.resolution = startingDevicePixelRatio;
          txt.interactive = true;
          txt.buttonMode = true;
          txt.position.x = baseOffsets.width / 2;
          txt.position.y = baseOffsets.height / 2;
          txt.cursor = "move";
          txt.anchor.set(0.5);
          txt.align = align;
          txt.bold = false;
          txt.italic = false;
          txt.locked = false;
          txt.permLocked = false;
          txt.lineJoin = "round";
          stickerContainer.addChild(txt);
          self.setSelectedObject(txt);
          txt
            .on("mousedown", window.editor.onDragStart)
            .on("touchstart", window.editor.onDragStart)
            .on("mouseup", window.editor.onDragEnd)
            .on("mouseupoutside", window.editor.onDragEnd)
            .on("touchend", window.editor.onDragEnd)
            .on("touchendoutside", window.editor.onDragEnd)
            .on("mousemove", window.editor.onDragMove)
            .on("touchmove", window.editor.onDragMove);
          txt.glowColor = 0xff00ff;
          txt.outlineColor = 0x000000;
          txt.dropShadowColor = 0x0;
          txt.dropShadowAlpha = 0.5;
          txt.dropShadowRotation = 45;
          txt.dropShadowBlur = 0.5;
          txt.dropShadowDistance = 5;
          txt.backgroundEnabled = false;
          txt.backgroundColor = 0xffffff;

          if (align === "left") {
            txt.anchor.set(0, 0.5);
            txt.pivot.set(txt.style.wordWrapWidth / 2, 0);
          }
          if (align === "right") {
            txt.anchor.set(1, 0.5);
            txt.pivot.set(0 - txt.style.wordWrapWidth / 2, 0);
          }
          if (align === "center") {
            txt.anchor.set(0.5);
            txt.pivot.set(0);
          }
          window.editor.stickerSelector.updateSelector();
          window.editor.positionEdp(txt);
          window.editor.resizeText(txt);
          if (callback) {
            callback(txt);
          }
        }
      },
      unexecute: function () {
        var x;
        for (x = 0; x < stickerContainer.children.length; x++) {
          if (
            stickerContainer.children[x].id &&
            stickerContainer.children[x].id === id
          ) {
            stickerContainer.removeChildAt(x);
          }
        }
        for (x = 0; x < stickerContainer.children.length; x++) {
          if (
            stickerContainer.children[x].id &&
            stickerContainer.children[x].id === childId
          ) {
            stickerContainer.removeChildAt(x);
          }
        }
        LeftMenuActionCreators.MenuSelected("main");
      },
    });
  },
  getStickerById: function (id) {
    for (var x = 0; x < stickerContainer.children.length; x++) {
      if (
        stickerContainer.children[x].id &&
        stickerContainer.children[x].id === id
      ) {
        return stickerContainer.children[x];
      }
    }
    return null;
  },
  getCutoutStickerById: function (id) {
    if (imgObject && imgObject.children && imgObject.children.length) {
      for (var x = 0; x < imgObject.children.length; x++) {
        if (imgObject.children[x].id && imgObject.children[x].id === id) {
          return imgObject.children[x];
        }
      }
    }
    return null;
  },
  getCropSelector: function () {
    for (var x = 0; x < stickerContainer.children.length; x++) {
      if (stickerContainer.children[x].crop) {
        return stickerContainer.children[x];
      }
    }
    return null;
  },
  copySticker: function (oldSticker) {
    var self = this;
    var id = JSONUtils.guid();
    self.CommandManager.execute({
      execute: function () {
        EditorStore.setShowSpinner(true);
        if (oldSticker instanceof PIXI.Text) {
          window.editor.addText(
            oldSticker.text,
            null,
            oldSticker.width,
            oldSticker.height,
            0,
            0,
            function (txt) {
              txt.position.x = oldSticker.position.x + 30;
              txt.position.y = oldSticker.position.y + 30;
              txt.style = new PIXI.TextStyle({
                align: oldSticker.style._align,
                breakWords: oldSticker.style._breakWords,
                dropShadow: oldSticker.style._dropShadow,
                dropShadowAlpha: oldSticker.style._align.dropShadowAlpha,
                dropShadowAngle: oldSticker.style._dropShadowAngle,
                dropShadowBlur: oldSticker.style._dropShadowBlur,
                dropShadowColor: oldSticker.style._dropShadowColor,
                dropShadowDistance: oldSticker.style._dropShadowDistance,
                fill: oldSticker.style._fill,
                fillGradientType: oldSticker.style._fillGradientType,
                fillGradientStops: oldSticker.style._fillGradientStops,
                fontFamily: oldSticker.style._fontFamily,
                fontSize: oldSticker.style._fontSize,
                fontStyle: oldSticker.style._fontStyle,
                fontVariant: oldSticker.style._fontVariant,
                fontWeight: oldSticker.style._fontWeight,
                letterSpacing: oldSticker.style._letterSpacing,
                lineHeight: oldSticker.style._lineHeight,
                lineJoin: "round",
                miterLimit: oldSticker.style._miterLimit,
                padding: oldSticker.style._padding,
                stroke: oldSticker.style._stroke,
                strokeThickness: oldSticker.style._strokeThickness,
                textBaseline: oldSticker.style._textBaseline,
                trim: oldSticker.style._trim,
                whiteSpace: oldSticker.style._whiteSpace,
                wordWrap: oldSticker.style._wordWrap,
                wordWrapWidth: oldSticker.style._wordWrapWidth,
                leading: oldSticker.style._leading,
              });
              txt.rotation = oldSticker.rotation;

              txt.glowColor = oldSticker.glowColor;
              txt.backgroundColor = oldSticker.backgroundColor;
              txt.outlineColor = oldSticker.outlineColor;
              txt.dropShadowColor = oldSticker.dropShadowColor;
              txt.glowSliderVal = oldSticker.glowSliderVal;
              txt.dropShadowDistance = oldSticker.dropShadowDistance;
              txt.dropShadowAlpha = oldSticker.dropShadowAlpha;
              txt.dropShadowBlur = oldSticker.dropShadowBlur;
              txt.dropShadowRotation = oldSticker.dropShadowRotation;
              txt.backgroundEnabled = oldSticker.backgroundEnabled;
              if (oldSticker.style._align === "left") {
                txt.anchor.set(0, 0.5);
                txt.pivot.set(txt.style.wordWrapWidth / 2, 0);
              }
              if (oldSticker.style._align === "right") {
                txt.anchor.set(1, 0.5);
                txt.pivot.set(0 - txt.style.wordWrapWidth / 2, 0);
              }
              if (oldSticker.style._align === "center") {
                txt.anchor.set(0.5);
                txt.pivot.set(0);
              }

              txt.updateText(false);
              if (oldSticker.locked) {
                txt.locked = oldSticker.locked;
              }
              if (oldSticker.permLocked) {
                txt.permLocked = oldSticker.permLocked;
              }
              txt.childId = oldSticker.childId;
              txt.filters = oldSticker.filters;
              if (txt.style.fontFamily) {
                WebFont.load({
                  custom: {
                    families: [txt.style.fontFamily],
                    urls: ["/app/css/fonts.css"],
                  },
                  timeout: 1000,
                  fontinactive: function () {
                    txt.updateText(false);
                    selectedObject = txt;
                    self.stickerSelector.updateSelector();
                    selectedObject = null;
                    self.stickerSelector.updateSelector();
                  },
                  fontactive: function () {
                    txt.updateText(false);
                    selectedObject = txt;
                    self.stickerSelector.updateSelector();
                    selectedObject = null;
                    self.stickerSelector.updateSelector();
                  },
                });
              }

              self.setSelectedObject(txt);

              EditorStore.setShowSpinner(false);
              EditorStore.emitChange();
            }
          );
        } else {
          var texture = new PIXI.Texture(oldSticker.texture);
          texture.dataUrl = oldSticker.texture.dataUrl;
          texture.assetUrl = oldSticker.texture.assetUrl;

          var sticker = new PIXI.Sprite(texture);
          sticker.svg = oldSticker.svg;
          sticker.id = id;
          sticker.interactive = true;
          sticker.buttonMode = true;
          sticker.anchor.set(0.5);
          sticker.scale.set(1);
          sticker.position.x = oldSticker.position.x + oldSticker.width / 3;
          sticker.position.y = oldSticker.position.y + oldSticker.height / 3;
          sticker.width = oldSticker.width;
          sticker.height = oldSticker.height;
          sticker.sourceUrl = oldSticker.sourceUrl;
          sticker.cursor = "move";
          sticker.alpha = oldSticker.alpha;
          sticker.hue = oldSticker.hue;
          sticker.filters = oldSticker.filters;
          sticker.rotation = oldSticker.rotation;

          stickerContainer.addChild(sticker);

          sticker
            .on("mousedown", window.editor.onDragStart)
            .on("touchstart", window.editor.onDragStart)
            .on("mouseup", window.editor.onDragEnd)
            .on("mouseupoutside", window.editor.onDragEnd)
            .on("touchend", window.editor.onDragEnd)
            .on("touchendoutside", window.editor.onDragEnd)
            .on("mousemove", window.editor.onDragMove)
            .on("touchmove", window.editor.onDragMove);
          self.setSelectedObject(sticker);

          EditorStore.setShowSpinner(false);
          EditorStore.emitChange();
        }
      },
      unexecute: function () {
        for (var x = 0; x < stickerContainer.children.length; x++) {
          if (
            stickerContainer.children[x].id &&
            stickerContainer.children[x].id === id
          ) {
            stickerContainer.removeChildAt(x);
          }
        }
      },
    });
  },
  removeSticker: function (sticker) {
    var self = this;
    window.editor.CommandManager.execute({
      execute: function () {
        if (sticker.childId) {
          var child = window.editor.getStickerById(sticker.childId);
          if (child) {
            sticker.parent.removeChild(child);
          }
        }
        if (sticker.parent?.children) sticker.parent.removeChild(sticker);
        self.setSelectedObject(null);
        if (renderer.textureGC) {
          renderer.textureGC.run();
        }
      },
      unexecute: function () {
        stickerContainer.addChild(sticker);
      },
    });
  },
  applyStickerEffect: function (item, obj) {
    var _imageSettings = EditorStore.getImageSettings();
    if (obj) _imageSettings = obj.imageSettings;
    var filters = [];
    if (_imageSettings.brightness !== 0) {
      filters.push({ filter: "brightness", value: _imageSettings.brightness });
    } else {
      filters.push({ filter: "brightness", value: 0 });
    }
    if (_imageSettings.contrast !== 0.8) {
      filters.push({ filter: "contrast", value: _imageSettings.contrast });
    }
    if (_imageSettings.saturation !== 0) {
      filters.push({ filter: "saturation", value: _imageSettings.saturation });
    }
    if (_imageSettings.hue !== 0) {
      filters.push({ filter: "hue", value: _imageSettings.hue });
    }
    if (_imageSettings.temperature !== 0) {
      filters.push({
        filter: "rgb",
        value: 1 + _imageSettings.temperature / 255,
        value2: "1",
        value3: 1 + (_imageSettings.temperature / 255) * -1,
      });
    }
    if (
      _imageSettings.red !== 1 ||
      _imageSettings.green !== 1 ||
      _imageSettings.blue !== 1
    ) {
      filters.push({
        filter: "rgb",
        value: _imageSettings.red,
        value2: _imageSettings.green,
        value3: _imageSettings.blue,
      });
    }
    if (_imageSettings.noise !== 0) {
      filters.push({ filter: "noise", value: _imageSettings.noise });
    }
    if (_imageSettings.bloom !== 0) {
      filters.push({ filter: "bloom", value: _imageSettings.bloom });
    }
    if (_imageSettings.emboss !== 0) {
      filters.push({ filter: "emboss", value: _imageSettings.emboss });
    }
    if (_imageSettings.triangleBlur !== 0) {
      filters.push({
        filter: "triangleBlur",
        value: _imageSettings.triangleBlur,
      });
    }
    if (_imageSettings.denoise !== 50) {
      filters.push({ filter: "denoise", value: _imageSettings.denoise });
    }
    if (_imageSettings.sharpen !== 0) {
      filters.push({ filter: "sharpen", value: _imageSettings.sharpen });
    }
    if (_imageSettings.blur !== 0) {
      filters.push({ filter: "blur", value: _imageSettings.blur });
    }
    var j;
    if (
      item &&
      item.Effect &&
      item.Effect.length === 0 &&
      !obj &&
      EditorStore.getCurrentEffect()
    ) {
      var currectEffect = EditorStore.getCurrentEffect();
      if (currectEffect && currectEffect.Effect) {
        item.basePath = currectEffect.basePath;
        for (j = 0; j < currectEffect.Effect.length; j++) {
          item.Effect.unshift(currectEffect.Effect[j]);
        }
      }
    }
    if (filters.length > 0) {
      for (j = 0; j < filters.length; j++) {
        item.Effect.unshift(filters[j]);
      }
      filters = [];
    }

    if (item.Effect) {
      for (var i = 0; i < item.Effect.length; i++) {
        switch (item.Effect[i].filter) {
          case "denoise":
            var denoiseFilter = new DenoiseFilter();
            denoiseFilter.exponent = parseFloat(item.Effect[i].value);
            denoiseFilter.resolution = startingDevicePixelRatio;
            filters.push(denoiseFilter);
            break;
          case "bloom":
            var bloomFilter = new Filters.BloomFilter();
            bloomFilter.blur = parseFloat(item.Effect[i].value);
            bloomFilter.resolution = startingDevicePixelRatio;
            filters.push(bloomFilter);
            break;
          case "triangleBlur":
            var triangleBlurFilter = new Filters.TriangleBlurFilter();
            triangleBlurFilter.delta = [
              parseFloat(item.Effect[i].value),
              parseFloat(item.Effect[i].value),
            ];
            triangleBlurFilter.resolution = startingDevicePixelRatio;
            filters.push(triangleBlurFilter);
            break;
          case "emboss":
            var embossFilter = new Filters.EmbossFilter();
            embossFilter.strength = parseFloat(item.Effect[i].value);
            embossFilter.resolution = startingDevicePixelRatio;
            filters.push(embossFilter);
            break;
          case "noise":
            var noiseFilter = new PIXI.filters.NoiseFilter();
            noiseFilter.noise = parseFloat(item.Effect[i].value);
            noiseFilter.resolution = startingDevicePixelRatio;
            filters.push(noiseFilter);
            break;
          case "brightness":
            var colorMatrix = new PIXI.filters.ColorMatrixFilter();
            colorMatrix.resolution = startingDevicePixelRatio;
            colorMatrix.brightness(1 + parseFloat(item.Effect[i].value), true);
            filters.push(colorMatrix);
            break;
          case "contrast":
            var colorMatrix2 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix2.contrast(parseFloat(item.Effect[i].value) - 0.8, true);
            colorMatrix2.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix2);
            break;
          case "rgb":
            var colorMatrix3 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix3.matrix = [
              parseFloat(item.Effect[i].value),
              0,
              0,
              0,
              0,
              0,
              parseFloat(item.Effect[i].value2),
              0,
              0,
              0,
              0,
              0,
              parseFloat(item.Effect[i].value3),
              0,
              0,
              0,
              0,
              0,
              1,
              0,
            ];
            colorMatrix3.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix3);
            break;
          case "sepia":
            filters.push(new PIXI.filters.SepiaFilter());
            break;
          case "posterize":
            var filter = new PIXI.filters.PixelateFilter();
            filter.size.x = 5;
            filter.size.y = 5;
            filters.push(filter);
            break;
          case "grayscale":
            filters.push(new PIXI.filters.GrayFilter());
            break;
          case "border":
            this.addBorder(
              "https://cdn.pizap.com/" + item.Effect[i].value,
              true
            );
            break;
          case "saturation":
            var colorMatrix4 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix4.saturate(parseFloat(item.Effect[i].value), true);
            colorMatrix4.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix4);
            break;
          case "hue":
            var colorMatrix5 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix5.hue(1 + parseFloat(item.Effect[i].value), true);
            colorMatrix5.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix5);
            break;
          case "temperature":
            var val = parseFloat(item.Effect[i].value);
            if (val !== 0) {
              var colorMatrix6 = new PIXI.filters.ColorMatrixFilter();
              colorMatrix6.matrix = [
                1 + val / 255,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                1 + (val / 255) * -1,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
              ];
              colorMatrix6.resolution = startingDevicePixelRatio;
              filters.push(colorMatrix6);
            }
            break;
          case "sharpen":
            var convo = new Filters.ConvolutionFilter(
              [0, -1, 0, -1, 5, -1, 0, -1, 0],
              baseObject.width,
              baseObject.height
            );
            convo.resultion = startingDevicePixelRatio;
            filters.push(convo);
            break;
          case "blur":
            var convo2 = new Filters.ConvolutionFilter(
              [0.11, 0.11, 0.11, 0.12, 0.11, 0.11, 0.11, 0.11, 0.11],
              baseObject.width,
              baseObject.height
            );
            convo2.resultion = startingDevicePixelRatio;
            filters.push(convo2);
            break;
          default:
            console.log("Unknown filter type " + item.Effect[i].filter);
        }
      }
      var sticker = EditorStore.getSelectedObject();
      if (obj) sticker = obj;
      if (filters.length > 0) {
        if (sticker) {
          if (sticker.filters && sticker.filters.length > 0) {
            for (let i = 0; i < sticker.filters.length; i++) {
              if (sticker.filters[i] instanceof Filters.DropShadowFilter) {
                filters = filters.concat(sticker.filters[i]);
              }
              if (sticker.filters[i] instanceof Filters.GlowFilter) {
                filters = filters.concat(sticker.filters[i]);
              }
            }
          }
          sticker.filters = null;
          sticker.filters = filters;
          AssetStore.emitChange();
        }
      }
    }
  },
  applyHotspotEffect: function (item) {
    var settings = EditorStore.getActiveHotspotSettings();
    var filters = [];
    if (settings.brightness !== 0) {
      filters.push({ filter: "brightness", value: settings.brightness });
    }
    if (settings.contrast !== 0.8) {
      filters.push({ filter: "contrast", value: settings.contrast });
    }
    if (settings.saturation !== 0) {
      filters.push({ filter: "saturation", value: settings.saturation });
    }
    if (settings.hue !== 0) {
      filters.push({ filter: "hue", value: settings.hue });
    }
    if (settings.temperature !== 0) {
      filters.push({
        filter: "rgb",
        value: 1 + settings.temperature / 255,
        value2: "1",
        value3: 1 + (settings.temperature / 255) * -1,
      });
    }
    if (settings.red !== 1 || settings.green !== 1 || settings.blue !== 1) {
      filters.push({
        filter: "rgb",
        value: settings.red,
        value2: settings.green,
        value3: settings.blue,
      });
    }
    if (settings.noise !== 0) {
      filters.push({ filter: "noise", value: settings.noise });
    }
    if (settings.bloom !== 0) {
      filters.push({ filter: "bloom", value: settings.bloom });
    }
    if (settings.emboss !== 0) {
      filters.push({ filter: "emboss", value: settings.emboss });
    }
    if (settings.triangleBlur !== 0) {
      filters.push({ filter: "triangleBlur", value: settings.triangleBlur });
    }
    if (settings.denoise !== 50) {
      filters.push({ filter: "denoise", value: settings.denoise });
    }
    if (settings.sharpen !== 0) {
      filters.push({ filter: "sharpen", value: settings.sharpen });
    }
    if (settings.blur !== 0) {
      filters.push({ filter: "blur", value: settings.blur });
    }
    var j;
    if (
      item &&
      item.Effect &&
      item.Effect.length === 0 &&
      EditorStore.getCurrentEffect()
    ) {
      var currectEffect = EditorStore.getCurrentEffect();
      item.basePath = currectEffect.basePath;
      if (currectEffect && currectEffect.Effect) {
        for (j = 0; j < currectEffect.Effect.length; j++) {
          item.Effect.unshift(currectEffect.Effect[j]);
        }
      }
    }
    if (filters.length > 0) {
      for (j = 0; j < filters.length; j++) {
        item.Effect.unshift(filters[j]);
      }
      filters = [];
    }

    if (item.Effect) {
      for (var i = 0; i < item.Effect.length; i++) {
        switch (item.Effect[i].filter) {
          case "denoise":
            var denoiseFilter = new DenoiseFilter();
            denoiseFilter.exponent = parseFloat(item.Effect[i].value);
            denoiseFilter.resolution = startingDevicePixelRatio;
            filters.push(denoiseFilter);
            break;
          case "bloom":
            var bloomFilter = new Filters.BloomFilter();
            bloomFilter.blur = parseFloat(item.Effect[i].value);
            bloomFilter.resolution = startingDevicePixelRatio;
            filters.push(bloomFilter);
            break;
          case "triangleBlur":
            var triangleBlurFilter = new Filters.TriangleBlurFilter();
            triangleBlurFilter.delta = [
              parseFloat(item.Effect[i].value),
              parseFloat(item.Effect[i].value),
            ];
            triangleBlurFilter.resolution = startingDevicePixelRatio;
            filters.push(triangleBlurFilter);
            break;
          case "emboss":
            var embossFilter = new Filters.EmbossFilter();
            embossFilter.strength = parseFloat(item.Effect[i].value);
            embossFilter.resolution = startingDevicePixelRatio;
            filters.push(embossFilter);
            break;
          case "noise":
            var noiseFilter = new PIXI.filters.NoiseFilter();
            noiseFilter.noise = parseFloat(item.Effect[i].value);
            noiseFilter.resolution = startingDevicePixelRatio;
            filters.push(noiseFilter);
            break;
          case "brightness":
            var colorMatrix = new PIXI.filters.ColorMatrixFilter();
            colorMatrix.resolution = startingDevicePixelRatio;
            colorMatrix.brightness(1 + parseFloat(item.Effect[i].value), true);
            filters.push(colorMatrix);
            break;
          case "contrast":
            var colorMatrix2 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix2.contrast(parseFloat(item.Effect[i].value) - 0.8, true);
            colorMatrix2.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix2);
            break;
          case "rgb":
            var colorMatrix3 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix3.matrix = [
              parseFloat(item.Effect[i].value),
              0,
              0,
              0,
              0,
              0,
              parseFloat(item.Effect[i].value2),
              0,
              0,
              0,
              0,
              0,
              parseFloat(item.Effect[i].value3),
              0,
              0,
              0,
              0,
              0,
              1,
              0,
            ];
            colorMatrix3.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix3);
            break;
          case "sepia":
            filters.push(new PIXI.filters.SepiaFilter());
            break;
          case "posterize":
            var filter = new PIXI.filters.PixelateFilter();
            filter.size.x = 5;
            filter.size.y = 5;
            filters.push(filter);
            break;
          case "grayscale":
            filters.push(new PIXI.filters.GrayFilter());
            break;
          case "border":
            this.addBorder(
              "https://cdn.pizap.com/" + item.Effect[i].value,
              true
            );
            break;
          case "saturation":
            var colorMatrix4 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix4.saturate(parseFloat(item.Effect[i].value), true);
            colorMatrix4.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix4);
            break;
          case "hue":
            var colorMatrix5 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix5.hue(1 + parseFloat(item.Effect[i].value), true);
            colorMatrix5.resolution = startingDevicePixelRatio;
            filters.push(colorMatrix5);
            break;
          case "temperature":
            var val = parseFloat(item.Effect[i].value);
            if (val !== 0) {
              var colorMatrix6 = new PIXI.filters.ColorMatrixFilter();
              colorMatrix6.matrix = [
                1 + val / 255,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                1 + (val / 255) * -1,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
              ];
              colorMatrix6.resolution = startingDevicePixelRatio;
              filters.push(colorMatrix6);
            }
            break;
          case "sharpen":
            var convo = new Filters.ConvolutionFilter(
              [0, -1, 0, -1, 5, -1, 0, -1, 0],
              baseObject.width,
              baseObject.height
            );
            convo.resultion = startingDevicePixelRatio;
            filters.push(convo);
            break;
          case "blur":
            var convo2 = new Filters.ConvolutionFilter(
              [0.11, 0.11, 0.11, 0.12, 0.11, 0.11, 0.11, 0.11, 0.11],
              baseObject.width,
              baseObject.height
            );
            convo2.resultion = startingDevicePixelRatio;
            filters.push(convo2);
            break;
          default:
            console.log("Unknown filter type " + item.Effect[i].filter);
        }
      }
      var hotspot = EditorStore.getSelectedActiveCollageHotspot();
      var hotspotImage;
      for (var a = 0; a < hotspot?.children.length; a++) {
        if (hotspot.children[a].CollageBackground) {
          hotspotImage = hotspot.children[a];
        }
      }

      if (filters.length > 0) {
        if (hotspotImage) {
          hotspotImage.filters = null;
          hotspotImage.filters = filters;
          AssetStore.emitChange();
        }
      } else {
        if (hotspotImage) {
          hotspotImage.filters = null;
          AssetStore.emitChange();
        }
      }
    }
  },
  realApplyEffect: function (item, update) {
    var img, ef, opac;
    const withBlendArguments = function (blendMode, opac) {
      return function () {
        var overlay = PIXI.Sprite.fromImage(item.basePath + ef.value);
        overlay.width = EditorStore.getCustomBorder()
          ? baseOffsets.width - baseObject.x
          : baseOffsets.width;
        overlay.height = EditorStore.getCustomBorder()
          ? baseOffsets.height - baseObject.y
          : baseOffsets.height;
        overlay.position.x = EditorStore.getCustomBorder() ? baseObject.x : 0;
        overlay.position.y = EditorStore.getCustomBorder() ? baseObject.y : 0;
        overlay.pluginName = "picture";
        if (ef.opacity) {
          overlay.alpha = parseFloat(ef.opacity);
        }
        overlay.blendMode = blendMode;
        overlay.texture.dataUrl = item.basePath + ef.value;
        effectBorderContainer.addChild(overlay);
        if (opac) {
          overlay.alpha = overlay.alpha * parseFloat(opac);
        }
        EditorStore.setShowSpinner(false);
      };
    };
    var _imageSettings = EditorStore.getImageSettings();
    var filters = [];
    if (_imageSettings.brightness !== 0) {
      filters.push({ filter: "brightness", value: _imageSettings.brightness });
    }
    if (_imageSettings.contrast !== 0.8) {
      filters.push({ filter: "contrast", value: _imageSettings.contrast });
    }
    if (_imageSettings.saturation !== 0) {
      filters.push({ filter: "saturation", value: _imageSettings.saturation });
    }
    if (_imageSettings.hue !== 0) {
      filters.push({ filter: "hue", value: _imageSettings.hue });
    }
    if (_imageSettings.temperature !== 0) {
      filters.push({
        filter: "rgb",
        value: 1 + _imageSettings.temperature / 255,
        value2: "1",
        value3: 1 + (_imageSettings.temperature / 255) * -1,
      });
    }
    if (
      _imageSettings.red !== 1 ||
      _imageSettings.green !== 1 ||
      _imageSettings.blue !== 1
    ) {
      filters.push({
        filter: "rgb",
        value: _imageSettings.red,
        value2: _imageSettings.green,
        value3: _imageSettings.blue,
      });
    }
    if (_imageSettings.noise !== 0) {
      filters.push({ filter: "noise", value: _imageSettings.noise });
    }
    if (_imageSettings.bloom !== 0) {
      filters.push({ filter: "bloom", value: _imageSettings.bloom });
    }
    if (_imageSettings.emboss !== 0) {
      filters.push({ filter: "emboss", value: _imageSettings.emboss });
    }
    if (_imageSettings.triangleBlur !== 0) {
      filters.push({
        filter: "triangleBlur",
        value: _imageSettings.triangleBlur,
      });
    }
    if (_imageSettings.denoise !== 50) {
      filters.push({ filter: "denoise", value: _imageSettings.denoise });
    }
    if (_imageSettings.sharpen !== 0) {
      filters.push({ filter: "sharpen", value: _imageSettings.sharpen });
    }
    if (_imageSettings.blur !== 0) {
      filters.push({ filter: "blur", value: _imageSettings.blur });
    }
    var j;
    if (
      item &&
      item.Effect &&
      item.Effect.length === 0 &&
      EditorStore.getCurrentEffect() &&
      EditorStore.getCurrentEffect().length > 0
    ) {
      var currectEffect = EditorStore.getCurrentEffect();
      item.basePath = currectEffect.basePath;
      for (j = 0; j < currectEffect.Effect?.length; j++) {
        item.Effect.unshift(currectEffect.Effect[j]);
      }
    }
    if (filters.length > 0) {
      for (j = 0; j < filters.length; j++) {
        item.Effect.unshift(filters[j]);
      }
      filters = [];
    }

    if (item.Effect) {
      if (EditorStore.getSelectedObject()) {
      } else {
        if (!update) {
          if (EditorStore.getEditorType() !== "collage")
            baseObject.removeChildren();
          effectBorderContainer.removeChildren();
        }
      }

      for (var i = 0; i < item.Effect.length; i++) {
        switch (item.Effect[i].filter) {
          case "denoise":
            var denoiseFilter = new DenoiseFilter();
            denoiseFilter.resolution = startingDevicePixelRatio;
            denoiseFilter.exponent = parseFloat(item.Effect[i].value);
            filters.push(denoiseFilter);
            break;
          case "bloom":
            var bloomFilter = new Filters.BloomFilter();
            bloomFilter.resolution = startingDevicePixelRatio;
            bloomFilter.blur = parseFloat(item.Effect[i].value);
            filters.push(bloomFilter);
            break;
          case "triangleBlur":
            //                        var triangleBlurFilter = new Filters.TriangleBlurFilter();
            //                        triangleBlurFilter.resolution = startingDevicePixelRatio;
            //                        triangleBlurFilter.delta = [parseFloat(item.Effect[i].value), parseFloat(item.Effect[i].value)];
            //                        filters.push(triangleBlurFilter);
            break;
          case "emboss":
            var embossFilter = new Filters.EmbossFilter();
            embossFilter.resolution = startingDevicePixelRatio;
            embossFilter.strength = parseFloat(item.Effect[i].value);
            filters.push(embossFilter);
            break;
          case "noise":
            var noiseFilter = new PIXI.filters.NoiseFilter();
            noiseFilter.resolution = startingDevicePixelRatio;
            noiseFilter.noise = parseFloat(item.Effect[i].value);
            filters.push(noiseFilter);
            break;
          case "brightness":
            var colorMatrix = new PIXI.filters.ColorMatrixFilter();
            colorMatrix.resolution = startingDevicePixelRatio;
            colorMatrix.brightness(1 + parseFloat(item.Effect[i].value), true);
            filters.push(colorMatrix);
            break;
          case "contrast":
            var colorMatrix6 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix6.resolution = startingDevicePixelRatio;
            colorMatrix6.contrast(
              parseFloat(item.Effect[i].value) - 0.8,
              false
            );
            filters.push(colorMatrix6);
            break;
          case "blend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.NORMAL,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "addblend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(PIXI.BLEND_MODES.ADD, item.zoom);
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "overlayBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.OVERLAY,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "darkenBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.DARKEN,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "lightenBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.LIGHTEN,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "screenBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.SCREEN,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "alphaBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(PIXI.BLEND_MODES.ADD, item.zoom);
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "multiplyBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.MULTIPLY,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "colorDodgeBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.COLOR_DODGE,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              opac = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac * parseFloat(item.zoom);
            }
            break;
          case "hardLightBlend":
            if (!update) {
              EditorStore.setShowSpinner(true);
              ef = item.Effect[i];
              img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = withBlendArguments(
                PIXI.BLEND_MODES.HARD_LIGHT,
                item.zoom
              );
              img.src = item.basePath + item.Effect[i].value;
            } else {
              var opac2 = item.Effect[i].opacity
                ? parseFloat(item.Effect[i].opacity)
                : 1;
              effectBorderContainer.children[0].alpha =
                opac2 * parseFloat(item.zoom);
            }
            break;
          case "rgb":
            var colorMatrix5 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix5.resolution = startingDevicePixelRatio;
            colorMatrix5.matrix = [
              parseFloat(item.Effect[i].value),
              0,
              0,
              0,
              0,
              0,
              parseFloat(item.Effect[i].value2),
              0,
              0,
              0,
              0,
              0,
              parseFloat(item.Effect[i].value3),
              0,
              0,
              0,
              0,
              0,
              1,
              0,
            ];
            filters.push(colorMatrix5);
            break;
          case "sepia":
            var sepiaFilter = new PIXI.filters.SepiaFilter();
            sepiaFilter.resolution = startingDevicePixelRatio;

            filters.push(sepiaFilter);
            break;
          case "posterize":
            var filter = new PIXI.filters.PixelateFilter();
            filter.resolution = startingDevicePixelRatio;
            filter.size.x = 5;
            filter.size.y = 5;
            filters.push(filter);
            break;
          case "grayscale":
            var grayscaleFilter = new PIXI.filters.GrayFilter();
            grayscaleFilter.resolution = startingDevicePixelRatio;
            filters.push(grayscaleFilter);
            break;
          case "border":
            this.addBorder(
              "https://cdn.pizap.com/" + item.Effect[i].value,
              true
            );
            break;
          case "saturation":
            var colorMatrix2 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix2.resolution = startingDevicePixelRatio;
            colorMatrix2.saturate(parseFloat(item.Effect[i].value), true);
            filters.push(colorMatrix2);
            break;
          case "hue":
            var colorMatrix3 = new PIXI.filters.ColorMatrixFilter();
            colorMatrix3.resolution = startingDevicePixelRatio;
            colorMatrix3.hue(1 + parseFloat(item.Effect[i].value), true);
            filters.push(colorMatrix3);
            break;
          case "temperature":
            var val = parseFloat(item.Effect[i].value);
            if (val !== 0) {
              var colorMatrix4 = new PIXI.filters.ColorMatrixFilter();
              colorMatrix4.resolution = startingDevicePixelRatio;
              colorMatrix4.matrix = [
                1 + val / 255,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                1 + (val / 255) * -1,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
              ];
              filters.push(colorMatrix4);
            }
            break;
          case "sharpen":
            var convo = new Filters.ConvolutionFilter(
              [0, -1, 0, -1, 5, -1, 0, -1, 0],
              baseObject.width,
              baseObject.height
            );
            convo.resolution = startingDevicePixelRatio;
            convo.resultion = startingDevicePixelRatio;
            filters.push(convo);
            break;
          case "blur":
            var convo1 = new Filters.ConvolutionFilter(
              [0.11, 0.11, 0.11, 0.12, 0.11, 0.11, 0.11, 0.11, 0.11],
              baseObject.width,
              baseObject.height
            );
            convo1.resolution = startingDevicePixelRatio;
            convo1.resultion = startingDevicePixelRatio;
            filters.push(convo1);
            break;
          default:
            console.log("Unknown filter type " + item.Effect[i].filter);
        }
      }
      if (filters.length > 0) {
        if (LeftMenuStore.get() !== "collagehotspot") {
          if (EditorStore.getSelectedObject()) {
            const blur = new PIXI.filters.BlurFilter();
            blur.blur = 0;
            blur.padding = 40;
            blur.resolution = 2;
            blur.quality = 1;
            EditorStore.getSelectedObject().filters = [blur, ...filters];
            AssetStore.emitChange();
          } else {
            if (EditorStore.getDesignBackground()) {
              EditorStore.getDesignBackground().filters = null;
              EditorStore.getDesignBackground().filters = filters;
              AssetStore.emitChange();
            } else {
              baseObject.filters = null;
              baseObject.filters = filters;
              AssetStore.emitChange();
            }
          }
        } else {
          if (EditorStore.getSelectedActiveCollageHotspot()) {
            EditorStore.getSelectedActiveCollageHotspot().filters = null;
            EditorStore.getSelectedActiveCollageHotspot().effect = item;
            EditorStore.getSelectedActiveCollageHotspot().filters = filters;
            AssetStore.emitChange();
          } else {
            EditorStore.getSelectedActiveCollageHotspot().filters = null;
            EditorStore.getSelectedActiveCollageHotspot().effect = item;
            EditorStore.getSelectedActiveCollageHotspot().filters = filters;
            AssetStore.emitChange();
          }
        }
      } else {
        if (LeftMenuStore.get() !== "collagehotspot") {
          if (EditorStore.getSelectedObject()) {
            EditorStore.getSelectedObject().filters = null;
            AssetStore.emitChange();
          } else {
            if (EditorStore.getDesignBackground()) {
              EditorStore.getDesignBackground().filters = null;
            } else {
              baseObject.filters = null;
            }
          }
        } else {
          if (EditorStore.getSelectedActiveCollageHotspot()) {
            EditorStore.getSelectedActiveCollageHotspot().filters = null;
            EditorStore.getSelectedActiveCollageHotspot().effect = item;
            AssetStore.emitChange();
          } else {
            EditorStore.getSelectedActiveCollageHotspot().filters = null;
            EditorStore.getSelectedActiveCollageHotspot().effect = item;
          }
        }
      }
    }
    window.editor.autoSave();
  },
  applyEffect: function (item) {
    window.editor.CommandManager.filter({
      execute: function () {
        window.editor.realApplyEffect(item);
      },
      unexecute: function () {
        var _imageSettings = {
          temperature: 0,
          brightness: 0,
          contrast: 0.8,
          saturation: 0,
          hue: 0,
          red: 1,
          green: 1,
          blue: 1,
          noise: 0,
          bloom: 0,
          emboss: 0,
          triangleBlur: 0,
          denoise: 50,
          sharpen: 0,
          blur: 0,
          // effect: null,
        };
        if (LeftMenuStore.get() === "edit_graphic") {
          EditorStore.setGraphicCurrentEffect(null);
          EditorStore.setImageSettings(_imageSettings);
        } else {
          if (EditorStore.getEditorType() === "collage") {
            if (window.editor.getSelectedObject()) {
              window.editor.getSelectedObject().filters = null;
            }
          } else {
            EditorStore.setCurrentEffect(null);
            EditorStore.setImageSettings(_imageSettings);
            window.editor.getBaseObject().removeChildren();
          }
          window.editor.getBaseObject().filters = null;
          if (EditorStore.getDesignBackground()) {
            EditorStore.getDesignBackground().filters = null;
          }
        }
        window.editor.getEffectBorderContainer().removeChildren();
        AssetStore.emitChange();
      },
    });
  },
  changePhoto: function (url, maintainRotation, isTouchup) {
    window.editor.loadTextureFromUrl(
      url,
      function (texture) {
        baseObject.texture = texture;
        if (EditorStore.getEditorType() === "edit") {
          if (maintainRotation) {
          } else {
            baseObject.rotation = 0;
          }
          baseObject.mask = null;

          var content = document.getElementById("container");
          var canvasWidth = content.clientWidth - 374;
          var canvasHeight = content.clientHeight - 46;
          var offsets = window.editor.scaleToFit(
            canvasWidth - canvasWidthOffset,
            canvasHeight - canvasHeightOffset,
            texture.width,
            texture.height
          );
          baseOffsets = offsets;
          baseObject.scale.set(offsets.width / baseObject.texture.width);
          originalSize = { width: texture.width, height: texture.height };

          renderer.resize(offsets.width, offsets.height);
          renderer.view.style.height = offsets.height + "px";
          renderer.view.style.width = offsets.width + "px";
          renderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
          memeWordWrapWidth = baseObject.width * 0.9;
          var s = topMemeText.style;
          s.wordWrapWidth = memeWordWrapWidth;
          topMemeText.style = s;
          topMemeText.position.x = offsets.width / 2;
          topMemeText.position.y = offsets.height / 16;
          s = bottomMemeText.style;
          s.wordWrapWidth = memeWordWrapWidth;
          bottomMemeText.style = s;
          bottomMemeText.position.x = offsets.width / 2;
          bottomMemeText.position.y = offsets.height - offsets.height / 16;

          if (
            effectBorderContainer &&
            effectBorderContainer.children &&
            effectBorderContainer.children.length > 0
          ) {
            for (var i = 0; i < effectBorderContainer.children.length; i++) {
              effectBorderContainer.children[i].width = stage.width;
              effectBorderContainer.children[i].height = stage.height;
            }
          }
          if (maintainRotation && baseObject.straightenScale) {
            baseObject.scale.set(
              baseObject.scale.x * baseObject.straightenScale,
              baseObject.scale.y * baseObject.straightenScale
            );
          }
          if (!maintainRotation) {
            baseObject.initialScaleX = baseObject.scale.x;
            baseObject.initialScaleY = baseObject.scale.y;
            baseObject.straightenScale = null;
          }
        } else {
          window.editor.addDesignBackground(texture, false);
        }
        EditorStore.setShowSpinner(false);
      },
      7000,
      null,
      isTouchup
    );
  },
  addBorder: function (url, effect) {
    var id = JSONUtils.guid();
    window.editor.CommandManager.execute({
      execute: function () {
        EditorStore.setShowSpinner(true);
        window.editor.loadTextureFromUrl(
          url,
          function (texture) {
            window.editor.customBorderRemove();

            var sticker = new PIXI.Sprite(texture);
            sticker.texture.dataUrl = url;

            sticker.id = id;
            sticker.position.x = 0;
            sticker.position.y = 0;
            sticker.effect = effect;
            if (
              baseObject.rotation === 1.5708 ||
              baseObject.rotation === 1.5708 * 3
            ) {
              sticker.width = baseOffsets.height;
              sticker.height = baseOffsets.width;
            } else {
              sticker.width = baseOffsets.width;
              sticker.height = baseOffsets.height;
            }
            if (effect) {
              effectBorderContainer.removeChildren();
              effectBorderContainer.addChild(sticker);
            } else {
              borderContainer.removeChildren();
              borderContainer.addChild(sticker);
            }
            AssetStore.emitChange();
            EditorStore.setShowSpinner(false);
          },
          7000
        );
      },
      unexecute: function () {
        if (effect) {
          effectBorderContainer.removeChildren();
        } else {
          borderContainer.removeChildren();
        }
      },
    });
  },
  dragOffsetX: 0,
  dragOffsetY: 0,
  dragStartX: 0,
  dragStartY: 0,
  scaleStartWidth: 0,
  scaleStartHeight: 0,
  rotateStart: 0,
  lastDragX: 0,
  lastDragY: 0,
  painting: false,
  moving: false,
  panning: false,
  cropping: false,
  currentPoint: -1,
  cutoutInitialZoom: 1,
  cutoutId: null,
  addCollagePicture: function (url, hs) {
    var self = this;
    var id = JSONUtils.guid();

    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = function () {
      var hotspot = window.editor.getCollageSpotSelected();
      var saveScale =
        window.editor.getOriginalSize().width /
        window.editor.getRenderer().view.width;
      var scale = 1;
      if (hotspot) {
        scale =
          (hotspot.width * saveScale * startingDevicePixelRatio) / this.width;
      }
      var baseTexture;
      if (scale < 1) {
        if (scale < 0.125) scale = 0.125;
        if (scale > 0.125 && scale < 0.25) scale = 0.25;
        if (scale > 0.25 && scale < 0.5) scale = 0.5;

        var imgData = window.editor.resizeImage(this, scale);
        baseTexture = PIXI.BaseTexture.fromImage(
          imgData,
          true,
          PIXI.SCALE_MODES.NEAREST
        );
      } else {
        baseTexture = PIXI.BaseTexture.fromImage(
          window.editor.getBase64Image(this),
          true,
          PIXI.SCALE_MODES.NEAREST
        );
      }

      if (baseTexture.hasLoaded) {
        if (hotspot) {
          self.addCollageBackground(baseTexture, id, hotspot);
        } else {
          self.addCollageBackground(baseTexture, id);
        }
      } else {
        baseTexture.on("loaded", function () {
          if (hotspot) {
            self.addCollageBackground(baseTexture, id, hotspot);
          } else {
            self.addCollageBackground(baseTexture, id);
          }
        });
      }
    };
    img.src = url;
  },
  addPicture: function (url, callback) {
    var self = this;
    var id = JSONUtils.guid();

    if (LeftMenuStore.get() === "init") {
      window.editor.initPhoto(url, () => {
        EditorActionCreators.ShowFilePicker(false);
      });
      return;
    }
    if (EditorStore.getFilePickerTool() === "reset") {
      EditorStore.setFilePickerTool(null);
      window.editor.changePhoto(url);
      return;
    }
    if (EditorStore.getFilePickerTool() === "profile") {
      EditorStore.setFilePickerTool(null);
      EditorActionCreators.changeProfilePhoto(url);
      EditorStore.setShowSpinner(false);
      return;
    }
    if (EditorStore.getFilePickerTool() === "collageBackground") {
      EditorStore.setFilePickerTool(null);
      window.editor.loadTextureFromUrl(
        url,
        function (texture) {
          collageBackgroundContainer.removeChildren();
          self.addDesignBackground(texture, id, function (sticker) {
            self.setSelectedObject(null);
            window.editor.autoSave();
            if (callback) callback();
          });
        },
        6000
      );
      return;
    }
    if (EditorStore.getFilePickerTool() === "collage") {
      EditorStore.setFilePickerTool(null);
      EditorStore.setShowSpinner(true);
      var hotspot = window.editor.getCollageSpotSelected();
      var saveScale =
        window.editor.getOriginalSize().width /
        window.editor.getRenderer().view.width;
      var maxSize =
        (hotspot.width * saveScale + hotspot.height * saveScale) * 2;
      window.editor.loadTextureFromUrl(
        url,
        function (texture) {
          EditorStore.setShowSpinner(false);
          self.addCollageBackground(texture, id, hotspot, function () {
            window.editor.autoSave();
          });
        },
        maxSize
      );
      return;
    }
    if (
      EditorStore.getFilePickerTool() === "cutout" ||
      EditorStore.getGraphicCutoutStyle()
    ) {
      EditorStore.setFilePickerTool(null);
      EditorStore.setShowSpinner(true);
      if (EditorStore.getCutoutMode() === "automatic") {
        if (EditorStore.getGraphicCutoutStyle()) {
          EditorActionCreators.RemoveSticker(EditorStore.getSelectedObject());
        }
        EditorActionCreators.removeBackground(url);
        return;
      }
      LeftMenuActionCreators.MenuSelected("cutout");
      window.editor.loadTextureFromUrl(
        url,
        function (texture) {
          var content = document.getElementById("container");
          var container = document.getElementById("mainContent");
          var mainCanvas = renderer.view;

          mainCanvas.style.display = "none";
          var offsets = window.editor.scaleToFit(
            content.clientWidth - 410,
            content.clientHeight - 112,
            texture.width,
            texture.height
          );
          cutoutRenderer = new PIXI.WebGLRenderer(
            offsets.width,
            offsets.height,
            {
              antialias: true,
              transparent: true,
              autoresize: true,
              preserveDrawingBuffer: true,
            }
          );
          window.editor.setupCursors(cutoutRenderer);
          var canvasHeight = content.clientHeight - 46;

          cutoutRenderer.view.style.top =
            (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

          cutoutRenderer.view.style.display = "block";
          container.appendChild(cutoutRenderer.view);
          requestAnimationFrame(window.editor.animateCutout);

          imgObject = new PIXI.Sprite(texture);
          texture.dataUrl = url;
          cutoutSize = { width: texture.width, height: texture.height };

          cutoutStage.addChild(imgObject);

          cutoutContainer = new PIXI.Graphics();
          cutoutStage.addChild(cutoutContainer);

          cutoutMask = new PIXI.Graphics();
          cutoutMask.renderable = false;

          imgObject.addChild(cutoutMask);

          cutoutStage.width = offsets.width;
          cutoutStage.height = offsets.height;

          window.addEventListener("resize", function () {
            if (cutoutRenderer) {
              var canvasWidth = content.clientWidth - 374;
              var canvasHeight = content.clientHeight - 46;
              var offsets = window.editor.scaleToFit(
                canvasWidth - canvasWidthOffset,
                canvasHeight - canvasHeightOffset,
                cutoutSize.width,
                cutoutSize.height
              );
              cutoutRenderer.view.style.height = offsets.height + "px";
              cutoutRenderer.view.style.width = offsets.width + "px";
              cutoutRenderer.view.style.top =
                (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
            }
          });
          cutoutRenderer.view.addEventListener(
            "mousedown",
            window.editor.onCutoutStart,
            false
          );
          cutoutRenderer.view.addEventListener(
            "mousemove",
            window.editor.onCutoutMove,
            false
          );
          cutoutRenderer.view.addEventListener(
            "mouseup",
            window.editor.onCutoutEnd,
            false
          );
          cutoutRenderer.view.addEventListener(
            "touchstart",
            window.editor.onCutoutStart,
            false
          );
          cutoutRenderer.view.addEventListener(
            "touchmove",
            window.editor.onCutoutMove,
            true
          );
          cutoutRenderer.view.addEventListener(
            "touchend",
            window.editor.onCutoutEnd,
            false
          );
          self.cutoutInitialZoom = cutoutStage.scale.x;
          cutoutStage.interactive = true;
          cutoutStage.buttonMode = true;
          if (
            EditorStore.getCutoutMode() !== "shapes" &&
            EditorStore.getCutoutMode() !== "crop"
          ) {
            cutoutStage.cursor = "crosshair";
          }
          if (EditorStore.getCutoutMode() === "crop") {
            EditorActionCreators.ShowCutoutZoom(true);
            window.editor.initCutoutCrop();
          }
          StickerCutoutSelector.init();
          StickerCutoutSelector.setCropCutoutObject(imgObject);
          origCutoutScale = imgObject.scale.x;
          origCutoutStageScale = cutoutStage.scale.x;
          EditorStore.setShowSpinner(false);
          window.editor.autoSave();
        },
        7000
      );
      return;
    }
    if (EditorStore.getFilePickerTool() === "design") {
      EditorStore.setFilePickerTool(null);
      EditorStore.setShowSpinner(true);
      window.editor.loadTextureFromUrl(
        url,
        function (texture) {
          texture.dataUrl = url;
          self.addDesignBackground(texture, id, () => {
            window.editor.autoSave();
          });
        },
        7000
      );
      return;
    }
    if (!url.startsWith("data:") && url.indexOf("svg") > 0) {
      window.editor.addSticker(url, null, null, function (sticker) {
        EditorStore.setShowSpinner(false);
      });
      if (callback) callback();
    } else {
      window.editor.CommandManager.execute({
        execute: function () {
          EditorStore.setShowSpinner(true);
          window.editor.loadTextureFromUrl(
            url,
            function (texture) {
              self.addPictureComplete(texture, id);
            },
            2000
          );

          if (callback) callback();
        },
        unexecute: function () {
          for (var x = 0; x < stickerContainer.children.length; x++) {
            if (
              stickerContainer.children[x].id &&
              stickerContainer.children[x].id === id
            ) {
              stickerContainer.removeChildAt(x);
            }
          }
        },
      });
    }
  },
  getBaseOffsets: function () {
    return baseOffsets;
  },
  addPictureComplete: function (texture, id) {
    var sticker = new PIXI.Sprite(new PIXI.Texture(texture));
    sticker.texture.dataUrl = texture.dataUrl;
    sticker.texture.assetUrl = texture.assetUrl;
    if (texture.assetUrl.indexOf(".svg") >= 1) {
      sticker.svg = true;
      sticker.sourceUrl = texture.dataUrl;
    } else {
      sticker.svg = false;
      sticker.sourceUrl = texture.assetUrl;
    }

    sticker.id = id;
    sticker.interactive = true;
    sticker.buttonMode = true;
    if (baseObject.rotation === 1.5708 || baseObject.rotation === 1.5708 * 3) {
      sticker.position.x = baseOffsets.height / 2;
      sticker.position.y = baseOffsets.width / 2;
    } else {
      sticker.position.x = baseOffsets.width / 2;
      sticker.position.y = baseOffsets.height / 2;
    }
    sticker.anchor.set(0.5);
    sticker.scale.set(1);
    sticker
      .on("mousedown", window.editor.onDragStart)
      .on("touchstart", window.editor.onDragStart)
      .on("mouseup", window.editor.onDragEnd)
      .on("mouseupoutside", window.editor.onDragEnd)
      .on("touchend", window.editor.onDragEnd)
      .on("touchendoutside", window.editor.onDragEnd)
      .on("mousemove", window.editor.onDragMove)
      .on("touchmove", window.editor.onDragMove);
    if (sticker.texture.width > baseOffsets.width * 0.4) {
      var scale = (baseOffsets.width * 0.4) / sticker.texture.width;
      sticker.scale.x = scale;
      sticker.scale.y = scale;
    }
    sticker.imageSettings = {
      temperature: 0,
      brightness: 0,
      contrast: 0.8,
      saturation: 0,
      hue: 0,
      red: 1,
      green: 1,
      blue: 1,
      noise: 0,
      bloom: 0,
      emboss: 0,
      triangleBlur: 0,
      denoise: 50,
      sharpen: 0,
      blur: 0,
    };

    stickerContainer.addChild(sticker);
    this.setSelectedObject(sticker);
    EditorStore.setShowSpinner(false);
    window.editor.positionEdp(sticker);
    window.editor.autoSave();
  },
  addDesignBackground: function (texture, id, callback) {
    baseObject.texture = texture;
    var sticker = new PIXI.Sprite(new PIXI.Texture(texture));
    sticker.texture.dataUrl = texture.dataUrl;
    sticker.texture.assetUrl = texture.assetUrl;
    if (id) {
      sticker.id = id;
    }
    sticker.interactive = true;
    sticker.buttonMode = true;
    sticker.DesignBackground = true;
    sticker.cursor = "move";

    if (baseObject.rotation === 1.5708 || baseObject.rotation === 1.5708 * 3) {
      sticker.position.x = baseOffsets.height / 2;
      sticker.position.y = baseOffsets.width / 2;
    } else {
      sticker.position.x = baseOffsets.width / 2;
      sticker.position.y = baseOffsets.height / 2;
    }
    sticker.anchor.set(0.5);
    sticker
      .on("mousedown", window.editor.onDragStart)
      .on("touchstart", window.editor.onDragStart)
      .on("mouseup", window.editor.onDragEnd)
      .on("mouseupoutside", window.editor.onDragEnd)
      .on("touchend", window.editor.onDragEnd)
      .on("touchendoutside", window.editor.onDragEnd)
      .on("mousemove", window.editor.onDragMove)
      .on("touchmove", window.editor.onDragMove);
    var scale = window.editor.scaleToFill(
      baseOffsets.width,
      baseOffsets.height,
      sticker.texture.width,
      sticker.texture.height
    );

    sticker.scale.x = scale;
    sticker.scale.y = scale;
    sticker.initialZoom = sticker.scale.x;
    if (EditorStore.getEditorType() === "collage") {
      collageBackgroundContainer.addChild(sticker);
    } else {
      backgroundContainer.addChild(sticker);
    }
    EditorStore.setDesignBackground(sticker);
    EditorStore.setShowSpinner(false);
    window.editor.positionEdp(sticker);
    this.setSelectedObject(sticker);
    if (callback) {
      callback(sticker);
    }
    return sticker;
  },
  addCollageBackground: function (texture, id, hotspot, callback) {
    EditorStore.setShowSpinner(true);
    //        var newTexture = new PIXI.Texture(texture);
    var sticker = new PIXI.Sprite(texture);
    sticker.id = id;
    //        sticker.texture.dataUrl = texture.dataUrl;
    sticker.interactive = true;
    sticker.buttonMode = true;
    sticker.CollageBackground = true;
    if (hotspot.initialRect) {
      var rect = hotspot.initialRect;
      if (sticker.rotation === 1.5708 || sticker.rotation === 1.5708 * 3) {
        sticker.position.x = rect.height / hotspot.scale.y / 2;
        sticker.position.y = rect.width / hotspot.scale.x / 2;
      } else {
        sticker.position.x = rect.width / hotspot.scale.x / 2;
        sticker.position.y = rect.height / hotspot.scale.y / 2;
      }
      sticker.anchor.set(0.5);
      sticker.scale.set(1);
      sticker
        .on("mousedown", window.editor.onDragStart)
        .on("touchstart", window.editor.onDragStart)
        .on("mouseup", window.editor.onDragEnd)
        .on("mouseupoutside", window.editor.onDragEnd)
        .on("touchend", window.editor.onDragEnd)
        .on("touchendoutside", window.editor.onDragEnd)
        .on("mousemove", window.editor.onDragMove)
        .on("touchmove", window.editor.onDragMove);
      var scale = window.editor.scaleToFill(
        rect.width / hotspot.scale.x,
        rect.height / hotspot.scale.y,
        sticker.texture.width,
        sticker.texture.height
      );
      sticker.scale.x = scale;
      sticker.scale.y = scale;
      sticker.initialZoom = sticker.scale.x;

      hotspot.addChild(sticker);
      if (callback) {
        callback(sticker);
      }
      EditorStore.setShowSpinner(false);
      return sticker;
    }
    EditorStore.setShowSpinner(false);
    return null;
  },
  deleteDesignBackground: function () {
    var background = EditorStore.getDesignBackground();
    if (EditorStore.getEditorType() === "collage") {
      collageBackgroundContainer.removeChildren();
    } else {
      backgroundContainer.removeChild(background);
    }

    EditorStore.setDesignBackground(null);
  },
  onDragStart: function (event) {
    if (EditorStore.getColorSelectorOpen()) return;
    if (EditorStore.getShowColorPicker()) EditorStore.setShowColorPicker(false);
    if (LeftMenuStore.get() !== "paint") {
      this.data = event.data;
      if (this.CollageBackground) {
        if (
          this.parent.mask.containsPoint(
            new PIXI.Point(event.data.global.x, event.data.global.y)
          )
        ) {
          this.dragging = true;
          this.dragOffsetX = this.x - this.data.global.x / stage.scale.x;
          this.dragOffsetY = this.y - this.data.global.y / stage.scale.y;
          this.dragStartX = this.x;
          this.dragStartY = this.y;
          window.editor.setSelectedObject(this);
          EditorStore.emitChange();
        }
        if (
          EditorStore.getSelectedCollageHotspot() !== null &&
          EditorStore.getSelectedCollageHotspot() !== this.parent
        ) {
          EditorActionCreators.CollageHotspotSelected(null);
        }
      } else {
        if (!this.locked) {
          document.addEventListener("mouseup", this.onDragEnd);
          this.dragging = true;
          this.dragOffsetX = this.x - this.data.global.x / stage.scale.x;
          this.dragOffsetY = this.y - this.data.global.y / stage.scale.y;
          this.dragStartX = this.x;
          this.dragStartY = this.y;
          if (!gridLines) {
            gridLines = new PIXI.Graphics();
            stage.addChild(gridLines);
            gridLines.clear();
          }
        }
        window.editor.setSelectedObject(this);
      }
      var edp = document.getElementById("editor-properties");
      if (
        EditorStore.getSelectedCollageHotspot() &&
        EditorStore.getShowPropertiesCollage()
      ) {
      } else {
        if (edp) edp.style.display = "none";
      }
    }
  },
  onCutoutDragStart: function (event) {
    event.stopPropagation();
    this.data = event.data;
    this.dragging = true;
    this.dragOffsetX = this.x - this.data.global.x / cutoutStage.scale.x;
    this.dragOffsetY = this.y - this.data.global.y / cutoutStage.scale.y;
    this.dragStartX = this.x;
    this.dragStartY = this.y;
    window.editor.setSelectedObject(this);
  },
  onDragEnd: function (event) {
    event.stopPropagation();
    if (EditorStore.getColorSelectorOpen()) return;
    document.removeEventListener("mouseup", this.onDragEnd);
    var self = this;
    var so = window.editor.getSelectedObject();
    if (self.CollageBackground && so !== null && so.CollageBackground) {
      for (var x = 0; x < window.editor.getBaseObject().children.length; x++) {
        if (window.editor.getBaseObject().children[x].mask) {
          if (
            window.editor
              .getBaseObject()
              .children[x].mask.containsPoint(
                new PIXI.Point(event.data.global.x, event.data.global.y)
              )
          ) {
            var found = false;
            for (
              var a = 0;
              a < window.editor.getBaseObject().children[x].children.length;
              a++
            ) {
              if (
                window.editor.getBaseObject().children[x].children[a]
                  .CollageBackground
              ) {
                if (
                  window.editor.getBaseObject().children[x].children[a] !== so
                ) {
                  found = true;
                  this.dragging = false;
                  so.dragging = false;
                  if (so.texture) {
                    window.editor.swapHotspots(
                      window.editor.getBaseObject().children[x].children[a],
                      so
                    );
                  }
                  return;
                }
              }
            }
            if (!found) {
              this.dragging = false;
              so.dragging = false;
              var texture = so.texture;
              if (texture) {
                if (window.editor.getBaseObject().children[x] !== so.parent) {
                  window.editor.addCollageBackground(
                    texture,
                    JSONUtils.guid(),
                    window.editor.getBaseObject().children[x]
                  );
                  so.parent.removeChild(so);
                }
              }
              return;
            }
          }
        }
      }
    }

    if (!this.data) return;
    if (this.locked) {
      window.editor.positionEdp(this);
      return;
    }
    this.hideSelector = false;

    var id = this.id;
    var startx = this.dragStartX;
    var starty = this.dragStartY;
    //        var dragx = this.dragOffsetX;
    //        var dragy = this.dragOffsetY;
    this.dragging = false;
    //        var newPosition = this.data.getLocalPosition(this.parent);
    this.data = null;
    window.editor.CommandManager.execute({
      execute: function () {
        var sticker = window.editor.getStickerById(id);
        if (
          sticker &&
          !sticker.DesignBackground &&
          !sticker.collagePlaceholder
        ) {
          //                    sticker.position.x = newPosition.x + dragx;
          //                    sticker.position.y = newPosition.y + dragy;
          window.editor.positionEdp(sticker);
          window.editor.stickerSelector.updateSelector();
        }
      },
      unexecute: function () {
        var sticker = window.editor.getStickerById(id);
        if (sticker && !sticker.DesignBackground) {
          sticker.position.x = startx;
          sticker.position.y = starty;
        }
      },
    });
    if (horizontalAlign) {
      stage.removeChild(horizontalAlign);
      horizontalAlign = null;
    }
    if (vertAlign) {
      stage.removeChild(vertAlign);
      vertAlign = null;
    }
    if (gridLines) {
      stage.removeChild(gridLines);
      gridLines = null;
    }
    EditorStore.emitChange();
  },
  onCutoutDragEnd: function () {
    if (!this.data) return;
    var id = this.id;
    var startx = this.dragStartX;
    var starty = this.dragStartY;
    var dragx = this.dragOffsetX;
    var dragy = this.dragOffsetY;
    this.dragging = false;
    var newPosition = this.data.getLocalPosition(this.parent);
    this.data = null;
    window.editor.CommandManager.execute({
      execute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        sticker.position.x = newPosition.x + dragx;
        sticker.position.y = newPosition.y + dragy;
      },
      unexecute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        sticker.position.x = startx;
        sticker.position.y = starty;
      },
    });
  },
  onDragMove: function (event) {
    if (EditorStore.getColorSelectorOpen()) return;

    var newX, newY;
    if (
      this.data &&
      !this.skewWidth &&
      !this.skewHeight &&
      !this.scaling &&
      this.dragging &&
      this === window.editor.getSelectedObject()
    ) {
      if (this.locked) return;
      var newPosition = this.data.getLocalPosition(this.parent);
      if (this.DesignBackground) {
        if (LeftMenuStore.get() !== "design") return;
        if (this.rotation === 1.5708 * 3 || this.rotation === 1.5708) {
          newX = newPosition.x + this.dragOffsetX - this.height / 2;
          newY = newPosition.y + this.dragOffsetY - this.width / 2;
          if (newX > 0) {
            newX = 0;
          }
          if (newX + this.height < baseOffsets.width) {
            newX = baseOffsets.width - this.height;
          }
          if (newY > 0) {
            newY = 0;
          }
          if (newY + this.width < baseOffsets.height) {
            newY = baseOffsets.height - this.width;
          }
          this.position.x = newX + this.height / 2;
          this.position.y = newY + this.width / 2;
        } else {
          newX = newPosition.x + this.dragOffsetX - this.width / 2;
          newY = newPosition.y + this.dragOffsetY - this.height / 2;
          if (newX > 0) {
            newX = 0;
          }
          if (newX + this.width < baseOffsets.width) {
            newX = baseOffsets.width - this.width;
          }
          if (newY > 0) {
            newY = 0;
          }
          if (newY + this.height < baseOffsets.height) {
            newY = baseOffsets.height - this.height;
          }
          this.position.x = newX + this.width / 2;
          this.position.y = newY + this.height / 2;
        }
      } else if (this.CollageBackground) {
        if (this.rotation === 1.5708 * 3 || this.rotation === 1.5708) {
          newX = newPosition.x + this.dragOffsetX + this.parent.x;
          newY = newPosition.y + this.dragOffsetY + this.parent.y;
          if (newX > this.height / 2) {
            newX = this.height / 2;
          }
          if (
            newX + this.height / 2 <
            this.parent.initialRect.width / this.parent.scale.x
          ) {
            newX =
              this.parent.initialRect.width / this.parent.scale.y -
              this.height / 2;
          }
          if (newY > this.width / 2) {
            newY = this.width / 2;
          }
          if (
            newY + this.width / 2 <
            this.parent.initialRect.height / this.parent.scale.y
          ) {
            newY =
              this.parent.initialRect.height / this.parent.scale.y -
              this.width / 2;
          }
          this.position.x = newX;
          this.position.y = newY;
        } else {
          newX =
            newPosition.x + this.dragOffsetX - this.width / 2 + this.parent.x;
          newY =
            newPosition.y + this.dragOffsetY - this.height / 2 + this.parent.y;
          if (newX > 0) {
            newX = 0;
          }
          if (
            newX + this.width <
            this.parent.initialRect.width / this.parent.scale.x
          ) {
            newX =
              this.parent.initialRect.width / this.parent.scale.x - this.width;
          }
          if (newY > 0) {
            newY = 0;
          }
          if (
            newY + this.height <
            this.parent.initialRect.height / this.parent.scale.y
          ) {
            newY =
              this.parent.initialRect.height / this.parent.scale.y -
              this.height;
          }
          this.position.x = newX + this.width / 2;
          this.position.y = newY + this.height / 2;
        }
      } else {
        this.position.x = newPosition.x + this.dragOffsetX;
        this.position.y = newPosition.y + this.dragOffsetY;
        if (
          Math.abs(
            renderer.view.width / startingDevicePixelRatio / 2 - this.position.x
          ) <= 5
        ) {
          this.position.x = renderer.view.width / startingDevicePixelRatio / 2;
          if (!horizontalAlign) {
            horizontalAlign = new PIXI.Graphics();
            stage.addChild(horizontalAlign);
            horizontalAlign.lineStyle(1, 0x00aced, 1);
            horizontalAlign.moveTo(
              renderer.view.width / startingDevicePixelRatio / 2,
              0
            );
            horizontalAlign.lineTo(
              renderer.view.width / startingDevicePixelRatio / 2,
              renderer.view.height / startingDevicePixelRatio
            );
          }
        } else {
          if (horizontalAlign) {
            stage.removeChild(horizontalAlign);
            horizontalAlign = null;
          }
        }
        if (gridLines) {
          gridLines.clear();

          var selItem = this;
          var child = null;
          if (selItem.childId) {
            try {
              child = window.editor.getStickerById(selItem.childId);
            } catch (e) {}
          }
          var selItemX =
            selItem.position.x -
            selItem.anchor.x * selItem.width -
            selItem.pivot.x;
          var selItemY =
            selItem.position.y -
            selItem.anchor.y * selItem.height -
            selItem.pivot.y;
          var sensitivity = 3 * devicePixelRatio;
          if (selItem.rotation === 0) {
            selItem.hideSelector = true;
            var newPos = null;
            stickerContainer.children.forEach(function (item) {
              if (selItem !== item && item.rotation === 0 && item !== child) {
                try {
                  var itemX =
                    item.position.x - item.anchor.x * item.width - item.pivot.x;
                  var itemY =
                    item.position.y -
                    item.anchor.y * item.height -
                    item.pivot.y;

                  if (Math.abs(selItemX - itemX) < sensitivity) {
                    //left left
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x,
                      selItem.position.y
                    );
                  }
                  if (Math.abs(selItemX - (itemX + item.width)) < sensitivity) {
                    //left right
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX + item.width,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x +
                        item.width,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(selItemX - (itemX + item.width / 2)) < sensitivity
                  ) {
                    //left center
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX + item.width / 2,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x +
                        item.width / 2,
                      selItem.position.y
                    );
                  }
                  if (Math.abs(selItemY - itemY) < sensitivity) {
                    //top top
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y
                    );
                  }
                  if (
                    Math.abs(selItemY - (itemY + item.height / 2)) < sensitivity
                  ) {
                    //top ycenter
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY + item.height / 2
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y +
                        item.height / 2
                    );
                  }
                  if (
                    Math.abs(selItemY - (itemY + item.height)) < sensitivity
                  ) {
                    //top bottom
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y +
                        item.height
                    );
                  }
                  if (
                    Math.abs(selItemX + selItem.width - (itemX + item.width)) <
                    sensitivity
                  ) {
                    //right right
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX + item.width,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x +
                        item.width -
                        selItem.width,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(
                      selItemX + selItem.width - (itemX + item.width / 2)
                    ) < sensitivity
                  ) {
                    //right xcenter
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX + item.width / 2,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x +
                        item.width / 2 -
                        selItem.width,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(selItemX + selItem.width - itemX) < sensitivity
                  ) {
                    //right left
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x -
                        selItem.width,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(
                      selItemY + selItem.height - (itemY + item.height)
                    ) < sensitivity
                  ) {
                    //bottom bottom
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y +
                        item.height -
                        selItem.height
                    );
                  }
                  if (
                    Math.abs(
                      selItemY + selItem.height - (itemY + item.height / 2)
                    ) < sensitivity
                  ) {
                    //bottom center
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY + item.height / 2
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y +
                        item.height / 2 -
                        selItem.height
                    );
                  }
                  if (
                    Math.abs(selItemY + selItem.height - itemY) < sensitivity
                  ) {
                    //bottom top
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y -
                        selItem.height
                    );
                  }
                  if (
                    Math.abs(
                      selItemX + selItem.width / 2 - (itemX + item.width / 2)
                    ) < sensitivity
                  ) {
                    //xcenter xcenter
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX + item.width / 2,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x +
                        item.width / 2 -
                        selItem.width / 2,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(selItemX + selItem.width / 2 - itemX) < sensitivity
                  ) {
                    //xcenter left
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x -
                        selItem.width / 2,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(
                      selItemX + selItem.width / 2 - (itemX + item.width)
                    ) < sensitivity
                  ) {
                    //xcenter right
                    window.editor.verticalDottedLine(
                      gridLines,
                      itemX + item.width,
                      itemY,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      itemX +
                        selItem.anchor.x * selItem.width +
                        selItem.pivot.x +
                        item.width -
                        selItem.width / 2,
                      selItem.position.y
                    );
                  }
                  if (
                    Math.abs(
                      selItemY + selItem.height / 2 - (itemY + item.height / 2)
                    ) < sensitivity
                  ) {
                    //ycenter ycenter
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY + item.height / 2
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y +
                        item.height / 2 -
                        selItem.height / 2
                    );
                  }
                  if (
                    Math.abs(selItemY + selItem.height / 2 - itemY) <
                    sensitivity
                  ) {
                    //ycenter top
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y -
                        selItem.height / 2
                    );
                  }
                  if (
                    Math.abs(
                      selItemY + selItem.height / 2 - (itemY + item.height)
                    ) < sensitivity
                  ) {
                    //ycenter bottom
                    window.editor.horizontalDottedLine(
                      gridLines,
                      itemX,
                      itemX + item.width,
                      itemY + item.height
                    );
                    newPos = new PIXI.Point(
                      selItem.position.x,
                      itemY +
                        selItem.anchor.y * selItem.height +
                        selItem.pivot.y +
                        item.height -
                        selItem.height / 2
                    );
                  }
                } catch (err) {}
              }
            });
            if (newPos) {
              selItem.position = newPos;
              selItemX =
                selItem.position.x -
                selItem.anchor.x * selItem.width -
                selItem.pivot.x;
              selItemY =
                selItem.position.y -
                selItem.anchor.y * selItem.height -
                selItem.pivot.y;
              sensitivity = 1;
              stickerContainer.children.forEach(function (item) {
                if (selItem !== item && item.rotation === 0 && item !== child) {
                  try {
                    var itemX =
                      item.position.x -
                      item.anchor.x * item.width -
                      item.pivot.x;
                    var itemY =
                      item.position.y -
                      item.anchor.y * item.height -
                      item.pivot.y;

                    if (Math.abs(selItemX - itemX) < sensitivity) {
                      //left left
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(selItemX - (itemX + item.width)) < sensitivity
                    ) {
                      //left right
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(selItemX - (itemX + item.width / 2)) <
                      sensitivity
                    ) {
                      //left center
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (Math.abs(selItemY - itemY) < sensitivity) {
                      //top top
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY
                      );
                    }
                    if (
                      Math.abs(selItemY - (itemY + item.height / 2)) <
                      sensitivity
                    ) {
                      //top ycenter
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY
                      );
                    }
                    if (
                      Math.abs(selItemY - (itemY + item.height)) < sensitivity
                    ) {
                      //top bottom
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY
                      );
                    }
                    if (
                      Math.abs(
                        selItemX + selItem.width - (itemX + item.width)
                      ) < sensitivity
                    ) {
                      //right right
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX + selItem.width,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(
                        selItemX + selItem.width - (itemX + item.width / 2)
                      ) < sensitivity
                    ) {
                      //right xcenter
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX + selItem.width,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(selItemX + selItem.width - itemX) < sensitivity
                    ) {
                      //right left
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX + selItem.width,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(
                        selItemY + selItem.height - (itemY + item.height)
                      ) < sensitivity
                    ) {
                      //bottom bottom
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(
                        selItemY + selItem.height - (itemY + item.height / 2)
                      ) < sensitivity
                    ) {
                      //bottom center
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(selItemY + selItem.height - itemY) < sensitivity
                    ) {
                      //bottom top
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(
                        selItemX + selItem.width / 2 - (itemX + item.width / 2)
                      ) < sensitivity
                    ) {
                      //xcenter xcenter
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX + selItem.width / 2,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(selItemX + selItem.width / 2 - itemX) <
                      sensitivity
                    ) {
                      //xcenter left
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX + selItem.width / 2,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(
                        selItemX + selItem.width / 2 - (itemX + item.width)
                      ) < sensitivity
                    ) {
                      //xcenter right
                      window.editor.verticalDottedLine(
                        gridLines,
                        selItemX + selItem.width / 2,
                        selItemY,
                        selItemY + selItem.height
                      );
                    }
                    if (
                      Math.abs(
                        selItemY +
                          selItem.height / 2 -
                          (itemY + item.height / 2)
                      ) < sensitivity
                    ) {
                      //ycenter ycenter
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY + selItem.height / 2
                      );
                    }
                    if (
                      Math.abs(selItemY + selItem.height / 2 - itemY) <
                      sensitivity
                    ) {
                      //ycenter top
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY + selItem.height / 2
                      );
                    }
                    if (
                      Math.abs(
                        selItemY + selItem.height / 2 - (itemY + item.height)
                      ) < sensitivity
                    ) {
                      //ycenter bottom
                      window.editor.horizontalDottedLine(
                        gridLines,
                        selItemX,
                        selItemX + selItem.width,
                        selItemY + selItem.height / 2
                      );
                    }
                  } catch (err) {}
                }
              });
            }
          }
        }
        if (
          Math.abs(
            renderer.view.height / startingDevicePixelRatio / 2 -
              this.position.y
          ) <= 5
        ) {
          this.position.y = renderer.view.height / startingDevicePixelRatio / 2;
          if (!vertAlign) {
            vertAlign = new PIXI.Graphics();
            stage.addChild(vertAlign);
            vertAlign.lineStyle(1, 0x00aced, 1);
            vertAlign.moveTo(
              0,
              renderer.view.height / startingDevicePixelRatio / 2
            );
            vertAlign.lineTo(
              renderer.view.width / startingDevicePixelRatio,
              renderer.view.height / startingDevicePixelRatio / 2
            );
          }
        } else {
          if (vertAlign) {
            stage.removeChild(vertAlign);
            vertAlign = null;
          }
        }

        if (Math.abs(0 - (this.position.x - this.width / 2)) <= 5) {
          this.position.x = 0 + this.width / 2;
        }
        if (
          Math.abs(
            renderer.view.width / startingDevicePixelRatio -
              (this.position.x + this.width / 2)
          ) <= 5
        ) {
          this.position.x =
            renderer.view.width / startingDevicePixelRatio - this.width / 2;
        }
        if (Math.abs(0 - (this.position.y - this.height / 2)) <= 5) {
          this.position.y = 0 + this.height / 2;
        }
        if (
          Math.abs(
            renderer.view.height / startingDevicePixelRatio -
              (this.position.y + this.height / 2)
          ) <= 5
        ) {
          this.position.y =
            renderer.view.height / startingDevicePixelRatio - this.height / 2;
        }
      }
      if (this.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          this.x - this.width / 2,
          this.y - this.height / 2,
          this.width,
          this.height
        );
        mask.endFill();
        baseObject.mask = mask;
      }

      if (this.childId) {
        var sticker = window.editor.getStickerById(this.childId);
        if (sticker.textBackground) {
          sticker.clear();

          sticker.position.x = this.position.x;
          sticker.position.y = this.position.y;
          sticker.beginFill(this.backgroundColor, 1);
          sticker.drawRect(
            0 - this.style.wordWrapWidth / 2,
            0 - this.height / 2,
            this.style.wordWrapWidth,
            this.height
          );
          sticker.endFill();
        } else {
          sticker.position.x = newPosition.x + this.dragOffsetX;
          sticker.position.y = newPosition.y + this.dragOffsetY;
          if (sticker.anchorY) {
            if (sticker.anchorY !== 0.5) {
              sticker.position.y =
                newPosition.y +
                this.dragOffsetY -
                this.height * sticker.anchorY;
            }
          }
        }
      }
      window.editor.stickerSelector.updateSelector();
      window.editor.autoSave();
    }
  },
  verticalDottedLine: function (g, x, y1, y2) {
    g.lineStyle(2, 0xff1493, 1);
    for (var spacer = y1; spacer < y2; spacer += 10) {
      g.moveTo(x, spacer);
      g.lineTo(x, spacer + 7);
    }
    //        g.lineStyle(1, 0xFF1493, 1);
    //        for(var spacer = y1;spacer < y2;spacer += 10){
    //            g.moveTo(x + 1, spacer);
    //            g.lineTo(x + 1, spacer + 5);
    //        }
  },
  horizontalDottedLine: function (g, x1, x2, y) {
    g.lineStyle(2, 0xff1493, 1);
    for (var spacer = x1; spacer < x2; spacer += 10) {
      g.moveTo(spacer, y);
      g.lineTo(spacer + 7, y);
    }
    //        g.lineStyle(1, 0xFF1493, 1);
    //        for(var spacer = x1;spacer < x2;spacer += 10){
    //            g.moveTo(spacer, y + 1);
    //            g.lineTo(spacer + 5, y + 1);
    //        }
  },
  onCutoutDragMove: function () {
    if (
      this.data &&
      !this.skewWidth &&
      !this.skewHeight &&
      !this.scaling &&
      this.dragging &&
      this === window.editor.getSelectedObject()
    ) {
      var newPosition = this.data.getLocalPosition(this.parent);
      this.position.x = newPosition.x + this.dragOffsetX;
      this.position.y = newPosition.y + this.dragOffsetY;
      if (this.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          this.x - this.width / 2,
          this.y - this.height / 2,
          this.width,
          this.height
        );
        mask.endFill();
        baseObject.mask = mask;
      }

      if (this.childId) {
        var sticker = window.editor.getCutoutStickerById(this.childId);
        sticker.position.x = newPosition.x + this.dragOffsetX;
        sticker.position.y = newPosition.y + this.dragOffsetY;
        if (sticker.anchorY) {
          if (sticker.anchorY !== 0.5) {
            sticker.position.y =
              newPosition.y + this.dragOffsetY - this.height * sticker.anchorY;
          }
        }
      }
      StickerCutoutSelector.updateSelector();
    }
  },
  onCropStart: function (event) {
    this.data = event.data;
    this.dragging = true;
    this.dragOffsetX = this.x - this.data.global.x / stage.scale.x;
    this.dragOffsetY = this.y - this.data.global.y / stage.scale.y;
    this.dragStartX = this.x;
    this.dragStartY = this.y;
    CropSelector.setCropObject(this);
    document.addEventListener("mouseup", this.onCropEnd);
  },
  onCropEnd: function () {
    if (!this.data) return;
    this.dragging = false;
    this.data = null;
    document.removeEventListener("mouseup", this.onCropEnd);
  },
  onCropMove: function () {
    if (
      this.data &&
      !this.skewWidth &&
      !this.skewHeight &&
      !this.scaling &&
      this.dragging &&
      this === CropSelector.getCropObject()
    ) {
      var newPosition = this.data.getLocalPosition(this.parent);
      var newPositionX = newPosition.x + this.dragOffsetX;
      var newPositionY = newPosition.y + this.dragOffsetY;
      if (newPositionX < this.width / 2) newPositionX = this.width / 2;
      if (newPositionY < this.height / 2) newPositionY = this.height / 2;
      if (
        baseObject.rotation === 1.5708 ||
        baseObject.rotation === 1.5708 * 3
      ) {
        if (newPositionX > baseObject.height - this.width / 2)
          newPositionX = baseObject.height - this.width / 2;
        if (newPositionY > baseObject.width - this.height / 2)
          newPositionY = baseObject.width - this.height / 2;
      } else {
        if (newPositionX > baseObject.width - this.width / 2)
          newPositionX = baseObject.width - this.width / 2;
        if (newPositionY > baseObject.height - this.height / 2)
          newPositionY = baseObject.height - this.height / 2;
      }
      this.position.x = newPositionX;
      this.position.y = newPositionY;
      if (this.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          this.x - this.width / 2,
          this.y - this.height / 2,
          this.width,
          this.height
        );
        mask.endFill();
        baseObject.mask = mask;
      }

      if (this.childId) {
        var sticker = window.editor.getStickerById(this.childId);
        sticker.position.x = newPosition.x + this.dragOffsetX;
        sticker.position.y = newPosition.y + this.dragOffsetY;
        if (sticker.anchorY) {
          if (sticker.anchorY !== 0.5) {
            sticker.position.y =
              newPosition.y + this.dragOffsetY - this.height * sticker.anchorY;
          }
        }
      }
      CropSelector.updateSelector();
    }
  },
  onCutoutCropStart: function (event) {
    this.data = event.data;
    this.dragging = true;
    this.dragOffsetX = this.x - this.data.global.x / cutoutStage.scale.x;
    this.dragOffsetY = this.y - this.data.global.y / cutoutStage.scale.y;
    this.dragStartX = this.x;
    this.dragStartY = this.y;
    window.editor.setCutoutCropObject(this);
    document.addEventListener("mouseup", this.onCutoutCropEnd);
  },
  onCutoutCropEnd: function () {
    if (!this.data) return;
    this.dragging = false;
    this.data = null;
    document.removeEventListener("mouseup", this.onCutoutCropEnd);
  },
  onCutoutCropMove: function () {
    if (
      this.data &&
      !this.skewWidth &&
      !this.skewHeight &&
      !this.scaling &&
      this.dragging &&
      this === window.editor.getCropCutoutObject()
    ) {
      var newPositionX =
        this.data.global.x / cutoutStage.scale.x + this.dragOffsetX;
      var newPositionY =
        this.data.global.y / cutoutStage.scale.y + this.dragOffsetY;
      if (newPositionX < 0) newPositionX = 0;
      if (newPositionY < 0) newPositionY = 0;
      if (imgObject.rotation === 1.5708 || imgObject.rotation === 1.5708 * 3) {
        if (newPositionX > imgObject.height) newPositionX = imgObject.height;
        if (newPositionY > imgObject.width) newPositionY = imgObject.width;
      } else {
        if (newPositionX > imgObject.width - this.width)
          newPositionX = imgObject.width - this.width;
        if (newPositionY > imgObject.height - this.height)
          newPositionY = imgObject.height - this.height;
      }
      this.position.x = newPositionX;
      this.position.y = newPositionY;
      if (this.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          this.x * imgObject.parent.scale.x,
          this.y * imgObject.parent.scale.y,
          this.width * imgObject.parent.scale.x,
          this.height * imgObject.parent.scale.y
        );
        mask.endFill();
        imgObject.mask = mask;
      }

      CropCutoutSelector.updateSelector();
    }
  },
  drawPoints: [],
  onDragOver: function (event) {
    event.preventDefault();
  },
  onDrop: function (event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("text");
    if (data) {
      switch (data) {
        case "text":
          LeftMenuActionCreators.MenuSelected("text_props");
          break;
        case "thought":
          LeftMenuActionCreators.MenuSelected("text_props");
          window.editor.addText(
            "",
            "https://static.pizap.com/html5/resources/editor/thought_bubble.svg",
            173,
            112,
            0.5,
            0.5
          );
          break;
        case "bubble":
          LeftMenuActionCreators.MenuSelected("text_props");
          window.editor.addText(
            "",
            "https://static.pizap.com/html5/resources/editor/comic_bubble.svg",
            193,
            130,
            0.5,
            0.5
          );
          break;
        case "talk":
          LeftMenuActionCreators.MenuSelected("text_props");
          window.editor.addText(
            "",
            "https://static.pizap.com/html5/resources/editor/round_bubble.svg",
            162,
            102,
            0.5,
            0.5
          );
          break;
        case "square":
          LeftMenuActionCreators.MenuSelected("text_props");
          window.editor.addText(
            "",
            "https://static.pizap.com/html5/resources/editor/square_bubble.svg",
            153,
            114,
            0.1,
            0.9
          );
          break;
        default:
          var mainCanvas = renderer.view;
          var dropOffsetX = mainCanvas.offsetLeft;
          var dropOffsetY = mainCanvas.offsetTop;
          var dropX = (event.clientX - dropOffsetX) / stage.scale.x;
          var dropY = (event.clientY - dropOffsetY) / stage.scale.y;
          window.editor.addSticker(data, dropX, dropY, function (sticker) {
            EditorStore.setShowSpinner(false);
          });
          break;
      }
    }
  },
  onPaintStart: function (event) {
    if (LeftMenuStore.get() === "paint" && !EditorStore.getShowColorPicker()) {
      this.painting = true;
      var mainCanvas = renderer.view;
      this.dragOffsetX = mainCanvas.offsetLeft;
      this.dragOffsetY = mainCanvas.offsetTop;
      window.editor.drawPoints = [];
      this.dragging = false;
      var colorCode = window.parseInt(EditorStore.getPaintColor().slice(1), 16);
      var brushSize = window.parseInt(EditorStore.getBrushSize());
      paintContainer.BLEND = PIXI.BLEND_MODES.SCREEN;
      paintContainer.lineStyle(brushSize / stage.scale.x, colorCode, 1);
      paintContainer.moveTo(
        event.clientX - this.dragOffsetX,
        event.clientY - this.dragOffsetY
      );
      this.lastDragX =
        (event.clientX - this.dragOffsetX + brushSize / 2) / stage.scale.x;
      this.lastDragY =
        (event.clientY - this.dragOffsetY + brushSize / 2) / stage.scale.y;
      window.editor.drawPoints.push(
        new PIXI.Point(this.lastDragX, this.lastDragY)
      );
      paintContainer.beginFill(colorCode);
      paintContainer.interactive = true;
      paintContainer.drawCircle(
        this.lastDragX,
        this.lastDragY,
        brushSize / stage.scale.x / 36
      );
      paintContainer.endFill();
    }
    if (EditorStore.getEditorMode() === "pan") {
      this.dragOffsetX = event.layerX;
      this.dragOffsetY = event.layerY;
      this.panning = true;
    }
  },
  onPaintEnd: function () {
    var i;
    if (LeftMenuStore.get() === "paint") {
      var id = JSONUtils.guid();

      this.painting = false;
      var colorCode = window.parseInt(EditorStore.getPaintColor().slice(1), 16);
      var brushSize = window.parseInt(EditorStore.getBrushSize());
      var points = window.editor.drawPoints;
      window.editor.CommandManager.execute({
        execute: function () {
          paintContainer.clear();
          var g = new PIXI.Graphics();
          g.id = id;
          if (EditorStore.getPaintMode() === "paint") {
            g.lineStyle(brushSize / stage.scale.x, colorCode, 1);
            if (EditorStore.getPaintBlur() > 0) {
              g.filters = [
                new PIXI.filters.BlurFilter(
                  EditorStore.getPaintBlur(),
                  1,
                  startingDevicePixelRatio,
                  5
                ),
              ];
            } else {
              g.filters = null;
            }
            g.alpha = parseFloat(EditorStore.getPaintAlpha());

            g.beginFill(colorCode);
            for (i = 0; i < points.length; i++) {
              g.drawCircle(
                points[i].x,
                points[i].y,
                brushSize / stage.scale.x / 36
              );
            }
            g.points = points;
            g.lineBlur = EditorStore.getPaintBlur();
            g.endFill();
          } else {
            var gl = window.editor.getRenderer().gl;
            g.blendMode = PIXI.BLEND_MODES.HARD_LIGHT;
            gl.disable(gl.BLEND);
            colorCode = 0x000000;
            g.alpha = 0;
            g.lineStyle(brushSize, colorCode, 1);
            for (i = 0; i < points.length; i++) {
              g.drawCircle(
                points[i].x,
                points[i].y,
                brushSize / stage.scale.x / 36
              );
            }
            g.points = points;
            g.lineBlur = EditorStore.getPaintBlur();
            gl.enable(gl.BLEND);
          }
          stickerContainer.addChild(g);
        },
        unexecute: function () {
          for (var x = 0; x < stickerContainer.children.length; x++) {
            if (
              stickerContainer.children[x].id &&
              stickerContainer.children[x].id === id
            ) {
              stickerContainer.removeChildAt(x);
            }
          }
        },
      });
    }
    if (EditorStore.getEditorMode() === "pan") {
      this.panning = false;
    }
  },
  onPaintMove: function (event) {
    var dist = function (a, b) {
      var x = a.x - b.x;
      var y = a.y - b.y;
      return x * x + y * y;
    };
    var x, y;
    if (LeftMenuStore.get() === "paint" && this.painting) {
      var brushSize =
        window.parseInt(EditorStore.getBrushSize()) / stage.scale.x;
      var currentX =
        (event.clientX - this.dragOffsetX + brushSize / 2) / stage.scale.x;
      var currentY =
        (event.clientY - this.dragOffsetY + brushSize / 2) / stage.scale.y;
      var p = new PIXI.Point(currentX, currentY);
      var last = window.editor.drawPoints[window.editor.drawPoints.length - 1];
      var colorCode = window.parseInt(EditorStore.getPaintColor().slice(1), 16);
      paintContainer.lineStyle(brushSize, colorCode, 1);
      if (EditorStore.getPaintMode() === "paint") {
        if (EditorStore.getPaintBlur() > 0) {
          paintContainer.filters = [
            new PIXI.filters.BlurFilter(
              EditorStore.getPaintBlur(),
              1,
              startingDevicePixelRatio,
              5
            ),
          ];
        } else {
          paintContainer.filters = null;
        }
        paintContainer.alpha = EditorStore.getPaintAlpha();
        paintContainer.beginFill(colorCode);
        if (dist(p, last) > 2) {
          var m = (last.y - p.y) / (last.x - p.x);

          if (last.x <= p.x) {
            for (x = last.x + 1; x < p.x; x++) {
              y = m * (x - last.x) + last.y;
              window.editor.drawPoints.push(new PIXI.Point(x, y));
              paintContainer.drawCircle(x, y, brushSize / 36);
            }
          } else {
            for (x = last.x - 1; x > p.x; x--) {
              y = m * (x - last.x) + last.y;
              window.editor.drawPoints.push(new PIXI.Point(x, y));
              paintContainer.drawCircle(x, y, brushSize / 36);
            }
          }
          if (last.y <= p.y) {
            if (m === -Infinity) {
              for (y = last.y + 1; y < p.y; y++) {
                window.editor.drawPoints.push(new PIXI.Point(p.x, y));
                paintContainer.drawCircle(p.x, y, brushSize / 36);
              }
            } else {
              for (y = last.y + 1; y < p.y; y++) {
                x = (y - last.y + last.x * m) / m;
                window.editor.drawPoints.push(new PIXI.Point(x, y));
                paintContainer.drawCircle(x, y, brushSize / 36);
              }
            }
          } else {
            if (m === Infinity) {
              for (y = last.y - 1; y > p.y; y--) {
                window.editor.drawPoints.push(new PIXI.Point(p.x, y));
                paintContainer.drawCircle(p.x, y, brushSize / 36);
              }
            } else {
              for (y = last.y - 1; y > p.y; y--) {
                x = (y - last.y + last.x * m) / m;
                window.editor.drawPoints.push(new PIXI.Point(x, y));
                paintContainer.drawCircle(x, y, brushSize / 36);
              }
            }
          }
        }

        window.editor.drawPoints.push(new PIXI.Point(currentX, currentY));
        paintContainer.drawCircle(currentX, currentY, brushSize / 36);
        paintContainer.endFill();
      } else {
        var gl = window.editor.getRenderer().gl;
        gl.disable(gl.BLEND);
        paintContainer.blendMode = PIXI.BLEND_MODES.HARD_LIGHT;
        colorCode = 0x000000;
        paintContainer.alpha = 0;
        paintContainer.lineStyle(brushSize, colorCode, 1);
        paintContainer.drawCircle(currentX, currentY, brushSize / 36);
        gl.enable(gl.BLEND);
      }

      this.lastDragX = currentX;
      this.lastDragY = currentY;
    }
    if (this.panning) {
      var dx = event.layerX - this.dragOffsetX;
      var dy = event.layerY - this.dragOffsetY;

      stage.position.x += dx;
      stage.position.y += dy;
      this.dragOffsetX = event.layerX;
      this.dragOffsetY = event.layerY;
    }
  },
  smoothPoints: function (ps) {
    if (ps.length > 4) {
      for (var i = 0; i < 4; ++i) {
        var j = ps.length - i - 2;
        var p0 = ps[j];
        var p1 = ps[j + 1];
        var a = 0.2;
        ps[j] = new PIXI.Point(
          p0.x * (1 - a) + p1.x * a,
          p0.y * (1 - a) + p1.y * a
        );
      }
    }
  },
  swapHotspots: function (spot1, spot2) {
    var texture1 = spot1.texture;
    var texture2 = spot2.texture;
    var id1 = spot1.id;
    var id2 = spot2.id;
    var hotspot1 = spot1.parent;
    var hotspot2 = spot2.parent;

    hotspot1.removeChild(spot1);
    hotspot2.removeChild(spot2);

    window.editor.addCollageBackground(texture1, id2, hotspot2);
    window.editor.addCollageBackground(texture2, id1, hotspot1);
  },
  cutoutPreview: function () {
    cutoutMask.clear();
    for (var x = 0; x < cutoutStage.children.length; x++) {
      if (cutoutStage.children[x].id) {
        var points = cutoutStage.children[x].graphicsData[0].shape.points;

        cutoutMask.lineStyle(cutoutStage.children[x].lineWidth, 0xffffff, 1);
        if (cutoutStage.children[x].closed) cutoutMask.beginFill();
        var maskshape = new PIXI.Polygon(points);
        maskshape.closed = cutoutStage.children[x].closed;

        cutoutMask.drawShape(maskshape);
        if (cutoutStage.children[x].closed) cutoutMask.endFill();

        cutoutStage.children[x].renderable = false;
      }
    }
    imgObject.mask = cutoutMask;
  },
  positionLocked: function (sticker) {
    try {
      let lockedButton = document.getElementById("lock-button");
      if (lockedButton && sticker) {
        if (sticker && sticker.locked && !sticker.permLocked) {
          lockedButton.style.display = "block";
        } else {
          lockedButton.style.display = "none";
        }
        lockedButton.style.top =
          window.editor.getRenderer().view.offsetTop +
          sticker.position.y -
          lockedButton.clientHeight / 2 +
          "px";
        lockedButton.style.left =
          window.editor.getRenderer().view.offsetLeft +
          sticker.position.x -
          lockedButton.clientWidth / 2 +
          "px";
      } else {
        lockedButton.style.display = "none";
      }
    } catch (error) {
      console.log("Something went wrong.");
    }
  },
  positionEdp: function (sticker) {
    const edp = document.getElementById("editor-properties");
    if (edp && sticker) {
      var width = sticker.width;
      if (sticker instanceof PIXI.Text) {
        width = sticker.style.wordWrapWidth;
      }

      edp.style.display = "block";
      if (
        sticker.DesignBackground ||
        sticker.locked ||
        sticker.CollageBackground
      ) {
        edp.style.display = "none";
      }
      if (!EditorStore.getPropManualPosition()) {
        if (sticker.CollageBackground) {
          if (sticker.parent && sticker.parent.mask) {
            var maskRect = sticker.parent.mask.getBounds();
            edp.style.top =
              window.editor.getRenderer().view.offsetTop +
              maskRect.y +
              30 +
              "px";
            if (
              window.editor.getRenderer().view.offsetTop + maskRect.y >
              window.innerHeight -
                window.editor.getRenderer().view.offsetTop -
                edp.children[0].clientHeight
            ) {
              edp.style.top =
                window.innerHeight -
                window.editor.getRenderer().view.offsetTop -
                edp.children[0].clientHeight +
                "px";
            }

            if (
              window.editor.getRenderer().view.offsetLeft + maskRect.x + 250 >
              window.editor.getRenderer().view.offsetLeft +
                window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                maskRect.x -
                100 +
                "px";
            } else {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                maskRect.x +
                150 +
                "px";
            }
          }
        } else {
          if (
            window.editor.getRenderer().view.offsetTop +
              sticker.position.y -
              sticker.height / 2 >
            60
          ) {
            edp.style.top =
              window.editor.getRenderer().view.offsetTop +
              sticker.position.y -
              sticker.height / 2 +
              "px";
          } else {
            edp.style.top = window.editor.getRenderer().view.offsetTop + "px";
          }
          if (
            window.editor.getRenderer().view.offsetTop +
              sticker.position.y -
              sticker.height / 2 >
            window.innerHeight -
              window.editor.getRenderer().view.offsetTop -
              edp.children[0].clientHeight
          ) {
            edp.style.top =
              window.innerHeight -
              window.editor.getRenderer().view.offsetTop -
              edp.children[0].clientHeight +
              "px";
          }

          var l;
          if (sticker instanceof PIXI.Text && sticker.style.align === "right") {
            if (
              sticker.position.x - sticker.pivot.x + 150 >
              window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) -
                width -
                100 +
                "px";
            } else {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) +
                20 +
                "px";
            }
            l =
              parseInt(edp.style.left.replace("px", ""), 10) -
              window.editor.getRenderer().view.offsetLeft;
            if (
              l < 10 ||
              l + 150 > window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                sticker.position.x +
                75 +
                "px";
              edp.style.top =
                window.editor.getRenderer().view.offsetTop +
                sticker.position.y -
                200 +
                "px";
            }
          } else if (
            sticker instanceof PIXI.Text &&
            sticker.style.align === "left"
          ) {
            if (
              sticker.position.x - sticker.pivot.x + width + 150 >
              window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) -
                width -
                100 +
                "px";
            } else {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) +
                width +
                20 +
                "px";
            }
            l =
              parseInt(edp.style.left.replace("px", ""), 10) -
              window.editor.getRenderer().view.offsetLeft;
            if (
              l < 10 ||
              l + 150 > window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) +
                75 +
                "px";
              edp.style.top =
                window.editor.getRenderer().view.offsetTop +
                sticker.position.y -
                200 +
                "px";
            }
          } else {
            if (
              sticker.position.x - sticker.pivot.x + width / 2 + 150 >
              window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) -
                width / 2 -
                100 +
                "px";
            } else {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) +
                width / 2 +
                20 +
                "px";
            }
            l =
              parseInt(edp.style.left.replace("px", ""), 10) -
              window.editor.getRenderer().view.offsetLeft;
            if (
              l < 10 ||
              l + 150 > window.editor.getRenderer().view.clientWidth
            ) {
              edp.style.left =
                window.editor.getRenderer().view.offsetLeft +
                (sticker.position.x - sticker.pivot.x) +
                75 +
                "px";
              edp.style.top =
                window.editor.getRenderer().view.offsetTop +
                sticker.position.y -
                200 +
                "px";
            }
          }
        }
        if (edp.style.top.replace("px", "") < 50) {
          edp.style.top =
            window.editor.getRenderer().view.offsetTop +
            sticker.position.y +
            sticker.height +
            "px";
        }
      }
    }
  },
  cutoutShow: function () {
    imgObject.mask = null;
    for (var x = 0; x < cutoutStage.children.length; x++) {
      if (cutoutStage.children[x].id) {
        cutoutStage.children[x].renderable = true;
      }
    }
    cutoutMask.renderable = false;
  },
  onCutoutKeyDown: function (event) {
    if (
      window.editor.moving &&
      window.editor.currentPoint >= 0 &&
      event.keyCode === 68
    ) {
      for (var x = 0; x < cutoutStage.children.length; x++) {
        if (
          cutoutStage.children[x].id &&
          cutoutStage.children[x].id === window.editor.cutoutId
        ) {
          var points = cutoutStage.children[x].graphicsData[0].shape.points;
          points.splice(window.editor.currentPoint, 2);
          var g = new PIXI.Graphics();
          g.id = cutoutStage.children[x].id;
          g.lineStyle(1, 0x00ff00, 0.7);
          g.beginFill(0x00ff00, 0.2);
          var shape = new PIXI.Polygon(points);
          shape.closed = true;

          g.drawShape(shape);
          g.endFill();

          g.lineStyle(1, 0xff0000, 0.7);
          for (var y = 0; y < points.length; y += 2) {
            g.drawRect(points[y] - 1, points[y + 1] - 1, 2, 2);
          }

          cutoutStage.removeChildAt(x);
          cutoutStage.addChild(g);
          window.editor.moving = false;
        }
      }
    }
    if (
      window.editor.getSelectedObject() &&
      (event.keyCode === 46 || event.keyCode === 8) &&
      !(window.editor.getSelectedObject() instanceof PIXI.Text) &&
      !window.editor.getSelectedObject().childId
    ) {
      EditorActionCreators.RemoveSticker(window.editor.getSelectedObject());
    }
    if (window.editor.getSelectedObject() && event.keyCode === 37) {
      window.editor.getSelectedObject().position.x--;
      window.editor.stickerSelector.updateSelector();
      window.editor.positionEdp(window.editor.getSelectedObject());
    }
    if (window.editor.getSelectedObject() && event.keyCode === 38) {
      window.editor.getSelectedObject().position.y--;
      window.editor.stickerSelector.updateSelector();
      window.editor.positionEdp(window.editor.getSelectedObject());
    }
    if (window.editor.getSelectedObject() && event.keyCode === 39) {
      window.editor.getSelectedObject().position.x++;
      window.editor.stickerSelector.updateSelector();
      window.editor.positionEdp(window.editor.getSelectedObject());
    }
    if (window.editor.getSelectedObject() && event.keyCode === 40) {
      window.editor.getSelectedObject().position.y++;
      window.editor.stickerSelector.updateSelector();
      window.editor.positionEdp(window.editor.getSelectedObject());
    }
  },
  getTempSelectedObject() {
    return tempSelObject;
  },
  setTempSelectedObject(object) {
    tempSelObject = object;
  },
  cutoutSave: function () {
    var url, rect;
    EditorStore.setShowSpinner(true);
    if (EditorStore.getCutoutMode() === "shape-selected") {
      cutoutStage.removeChildAt(0);
      window.editor.setSelectedObject(null);
      StickerCutoutSelector.updateSelector();
      cutoutStage.scale.set(1);
      cutoutMask.renderable = true;
      rect = imgObject.children[1].getBounds();
      imgObject.x = rect.x * -1;
      imgObject.y = rect.y * -1;
      cutoutRenderer.autoresize = false;
      cutoutRenderer.resize(rect.width, rect.height);
      cutoutRenderer.render(cutoutStage);
      url = cutoutRenderer.view.toDataURL();
      window.editor.removeCutout();
      LeftMenuActionCreators.saveCutout(url);
    } else {
      var imgScale = 1 / imgObject.scale.x;
      cutoutMask.clear();
      for (var x = 0; x < cutoutStage.children.length; x++) {
        if (cutoutStage.children[x].id) {
          var points = cutoutStage.children[x].graphicsData[0].shape.points;
          for (var y = 0; y < points.length; y++) {
            points[y] = points[y] * imgScale;
          }
          cutoutMask.beginFill();
          var maskshape = new PIXI.Polygon(points);
          maskshape.closed = true;

          cutoutMask.drawShape(maskshape);
          cutoutMask.endFill();

          cutoutStage.children[x].renderable = false;
        }
      }
      cutoutStage.scale.set(1);
      imgObject.mask = cutoutMask;
      cutoutMask.renderable = false;
      rect = cutoutMask.getBounds();
      rect.x = rect.x * 1 * imgScale;
      rect.y = rect.y * 1 * imgScale;
      rect.width = rect.width * 1 * imgScale;
      rect.height = rect.height * 1 * imgScale;
      imgObject.x = rect.x * -1;
      imgObject.y = rect.y * -1;
      imgObject.scale.set(1);
      cutoutRenderer.autoresize = false;
      cutoutRenderer.resize(rect.width, rect.height);
      cutoutRenderer.render(cutoutStage);
      url = cutoutRenderer.view.toDataURL();
      LeftMenuActionCreators.saveCutout(url);
      EditorActionCreators.CutoutMode("crop");
      window.editor.removeCutout();
    }
    LeftMenuActionCreators.MenuSelected("main");
  },
  onCutoutStartPointing: function () {
    window.editor.drawPoints = [];
  },
  onCutoutStart: function (event) {
    var dist = function (a, b) {
      var x = Math.abs(a.x - b.x);
      var y = Math.abs(a.y - b.y);
      return x + y;
    };
    var newX, newY, p, z;
    if (EditorStore.getCutoutMode() === "line") {
      this.painting = true;
      this.dragOffsetX = cutoutRenderer.view.offsetLeft;
      this.dragOffsetY = cutoutRenderer.view.offsetTop;
      window.editor.drawPoints = [];
      this.dragging = false;
      cutoutContainer.lineStyle(1, 0x00ff00, 0.6);
      cutoutContainer.moveTo(
        event.clientX - window.editor.dragOffsetX,
        event.clientY - window.editor.dragOffsetY
      );
      this.lastDragX =
        event.clientX - window.editor.dragOffsetX - cutoutStage.position.x;
      this.lastDragY =
        event.clientY - window.editor.dragOffsetY - cutoutStage.position.y;

      newX = this.lastDragX / cutoutStage.scale.x;
      newY = this.lastDragY / cutoutStage.scale.y;
      p = new PIXI.Point(newX, newY);
      window.editor.drawPoints.push(p);
    }
    if (EditorStore.getCutoutMode() === "move") {
      this.dragOffsetX = cutoutRenderer.view.offsetLeft;
      this.dragOffsetY = cutoutRenderer.view.offsetTop;
      var currentX = event.clientX - this.dragOffsetX - cutoutStage.position.x;
      var currentY = event.clientY - this.dragOffsetY - cutoutStage.position.y;

      newX = currentX / cutoutStage.scale.x;
      newY = currentY / cutoutStage.scale.y;
      window.editor.currentPoint = -1;
      for (var x = 0; x < cutoutStage.children.length; x++) {
        if (cutoutStage.children[x].id) {
          var points = cutoutStage.children[x].points;
          for (z = 0; z < points.length; z++) {
            if (
              dist(
                new PIXI.Point(points[z].x, points[z].y),
                new PIXI.Point(newX, newY)
              ) <=
              8 / cutoutStage.scale.x
            ) {
              window.editor.currentPoint = z;
              window.editor.moving = true;
              window.editor.cutoutId = cutoutStage.children[x].id;
            }
          }
        }
      }
    }
    if (
      EditorStore.getCutoutMode() === "point" ||
      EditorStore.getCutoutMode() === "curve"
    ) {
      this.dragOffsetX = cutoutRenderer.view.offsetLeft;
      this.dragOffsetY = cutoutRenderer.view.offsetTop;
      cutoutContainer.lineStyle(1 / cutoutStage.scale.x, 0x00ff00, 1);
      cutoutContainer.moveTo(
        event.clientX - this.dragOffsetX,
        event.clientY - this.dragOffsetY
      );
      cutoutContainer.filters = [new Filters.GlowFilter(10, 2, 0, 0x0, 0.5)];

      this.lastDragX =
        event.clientX - this.dragOffsetX - cutoutStage.position.x;
      this.lastDragY =
        event.clientY - this.dragOffsetY - cutoutStage.position.y;

      newX = this.lastDragX / cutoutStage.scale.x;
      newY = this.lastDragY / cutoutStage.scale.y;
      var found = false;
      p = new PIXI.Point(newX, newY);
      for (z = 0; z < window.editor.drawPoints.length; z++) {
        if (dist(window.editor.drawPoints[z], p) <= 12 / cutoutStage.scale.x) {
          found = true;
          if (
            window.editor.drawPoints.length > 2 &&
            dist(window.editor.drawPoints[0], p) <= 12 / cutoutStage.scale.x
          ) {
            window.editor.drawPoints.push(window.editor.drawPoints[0]);
            window.editor.onCutoutDoubleClick(event);
          }
        }
      }
      if (!found) {
        var dps = window.editor.drawPoints.slice();
        window.editor.CommandManager.execute({
          execute: function () {
            window.editor.drawPoints.push(p);
            if (window.editor.drawPoints.length === 1) {
              var img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = function () {
                var s = PIXI.Sprite.fromImage(
                  "https://static.pizap.com/html5/resources/editor/target.svg"
                );
                s.targetSprite = true;
                s.height = 25 / cutoutStage.scale.x;
                s.width = 25 / cutoutStage.scale.x;
                s.anchor.set(0.5);
                s.position.x = p.x;
                s.position.y = p.y;
                s.interactive = true;
                cutoutStage.addChild(s);

                s.mouseover = function () {
                  var img2 = new Image();
                  img2.setAttribute("crossOrigin", "anonymous");
                  img2.onload = function () {
                    s.texture = new PIXI.Texture(
                      new PIXI.BaseTexture(
                        img2,
                        PIXI.SCALE_MODES.NEAREST,
                        startingDevicePixelRatio
                      )
                    );
                  };
                  img2.src =
                    "https://static.pizap.com/html5/resources/editor/target-red.svg";
                };

                s.mouseout = function () {
                  var img3 = new Image();
                  img3.setAttribute("crossOrigin", "anonymous");
                  img3.onload = function () {
                    s.texture = new PIXI.Texture(
                      new PIXI.BaseTexture(
                        img3,
                        PIXI.SCALE_MODES.NEAREST,
                        startingDevicePixelRatio
                      )
                    );
                  };
                  img3.src =
                    "https://static.pizap.com/html5/resources/editor/target.svg";
                };
              };
              img.src =
                "https://static.pizap.com/html5/resources/editor/target.svg";
            }
            window.editor.onCutoutMove(null);
          },
          unexecute: function () {
            window.editor.drawPoints = dps;
            if (dps.length === 0) {
              for (var z = 0; z < cutoutStage.children.length; z++) {
                if (cutoutStage.children[z].targetSprite) {
                  cutoutStage.removeChildAt(z);
                }
              }
            }
            window.editor.onCutoutMove(null);
          },
        });
      }
    }

    if (EditorStore.getCutoutMode() === "pan") {
      this.dragOffsetX = event.layerX;
      this.dragOffsetY = event.layerY;
      this.panning = true;
    }
    if (EditorStore.getCutoutMode() === "paint") {
      this.painting = true;
      this.dragOffsetX = cutoutRenderer.view.offsetLeft;
      this.dragOffsetY = cutoutRenderer.view.offsetTop;
      window.editor.drawPoints = [];
      this.dragging = false;
      cutoutContainer.lineStyle(
        window.parseInt(EditorStore.getBrushSize()),
        0x00ff00,
        0.6
      );
      cutoutContainer.moveTo(
        event.clientX - this.dragOffsetX,
        event.clientY - this.dragOffsetY
      );
      this.lastDragX =
        event.clientX - this.dragOffsetX - cutoutStage.position.x;
      this.lastDragY =
        event.clientY - this.dragOffsetY - cutoutStage.position.y;

      newX = this.lastDragX / cutoutStage.scale.x;
      newY = this.lastDragY / cutoutStage.scale.y;
      p = new PIXI.Point(newX, newY);
      window.editor.drawPoints.push(p);
    }
  },
  onCutoutDoubleClick: function () {
    var id = JSONUtils.guid();
    if (
      EditorStore.getCutoutMode() === "point" ||
      EditorStore.getCutoutMode() === "curve"
    ) {
      var points = window.editor.drawPoints;
      window.editor.CommandManager.execute({
        execute: function () {
          cutoutContainer.clear();
          var g = new PIXI.Graphics();
          g.id = id;
          g.lineStyle(1 / cutoutStage.scale.x, 0xffffff, 1);

          var shape = new PIXI.Polygon(points);
          shape.closed = true;

          g.closed = true;
          g.lineWidth = 1 / cutoutStage.scale.x;
          g.points = points;
          g.filters = [new Filters.GlowFilter(10, 2, 0, 0x0, 0.5)];
          g.mode = EditorStore.getCutoutMode();
          g.drawShape(shape);
          cutoutStage.addChild(g);
          window.editor.drawPoints = [];
          for (var z = 0; z < cutoutStage.children.length; z++) {
            if (cutoutStage.children[z].targetSprite) {
              cutoutStage.removeChildAt(z);
            }
          }
          window.editor.currentPoint = -1;
          EditorActionCreators.CutoutMode("move");
        },
        unexecute: function () {
          for (var x = 0; x < cutoutStage.children.length; x++) {
            if (
              cutoutStage.children[x].id &&
              cutoutStage.children[x].id === id
            ) {
              cutoutStage.removeChildAt(x);
            }
          }
        },
      });
    }
  },
  onCutoutEnd: function () {
    var id = JSONUtils.guid();
    var points;

    if (EditorStore.getCutoutMode() === "line") {
      this.painting = false;
      points = window.editor.drawPoints;
      window.editor.CommandManager.execute({
        execute: function () {
          cutoutContainer.clear();
          var g = new PIXI.Graphics();
          g.id = id;
          g.lineStyle(1, 0x00ff00, 0.7);
          g.beginFill(0x00ff00, 0.2);
          var shape = new PIXI.Polygon(points);
          shape.closed = true;
          g.closed = true;
          g.lineWidth = 1;
          g.drawShape(shape);
          g.endFill();

          cutoutStage.addChild(g);
        },
        unexecute: function () {
          for (var x = 0; x < cutoutStage.children.length; x++) {
            if (
              cutoutStage.children[x].id &&
              cutoutStage.children[x].id === id
            ) {
              cutoutStage.removeChildAt(x);
            }
          }
        },
      });
    }
    if (EditorStore.getCutoutMode() === "move") {
      window.editor.moving = false;
    }
    if (EditorStore.getCutoutMode() === "pan") {
      this.panning = false;
    }
    if (EditorStore.getCutoutMode() === "paint") {
      this.painting = false;
      points = window.editor.drawPoints;
      window.editor.CommandManager.execute({
        execute: function () {
          cutoutContainer.clear();
          var g = new PIXI.Graphics();
          g.id = id;
          g.lineStyle(
            window.parseInt(EditorStore.getBrushSize()),
            0x00ff00,
            0.7
          );
          var shape = new PIXI.Polygon(points);
          shape.closed = false;
          g.drawShape(shape);
          g.closed = false;
          g.lineWidth = window.parseInt(EditorStore.getBrushSize());

          cutoutStage.addChild(g);
        },
        unexecute: function () {
          for (var x = 0; x < cutoutStage.children.length; x++) {
            if (
              cutoutStage.children[x].id &&
              cutoutStage.children[x].id === id
            ) {
              cutoutStage.removeChildAt(x);
            }
          }
        },
      });
    }
  },
  onCropComplete: function () {
    cutoutStage.removeChild(CropCutoutSelector.getCropCutoutContainer());
    var scale = 1 / cutoutStage.scale.x;

    var rect = new PIXI.Rectangle(
      cropCutoutObject.x,
      cropCutoutObject.y,
      cropCutoutObject.width,
      cropCutoutObject.height
    );
    if (rect.width / scale < 50 || rect.height / scale < 50) {
      cutoutStage.scale.set(1 / scale);
      return;
    }
    if (rect.x - 10 * scale > 0) {
      rect.x = rect.x - 10 * scale;
    }
    if (rect.y - 10 * scale > 0) {
      rect.y = rect.y - 10 * scale;
    }
    if (rect.height + rect.y + 20 * scale < imgObject.height) {
      rect.height = rect.height + 20 * scale;
    }
    if (rect.width + rect.x + 20 * scale < imgObject.width) {
      rect.width = rect.width + 20 * scale;
    }
    cutoutRenderer.autoresize = false;

    imgObject.mask = null;
    imgObject.texture = new PIXI.Texture(imgObject.texture, rect);
    var content = document.getElementById("container");
    var canvasWidth = content.clientWidth - 374;
    var canvasHeight = content.clientHeight - 46;
    var offsets = window.editor.scaleToFit(
      canvasWidth - 24,
      canvasHeight - canvasHeightOffset,
      rect.width,
      rect.height
    );
    imgObject.scale.set(offsets.width / imgObject.texture.width);
    imgObject.parent.scale.set(1);
    cutoutSize = {
      width: imgObject.texture.width,
      height: imgObject.texture.height,
    };

    cutoutRenderer.resize(offsets.width, offsets.height);
    cutoutRenderer.view.style.height = offsets.height + "px";
    cutoutRenderer.view.style.width = offsets.width + "px";
    cutoutRenderer.view.style.top =
      (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";

    window.editor.onCutoutStartPointing();
    EditorActionCreators.CutoutMode("point");
    document.removeEventListener("mouseup", window.editor.onCutoutEnd);
  },
  onCutoutCropReset: function () {
    imgObject.mask = null;
    imgObject.texture = new PIXI.Texture(imgObject.texture);
    imgObject.scale.set(origCutoutScale);
    cutoutStage.scale.set(origCutoutStageScale);
    var content = document.getElementById("container");

    var canvasWidth = content.clientWidth - 374;
    var canvasHeight = content.clientHeight - 46;
    var offsets = window.editor.scaleToFit(
      canvasWidth - 24,
      canvasHeight - canvasHeightOffset,
      imgObject.texture.width,
      imgObject.texture.height
    );
    cutoutSize = {
      width: imgObject.texture.width,
      height: imgObject.texture.height,
    };
    cutoutStage.width = offsets.width;
    cutoutStage.height = offsets.height;

    cutoutRenderer.resize(offsets.width, offsets.height);
    cutoutRenderer.view.style.height = offsets.height + "px";
    cutoutRenderer.view.style.width = offsets.width + "px";
    cutoutRenderer.view.style.top =
      (canvasHeight - -40 - offsets.height) / 2 + 28 + "px";
    EditorActionCreators.CutoutMode("crop");
  },
  onCutoutMove: function (event) {
    var dist = function (a, b) {
      var x = a.x - b.x;
      var y = a.y - b.y;
      return x * x + y * y;
    };
    var a,
      b,
      c,
      x,
      y,
      ps,
      newX,
      newY,
      currentX,
      currentY,
      shape,
      g,
      isDirty,
      points;

    if (this.painting) {
      currentX = event.clientX - this.dragOffsetX - cutoutStage.position.x;
      currentY = event.clientY - this.dragOffsetY - cutoutStage.position.y;

      newX = currentX / cutoutStage.scale.x;
      newY = currentY / cutoutStage.scale.y;

      var p = new PIXI.Point(newX, newY);
      var last = window.editor.drawPoints[window.editor.drawPoints.length - 1];

      if (dist(p, last) > 8) {
        cutoutContainer.clear();
        if (EditorStore.getCutoutMode() === "paint") {
          cutoutContainer.lineStyle(
            window.parseInt(EditorStore.getBrushSize()),
            0x00ff00,
            0.6
          );
        } else {
          cutoutContainer.lineStyle(1, 0x00ff00, 0.6);
        }

        window.editor.drawPoints.push(new PIXI.Point(newX, newY));
        window.editor.smoothPoints(window.editor.drawPoints);

        shape = new PIXI.Polygon(window.editor.drawPoints);
        shape.closed = false;
        cutoutContainer.drawShape(shape);
        this.lastDragX = newX;
        this.lastDragY = newY;
      }
    }
    if (window.editor.moving) {
      currentX = event.clientX - this.dragOffsetX - cutoutStage.position.x;
      currentY = event.clientY - this.dragOffsetY - cutoutStage.position.y;

      newX = currentX / cutoutStage.scale.x;
      newY = currentY / cutoutStage.scale.y;

      for (x = 0; x < cutoutStage.children.length; x++) {
        if (
          cutoutStage.children[x].id &&
          cutoutStage.children[x].id === window.editor.cutoutId &&
          window.editor.currentPoint >= 0
        ) {
          cutoutStage.cursor = "move";
          points = cutoutStage.children[x].points;
          isDirty = false;
          isDirty = true;
          points[window.editor.currentPoint].x = newX;
          points[window.editor.currentPoint].y = newY;
          if (isDirty) {
            g = new PIXI.Graphics();
            g.id = cutoutStage.children[x].id;
            g.lineStyle(cutoutStage.children[x].lineWidth, 0xffffff, 1);
            if (cutoutStage.children[x].mode === "curve") {
              g.moveTo(points[0].x, points[0].y);

              for (y = 1; y < points.length; y++) {
                a = points[y - 1].x - points[y].x;
                b = points[y - 1].y - points[y].y;

                c = Math.sqrt(a * a + b * b);

                g.arcTo(
                  points[y - 1].x,
                  points[y - 1].y,
                  points[y].x,
                  points[y].y,
                  Math.abs(c) / 2
                );
              }
              g.currentPath.shape.closed = true;
            } else {
              shape = new PIXI.Polygon(points);
              shape.closed = cutoutStage.children[x].closed;
              g.drawShape(shape);
            }
            g.lineStyle(1 / cutoutStage.scale.x, 0xffffff, 1);
            for (y = 0; y < points.length; y++) {
              g.drawRect(
                points[y].x - 3 / cutoutStage.scale.x,
                points[y].y - 3 / cutoutStage.scale.x,
                6 / cutoutStage.scale.x,
                6 / cutoutStage.scale.x
              );
            }
            g.lineWidth = cutoutStage.children[x].lineWidth;
            g.closed = cutoutStage.children[x].closed;
            g.points = cutoutStage.children[x].points;
            g.filters = cutoutStage.children[x].filters;
            g.mode = cutoutStage.children[x].mode;
            cutoutStage.removeChildAt(x);
            cutoutStage.addChild(g);
          }
        }
      }
      this.lastDragX = newX;
      this.lastDragY = newY;
    } else {
      if (EditorStore.getCutoutMode() === "move") {
        cutoutStage.cursor = "default";

        this.dragOffsetX = cutoutRenderer.view.offsetLeft;
        this.dragOffsetY = cutoutRenderer.view.offsetTop;
        currentX = event.clientX - this.dragOffsetX - cutoutStage.position.x;
        currentY = event.clientY - this.dragOffsetY - cutoutStage.position.y;

        newX = currentX / cutoutStage.scale.x;
        newY = currentY / cutoutStage.scale.y;

        for (x = 0; x < cutoutStage.children.length; x++) {
          if (cutoutStage.children[x].id) {
            this.currentPoint = -1;
            points = cutoutStage.children[x].points;
            isDirty = false;
            if (points && points.length > 0) {
              for (var z = 0; z < points.length; z++) {
                if (
                  dist(
                    new PIXI.Point(points[z].x, points[z].y),
                    new PIXI.Point(newX, newY)
                  ) <=
                  8 / cutoutStage.scale.x
                ) {
                  isDirty = true;
                  window.editor.currentPoint = z;
                  window.editor.cutoutId = cutoutStage.children[x].id;
                  cutoutStage.cursor = "move";
                }
              }
              g = new PIXI.Graphics();
              g.id = cutoutStage.children[x].id;
              g.lineStyle(cutoutStage.children[x].lineWidth, 0xffffff, 1);
              if (cutoutStage.children[x].mode === "curve") {
                g.moveTo(points[0].x, points[0].y);

                for (y = 1; y < points.length; y++) {
                  a = points[y - 1].x - points[y].x;
                  b = points[y - 1].y - points[y].y;

                  c = Math.sqrt(a * a + b * b);

                  g.arcTo(
                    points[y - 1].x,
                    points[y - 1].y,
                    points[y].x,
                    points[y].y,
                    Math.abs(c) / 2
                  );
                }
                g.currentPath.shape.closed = true;
              } else {
                shape = new PIXI.Polygon(points);
                shape.closed = cutoutStage.children[x].closed;
                g.drawShape(shape);
              }
              for (y = 0; y < points.length; y++) {
                if (y === window.editor.currentPoint) {
                  g.lineStyle(1 / cutoutStage.scale.x, 0x00ff00, 1);
                } else {
                  g.lineStyle(1 / cutoutStage.scale.x, 0xffffff, 1);
                }
                g.drawRect(
                  points[y].x - 3 / cutoutStage.scale.x,
                  points[y].y - 3 / cutoutStage.scale.x,
                  6 / cutoutStage.scale.x,
                  6 / cutoutStage.scale.x
                );
              }
              g.lineWidth = cutoutStage.children[x].lineWidth;
              g.closed = cutoutStage.children[x].closed;
              g.points = cutoutStage.children[x].points;
              g.filters = cutoutStage.children[x].filters;
              g.mode = cutoutStage.children[x].mode;
              cutoutStage.removeChildAt(x);
              cutoutStage.addChild(g);
            }
          }
        }
      }
    }
    if (EditorStore.getCutoutMode() === "point") {
      if (event !== null) {
        cutoutStage.cursor = "crosshair";

        currentX = event.clientX - this.dragOffsetX - cutoutStage.position.x;
        currentY = event.clientY - this.dragOffsetY - cutoutStage.position.y;
        newX = currentX / cutoutStage.scale.x;
        newY = currentY / cutoutStage.scale.y;
      }
      cutoutContainer.clear();
      cutoutContainer.lineStyle(2 / cutoutStage.scale.x, 0xffffff, 1);
      ps = window.editor.drawPoints.slice(0);

      if (event !== null) ps.push(new PIXI.Point(newX, newY));

      shape = new PIXI.Polygon(ps);
      shape.closed = false;
      cutoutContainer.drawShape(shape);
      if (event !== null) {
        this.lastDragX = newX;
        this.lastDragY = newY;
      }
    }
    if (EditorStore.getCutoutMode() === "curve") {
      currentX = event.clientX - this.dragOffsetX - cutoutStage.position.x;
      currentY = event.clientY - this.dragOffsetY - cutoutStage.position.y;

      newX = currentX / cutoutStage.scale.x;
      newY = currentY / cutoutStage.scale.y;

      cutoutContainer.clear();
      cutoutContainer.lineStyle(2 / cutoutStage.scale.x, 0xffffff, 1);
      ps = window.editor.drawPoints.slice(0);

      ps.push(new PIXI.Point(newX, newY));
      cutoutContainer.moveTo(ps[0].x, ps[0].y);
      for (y = 1; y < ps.length; y++) {
        a = ps[y - 1].x - ps[y].x;
        b = ps[y - 1].y - ps[y].y;

        c = Math.sqrt(a * a + b * b);

        cutoutContainer.arcTo(
          ps[y - 1].x,
          ps[y - 1].y,
          ps[y].x,
          ps[y].y,
          Math.abs(c) / 2
        );
      }
      a = ps[ps.length - 1].x - newX;
      b = ps[ps.length - 1].y - newY;

      c = Math.sqrt(a * a + b * b);
      cutoutContainer.arcTo(
        ps[ps.length - 1].x,
        ps[ps.length - 1].y,
        newX,
        newY,
        Math.abs(c) / 2
      );
      if (
        dist(new PIXI.Point(ps[0].x, ps[0].y), new PIXI.Point(newX, newY)) <=
        8 / cutoutStage.scale.x
      ) {
        cutoutContainer.lineStyle(1 / cutoutStage.scale.x, 0x00ff00, 1);
        cutoutContainer.drawRect(
          ps[0].x - 3 / cutoutStage.scale.x,
          ps[0].y - 3 / cutoutStage.scale.x,
          6 / cutoutStage.scale.x,
          6 / cutoutStage.scale.x
        );
      }

      this.lastDragX = newX;
      this.lastDragY = newY;
    }
    if (this.panning) {
      var dx = event.layerX - this.dragOffsetX;
      var dy = event.layerY - this.dragOffsetY;

      if (
        cutoutStage.position.x + dx <= 0 &&
        cutoutStage.width +
          (cutoutStage.position.x + dx) -
          cutoutRenderer.view.width >
          0
      )
        cutoutStage.position.x += dx;
      if (
        cutoutStage.position.y + dy <= 0 &&
        cutoutStage.height +
          (cutoutStage.position.y + dy) -
          cutoutRenderer.view.height >
          0
      )
        cutoutStage.position.y += dy;
      this.dragOffsetX = event.layerX;
      this.dragOffsetY = event.layerY;
    }
    if (
      EditorStore.getCutoutMode() === "shapes" ||
      EditorStore.getCutoutMode() === "shape-selected"
    ) {
      cutoutContainer.clear();
    }
  },
  onCutoutZoom: function (value) {
    cutoutStage.scale.set(value);
  },
  getCutoutZoom: function () {
    return cutoutStage.scale.x;
  },
  getCutoutInitialZoom: function () {
    return this.cutoutInitialZoom;
  },
  redrawSvg: function (sticker, finishwidth, finishheight, callback = null) {
    if (sticker === null) return;
    if (sticker && sticker.svg === true) {
      var saveScale = window.editor.getSaveScale();
      var xmlText = sticker.sourceUrl;
      var source = new Image();
      source.setAttribute("crossOrigin", "anonymous");
      source.width = finishwidth * saveScale * startingDevicePixelRatio;
      source.height = finishheight * saveScale * startingDevicePixelRatio;
      source.onload = function () {
        var newsticker = new PIXI.Sprite(new PIXI.Texture.fromCanvas(source));
        newsticker.texture.dataUrl = xmlText;
        newsticker.id = sticker.id;
        newsticker.interactive = true;
        newsticker.buttonMode = true;
        newsticker.position = sticker.position;
        newsticker.anchor.set(0.5);
        newsticker.imageSettings = sticker.imageSettings;
        // newsticker.scale.set(1);
        newsticker.sourceUrl = sticker.sourceUrl;
        newsticker.originalUrl = sticker.originalUrl;
        newsticker.svg = sticker.svg;
        newsticker.scale.x = sticker.scale.x;
        newsticker.scale.y = sticker.scale.y;
        newsticker.width = finishwidth;
        newsticker.height = finishheight;
        newsticker.cursor = "move";
        newsticker.rotation = sticker.rotation;
        newsticker.blendMode = sticker.blendMode;
        newsticker.pluginName = sticker.pluginName;
        newsticker.alpha = sticker.alpha;
        newsticker.filters = sticker.filters;
        newsticker.fillColor = sticker.fillColor;
        newsticker.isShape = sticker.isShape;
        if (sticker.shape) {
          newsticker.shape = sticker.shape;
          imgObject.mask = newsticker;
        }
        newsticker
          .on("mousedown", window.editor.onDragStart)
          .on("touchstart", window.editor.onDragStart)
          .on("mouseup", window.editor.onDragEnd)
          .on("mouseupoutside", window.editor.onDragEnd)
          .on("touchend", window.editor.onDragEnd)
          .on("touchendoutside", window.editor.onDragEnd)
          .on("mousemove", window.editor.onDragMove)
          .on("touchmove", window.editor.onDragMove);
        const selectedObject = window.editor.getSelectedObject() || sticker;
        const stickerIndex = stickerContainer.children.indexOf(selectedObject);
        if (stickerIndex !== -1) {
          stickerContainer.removeChildAt(stickerIndex);
          stickerContainer.addChild(newsticker);
          window.editor.setSelectedObject(newsticker);
          if (callback) callback();
        }
      };
      source.onerror = function (e) {
        if (callback) callback();
        console.log(e);
      };
      source.src = xmlText;
    } else {
      sticker.width = finishwidth;
      sticker.height = finishheight;
    }
    window.editor.positionEdp(sticker);
  },
  removeCutout: function () {
    EditorActionCreators.CutoutMode("crop");
    var mainCanvas = renderer.view;
    mainCanvas.style.display = "block";
    renderer.render(stage);
    if (cutoutRenderer) {
      try {
        cutoutRenderer.destroy(true);
      } catch (e) {}
      cutoutRenderer = null;
      cutoutContainer.destroy();
      cutoutContainer = null;
      cutoutMask.destroy();
      cutoutMask = null;
      imgObject = null;
      cutoutStage.removeChildren();
    }
    window.editor.stickerSelector.updateSelector();
  },
  setSelectedObject: async function (object) {
    if (object?.permLocked) return;
    const menu = LeftMenuStore.get();
    if (object && object instanceof PIXI.Text) originalText = object.text;
    if (
      menu !== "design" &&
      object &&
      object === EditorStore.getDesignBackground()
    ) {
      if (menu === "text_props") {
        LeftMenuStore.setMenuSelected("text");
      } else if (menu === "edit_graphic") {
        AssetStore.setRememberPhotos(true);
        LeftMenuStore.setMenuSelected("photos");
      } else if (menu === "edit_graphic_svg") {
        AssetStore.setRememberStickers(true);
        LeftMenuStore.setMenuSelected("sticker");
      }
    }

    if (
      object &&
      !object.DesignBackground &&
      EditorStore.getCutoutMode() !== "shape-selected" &&
      object?.sourceUrl
    ) {
      if (object?.svg) {
        LeftMenuStore.setMenuSelected("edit_graphic_svg");
      } else {
        if (!object.sourceUrl.includes(".svg")) {
          checkImageTransparency(object.sourceUrl, (hasTransparency) => {
            if (hasTransparency) {
              LeftMenuStore.setMenuSelected("edit_graphic_svg");
            } else {
              LeftMenuStore.setMenuSelected("edit_graphic");
            }
          });
        }
      }
      window.editor.showOutline(false);
    }

    var child;
    if (selectedObject && !object && menu === "text_props") {
      if (selectedObject instanceof PIXI.Text) {
        LeftMenuStore.setMenuSelected("text");
      }
    }

    if (
      selectedObject &&
      selectedObject.childId &&
      !object &&
      menu === "text_props"
    ) {
      child = this.getStickerById(selectedObject.childId);
      if (child instanceof PIXI.Text || child === null) {
        LeftMenuStore.setMenuSelected("text");
      }
    }

    selectedObject = object;
    if (object && object.isSelected) object.isSelected(true);
    if (selectedObject && selectedObject.isSelected)
      selectedObject.isSelected(false);
    if (object && object !== EditorStore.getDesignBackground()) {
      if (
        object instanceof PIXI.Text &&
        object.id !== "MemeTop" &&
        object.id !== "MemeBottom" &&
        !object.locked
      ) {
        if (menu !== "text_props") {
          LeftMenuActionCreators.MenuSelected("text_props");
        }
      } else {
        if (object && object.childId) {
          child = this.getStickerById(object.childId);
          if (child instanceof PIXI.Text) {
            LeftMenuStore.setMenuSelected("text_props");
          }
        }
      }
      window.editor.positionEdp(object);
      window.editor.positionLocked(object);
    } else {
      var edp = document.getElementById("editor-properties");
      window.editor.positionLocked(object);

      if (
        EditorStore.getSelectedCollageHotspot() &&
        EditorStore.getShowPropertiesCollage()
      ) {
        window.editor.positionEdp(EditorStore.getSelectedCollageHotspot());
      } else {
        if (edp) {
          edp.style.display = "none";
        }
      }
    }
    // if (selectedObject && selectedObject instanceof PIXI.Text) {
    //   EditorStore.setShowDoubleArrow(true);
    // } else {
    //   EditorStore.setShowDoubleArrow(false);
    // }
    this.stickerSelector.updateSelector();
    EditorStore.setSelectedObject(object);
  },
  setCutoutCropObject: function (object) {
    cropCutoutObject = object;
    CropCutoutSelector.updateSelector();
  },
  getImgObject: function () {
    return imgObject;
  },
  parsedStyleForCSS: function (cssString) {
    var el = document.createElement("span");
    el.setAttribute("style", cssString);

    return el.style; // CSSStyleDeclaration object
  },
  getSelectedObject: function () {
    return selectedObject;
  },
  getCropCutoutObject: function () {
    return cropCutoutObject;
  },
  getBorderContainer: function () {
    return borderContainer;
  },
  getEffectBorderContainer: function () {
    return effectBorderContainer;
  },
  meme: {
    top: {
      setText: function (text) {
        topMemeText.text = text.toUpperCase();
        topMemeText.boundText = text;
        EditorStore.emitChange();
        window.editor.autoSave();
      },
      setColor: function (color) {
        topMemeColor = color;
        topMemeText.style = {
          fontSize: topMemeSize,
          fontFamily: "Impact",
          fill: topMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: topMemeSize / 20,
        };

        EditorStore.emitChange();
        window.editor.autoSave();
      },
      getText: function () {
        if (topMemeText.boundText === " ") return "";

        return topMemeText.boundText;
      },
      getColor: function () {
        return topMemeColor;
      },
      getSize: function () {
        return topMemeSize;
      },
      setSize: function (size) {
        topMemeSize = parseInt(size, 10);
        topMemeText.style = {
          fontSize: topMemeSize,
          fontFamily: "Impact",
          fill: topMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: topMemeSize / 20,
        };
        EditorStore.emitChange();
        window.editor.autoSave();
      },
    },
    bottom: {
      setText: function (text) {
        bottomMemeText.text = text.toUpperCase();
        bottomMemeText.boundText = text;

        EditorStore.emitChange();
        window.editor.autoSave();
      },
      setColor: function (color) {
        bottomMemeColor = color;
        bottomMemeText.style = {
          fontSize: bottomMemeSize,
          fontFamily: "Impact",
          fill: bottomMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: bottomMemeSize / 20,
        };
        EditorStore.emitChange();
        window.editor.autoSave();
      },
      getText: function () {
        if (bottomMemeText.boundText === " ") return "";
        return bottomMemeText.boundText;
      },
      getColor: function () {
        return bottomMemeColor;
      },
      getSize: function () {
        return bottomMemeSize;
      },
      setSize: function (size) {
        bottomMemeSize = parseInt(size, 10);
        bottomMemeText.style = {
          fontSize: bottomMemeSize,
          fontFamily: "Impact",
          fill: bottomMemeColor,
          align: "center",
          wordWrap: true,
          wordWrapWidth: memeWordWrapWidth,
          stroke: "#000000",
          strokeThickness: bottomMemeSize / 20,
        };
        EditorStore.emitChange();
        window.editor.autoSave();
      },
    },
  },
  stickerSelector: {
    leftText: PIXI.Sprite.fromImage(WhitePill2Img),
    rightText: PIXI.Sprite.fromImage(WhitePill2Img),
    leftTop: PIXI.Sprite.fromImage(WhiteCircleImg),
    rightTop: PIXI.Sprite.fromImage(WhiteCircleImg),
    LeftBottom: PIXI.Sprite.fromImage(WhiteCircleImg),
    RightBottom: PIXI.Sprite.fromImage(WhiteCircleImg),
    MiddleTop: PIXI.Sprite.fromImage(WhitePillImg),
    MiddleBottom: PIXI.Sprite.fromImage(WhitePillImg),
    MiddleLeft: PIXI.Sprite.fromImage(WhitePill2Img),
    MiddleRight: PIXI.Sprite.fromImage(WhitePill2Img),
    rotate: PIXI.Sprite.fromImage(RotateControlImg),
    border: new PIXI.Graphics(),
    currentSelector: null,
    originalX: 0,
    originalY: 0,
    textCenterX: 0,
    textCenterY: 0,
    init: function () {
      selectorContainer = new PIXI.Container();
      selectorContainer.addChild(this.border);
      this.leftText.anchor.set(0.5);
      this.leftText.scale.set(0.5 / window.editor.getScale());
      this.leftText.interactive = true;
      this.leftText.buttonMode = true;
      this.leftText.renderable = false;
      this.leftText.cursor = "w-resize";
      this.leftText.customCursor = "w-resize";
      this.leftText.leftText = true;
      selectorContainer.addChild(this.leftText);
      this.leftText
        .on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onWidthStart)
        .on("touchstart", window.editor.stickerSelector.onWidthStart)
        .on("mouseup", window.editor.stickerSelector.onWidthEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onWidthEnd)
        .on("touchend", window.editor.stickerSelector.onWidthEnd)
        .on("touchendoutside", window.editor.stickerSelector.onWidthEnd)
        .on("mousemove", window.editor.stickerSelector.onWidthMove)
        .on("touchmove", window.editor.stickerSelector.onWidthMove);

      this.rightText.anchor.set(0.5);
      this.rightText.scale.set(0.5 / window.editor.getScale());
      this.rightText.interactive = true;
      this.rightText.buttonMode = true;
      this.rightText.renderable = false;
      this.rightText.cursor = "e-resize";
      this.rightText.customCursor = "e-resize";
      this.rightText.rightText = true;
      selectorContainer.addChild(this.rightText);
      this.rightText
        .on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onWidthStart)
        .on("touchstart", window.editor.stickerSelector.onWidthStart)
        .on("mouseup", window.editor.stickerSelector.onWidthEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onWidthEnd)
        .on("touchend", window.editor.stickerSelector.onWidthEnd)
        .on("touchendoutside", window.editor.stickerSelector.onWidthEnd)
        .on("mousemove", window.editor.stickerSelector.onWidthMove)
        .on("touchmove", window.editor.stickerSelector.onWidthMove);

      this.leftTop.resolution = startingDevicePixelRatio;
      this.leftTop.anchor.set(0.5);
      this.leftTop.scale.set(0.5 / window.editor.getScale());
      this.leftTop.interactive = true;
      this.leftTop.buttonMode = true;
      this.leftTop.renderable = false;
      this.leftTop.cursor = "nw-resize";
      this.leftTop.customCursor = "nw-resize";
      this.leftTop.leftTop = true;
      selectorContainer.addChild(this.leftTop);
      this.leftTop
        .on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onScaleStart)
        .on("touchstart", window.editor.stickerSelector.onScaleStart)
        .on("mouseup", window.editor.stickerSelector.onScaleEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onScaleEnd)
        .on("touchend", window.editor.stickerSelector.onScaleEnd)
        .on("touchendoutside", window.editor.stickerSelector.onScaleEnd)
        .on("mousemove", window.editor.stickerSelector.onScaleMove)
        .on("touchmove", window.editor.stickerSelector.onScaleMove);
      this.rightTop.resolution = startingDevicePixelRatio;
      this.rightTop.anchor.set(0.5);
      this.rightTop.scale.set(0.5 / window.editor.getScale());
      this.rightTop.interactive = true;
      this.rightTop.buttonMode = true;
      this.rightTop.renderable = false;
      this.rightTop.cursor = "ne-resize";
      this.rightTop.customCursor = "ne-resize";
      this.rightTop.rightTop = true;
      selectorContainer.addChild(this.rightTop);
      this.rightTop
        .on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onScaleStart)
        .on("touchstart", window.editor.stickerSelector.onScaleStart)
        .on("mouseup", window.editor.stickerSelector.onScaleEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onScaleEnd)
        .on("touchend", window.editor.stickerSelector.onScaleEnd)
        .on("touchendoutside", window.editor.stickerSelector.onScaleEnd)
        .on("mousemove", window.editor.stickerSelector.onScaleMove)
        .on("touchmove", window.editor.stickerSelector.onScaleMove);

      this.LeftBottom.resolution = startingDevicePixelRatio;
      this.LeftBottom.anchor.set(0.5);
      this.LeftBottom.scale.set(0.5 / window.editor.getScale());
      this.LeftBottom.interactive = true;
      this.LeftBottom.buttonMode = true;
      this.LeftBottom.renderable = false;
      this.LeftBottom.cursor = "sw-resize";
      this.LeftBottom.customCursor = "ne-resize";
      this.LeftBottom.LeftBottom = true;
      selectorContainer.addChild(this.LeftBottom);
      this.LeftBottom.on(
        "mousedown",
        window.editor.stickerSelector.onScaleStart
      )
        .on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("touchstart", window.editor.stickerSelector.onScaleStart)
        .on("mouseup", window.editor.stickerSelector.onScaleEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onScaleEnd)
        .on("touchend", window.editor.stickerSelector.onScaleEnd)
        .on("touchendoutside", window.editor.stickerSelector.onScaleEnd)
        .on("mousemove", window.editor.stickerSelector.onScaleMove)
        .on("touchmove", window.editor.stickerSelector.onScaleMove);

      this.RightBottom.anchor.set(0.5);
      this.RightBottom.resolution = startingDevicePixelRatio;
      this.RightBottom.scale.set(0.5 / window.editor.getScale());
      this.RightBottom.interactive = true;
      this.RightBottom.buttonMode = true;
      this.RightBottom.renderable = false;
      this.RightBottom.cursor = "se-resize";
      this.RightBottom.customCursor = "se-resize";
      this.RightBottom.RightBottom = true;
      selectorContainer.addChild(this.RightBottom);
      this.RightBottom.on(
        "mouseover",
        window.editor.stickerSelector.onMouseOver
      )
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onScaleStart)
        .on("touchstart", window.editor.stickerSelector.onScaleStart)
        .on("mouseup", window.editor.stickerSelector.onScaleEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onScaleEnd)
        .on("touchend", window.editor.stickerSelector.onScaleEnd)
        .on("touchendoutside", window.editor.stickerSelector.onScaleEnd)
        .on("mousemove", window.editor.stickerSelector.onScaleMove)
        .on("touchmove", window.editor.stickerSelector.onScaleMove);
      this.MiddleTop.resolution = startingDevicePixelRatio;
      this.MiddleTop.anchor.set(0.5);
      this.MiddleTop.scale.set(0.5 / window.editor.getScale());
      this.MiddleTop.interactive = true;
      this.MiddleTop.buttonMode = true;
      this.MiddleTop.renderable = false;
      this.MiddleTop.cursor = "n-resize";
      this.MiddleTop.customCursor = "n-resize";
      this.MiddleTop.MiddleTop = true;
      selectorContainer.addChild(this.MiddleTop);
      this.MiddleTop.on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onHeightStart)
        .on("touchstart", window.editor.stickerSelector.onHeightStart)
        .on("mouseup", window.editor.stickerSelector.onHeightEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onHeightEnd)
        .on("touchend", window.editor.stickerSelector.onHeightEnd)
        .on("touchendoutside", window.editor.stickerSelector.onHeightEnd)
        .on("mousemove", window.editor.stickerSelector.onHeightMove)
        .on("touchmove", window.editor.stickerSelector.onHeightMove);

      this.MiddleBottom.anchor.set(0.5);
      this.MiddleBottom.scale.set(0.5 / window.editor.getScale());
      this.MiddleBottom.interactive = true;
      this.MiddleBottom.buttonMode = true;
      this.MiddleBottom.renderable = false;
      this.MiddleBottom.cursor = "s-resize";
      this.MiddleBottom.customCursor = "s-resize";
      this.MiddleBottom.MiddleBottom = true;
      selectorContainer.addChild(this.MiddleBottom);
      this.MiddleBottom.on(
        "mouseover",
        window.editor.stickerSelector.onMouseOver
      )
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onHeightStart)
        .on("touchstart", window.editor.stickerSelector.onHeightStart)
        .on("mouseup", window.editor.stickerSelector.onHeightEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onHeightEnd)
        .on("touchend", window.editor.stickerSelector.onHeightEnd)
        .on("touchendoutside", window.editor.stickerSelector.onHeightEnd)
        .on("mousemove", window.editor.stickerSelector.onHeightMove)
        .on("touchmove", window.editor.stickerSelector.onHeightMove);
      this.MiddleLeft.resolution = startingDevicePixelRatio;
      this.MiddleLeft.anchor.set(0.5);
      this.MiddleLeft.scale.set(0.5 / window.editor.getScale());
      this.MiddleLeft.interactive = true;
      this.MiddleLeft.buttonMode = true;
      this.MiddleLeft.renderable = false;
      this.MiddleLeft.cursor = "w-resize";
      this.MiddleLeft.customCursor = "w-resize";
      this.MiddleLeft.MiddleLeft = true;

      selectorContainer.addChild(this.MiddleLeft);
      this.MiddleLeft.on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onWidthStart)
        .on("touchstart", window.editor.stickerSelector.onWidthStart)
        .on("mouseup", window.editor.stickerSelector.onWidthEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onWidthEnd)
        .on("touchend", window.editor.stickerSelector.onWidthEnd)
        .on("touchendoutside", window.editor.stickerSelector.onWidthEnd)
        .on("mousemove", window.editor.stickerSelector.onWidthMove)
        .on("touchmove", window.editor.stickerSelector.onWidthMove);
      this.MiddleRight.anchor.set(0.5);
      this.MiddleRight.resolution = startingDevicePixelRatio;
      this.MiddleRight.anchor.set(0.5);
      this.MiddleRight.scale.set(0.5 / window.editor.getScale());
      this.MiddleRight.interactive = true;
      this.MiddleRight.buttonMode = true;
      this.MiddleRight.renderable = false;
      this.MiddleRight.cursor = "e-resize";
      this.MiddleRight.customCursor = "e-resize";
      this.MiddleRight.MiddleRight = true;
      selectorContainer.addChild(this.MiddleRight);
      this.MiddleRight.on(
        "mouseover",
        window.editor.stickerSelector.onMouseOver
      )
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onWidthStart)
        .on("touchstart", window.editor.stickerSelector.onWidthStart)
        .on("mouseup", window.editor.stickerSelector.onWidthEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onWidthEnd)
        .on("touchend", window.editor.stickerSelector.onWidthEnd)
        .on("touchendoutside", window.editor.stickerSelector.onWidthEnd)
        .on("mousemove", window.editor.stickerSelector.onWidthMove)
        .on("touchmove", window.editor.stickerSelector.onWidthMove);
      this.rotate.resolution = startingDevicePixelRatio;
      this.rotate.anchor.set(0.5);
      this.rotate.scale.set(0.5 / window.editor.getScale());
      this.rotate.interactive = true;
      this.rotate.buttonMode = true;
      this.rotate.renderable = false;
      this.rotate.cursor = `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'%3E%3Cdefs%3E%3Cfilter id='a' width='266.7%25' height='156.2%25' x='-75%25' y='-21.9%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 24V0h24v24z'/%3E%3Cg fill-rule='nonzero' filter='url(%23a)' transform='rotate(-90 8.83 6.441)'%3E%3Cuse fill='%23000' fill-rule='evenodd' xlink:href='%23b'/%3E%3Cpath stroke='%23FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 12 12,auto`;
      this.rotate.customCursor = `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'%3E%3Cdefs%3E%3Cfilter id='a' width='266.7%25' height='156.2%25' x='-75%25' y='-21.9%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 24V0h24v24z'/%3E%3Cg fill-rule='nonzero' filter='url(%23a)' transform='rotate(-90 8.83 6.441)'%3E%3Cuse fill='%23000' fill-rule='evenodd' xlink:href='%23b'/%3E%3Cpath stroke='%23FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 12 12,auto`;
      this.rotate.rotate = true;
      selectorContainer.addChild(this.rotate);
      this.rotate
        .on("mouseover", window.editor.stickerSelector.onMouseOver)
        .on("mouseout", window.editor.stickerSelector.onMouseOut)
        .on("mousedown", window.editor.stickerSelector.onRotateStart)
        .on("touchstart", window.editor.stickerSelector.onRotateStart)
        .on("mouseup", window.editor.stickerSelector.onRotateEnd)
        .on("mouseupoutside", window.editor.stickerSelector.onRotateEnd)
        .on("touchend", window.editor.stickerSelector.onRotateEnd)
        .on("touchendoutside", window.editor.stickerSelector.onRotateEnd)
        .on("mousemove", window.editor.stickerSelector.onRotateMove)
        .on("touchmove", window.editor.stickerSelector.onRotateMove);
    },
    stickerEnable: function (sticker, val) {
      sticker.renderable = val;
      sticker.interactive = val;
      sticker.buttonMode = val;
      if (val === true) {
        sticker.cursor = sticker.customCursor;
      }
    },
    updateSelector: function () {
      if (
        selectedObject &&
        !selectedObject.DesignBackground &&
        !selectedObject.CollageBackground &&
        !selectedObject.Meme &&
        !selectedObject.locked &&
        !selectedObject.hideSelector
      ) {
        selectorContainer.x = selectedObject.x;
        selectorContainer.y = selectedObject.y;
        selectorContainer.pivot = selectedObject.pivot;
        var width = selectedObject.width;
        if (selectedObject instanceof PIXI.Text) {
          width = selectedObject.style.wordWrapWidth;
        }
        var height = selectedObject.height;

        this.border.clear();
        this.border.lineStyle(2, 0x388cff, 0.75);
        if (selectedObject instanceof PIXI.Text) {
          if (selectedObject.style.align === "left") {
            this.border.drawRect(0, 0 - height / 2, width, height);
          }
          if (selectedObject.style.align === "right") {
            this.border.drawRect(0 - width, 0 - height / 2, width, height);
          }
          if (selectedObject.style.align === "center") {
            this.border.drawRect(0 - width / 2, 0 - height / 2, width, height);
          }
        } else {
          this.border.drawRect(0 - width / 2, 0 - height / 2, width, height);
        }

        this.stickerEnable(this.rotate, !selectedObject.crop);
        this.leftTop.position.x = 0 - width / 2;
        this.leftTop.position.y = 0 - height / 2;
        this.stickerEnable(this.leftTop, true);
        this.rightTop.position.x = width / 2;
        this.rightTop.position.y = 0 - height / 2;
        this.stickerEnable(this.rightTop, true);
        this.LeftBottom.position.x = 0 - width / 2;
        this.LeftBottom.position.y = height / 2;
        this.stickerEnable(this.LeftBottom, true);
        this.RightBottom.position.x = width / 2;
        this.RightBottom.position.y = height / 2;
        this.stickerEnable(this.RightBottom, true);
        this.MiddleBottom.position.x = 0;
        this.MiddleBottom.position.y = height / 2;
        this.stickerEnable(this.MiddleBottom, true);
        this.MiddleTop.position.x = 0;
        this.MiddleTop.position.y = 0 - height / 2;
        this.stickerEnable(this.MiddleTop, true);
        this.MiddleLeft.position.x = 0 - width / 2;
        this.MiddleLeft.position.y = 0;
        this.stickerEnable(this.MiddleLeft, true);
        this.MiddleRight.position.x = width / 2;
        this.MiddleRight.position.y = 0;

        const baseWidth = baseObject.width;
        const baseHeight = baseObject.height;
        const wt_points = {
          tx: this.MiddleTop.worldTransform.tx,
          ty: this.MiddleTop.worldTransform.ty,
        };
        const wb_points = {
          tx: this.MiddleBottom.worldTransform.tx,
          ty: this.MiddleBottom.worldTransform.ty,
        };
        const wl_points = {
          tx: this.MiddleLeft.worldTransform.tx,
          ty: this.MiddleLeft.worldTransform.ty,
        };
        const wr_points = {
          tx: this.MiddleRight.worldTransform.tx,
          ty: this.MiddleRight.worldTransform.ty,
        };
        if (
          selectedObject.position.x <= baseWidth / 2 &&
          selectedObject.position.y <= baseHeight / 2
        ) {
          if (wt_points.tx <= 35 || wt_points.ty <= 35) {
            if (wb_points.tx >= 35 && wb_points.ty >= 35) {
              this.rotate.position.x = 0;
              this.rotate.position.y = height / 2 + 35 / stage.scale.x;
              rotateIconPos = "middleBottom";
            } else if (wr_points.tx >= 35 && wr_points.ty > 35) {
              this.rotate.position.x = width / 2 + 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleRight";
            } else if (wl_points.tx >= 35 && wl_points.ty > 35) {
              this.rotate.position.x = 0 - width / 2 - 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleLeft";
            } else {
              this.rotate.position.x = 0;
              this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
              rotateIconPos = "middleTop";
            }
          } else {
            this.rotate.position.x = 0;
            this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
            rotateIconPos = "middleTop";
          }
        } else if (
          selectedObject.position.x >= baseWidth / 2 &&
          selectedObject.position.y <= baseHeight / 2
        ) {
          if (wt_points.tx >= baseWidth - 35 || wt_points.ty <= 35) {
            if (wb_points.tx <= baseWidth - 35 && wb_points.ty >= 35) {
              this.rotate.position.x = 0;
              this.rotate.position.y = height / 2 + 35 / stage.scale.x;
              rotateIconPos = "middleBottom";
            } else if (wr_points.tx <= baseWidth - 35 && wr_points.ty > 35) {
              this.rotate.position.x = width / 2 + 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleRight";
            } else if (wl_points.tx <= baseWidth - 35 && wl_points.ty > 35) {
              this.rotate.position.x = 0 - width / 2 - 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleLeft";
            } else {
              this.rotate.position.x = 0;
              this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
              rotateIconPos = "middleTop";
            }
          } else {
            this.rotate.position.x = 0;
            this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
            rotateIconPos = "middleTop";
          }
        } else if (
          selectedObject.position.x <= baseWidth / 2 &&
          selectedObject.position.y >= baseHeight / 2
        ) {
          if (wt_points.tx <= 35 || wt_points.ty >= baseHeight - 35) {
            if (wb_points.tx >= 35 && wb_points.ty <= baseHeight - 35) {
              this.rotate.position.x = 0;
              this.rotate.position.y = height / 2 + 35 / stage.scale.x;
              rotateIconPos = "middleBottom";
            } else if (wr_points.tx >= 35 && wr_points.ty <= baseHeight - 35) {
              this.rotate.position.x = width / 2 + 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleRight";
            } else if (wl_points.tx >= 35 && wl_points.ty <= baseHeight - 35) {
              this.rotate.position.x = 0 - width / 2 - 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleLeft";
            } else {
              this.rotate.position.x = 0;
              this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
              rotateIconPos = "middleTop";
            }
          } else {
            this.rotate.position.x = 0;
            this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
            rotateIconPos = "middleTop";
          }
        } else if (
          selectedObject.position.x >= baseWidth / 2 &&
          selectedObject.position.y >= baseHeight / 2
        ) {
          if (
            wt_points.tx >= baseWidth - 35 ||
            wt_points.ty >= baseHeight - 35
          ) {
            if (
              wb_points.tx <= baseWidth - 35 &&
              wb_points.ty <= baseHeight - 35
            ) {
              this.rotate.position.x = 0;
              this.rotate.position.y = height / 2 + 35 / stage.scale.x;
              rotateIconPos = "middleBottom";
            } else if (
              wr_points.tx <= baseWidth - 35 &&
              wr_points.ty <= baseHeight - 35
            ) {
              this.rotate.position.x = width / 2 + 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleRight";
            } else if (
              wl_points.tx <= baseWidth - 35 &&
              wl_points.ty <= baseHeight - 35
            ) {
              this.rotate.position.x = 0 - width / 2 - 35 / stage.scale.x;
              this.rotate.position.y = 0;
              rotateIconPos = "middleLeft";
            } else {
              this.rotate.position.x = 0;
              this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
              rotateIconPos = "middleTop";
            }
          } else {
            this.rotate.position.x = 0;
            this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
            rotateIconPos = "middleTop";
          }
        } else {
          this.rotate.position.x = 0;
          this.rotate.position.y = 0 - height / 2 - 35 / stage.scale.x;
          rotateIconPos = "middleTop";
        }

        if (height < 50 || width < 50) {
          this.MiddleRight.scale.set(0.25 / window.editor.getScale());
          this.MiddleLeft.scale.set(0.25 / window.editor.getScale());
          this.MiddleTop.scale.set(0.25 / window.editor.getScale());
          this.MiddleBottom.scale.set(0.25 / window.editor.getScale());
          this.LeftBottom.scale.set(0.25 / window.editor.getScale());
          this.leftTop.scale.set(0.25 / window.editor.getScale());
          this.RightBottom.scale.set(0.25 / window.editor.getScale());
          this.rightTop.scale.set(0.25 / window.editor.getScale());
        } else {
          this.MiddleRight.scale.set(0.5 / window.editor.getScale());
          this.MiddleLeft.scale.set(0.5 / window.editor.getScale());
          this.MiddleTop.scale.set(0.5 / window.editor.getScale());
          this.MiddleBottom.scale.set(0.5 / window.editor.getScale());
          this.LeftBottom.scale.set(0.5 / window.editor.getScale());
          this.leftTop.scale.set(0.5 / window.editor.getScale());
          this.RightBottom.scale.set(0.5 / window.editor.getScale());
          this.rightTop.scale.set(0.5 / window.editor.getScale());
        }

        this.stickerEnable(this.MiddleRight, true);
        selectorContainer.rotation = selectedObject.rotation;
        if (selectedObject instanceof PIXI.Text) {
          this.stickerEnable(this.MiddleTop, false);
          this.stickerEnable(this.MiddleBottom, false);
          this.stickerEnable(this.MiddleLeft, false);
          this.stickerEnable(this.MiddleRight, false);

          /** Remove corner handler of text */
          this.stickerEnable(this.leftTop, false);
          this.stickerEnable(this.rightTop, false);
          this.stickerEnable(this.LeftBottom, false);
          this.stickerEnable(this.RightBottom, false);

          this.leftText.position.y = 0;
          this.stickerEnable(this.leftText, true);
          this.rightText.position.y = 0;
          this.stickerEnable(this.rightText, true);
          this.leftText.interactive = true;
          this.leftText.buttonMode = true;
          this.rightText.interactive = true;
          this.rightText.buttonMode = true;
          this.rightText.cursor = "e-resize";
          this.leftText.cursor = "w-resize";
          this.MiddleRight.cursor = "e-resize";
          this.MiddleLeft.cursor = "w-resize";
          if (selectedObject.style.align === "left") {
            this.leftText.position.x = 0;
            this.rightText.position.x = width;
            this.MiddleLeft.position.x = 0;
            this.MiddleRight.position.x = width;
            this.MiddleBottom.position.x = width / 2;
            this.MiddleTop.position.x = width / 2;
            this.leftTop.position.x = 0;
            this.rightTop.position.x = width;
            this.LeftBottom.position.x = 0;
            this.RightBottom.position.x = width;
            this.rotate.position.x = width / 2;
            // if (!selectedObject.backgroundEnabled) {
            //   this.border.moveTo(width / 2, 0 - height / 2);
            //   this.border.lineTo(
            //     width / 2,
            //     0 - height / 2 - 35 / stage.scale.x
            //   );
            // }
            selectedObject.hitArea = new PIXI.Rectangle(
              0,
              0 - height / 2,
              width,
              height
            );
          }
          if (selectedObject.style.align === "center") {
            this.leftText.position.x = 0 - width / 2;
            this.rightText.position.x = width / 2;
            // if (!selectedObject.backgroundEnabled) {
            //   this.border.moveTo(0, 0 - height / 2);
            //   this.border.lineTo(0, 0 - height / 2 - 35 / stage.scale.x);
            // }
            selectedObject.hitArea = new PIXI.Rectangle(
              0 - width / 2,
              0 - height / 2,
              width,
              height
            );
          }
          if (selectedObject.style.align === "right") {
            this.leftText.position.x = 0 - width;
            this.rightText.position.x = 0;
            this.MiddleLeft.position.x = 0 - width;
            this.MiddleRight.position.x = 0;
            this.MiddleBottom.position.x = 0 - width / 2;
            this.MiddleTop.position.x = 0 - width / 2;
            this.rotate.position.x = 0 - width / 2;
            this.leftTop.position.x = 0 - width;
            this.rightTop.position.x = 0;
            this.LeftBottom.position.x = 0 - width;
            this.RightBottom.position.x = 0;
            // if (!selectedObject.backgroundEnabled) {
            //   this.border.moveTo(0 - width / 2, 0 - height / 2);
            //   this.border.lineTo(
            //     0 - width / 2,
            //     0 - height / 2 - 35 / stage.scale.x
            //   );
            // }
            selectedObject.hitArea = new PIXI.Rectangle(
              0 - width,
              0 - height / 2,
              width,
              height
            );
          }
        } else {
          this.leftText.position.x = 0 - width / 2;
          this.leftText.position.y = 0;
          this.stickerEnable(this.leftText, false);
          this.rightText.position.x = width / 2;
          this.rightText.position.y = 0;
          this.stickerEnable(this.rightText, false);
          if (selectedObject.curveSprite === true) {
            this.stickerEnable(this.leftTop, false);
            this.stickerEnable(this.rightTop, false);
            this.stickerEnable(this.LeftBottom, false);
            this.stickerEnable(this.RightBottom, false);
            this.stickerEnable(this.MiddleBottom, false);
            this.stickerEnable(this.MiddleLeft, false);
            this.stickerEnable(this.MiddleRight, false);
            this.stickerEnable(this.MiddleTop, false);
            this.stickerEnable(this.leftText, false);
            this.stickerEnable(this.rightText, false);
          }
        }
      } else {
        this.cancelSelector();
      }
    },
    cancelSelector: function () {
      this.stickerEnable(this.rotate, false);
      this.stickerEnable(this.leftTop, false);
      this.stickerEnable(this.rightTop, false);
      this.stickerEnable(this.LeftBottom, false);
      this.stickerEnable(this.RightBottom, false);
      this.stickerEnable(this.MiddleBottom, false);
      this.stickerEnable(this.MiddleLeft, false);
      this.stickerEnable(this.MiddleRight, false);
      this.stickerEnable(this.MiddleTop, false);
      this.stickerEnable(this.leftText, false);
      this.stickerEnable(this.rightText, false);
      this.border.clear();
    },
    onScaleStart: function (event) {
      event.stopPropagation();
      if (selectedObject && event && event.data) {
        if (selectedObject instanceof PIXI.Text) {
          EditorActionCreators.FontSize(selectedObject.style.fontSize, true);
          window.editor.stickerSelector.textCenterY = selectedObject.y;
          isDragging = true;
          if (event.currentTarget.leftTop) {
            window.editor.stickerSelector.leftTop.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = true;
            window.editor.stickerSelector.rightTop.visible = false;
            window.editor.stickerSelector.LeftBottom.visible = false;
            window.editor.stickerSelector.RightBottom.visible = false;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          } else if (event.currentTarget.rightTop) {
            window.editor.stickerSelector.rightTop.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = false;
            window.editor.stickerSelector.rightTop.visible = true;
            window.editor.stickerSelector.LeftBottom.visible = false;
            window.editor.stickerSelector.RightBottom.visible = false;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          } else if (event.currentTarget.LeftBottom) {
            window.editor.stickerSelector.LeftBottom.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = false;
            window.editor.stickerSelector.rightTop.visible = false;
            window.editor.stickerSelector.LeftBottom.visible = true;
            window.editor.stickerSelector.RightBottom.visible = false;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          } else if (event.currentTarget.RightBottom) {
            window.editor.stickerSelector.RightBottom.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = false;
            window.editor.stickerSelector.rightTop.visible = false;
            window.editor.stickerSelector.LeftBottom.visible = false;
            window.editor.stickerSelector.RightBottom.visible = true;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          }
          if (selectedObject.style.align === "left") {
            window.editor.stickerSelector.textCenterX =
              selectedObject.x + selectedObject.style.wordWrapWidth / 2;
          }
          if (selectedObject.style.align === "center") {
            window.editor.stickerSelector.textCenterX = selectedObject.x;
          }
          if (selectedObject.style.align === "right") {
            window.editor.stickerSelector.textCenterX =
              selectedObject.x - selectedObject.style.wordWrapWidth / 2;
          }
          if (selectedObject && selectedObject.locked) return;
          selectedObject.data = event.data;
          selectedObject.scaling = true;
          selectedObject.dragging = false;
          window.editor.scaleStartWidth = selectedObject.style.wordWrapWidth;
          window.editor.scaleStartHeight = selectedObject.height;
          window.editor.stickerSelector.originalX =
            selectedObject.width - event.data.global.x - selectedObject.x;
          var edp = document.getElementById("editor-properties");
          edp.style.display = "none";
        } else {
          if (selectedObject && selectedObject.locked) return;
          isDragging = true;
          if (event.currentTarget.leftTop) {
            window.editor.stickerSelector.leftTop.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = true;
            window.editor.stickerSelector.rightTop.visible = false;
            window.editor.stickerSelector.LeftBottom.visible = false;
            window.editor.stickerSelector.RightBottom.visible = false;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          } else if (event.currentTarget.rightTop) {
            window.editor.stickerSelector.rightTop.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = false;
            window.editor.stickerSelector.rightTop.visible = true;
            window.editor.stickerSelector.LeftBottom.visible = false;
            window.editor.stickerSelector.RightBottom.visible = false;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          } else if (event.currentTarget.LeftBottom) {
            window.editor.stickerSelector.LeftBottom.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = false;
            window.editor.stickerSelector.rightTop.visible = false;
            window.editor.stickerSelector.LeftBottom.visible = true;
            window.editor.stickerSelector.RightBottom.visible = false;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          } else if (event.currentTarget.RightBottom) {
            window.editor.stickerSelector.RightBottom.tint = 0x006dff;
            window.editor.stickerSelector.MiddleLeft.visible = false;
            window.editor.stickerSelector.MiddleRight.visible = false;
            window.editor.stickerSelector.rotate.visible = false;
            window.editor.stickerSelector.leftTop.visible = false;
            window.editor.stickerSelector.rightTop.visible = false;
            window.editor.stickerSelector.LeftBottom.visible = false;
            window.editor.stickerSelector.RightBottom.visible = true;
            window.editor.stickerSelector.leftText.visible = false;
            window.editor.stickerSelector.rightText.visible = false;
            window.editor.stickerSelector.MiddleBottom.visible = false;
            window.editor.stickerSelector.MiddleTop.visible = false;
          }
          selectedObject.data = event.data;
          selectedObject.scaling = true;
          selectedObject.dragging = false;
          window.editor.scaleStartWidth = selectedObject.width;
          window.editor.scaleStartHeight = selectedObject.height;
          window.editor.stickerSelector.originalX =
            selectedObject.width - event.data.global.x - selectedObject.x;
          edp = document.getElementById("editor-properties");
          edp.style.display = "none";
        }
      }
    },
    onScaleEnd: function (event) {
      if (selectedObject) {
        if (selectedObject && selectedObject.locked) return;
        isDragging = false;
        window.editor.stickerSelector.LeftBottom.tint = 0xffffff;
        window.editor.stickerSelector.RightBottom.tint = 0xffffff;
        window.editor.stickerSelector.leftTop.tint = 0xffffff;
        window.editor.stickerSelector.rightTop.tint = 0xffffff;

        window.editor.stickerSelector.MiddleLeft.visible = true;
        window.editor.stickerSelector.MiddleRight.visible = true;
        window.editor.stickerSelector.rotate.visible = true;
        window.editor.stickerSelector.leftTop.visible = true;
        window.editor.stickerSelector.rightTop.visible = true;
        window.editor.stickerSelector.LeftBottom.visible = true;
        window.editor.stickerSelector.RightBottom.visible = true;
        window.editor.stickerSelector.leftText.visible = true;
        window.editor.stickerSelector.rightText.visible = true;
        window.editor.stickerSelector.MiddleBottom.visible = true;
        window.editor.stickerSelector.MiddleTop.visible = true;

        const id = selectedObject.id;
        const startwidth = window.editor.scaleStartWidth;
        const startheight = window.editor.scaleStartHeight;
        const finishwidth = selectedObject.width;
        const finishheight = selectedObject.height;
        selectedObject.scaling = false;
        selectedObject.data = null;
        window.editor.CommandManager.execute({
          execute: function () {
            const sticker = window.editor.getStickerById(id);
            window.editor.redrawSvg(sticker, finishwidth, finishheight);
            window.editor.positionEdp(sticker);
          },
          unexecute: function () {
            const sticker = window.editor.getStickerById(id);
            sticker.width = startwidth;
            sticker.height = startheight;
          },
        });
      }
    },
    onScaleMove: function (event) {
      var newWidth;
      if (selectedObject instanceof PIXI.Text) {
        if (selectedObject && selectedObject.scaling) {
          var tempX =
            event.data.global.x / stage.scale.x -
            window.editor.stickerSelector.textCenterX;
          var tempY =
            event.data.global.y / stage.scale.y -
            window.editor.stickerSelector.textCenterY;
          var rotatedX =
            tempX * Math.cos(selectedObject.rotation) +
            tempY * Math.sin(selectedObject.rotation);
          var unRotatedX = rotatedX + selectedObject.x;
          var rotatedY =
            tempY * Math.cos(selectedObject.rotation) +
            tempX * Math.sin(selectedObject.rotation);
          var unRotatedY = rotatedY + selectedObject.y;
          newWidth =
            Math.abs(unRotatedX - window.editor.stickerSelector.textCenterX) *
            2;
          var height =
            Math.abs(unRotatedY - window.editor.stickerSelector.textCenterY) *
            2;
          const oldWidth = selectedObject.style.wordWrapWidth;
          const deltaWidth = newWidth - oldWidth;

          if (deltaWidth !== 0) {
            if (newWidth < 20 || height < 20) return;
            selectedObject.style.wordWrapWidth = newWidth;
            window.editor.stickerSelector.updateSelector();
            let style = selectedObject.style;
            style.wordWrapWidth = newWidth;
            selectedObject.updateText(true);
            let fontSize = style.fontSize;
            if (isNaN(fontSize)) {
              fontSize = 50;
            }
            if (typeof fontSize.replace === "function") {
              fontSize = fontSize.replace("px", "");
              fontSize = parseInt(fontSize, 10);
            }
            fontSize += deltaWidth * 0.12;
            if (fontSize > 1) style.fontSize = fontSize;
            selectedObject.updateText(true);
          }
        }
      } else {
        if (LeftMenuStore.get() !== "cutout") {
          if (selectedObject && selectedObject.scaling) {
            if (selectedObject instanceof PIXI.Text) {
              newWidth = Math.abs(event.data.global.x - selectedObject.x) * 2;
              var newHeight =
                Math.abs(event.data.global.y - selectedObject.y) * 2;
              if (newWidth < 20 || newHeight < 20) return;

              var lines = selectedObject.text.split("\n");
              style = selectedObject.style;
              style.fontSize = (newHeight * 2) / lines.length + "px ";
              style.wordWrapWidth = newWidth;
              selectedObject.style = style;
              window.editor.stickerSelector.updateSelector();
            } else {
              tempX = event.data.global.x / stage.scale.x - selectedObject.x;
              tempY = event.data.global.y / stage.scale.y - selectedObject.y;

              rotatedX =
                tempX * Math.cos(selectedObject.rotation) +
                tempY * Math.sin(selectedObject.rotation);

              unRotatedX = rotatedX + selectedObject.x;

              newWidth = Math.abs(unRotatedX - selectedObject.x) * 2;
              height = window.editor.stickerSelector.calculateGetScaledHeight(
                selectedObject.width,
                selectedObject.height,
                newWidth
              );
              if (newWidth < 10 || height < 10) return;
              selectedObject.width = newWidth;
              selectedObject.height = height;
              window.editor.stickerSelector.updateSelector();
              window.editor.resizeText(selectedObject);
              if (selectedObject.crop) {
                var mask = new PIXI.Graphics();
                mask.beginFill(0xffffff, 1);
                mask.drawRect(
                  selectedObject.x - selectedObject.width / 2,
                  selectedObject.y - selectedObject.height / 2,
                  selectedObject.width,
                  selectedObject.height
                );
                mask.endFill();
                baseObject.mask = mask;
              }
            }
            window.editor.autoSave();
          }
        }
      }
    },
    onHeightStart: function (event) {
      event.stopPropagation();
      if (selectedObject) {
        if (selectedObject && selectedObject.locked) return;
        isDragging = true;
        if (event.currentTarget.MiddleTop) {
          window.editor.stickerSelector.MiddleTop.tint = 0x006dff;
          window.editor.stickerSelector.MiddleLeft.visible = false;
          window.editor.stickerSelector.MiddleRight.visible = false;
          window.editor.stickerSelector.rotate.visible = false;
          window.editor.stickerSelector.leftTop.visible = false;
          window.editor.stickerSelector.rightTop.visible = false;
          window.editor.stickerSelector.LeftBottom.visible = false;
          window.editor.stickerSelector.RightBottom.visible = false;
          window.editor.stickerSelector.leftText.visible = false;
          window.editor.stickerSelector.rightText.visible = false;
          window.editor.stickerSelector.MiddleBottom.visible = false;
          window.editor.stickerSelector.MiddleTop.visible = true;
        } else if (event.currentTarget.MiddleBottom) {
          window.editor.stickerSelector.MiddleBottom.tint = 0x006dff;
          window.editor.stickerSelector.MiddleLeft.visible = false;
          window.editor.stickerSelector.MiddleRight.visible = false;
          window.editor.stickerSelector.rotate.visible = false;
          window.editor.stickerSelector.leftTop.visible = false;
          window.editor.stickerSelector.rightTop.visible = false;
          window.editor.stickerSelector.LeftBottom.visible = false;
          window.editor.stickerSelector.RightBottom.visible = false;
          window.editor.stickerSelector.leftText.visible = false;
          window.editor.stickerSelector.rightText.visible = false;
          window.editor.stickerSelector.MiddleBottom.visible = true;
          window.editor.stickerSelector.MiddleTop.visible = false;
        }
        selectedObject.data = event.data;
        selectedObject.skewHeight = true;
        selectedObject.skewWidth = false;
        selectedObject.dragging = false;
        selectedObject.scaling = false;
        if (selectedObject instanceof PIXI.Container) {
          window.editor.scaleStartHeight = selectedObject._height;
          window.editor.stickerSelector.originalY =
            selectedObject.y + selectedObject._height / 2;
          window.editor.scaleStartWidth = selectedObject._width;
          window.editor.scaleStartHeight = selectedObject._height;
          window.editor.stickerSelector.originalX =
            selectedObject._width - event.data.global.x - selectedObject.x;
        } else {
          window.editor.scaleStartHeight = selectedObject.height;
          window.editor.stickerSelector.originalY =
            selectedObject.height -
            Math.abs(event.data.global.y - selectedObject.y) * 2;
          window.editor.scaleStartWidth = selectedObject.width;
          window.editor.scaleStartHeight = selectedObject.height;
          window.editor.stickerSelector.originalX =
            selectedObject.width - event.data.global.x - selectedObject.x;
        }
        var edp = document.getElementById("editor-properties");
        edp.style.display = "none";
      }
    },
    onHeightEnd: function (event) {
      if (selectedObject) {
        if (selectedObject && selectedObject.locked) return;
        isDragging = false;
        window.editor.stickerSelector.MiddleBottom.tint = 0xffffff;
        window.editor.stickerSelector.MiddleTop.tint = 0xffffff;
        window.editor.stickerSelector.MiddleLeft.visible = true;
        window.editor.stickerSelector.MiddleRight.visible = true;
        window.editor.stickerSelector.rotate.visible = true;
        window.editor.stickerSelector.leftTop.visible = true;
        window.editor.stickerSelector.rightTop.visible = true;
        window.editor.stickerSelector.LeftBottom.visible = true;
        window.editor.stickerSelector.RightBottom.visible = true;
        window.editor.stickerSelector.leftText.visible = true;
        window.editor.stickerSelector.rightText.visible = true;
        window.editor.stickerSelector.MiddleBottom.visible = true;
        window.editor.stickerSelector.MiddleTop.visible = true;

        var id = selectedObject.id;
        var startheight = window.editor.scaleStartHeight;
        var finishheight = selectedObject.height;
        selectedObject.skewHeight = false;
        selectedObject.skewWidth = false;
        selectedObject.scaling = false;
        selectedObject.data = null;
        window.editor.CommandManager.execute({
          execute: function () {
            var sticker = window.editor.getStickerById(id);
            if (!(sticker instanceof PIXI.Text)) {
              sticker.height = finishheight;
              window.editor.redrawSvg(sticker, sticker.width, sticker.height);
            }

            window.editor.positionEdp(sticker);
          },
          unexecute: function () {
            var sticker = window.editor.getStickerById(id);
            sticker.height = startheight;
          },
        });
      }
    },
    onHeightMove: function (event) {
      if (selectedObject && selectedObject.skewHeight) {
        var offsetX = Math.pow(
          Math.abs(event.data.global.x / stage.scale.x - selectedObject.x),
          2
        );
        var offsetY = Math.pow(
          Math.abs(event.data.global.y / stage.scale.y - selectedObject.y),
          2
        );
        var radius = Math.sqrt(offsetX + offsetY);

        if (radius * 2 < 20) return;

        selectedObject.height = radius * 2;
        window.editor.stickerSelector.updateSelector();
        window.editor.resizeText(selectedObject);
        if (selectedObject.crop) {
          var mask = new PIXI.Graphics();
          mask.beginFill(0xffffff, 1);
          mask.drawRect(
            selectedObject.x - selectedObject.width / 2,
            selectedObject.y - selectedObject.height / 2,
            selectedObject.width,
            selectedObject.height
          );
          mask.endFill();
          baseObject.mask = mask;
        }
        if (selectedObject.childId) {
          var sticker = window.editor.getStickerById(selectedObject.childId);
          if (sticker && sticker.textBackground) {
            sticker.clear();
            sticker.position.x = selectedObject.position.x;
            sticker.position.y = selectedObject.position.y;
            sticker.beginFill(selectedObject.backgroundColor, 1);
            sticker.drawRect(
              0 - selectedObject.style.wordWrapWidth / 2,
              0 - selectedObject.height / 2,
              selectedObject.style.wordWrapWidth,
              selectedObject.height
            );
            sticker.endFill();
          }
        }
        window.editor.autoSave();
      }
    },
    onMouseOver: function (e) {
      const handle = e.currentTarget;
      if (handle.LeftBottom) {
        window.editor.stickerSelector.LeftBottom.tint = 0x006dff;
      } else if (handle.RightBottom) {
        window.editor.stickerSelector.RightBottom.tint = 0x006dff;
      } else if (handle.rightTop) {
        window.editor.stickerSelector.rightTop.tint = 0x006dff;
      } else if (handle.leftTop) {
        window.editor.stickerSelector.leftTop.tint = 0x006dff;
      } else if (handle.rightText) {
        window.editor.stickerSelector.rightText.tint = 0x006dff;
      } else if (handle.leftText) {
        window.editor.stickerSelector.leftText.tint = 0x006dff;
      } else if (handle.MiddleRight) {
        window.editor.stickerSelector.MiddleRight.tint = 0x006dff;
      } else if (handle.MiddleLeft) {
        window.editor.stickerSelector.MiddleLeft.tint = 0x006dff;
      } else if (handle.MiddleBottom) {
        window.editor.stickerSelector.MiddleBottom.tint = 0x006dff;
      } else if (handle.MiddleTop) {
        window.editor.stickerSelector.MiddleTop.tint = 0x006dff;
      } else if (handle.rotate) {
        window.editor.stickerSelector.rotate.tint = 0x006dff;
      }
    },

    onMouseOut: function (e) {
      const handle = e.currentTarget;
      if (!isDragging) {
        if (handle.LeftBottom) {
          window.editor.stickerSelector.LeftBottom.tint = 0xffffff;
        } else if (handle.RightBottom) {
          window.editor.stickerSelector.RightBottom.tint = 0xffffff;
        } else if (handle.rightTop) {
          window.editor.stickerSelector.rightTop.tint = 0xffffff;
        } else if (handle.leftTop) {
          window.editor.stickerSelector.leftTop.tint = 0xffffff;
        } else if (handle.rightText) {
          window.editor.stickerSelector.rightText.tint = 0xffffff;
        } else if (handle.leftText) {
          window.editor.stickerSelector.leftText.tint = 0xffffff;
        } else if (handle.MiddleRight) {
          window.editor.stickerSelector.MiddleRight.tint = 0xffffff;
        } else if (handle.MiddleLeft) {
          window.editor.stickerSelector.MiddleLeft.tint = 0xffffff;
        } else if (handle.MiddleBottom) {
          window.editor.stickerSelector.MiddleBottom.tint = 0xffffff;
        } else if (handle.MiddleTop) {
          window.editor.stickerSelector.MiddleTop.tint = 0xffffff;
        } else if (handle.rotate) {
          window.editor.stickerSelector.rotate.tint = 0xffffff;
        }
      }
    },
    onWidthStart: function (event) {
      event.stopPropagation();
      if (!selectedObject) return;
      if (selectedObject && selectedObject.locked) return;
      isDragging = true;
      window.editor.stickerSelector.currentSelector = event.currentTarget;
      if (event.currentTarget.MiddleLeft) {
        window.editor.stickerSelector.MiddleLeft.tint = 0x006dff;
        window.editor.stickerSelector.MiddleLeft.visible = true;
        window.editor.stickerSelector.MiddleRight.visible = false;
        window.editor.stickerSelector.rotate.visible = false;
        window.editor.stickerSelector.leftTop.visible = false;
        window.editor.stickerSelector.rightTop.visible = false;
        window.editor.stickerSelector.LeftBottom.visible = false;
        window.editor.stickerSelector.RightBottom.visible = false;
        window.editor.stickerSelector.leftText.visible = false;
        window.editor.stickerSelector.rightText.visible = false;
        window.editor.stickerSelector.MiddleBottom.visible = false;
        window.editor.stickerSelector.MiddleTop.visible = false;
      } else if (event.currentTarget.MiddleRight) {
        window.editor.stickerSelector.MiddleRight.tint = 0x006dff;
        window.editor.stickerSelector.MiddleRight.visible = true;
        window.editor.stickerSelector.MiddleLeft.visible = false;
        window.editor.stickerSelector.rotate.visible = false;
        window.editor.stickerSelector.leftTop.visible = false;
        window.editor.stickerSelector.rightTop.visible = false;
        window.editor.stickerSelector.LeftBottom.visible = false;
        window.editor.stickerSelector.RightBottom.visible = false;
        window.editor.stickerSelector.leftText.visible = false;
        window.editor.stickerSelector.rightText.visible = false;
        window.editor.stickerSelector.MiddleBottom.visible = false;
        window.editor.stickerSelector.MiddleTop.visible = false;
      } else if (event.currentTarget.leftText) {
        window.editor.stickerSelector.leftText.tint = 0x006dff;
        window.editor.stickerSelector.MiddleRight.visible = false;
        window.editor.stickerSelector.MiddleLeft.visible = false;
        window.editor.stickerSelector.rotate.visible = false;
        window.editor.stickerSelector.leftTop.visible = false;
        window.editor.stickerSelector.rightTop.visible = false;
        window.editor.stickerSelector.LeftBottom.visible = false;
        window.editor.stickerSelector.RightBottom.visible = false;
        window.editor.stickerSelector.leftText.visible = true;
        window.editor.stickerSelector.rightText.visible = false;
        window.editor.stickerSelector.MiddleBottom.visible = false;
        window.editor.stickerSelector.MiddleTop.visible = false;
      } else if (event.currentTarget.rightText) {
        window.editor.stickerSelector.rightText.tint = 0x006dff;
        window.editor.stickerSelector.MiddleRight.visible = false;
        window.editor.stickerSelector.MiddleLeft.visible = false;
        window.editor.stickerSelector.rotate.visible = false;
        window.editor.stickerSelector.leftTop.visible = false;
        window.editor.stickerSelector.rightTop.visible = false;
        window.editor.stickerSelector.LeftBottom.visible = false;
        window.editor.stickerSelector.RightBottom.visible = false;
        window.editor.stickerSelector.leftText.visible = false;
        window.editor.stickerSelector.rightText.visible = true;
        window.editor.stickerSelector.MiddleBottom.visible = false;
        window.editor.stickerSelector.MiddleTop.visible = false;
      }

      selectedObject.data = event.data;
      selectedObject.skewHeight = false;
      selectedObject.skewWidth = true;
      selectedObject.scaling = false;
      selectedObject.dragging = false;
      selectedObject.rightPoint = new PIXI.Point(
        window.editor.stickerSelector.MiddleRight.parent.x +
          window.editor.stickerSelector.MiddleRight.x,
        window.editor.stickerSelector.MiddleRight.parent.y +
          window.editor.stickerSelector.MiddleRight.y
      );
      window.editor.scaleStartWidth = selectedObject.width;
      window.editor.scaleStartHeight = selectedObject.height;
      window.editor.stickerSelector.originalX =
        selectedObject.width -
        Math.abs(event.data.global.x - selectedObject.x) * 2;
      var edp = document.getElementById("editor-properties");
      edp.style.display = "none";
    },
    onWidthEnd: function (event) {
      if (selectedObject && selectedObject.id) {
        if (selectedObject && selectedObject.locked) return;
        isDragging = false;
        window.editor.stickerSelector.MiddleLeft.tint = 0xffffff;
        window.editor.stickerSelector.MiddleRight.tint = 0xffffff;
        window.editor.stickerSelector.leftText.tint = 0xffffff;
        window.editor.stickerSelector.rightText.tint = 0xffffff;

        window.editor.stickerSelector.MiddleLeft.visible = true;
        window.editor.stickerSelector.MiddleRight.visible = true;
        window.editor.stickerSelector.rotate.visible = true;
        window.editor.stickerSelector.leftTop.visible = true;
        window.editor.stickerSelector.rightTop.visible = true;
        window.editor.stickerSelector.LeftBottom.visible = true;
        window.editor.stickerSelector.RightBottom.visible = true;
        window.editor.stickerSelector.leftText.visible = true;
        window.editor.stickerSelector.rightText.visible = true;
        window.editor.stickerSelector.MiddleBottom.visible = true;
        window.editor.stickerSelector.MiddleTop.visible = true;

        var id = selectedObject.id;
        var startwidth = window.editor.scaleStartWidth;
        var finishwidth = selectedObject.width;
        selectedObject.scaling = false;
        selectedObject.skewHeight = false;
        selectedObject.skewWidth = false;
        selectedObject.data = null;
        /*                if(selectedObject.style.align === "left") {
                                    var offset = selectedObject.pivot.x - (selectedObject.style.wordWrapWidth / 2);
                                    selectedObject.pivot.set(selectedObject.style.wordWrapWidth / 2, 0);
                                    selectedObject.position.x -= offset;
                                }
                                if(selectedObject.style.align === "right") {
                                    var offset = selectedObject.pivot.x + (selectedObject.style.wordWrapWidth / 2);
                                    selectedObject.pivot.set(0 - (selectedObject.style.wordWrapWidth / 2), 0);
                                    selectedObject.position.x -= offset;
                                }
                */
        if (selectedObject && selectedObject.updateText)
          selectedObject.updateText(true);

        window.editor.CommandManager.execute({
          execute: function () {
            var sticker = window.editor.getStickerById(id);
            if (!(sticker instanceof PIXI.Text)) {
              sticker.width = finishwidth;
              window.editor.redrawSvg(sticker, sticker.width, sticker.height);
            }
            window.editor.positionEdp(sticker);
          },
          unexecute: function () {
            var sticker = window.editor.getStickerById(id);
            sticker.width = startwidth;
          },
        });
      }
    },
    onWidthMove: function (event) {
      var offsetX;
      var offsetY;
      var radius;
      if (selectedObject && selectedObject.skewWidth) {
        if (selectedObject instanceof PIXI.Text) {
          var style = selectedObject.style;
          if (selectedObject.style.align === "left") {
            if (
              window.editor.stickerSelector.currentSelector ===
              window.editor.stickerSelector.leftText
            ) {
              offsetX = Math.pow(
                Math.abs(
                  event.data.global.x / stage.scale.x -
                    (selectedObject.rightPoint.x - selectedObject.pivot.x)
                ),
                2
              );
              offsetY = Math.pow(
                Math.abs(
                  event.data.global.y / stage.scale.y -
                    selectedObject.rightPoint.y
                ),
                2
              );
              radius = Math.sqrt(offsetX + offsetY);
            } else {
              offsetX = Math.pow(
                Math.abs(
                  event.data.global.x / stage.scale.x -
                    (selectedObject.x - selectedObject.pivot.x)
                ),
                2
              );
              offsetY = Math.pow(
                Math.abs(
                  event.data.global.y / stage.scale.y - selectedObject.y
                ),
                2
              );
              radius = Math.sqrt(offsetX + offsetY);
            }
          }
          if (selectedObject.style.align === "center") {
            offsetX = Math.pow(
              Math.abs(event.data.global.x / stage.scale.x - selectedObject.x),
              2
            );
            offsetY = Math.pow(
              Math.abs(event.data.global.y / stage.scale.y - selectedObject.y),
              2
            );
            radius = Math.sqrt(offsetX + offsetY);
          }
          if (selectedObject.style.align === "right") {
            if (
              window.editor.stickerSelector.currentSelector ===
              window.editor.stickerSelector.rightText
            ) {
              offsetX = Math.pow(
                Math.abs(
                  event.data.global.x / stage.scale.x -
                    (selectedObject.x -
                      style.wordWrapWidth -
                      selectedObject.pivot.x)
                ),
                2
              );
              offsetY = Math.pow(
                Math.abs(
                  event.data.global.y / stage.scale.y - selectedObject.y
                ),
                2
              );
              radius = Math.sqrt(offsetX + offsetY);
            } else {
              offsetX = Math.pow(
                Math.abs(
                  event.data.global.x / stage.scale.x -
                    (selectedObject.x - selectedObject.pivot.x)
                ),
                2
              );
              offsetY = Math.pow(
                Math.abs(
                  event.data.global.y / stage.scale.y - selectedObject.y
                ),
                2
              );
              radius = Math.sqrt(offsetX + offsetY);
            }
          }
          const wrapRadius =
            selectedObject.style.align === "center" ? radius * 2 : radius;
          if (wrapRadius < 20) return;
          var words = originalText.split(" ");
          let lines = [];
          for (var i = 0; i < words.length; i++) {
            var wordLength = selectedObject.context.measureText(
              words[i],
              selectedObject.style
            ).width;
            let line = "";
            if (wordLength > wrapRadius) {
              for (let j = 0; j < words[i].length; j++) {
                line = line + words[i][j];
                let testWidth = PIXI.TextMetrics.measureText(
                  line,
                  selectedObject.style
                ).width;
                if (testWidth > wrapRadius && j > 0) {
                  lines.push(line);
                  line = "";
                }
              }
              lines.push(line);
            } else {
              lines.push(words[i]);
            }
          }
          selectedObject.text = lines.join(" ");
          if (selectedObject.style.align === "center") {
            style.wordWrapWidth = wrapRadius;
          } else {
            var difference;
            var offset;
            if (selectedObject.style.align === "left") {
              difference = (radius - style.wordWrapWidth) * stage.scale.x;
              if (
                window.editor.stickerSelector.currentSelector ===
                window.editor.stickerSelector.leftText
              ) {
                selectedObject.position.x -= difference;
              }
              style.wordWrapWidth += difference;
              offset =
                selectedObject.pivot.x - selectedObject.style.wordWrapWidth / 2;
              selectedObject.pivot.set(
                selectedObject.style.wordWrapWidth / 2,
                0
              );
              selectedObject.position.x -= offset;
            }
            if (selectedObject.style.align === "right") {
              difference = radius - style.wordWrapWidth;
              if (
                window.editor.stickerSelector.currentSelector ===
                window.editor.stickerSelector.rightText
              ) {
                selectedObject.position.x += difference;
              }
              style.wordWrapWidth += difference;
              offset =
                selectedObject.pivot.x + selectedObject.style.wordWrapWidth / 2;
              selectedObject.pivot.set(
                0 - selectedObject.style.wordWrapWidth / 2,
                0
              );
              selectedObject.position.x -= offset;
            }
          }

          selectedObject.style = style;
          if (selectedObject.scale.x !== selectedObject.scale.y) {
            selectedObject.height =
              selectedObject.height / selectedObject.scale.y;
            selectedObject.updateText(true);
          }

          window.editor.stickerSelector.updateSelector();
          if (selectedObject.childId) {
            var sticker = window.editor.getStickerById(selectedObject.childId);
            if (sticker && sticker.textBackground) {
              sticker.clear();
              sticker.position.x = selectedObject.position.x;
              sticker.position.y = selectedObject.position.y;
              sticker.beginFill(selectedObject.backgroundColor, 1);
              sticker.drawRect(
                0 - selectedObject.style.wordWrapWidth / 2,
                0 - selectedObject.height / 2,
                selectedObject.style.wordWrapWidth,
                selectedObject.height
              );
              sticker.endFill();
            }
          }
        } else {
          offsetX = Math.pow(
            Math.abs(event.data.global.x / stage.scale.x - selectedObject.x),
            2
          );
          offsetY = Math.pow(
            Math.abs(event.data.global.y / stage.scale.y - selectedObject.y),
            2
          );
          radius = Math.sqrt(offsetX + offsetY);
          if (radius * 2 < 20) return;

          selectedObject.width = radius * 2;
          window.editor.stickerSelector.updateSelector();
          window.editor.resizeText(selectedObject);
          if (selectedObject.crop) {
            var mask = new PIXI.Graphics();
            mask.beginFill(0xffffff, 1);
            mask.drawRect(
              selectedObject.x - selectedObject.width / 2,
              selectedObject.y - selectedObject.height / 2,
              selectedObject.width,
              selectedObject.height
            );
            mask.endFill();
            baseObject.mask = mask;
          }
        }
        window.editor.autoSave();
      }
    },
    onRotateStart: function (event) {
      event.stopPropagation();
      if (selectedObject) {
        if (selectedObject && selectedObject.locked) return;
        window.editor.stickerSelector.rotate.tint = 0x006dff;
        isDragging = true;
        window.editor.stickerSelector.MiddleLeft.visible = false;
        window.editor.stickerSelector.MiddleRight.visible = false;
        window.editor.stickerSelector.rotate.visible = true;
        window.editor.stickerSelector.leftTop.visible = false;
        window.editor.stickerSelector.rightTop.visible = false;
        window.editor.stickerSelector.LeftBottom.visible = false;
        window.editor.stickerSelector.RightBottom.visible = false;
        window.editor.stickerSelector.leftText.visible = false;
        window.editor.stickerSelector.rightText.visible = false;
        window.editor.stickerSelector.MiddleBottom.visible = false;
        window.editor.stickerSelector.MiddleTop.visible = false;
        selectedObject.rotating = true;
        selectedObject.scaling = false;
        selectedObject.skewHeight = false;
        selectedObject.skewWidth = false;
        var edp = document.getElementById("editor-properties");
        edp.style.display = "none";
      }
    },
    onRotateMove: function (event) {
      var sticker;
      if (selectedObject && selectedObject.rotating) {
        var rotateRadian = Math.atan2(
          selectedObject.y - event.data.global.y / window.editor.getScale(),
          selectedObject.x - event.data.global.x / window.editor.getScale()
        );
        switch (rotateIconPos) {
          case "middleTop":
            selectedObject.rotation = rotateRadian - Math.PI / 2;
            break;
          case "middleBottom":
            selectedObject.rotation = rotateRadian + Math.PI / 2;
            break;
          case "middleLeft":
            selectedObject.rotation = rotateRadian;
            break;
          case "middleRight":
            selectedObject.rotation = rotateRadian - Math.PI;
            break;
          default:
            selectedObject.rotation = rotateRadian - Math.PI / 2;
            break;
        }
        var mod = selectedObject.rotation % (Math.PI / 4);
        if (Math.abs(mod) < 0.05 || Math.abs(mod) >= 0.73) {
          if (selectedObject.rotation < 0) {
            if (Math.abs(mod) >= 0.73) {
              selectedObject.rotation =
                selectedObject.rotation - (0.78398 - Math.abs(mod));
            } else {
              selectedObject.rotation = selectedObject.rotation - mod;
            }
          } else {
            if (Math.abs(mod) >= 0.73) {
              selectedObject.rotation =
                selectedObject.rotation + (0.78398 - Math.abs(mod));
            } else {
              selectedObject.rotation = selectedObject.rotation - mod;
            }
          }
        }
        if (selectedObject.childId) {
          sticker = window.editor.getStickerById(selectedObject.childId);
          if (sticker && sticker.textBackground) {
            sticker.rotation = selectedObject.rotation;
            sticker.clear();
            sticker.position.x = selectedObject.position.x;
            sticker.position.y = selectedObject.position.y;
            sticker.beginFill(selectedObject.backgroundColor, 1);
            sticker.drawRect(
              0 - selectedObject.style.wordWrapWidth / 2,
              0 - selectedObject.height / 2,
              selectedObject.style.wordWrapWidth,
              selectedObject.height
            );
            sticker.endFill();
          }
        }
        if (selectedObject.childId && !selectedObject.backgroundEnabled) {
          sticker = window.editor.getStickerById(selectedObject.childId);
          sticker.rotation = rotateRadian - 1.6;
        }

        window.editor.stickerSelector.updateSelector();
        window.editor.autoSave();
      }
    },
    onRotateEnd: function (event) {
      if (!selectedObject) return;
      if (selectedObject && selectedObject.locked) return;
      isDragging = false;
      window.editor.stickerSelector.rotate.tint = 0xffffff;
      window.editor.stickerSelector.MiddleLeft.visible = true;
      window.editor.stickerSelector.MiddleRight.visible = true;
      window.editor.stickerSelector.rotate.visible = true;
      window.editor.stickerSelector.leftTop.visible = true;
      window.editor.stickerSelector.rightTop.visible = true;
      window.editor.stickerSelector.LeftBottom.visible = true;
      window.editor.stickerSelector.RightBottom.visible = true;
      window.editor.stickerSelector.leftText.visible = true;
      window.editor.stickerSelector.rightText.visible = true;
      window.editor.stickerSelector.MiddleBottom.visible = true;
      window.editor.stickerSelector.MiddleTop.visible = true;

      var id = selectedObject.id;
      selectedObject.rotating = false;
      selectedObject.scaling = false;
      selectedObject.skewHeight = false;
      selectedObject.skewWidth = false;
      selectedObject.data = null;
      var finishRotation = selectedObject.rotation;
      var startRotation = window.editor.rotateStart;
      window.editor.CommandManager.execute({
        execute: function () {
          var sticker = window.editor.getStickerById(id);
          sticker.rotation = finishRotation;
          window.editor.positionEdp(sticker);
        },
        unexecute: function () {
          var sticker = window.editor.getStickerById(id);
          sticker.rotation = startRotation;
        },
      });
    },
    calculateGetScaledHeight: function (width, height, newWidth) {
      return (height * newWidth) / width;
    },
    calculateGetScaledWidth: function (width, height, newHeight) {
      return (width * newHeight) / height;
    },
  },
  resizeText: function (parent) {
    if (parent && parent.childId) {
      var sticker = window.editor.getStickerById(parent.childId);
      if (!sticker.textBackground) {
        if (sticker.text !== null && sticker.text !== " ") {
          var style = sticker.style;
          style.wordWrapWidth = parent.width * sticker.wrapPercent;
          sticker.updateText(true);
          var fontSize = style.fontSize;
          if (isNaN(fontSize)) {
            fontSize = 50;
          }
          if (typeof fontSize.replace === "function") {
            fontSize = fontSize.replace("px", "");
            fontSize = parseInt(fontSize, 10);
          }
          var loopCounter = 0;
          while (sticker.width > parent.width * sticker.wrapPercent) {
            loopCounter++;
            if (loopCounter === 50) break;
            fontSize--;
            style.fontSize = fontSize;
            sticker.updateText(true);
          }
          var lastWidth = 0;
          loopCounter = 0;
          while (
            sticker.height > parent.height * 0.5 &&
            sticker.width <= parent.width * sticker.wrapPercent
          ) {
            loopCounter++;
            if (loopCounter === 50) break;
            fontSize--;
            style.fontSize = fontSize;
            sticker.updateText(true);
            if (lastWidth === sticker.width) break;
            lastWidth = sticker.width;
          }
          loopCounter = 0;
          while (
            sticker.height < parent.height * 0.5 &&
            sticker.width <= parent.width * sticker.wrapPercent
          ) {
            loopCounter++;
            if (loopCounter === 50) break;
            fontSize++;
            style.fontSize = Math.abs(fontSize);
            sticker.updateText(true);
            if (lastWidth === sticker.width) break;
            lastWidth = sticker.width;
          }
        }
      }
    }
  },
  showOutline: function (value) {
    if (renderer) {
      if (value) {
        renderer.view.style.outline = "solid 3px #006dff";
        renderer.view.style.border = "solid 3px #1c2126";
        renderer.view.style.borderRadius = 5 + "px";
        renderer.view.style.marginTop = -3 + "px";
      } else {
        renderer.view.style.borderRadius = 0 + "px";
        renderer.view.style.outline = "none";
        renderer.view.style.border = "none";
        renderer.view.style.marginTop = 0 + "px";
      }
    }
  },
  addTextStyle: function (project) {
    let self = this;
    var newScale = 1;
    if (baseObject != null) {
      const newSize = window.editor.scaleToFit(
        baseObject.width,
        baseObject.height,
        project.renderer.width,
        project.renderer.height
      );
      newScale = newSize.width / project.stage.children[0].width;
      self.setupProjectItems(project, newScale, false);
    } else {
      EditorStore.setShowSpinner(false);
    }
  },
  hideGizmo: function (value) {
    const edp = document.getElementById("editor-properties");
    if (value) {
      if (edp) edp.style.display = "none";
      this.stickerSelector.border.clear();
      this.stickerSelector.rotate.visible = false;
      this.stickerSelector.leftText.visible = false;
      this.stickerSelector.rightText.visible = false;
      this.stickerSelector.leftTop.visible = false;
      this.stickerSelector.rightTop.visible = false;
      this.stickerSelector.LeftBottom.visible = false;
      this.stickerSelector.RightBottom.visible = false;
      this.stickerSelector.MiddleTop.visible = false;
      this.stickerSelector.MiddleBottom.visible = false;
      this.stickerSelector.MiddleLeft.visible = false;
      this.stickerSelector.MiddleRight.visible = false;
    } else {
      if (edp) edp.style.display = "block";
      this.stickerSelector.rotate.visible = true;
      this.stickerSelector.rotate.visible = true;
      this.stickerSelector.leftText.visible = true;
      this.stickerSelector.rightText.visible = true;
      this.stickerSelector.leftTop.visible = true;
      this.stickerSelector.rightTop.visible = true;
      this.stickerSelector.LeftBottom.visible = true;
      this.stickerSelector.RightBottom.visible = true;
      this.stickerSelector.MiddleTop.visible = true;
      this.stickerSelector.MiddleBottom.visible = true;
      this.stickerSelector.MiddleLeft.visible = true;
      this.stickerSelector.MiddleRight.visible = true;
      this.stickerSelector.updateSelector();
    }
  },
  setOriginalText: function (value) {
    originalText = value;
  },
  setColorWindowShow: function (value) {
    colorWindowShow = value;
  },
  getColorWindowShow: function () {
    return colorWindowShow;
  },
};



if (imageUrl) {
  // Set the editor to "edit" mode (if needed)
  EditorStore.setEditorType("edit");
  

  // Call the same function that handles image selection manually
  window.editor.initPhoto(imageUrl, function () {
    console.log("Image loaded into edit mode and canvas adjusted.");
    EditorActionCreators.ShowFilePicker(false); 
  });
      
}



document.addEventListener("keydown", window.editor.onCutoutKeyDown, false);
EditorStore.setShowSpinner(false);
