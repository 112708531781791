import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import EditorActionCreators from "../actions/EditorActionCreators";
import Autosuggest from "react-autosuggest";
import UserStore from "../stores/UserStore";
import JSONUtils from "../utils/JSONUtils";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import ProCrownBadge from "../images/editor/pro-crown-badge.png";
import ProGet from "../components/ProGet.react";
import ProGetSave from "../components/ProGetSave.react";
import ProTeaser from "../components/ProTeaser.react";

// Imports
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import SkeletonLoading from "../components/SkeletonLoadingImage";

function getStateFromStores() {
  return {
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro()
  };
}

const SearchPage2 = () => {
  const { keyword } = useParams();
  const navigate = useNavigate();

  // ------------------- Local states -------------------
  const [results, setResults] = useState([]);
  const [activeTab, setActiveTab] = useState("templates");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [storeState, setStoreState] = useState(getStateFromStores());

  // For the new suggestions logic
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState(keyword || "");

  const suggestionSelected = useRef(false);
  const [featuredBackgrounds, setFeaturedBackgrounds] = useState([]);

  // Add UserStore change listener
  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setStoreState(getStateFromStores());
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchFeaturedBackgrounds();
        setFeaturedBackgrounds(data);
      } catch (err) {
        console.error("Failed to fetch featured backgrounds:", err);
      }
    })();
  }, []);

  useEffect(() => {
    // Fallback search for templates
    fetchTemplates(keyword ? keyword.toLowerCase() : "", true);
  }, [keyword]);

  // ==================== FETCH METHODS (unchanged) ====================
  const fetchTemplates = (term, fallbackMode = false) => {
    setActiveTab("templates");
    setIsLoading(true);
    setError(null);

    const lowerTerm = term.toLowerCase();

    return fetch(
      `${process.env.REACT_APP_SERVER_API}/projectTag/tag/${lowerTerm}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        if (Array.isArray(data) && data.length > 0) {
          // Mark some templates as PRO (e.g., every third item)
          const processedData = data.map((item, index) => ({
            ...item,
            isProOnly: index >= 3,
          }));
          setResults(processedData);
        } else if (fallbackMode) {
          return fetchPiZapBackgrounds(lowerTerm, true);
        } else {
          setResults([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const fetchPiZapBackgrounds = async (term, fallbackMode = false) => {
    setActiveTab("pizap");
    setIsLoading(true);
    setError(null);

    const lowerTerm = term.toLowerCase();

    try {
      let finalResults = [];
      for (const category of featuredBackgrounds) {
        if (!Array.isArray(category.pizapAssets)) continue;
        const matched = category.pizapAssets.filter((item) => {
          if (!Array.isArray(item.tags)) return false;
          return item.tags.some((tag) =>
            typeof tag === "string"
              ? tag.toLowerCase().includes(lowerTerm)
              : false
          );
        });
        finalResults = finalResults.concat(matched);
      }

      if (finalResults.length > 0) {
        // Mark some backgrounds as PRO (e.g., every fourth item)
        const processedResults = finalResults.map((item, index) => ({
          ...item,
          isProOnly: index >= 3,
        }));
        setResults(processedResults);
      } else if (fallbackMode) {
        await fetchStickers(lowerTerm, true);
      } else {
        setResults([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  const fetchStickers = (term, fallbackMode = false) => {
    setActiveTab("stickers");
    setIsLoading(true);
    setError(null);

    const lowerTerm = encodeURIComponent(term.toLowerCase());

    return fetch(
      `${process.env.REACT_APP_SERVER_API}/pizapAsset/search/${lowerTerm}*`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          // Mark some stickers as PRO (e.g., every second item)
          const processedData = data.map((item, index) => ({
            ...item,
            isProOnly: index >= 3,
          }));
          setResults(processedData);
        } else if (fallbackMode) {
          return fetchPixabay(term, true);
        } else {
          setResults([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err?.message || "Sticker fetch error");
        setIsLoading(false);
      });
  };

  const fetchPixabay = (term, fallbackMode = false) => {
    setActiveTab("pixabay");
    setIsLoading(true);
    setError(null);

    const lowerTerm = encodeURIComponent(term.toLowerCase());

    JSONUtils.getJSON(
      `https://www.pizap.com/pixabay/search?q=${lowerTerm}`,
      "GET",
      null,
      (data) => {
        if (data?.hits?.length > 0) {
          // Mark some Pixabay items as PRO (e.g., every fifth item)
          const processedData = data.hits.map((item, index) => ({
            ...item,
            isProOnly: index >= 20,
          }));
          setResults(processedData);
        } else if (fallbackMode) {
          fetchUnsplash(term, true);
        } else {
          setResults([]);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        setError(err?.message || "Pixabay fetch error");
      }
    );
  };

  const fetchUnsplash = (term, fallbackMode = false) => {
    setActiveTab("unsplash");
    setIsLoading(true);
    setError(null);

    const lowerTerm = encodeURIComponent(term.toLowerCase());

    JSONUtils.getJSON(
      `${process.env.REACT_APP_SERVER_API}/unsplash?query=${lowerTerm}`,
      "GET",
      null,
      (data) => {
        if (data?.results?.length > 0) {
          // Mark some Unsplash items as PRO (e.g., every third item)
          const processedData = data.results.map((item, index) => ({
            ...item,
            isProOnly: index >= 20,
          }));
          setResults(processedData);
        } else {
          setResults([]);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        setError(err?.message || "Unsplash fetch error");
      }
    );
  };

  const fetchFeaturedBackgrounds = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_API}/pizapAsset/featuredBackground`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    );
    const data = await res.json();
    return data;
  };

  // ------------------- HELPER: THUMBNAILS & Editor usage -------------------
  const getThumbnailUrl = (item) => {
    if (item.thumbnail) return item.thumbnail; // Templates, piZap
    if (item.previewURL) return item.previewURL; // Pixabay
    if (item.urls && item.urls.thumb) return item.urls.thumb; // Unsplash
    return "";
  };

  const handleItemClick = (item) => {
    // If the item is PRO and user is not PRO, show the pro popup
    if (item.isProOnly && !storeState.isPro) {
      console.log("Pro item clicked, triggering pro popup");
      UserActionCreators.showProGet(true);
      return;
    }

    // piZap Templates => go to /app/?project=...
    if (activeTab === "templates" && item.type === "PROJECT" && item.src) {
      navigate(`/app/?project=${encodeURIComponent(item.src)}`);
      return;
    }

    // piZap Stickers
    if (activeTab === "stickers") {
      // Get the current project parameter from the URL (if available)
      const currentProject = new URLSearchParams(window.location.search).get("project") || "";
      // Use the sticker URL from the item
      const stickerUrl = item.url || item.src;
      // Build an absolute URL with both project and sticker parameters
      const absoluteUrl = `https://www.pizap.com/app/?project=https://www.pizap.com/apiv2/projects/pizaptemplates/6cf08491-3a27-6c10-6010-e7afc370f701.pizap&sticker=${encodeURIComponent(stickerUrl)}`;
      
      window.location.href = absoluteUrl;
      return;
    }
    // Otherwise => backgrounds or images
    navigate("/app/?type=edit");
    setTimeout(() => {
      const source =
        item.url ||
        item.fullHDURL ||
        (item.urls && item.urls.regular) ||
        item.src;

      window.editor.addPicture(source, null, null, () => {
        console.log("Image loaded");
      });
    }, 500);
  };

  // ------------------- AUTOSUGGEST LOGIC (unchanged) -------------------
  const getSuggestions = async (value) => {
    const lowerValue = value.toLowerCase();
    let items = [];

    // piZap Templates
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_API}/pizapTemplate/searchTag/${encodeURIComponent(
          lowerValue
        )}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      );
      const results = await res.json();
      if (Array.isArray(results) && results.length > 0) {
        items.push({ title: "piZap Templates", items: results });
      }
    } catch (err) {
      console.error("getSuggestions -> Templates error:", err);
    }

    // Images & Backgrounds
    let bgs = [];
    featuredBackgrounds.forEach((cat) => {
      if (cat.name) {
        bgs.push(cat.name);
      }
    });
    const resBgs = bgs.filter((item) =>
      item.toLowerCase().includes(lowerValue)
    );
    if (resBgs.length > 0) {
      items.push({ title: "Images & Backgrounds", items: resBgs });
    }

    // piZap Stickers
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_API}/pizapAsset/searchTag/${encodeURIComponent(
          lowerValue
        )}*`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      );
      const results = await res.json();
      if (Array.isArray(results) && results.length > 0) {
        items.push({ title: "Stickers", items: results });
      }
    } catch (err) {
      console.error("getSuggestions -> Stickers error:", err);
    }

    return items;
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (!value.trim()) {
      setSuggestions([]);
      return;
    }
    const res = await getSuggestions(value);
    setSuggestions(res);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const renderSectionTitle = (section) => section.title;
  const getSectionSuggestions = (section) => section.items;

  const onSuggestionSelected = (event, { suggestion, sectionIndex }) => {
    suggestionSelected.current = true;
    const currentSection = suggestions[sectionIndex].title;

    if (currentSection === "piZap Templates") {
      navigate(`/app/templateSearch/${encodeURIComponent(suggestion)}`);
    } else if (currentSection === "Images & Backgrounds") {
      navigate(`/app/templateSearch/${encodeURIComponent(suggestion)}`, {
        state: {
          activeTab: "images_backgrounds",
          subTab: "pizap",
        },
      });
    } else if (currentSection === "Stickers") {
      navigate(`/app/templateSearch/${encodeURIComponent(suggestion)}`, {
        state: {
          activeTab: "stickers",
          subTab: "",
        },
      });
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      if (!suggestionSelected.current) {
        // fallback search
        fetchTemplates(searchValue, true);
      }
      suggestionSelected.current = false;
    }
  };

  const inputProps = {
    placeholder: "Search something...",
    value: searchValue,
    onChange: (e, { newValue }) => setSearchValue(newValue),
    onKeyDown: onKeyDownHandler,
  };

  // ------------------- BASIC STYLES (unchanged) -------------------
  const topBarStyle = {
    background: "#222",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const leftStyle = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  };
  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  };

  // ------------------- RENDERING -------------------
  return (
    <div style={{ background: "#1c1c1c", minHeight: "100vh", color: "#fff" }}>
      {/* TOP NAV */}
      <div style={topBarStyle}>
        <div style={leftStyle}>
          <a
            href="/app/start"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            &lt; Home
          </a>
        </div>

        <div style={centerStyle}>
          {/* AUTOSUGGEST SEARCH */}
          <div style={{ width: "240px", position: "relative" }}>
            <Autosuggest
              multiSection={true}
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={inputProps}
            />
          </div>

          {/* TABS (unchanged order) */}
          <div style={{ display: "flex", gap: "12px", marginTop: "8px" }}>
            <button
              onClick={() => fetchTemplates(searchValue, false)}
              style={{
                background: activeTab === "templates" ? "#006dff" : "#444",
                color: "#fff",
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
            >
              piZap Templates
            </button>
            <button
              onClick={() => fetchPiZapBackgrounds(searchValue, false)}
              style={{
                background: activeTab === "pizap" ? "#006dff" : "#444",
                color: "#fff",
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
            >
              piZap Backgrounds
            </button>
            <button
              onClick={() => fetchStickers(searchValue, false)}
              style={{
                background: activeTab === "stickers" ? "#006dff" : "#444",
                color: "#fff",
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
            >
              piZap Stickers
            </button>
            <button
              onClick={() => fetchPixabay(searchValue, false)}
              style={{
                background: activeTab === "pixabay" ? "#006dff" : "#444",
                color: "#fff",
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Pixabay
            </button>
            <button
              onClick={() => fetchUnsplash(searchValue, false)}
              style={{
                background: activeTab === "unsplash" ? "#006dff" : "#444",
                color: "#fff",
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Unsplash
            </button>
          </div>
        </div>

        <div></div>
      </div>

      {/* CONTENT with Masonry */}
      <div style={{ padding: "20px" }}>
        {isLoading ? (
          /* Show skeleton placeholders in a Masonry */
          <ResponsiveMasonry
            className="search-masonry"
            columnsCountBreakPoints={{
              350: 2,
              750: 3,
              900: 4,
              1024: 5,
              1200: 7,
            }}
          >
            <Masonry gutter="16px">
              {/* Add as many placeholders as you like */}
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
            </Masonry>
          </ResponsiveMasonry>
        ) : error ? (
          <div style={{ color: "red", marginBottom: "16px" }}>
            Error: {error}
          </div>
        ) : results.length === 0 ? (
            <div
            style={{
              color: "#fff",
              marginBottom: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50px"
            }}
          >
            No results
          </div>
        ) : (
          /* Normal rendering of results in a Masonry layout */
          <ResponsiveMasonry
            className="search-masonry"
            columnsCountBreakPoints={{
              350: 2,
              750: 3,
              900: 4,
              1024: 5,
              1200: 7,
            }}
          >
            <Masonry gutter="16px">
              {results.map((item, idx) => {
                const thumb = getThumbnailUrl(item);
                if (!thumb) return null;

                return (
                  <div
                    key={idx}
                    onClick={() => handleItemClick(item)}
                    style={{
                      borderRadius: "4px",
                      overflow: "hidden",
                      cursor: "pointer",
                      maxWidth: activeTab === "stickers" ? "100px" : "initial",
                      position: "relative", // For positioning the crown icon
                    }}
                  >
                    <img
                      src={thumb}
                      alt={keyword}
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                      }}
                    />
                    
                    {/* PRO Crown Badge using existing image */}
                    {item.isProOnly && !storeState.isPro && (
                      <img
                        src={ProCrownBadge}
                        className="pro-overlay"
                        alt="pro-overlay"
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          pointerEvents: "none",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        )}
      </div>

      {/* Render the ProGet, ProGetSave, and ProTeaser popups conditionally */}
      {storeState.showProGet && <ProGet />}
      {storeState.showProGetSave && <ProGetSave />}
      {storeState.showPro && <ProTeaser />}
    </div>
  );
};

export default SearchPage2;