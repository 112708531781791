import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import UserActionCreators from "../actions/UserActionCreators";
import EditorStore from "../stores/EditorStore";
import { useGoogleLogin } from "@react-oauth/google";

const LoginMain = () => {
  let auth2;

  useEffect(() => {
    // Incognito detection using the temporary filesystem API
    const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
    if (!fs) {
      // API not available – cannot determine reliably
      return;
    }
    fs(
      window.TEMPORARY,
      100,
      () => {
        // Success: likely not incognito; do nothing or handle accordingly
      },
      () => {
        // Error: possibly incognito mode, so show an alert
        alert("It looks like you're using INCOGNITO MODE! Google and Facebook login don't work on Incognito mode unless you allow third party cookies, please use a standard browser mode to create an account");
      }
    );
  }, []);


  useEffect(() => {
    if (window.gapi && window.gapi.auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: "369311205870.apps.googleusercontent.com",
        scope: "profile",
        fetch_basic_profile: false,
      });
      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(signinChanged);
      // Listen for changes to current user.
      auth2.currentUser.listen(userChanged);
    }
  }, []);

  const _facebookClick = (event) => {
    if (EditorStore.isElectron()) {
      window.FBLogin();
    } else {
      window.FB.login(
        function (response) {
          if (response.status === "connected") {
            var myData = JSON.stringify({
              facebookToken: response.authResponse.accessToken,
            });
            UserActionCreators.logFacebookIn(myData);
          } else if (response.status === "not_authorized") {
            //document.getElementById('status').innerHTML = 'Please log into this app.';
          } else {
            //document.getElementById('status').innerHTML = 'Please log into Facebook.';
          }
        },
        { scope: "email" }
      );
    }
  };

  // const loginGoogle = useGoogleLogin({
  //   onSuccess: async (res) => {
  //     const data = await fetch(
  //       "https://www.googleapis.com/oauth2/v3/userinfo",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${res.access_token}`,
  //         },
  //       }
  //     );
  //     console.log(res);
  //     const userInfo = await data.json();
  //     console.log(userInfo);
  //     const myData = JSON.stringify({
  //       googleToken: res.access_token,
  //     });
  //     UserActionCreators.logGoogleIn(myData);
  //   },
  //   onError: () => {
  //     console.log("Login Failed");
  //   },
  // });

  const loginGoogle = (event) => {
    window.editor.setProcessGoogle(true);
    if (!auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: "369311205870.apps.googleusercontent.com",
        scope: "profile",
        fetch_basic_profile: false,
      });
      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(signinChanged);
      // Listen for changes to current user.
      auth2.currentUser.listen(userChanged);
    }
    auth2.signIn().then(() => {
      console.log("signin finished");
    });
  };

  const signinChanged = (user) => {
    if (user && typeof yourFunctionName === "function" && user.isSignedIn())
      window.editor.googleLogin(user);
  };

  const userChanged = (user) => {
    if (user && user.isSignedIn()) window.editor.googleLogin(user);
  };

  const _emailClick = () => {
    UserActionCreators.loginMode("register");
  };

  return (
    <div className="login-main-btns">
      <span className="login-instruction">Try piZap free</span>
      <div className="loginMainButtonContainer">
      <a className="btn btn-gplus" onClick={loginGoogle}>
          <i className="icon icon-gplus-light"></i>
          <span>Sign up with Google</span>
        </a>
        <a className="btn btn-facebook" onClick={_facebookClick}>
          <i className="icon icon-facebook-light"></i>
          <span>Sign up with Facebook</span>
        </a>
        {/*<a className="btn btn-email" onClick={_emailClick}>
          <i className="icon icon-email"></i>
          <span>Sign up with Email</span>
        </a>*/}	
      </div>
      <div className="alreadySigned">
        Already signed up? <Link to="/app/login">Log in</Link>
      </div>
    </div>
  );
};

export default LoginMain;
