import React, { useEffect, useMemo, useRef, useState } from "react";
import AssetStore from "../stores/AssetStore";
import FacebookStore from "../stores/FacebookStore";
import UserStore from "../stores/UserStore";
import EditorActionCreators from "../actions/EditorActionCreators";
import JSONUtils from "../utils/JSONUtils";
import PixabayImageSrc from "../images/editor/pixabay_logo.svg";
import UnsplashImageSrc from "../images/editor/unsplash_wordmark_logo.svg";
import ProCrown from "./ProCrown.react";
import EditorDispatcher from "../dispatcher/EditorDispatcher";
import ActionTypes from "../constants/EditorConstants";
import ImageGallery from "./ImageGallery";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import Lockr from "lockr";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import { checkUsageLimit } from "../utils/utils";
import Gallery from "react-grid-gallery";
import ThumbOverlay from "./ThumbOverLay.react";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";
import { ReactComponent as IconClear } from "../images/editor/clear_icon.svg";
import { ReactComponent as IconSearch } from "../images/editor/search_icon.svg";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import LeftMenuStore from "../stores/LeftMenuStore";
import SkeletonLoading from "./SkeletonLoadingImage";
import { toast, Flip } from "react-toastify";
import AIStyleItem from "./AIStyleItem";

const FilePickerOverlay = (props) => {
  const { data } = props;
  const handleClick = (e) => {
    window.editor.addPicture(data.src);
    EditorActionCreators.ShowFilePicker(false);
  };
  return (
    <div className="thumbOverlay">
      <div className="thumbOverlayEdit clickable" onClick={handleClick}></div>
    </div>
  );
};

function getStateFromStores(oldState) {
  return {
    type: oldState.type,
    selectedItem: AssetStore.getSelectedItem(),
    featuredBackgrounds: AssetStore.getFeaturedBackgrounds(),
    featuredBackgroundsMeme: AssetStore.getFeaturedBackgroundsMeme(),
    featuredBackgroundsEmoji: AssetStore.getFeaturedBackgroundsEmoji(),
    aiStyles: AssetStore.getAIStyles(),
    aiSuggestions: AssetStore.getAISuggestions(),
    folders: getFoldersState(oldState.type),
    folderItems: getFolderItemState(oldState.type),
    pickerSearchResults: AssetStore.getPickerSearchResults(),
    cutouts: UserStore.getCutouts(),
  };
}

function getFoldersState(type) {
  switch (type) {
    case "background":
      return AssetStore.getBackgroundFolders();
    case "facebook":
      return FacebookStore.getFolders();
    default:
      return AssetStore.getBackgroundFolders();
  }
}

function getFolderItemState(type) {
  switch (type) {
    case "background":
      return AssetStore.getBackgroundFolderDetails();
    case "facebook":
    case "flickr":
      return FacebookStore.getFolderItems();
    case "cutout":
      return UserStore.getCutouts();
    case "projectAssets":
      return UserStore.getProjectAssets();
    default:
  }
}

function getFeaturedBackgroundFolderItem(item, idx) {
  item.src = item.url;
  item.icon = item.thumbnail;
  item.key = idx;
  item.isProOnly =
    item.isProOnly ||
    item.tags?.includes("pizapPRO") ||
    item.oldTags?.includes("pizapPRO");
  return <ImageGallery key={item.key} item={item} type="background" />;
}

function getSearchFolderItem(searchItem) {
  let item = {
    key: searchItem.id,
    src: `${
      process.env.REACT_APP_SERVER_API
    }/proxy/${searchItem?.fullHDURL?.replace("//", "")}`,
    icon: searchItem.previewURL,
    originalItem: searchItem,
  };

  return <ImageGallery key={item.key} item={item} type="background" />;
}

const getBingImageItem = (searchItem, index) => {
  let item = {
    key: index,
    src: `${
      process.env.REACT_APP_SERVER_API
    }/proxy/${searchItem?.contentUrl?.replace("//", "")}`,
    icon: searchItem.thumbnailUrl,
    originalItem: searchItem,
  };
  return (
    <ImageGallery
      key={index}
      id={index}
      item={item}
      type="background"
      isbing={true}
    />
  );
};
function getUnsplashFolderItem(searchItem) {
  let item = {
    key: searchItem.id,
    src: searchItem.urls.regular,
    icon: searchItem.urls.thumb,
    originalItem: searchItem,
  };
  return <ImageGallery key={item.key} item={item} type="background" />;
}

function getFacebookFolderItem(item) {
  let newItem = {
    key: item.id,
    src: item.images[0].source,
    icon: item.picture,
    originalItem: item,
  };
  return <ImageGallery key={newItem.key} item={newItem} type="facebook" />;
}

const FilePicker = (props) => {
  const navigate = useNavigate();
  const isPro = UserStore.isPro();
  const [data, setData] = useState(getStateFromStores({ type: props.type }));
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [cutouts, setCutouts] = useState([]);
  const [uplods, setUploads] = useState([]);
  const [title, setTitle] = useState("Stock Images");
  const [prompt, setPrompt] = useState("");
  const [currentStyle, setCurrentStyle] = useState(null);
  const [suggestions, setSuggstions] = useState([]);
  const [imageSize, setImageSize] = useState("square");
  const [backgrounds, setBackgrounds] = useState([]);
  const featuredApp = Lockr.get("featuredApp");
  const [aiStyle, setAIStyle] = useState("");
  const aiStyleRef = useRef(null);
  const aiStickerStyleRef = useRef(null);
  const [startIndex, setStartIndex] = useState(0);
  const scrollRef = useRef(null);
  const stockScrollRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (props.type === "cutout") {
      setTitle("Cutouts");
      loadMoreCutouts();
    } else if (props.type === "background") {
      setTitle("Stock Images");
    } else if (props.type === "projectAssets") {
      setTitle("Uploads");
      loadMoreUploads();
    } else if (props.type === "ai_image") {
      setTitle("Magic A.I. Image Maker");
      if (data.aiSuggestions === null) {
        AssetActionCreators.loadAISuggestions();
      }
      setAIStyle("aifeatured");
      AssetActionCreators.loadAiStyles("aifeatured");
    } else if (props.type === "ai_sticker") {
      setTitle("Magic A.I. Sticker Maker");
      setAIStyle("aistickerfeatured");
      if (data.aiSuggestions === null) {
        AssetActionCreators.loadAISuggestions();
      }
      AssetActionCreators.loadAiStyles("aistickerfeatured");
    }
  }, [props.type]);

  useEffect(() => {
    // Only do this check if we’re in AI mode (ai_image or ai_sticker)
    if (props.type === "ai_image" || props.type === "ai_sticker") {
      if (data.aiStyles === null || data.aiSuggestions === null) {
        setIsLoading(true);
      } else {
        // When the store finishes loading new styles, turn off loading
        setIsLoading(false);
      }
    }
  }, [data.aiStyles, data.aiSuggestions, props.type]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      AssetStore.addChangeListener(_onChange);
      FacebookStore.addChangeListener(_onChange);
      UserStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
      FacebookStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const handleInputChange = (event, { newValue, method }) => {
    setSearchValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch(e);
    }
  };

  const handleSearch = (e, suggestion) => {
    if (suggestion) {
      setSearchValue(suggestion.suggestion);
    }
    handlePizapSearch();
  };

  const inputProps = {
    placeholder: "Search...",
    value: searchValue,
    onChange: handleInputChange,
    onKeyDown: handleKeyDown,
  };

  const getSuggestions = async (value) => {
    let items = [];
    const res = backgrounds.filter((item) => {
      return item.tag.toLowerCase().includes(value);
    });
    if (res.length > 0) {
      res.map((item) => {
        items.push(item.tag);
      });
    }
    return [
      {
        title: "",
        items: items,
      },
    ];
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const res = await getSuggestions(value);
    setSuggstions(res);
  };

  const onSuggestionsClearRequested = () => {
    setSuggstions([]);
  };

  const getSuggestionValue = (suggestion) => {
    setSearchValue(suggestion);
    return suggestion;
  };

  const renderSuggestion = (suggestion) => {
    return <span>{suggestion}</span>;
  };

  const renderSectionTitle = (section) => {
    return section.title;
  };

  const getSectionSuggestions = (section) => {
    return section.items;
  };

  const handleCategorySelected = (item) => {
    setSelectedCategory(item.name);
    setSearchValue("");
    EditorDispatcher.dispatch({
      type: ActionTypes.FILE_PICKER_CATEGORY_SELECTED,
      data: item,
      itemType: props.type,
    });
    AssetActionCreators.setSearchResults({
      activeTab: "category",
      catname: searchValue ? searchValue : item.name,
      results: item.pizapAssets,
    });
    AssetStore.emitChange();
  };

  const _cancelClick = (event) => {
    event.preventDefault();
    if (LeftMenuStore.get() !== "init") {
      setSearchValue("");
      EditorActionCreators.SetPickerState(null);
      EditorActionCreators.ShowFilePicker(false);
    } else {
      navigate("/app/start");
    }
  };

  const _onChange = () => {
    setData(getStateFromStores(data));
  };

  const gotoCutouts = () => {
    EditorActionCreators.ShowCutoutInstructions(true);
    EditorStore.showFilePicker(false);
    window.editor.setSelectedObject(null);
  };

  const handlePizapSearch = async () => {
    stockScrollRef?.current && stockScrollRef.current.scrollTo(0, 0);
    const results = backgrounds.filter((item) =>
      item.tag
        .toLowerCase()
        .includes(
          searchValue
            ? searchValue.toLocaleLowerCase()
            : selectedCategory.toLocaleLowerCase()
        )
    );
    if (results.length > 0) {
      AssetActionCreators.setSearchResults({
        activeTab: "pizap",
        catname: searchValue ? searchValue : selectedCategory,
        results: results[0].pizapAssets,
      });
    } else {
      handleClickPixabayTab();
    }
  };

  const items = useMemo(() => {
    setSelectedCategory(data.pickerSearchResults.catname);
    if (props.type === "background") {
      if (data.pickerSearchResults.activeTab === "category") {
        return data.pickerSearchResults.results
          ? data.pickerSearchResults.results?.map(
              getFeaturedBackgroundFolderItem
            )
          : data.selectedItem.pizapAssets.map(getFeaturedBackgroundFolderItem);
      } else if (data.pickerSearchResults.activeTab === "pizap") {
        return data.pickerSearchResults.results &&
          data.pickerSearchResults.results?.length > 0 ? (
          data.pickerSearchResults.results.map(getFeaturedBackgroundFolderItem)
        ) : (
          <span className="no-pizap-images">
            No piZap images for this search term.
          </span>
        );
      } else if (data.pickerSearchResults.activeTab === "pixabay") {
        return data.pickerSearchResults.results &&
          data.pickerSearchResults.results?.length > 0 ? (
          data.pickerSearchResults.results.map(getSearchFolderItem)
        ) : (
          <span className="no-pizap-images">
            No Pixabay images for this search term.
          </span>
        );
      } else if (data.pickerSearchResults.activeTab === "unsplash") {
        return data.pickerSearchResults.results &&
          data.pickerSearchResults.results?.length > 0 ? (
          data.pickerSearchResults.results.map(getUnsplashFolderItem)
        ) : (
          <span className="no-pizap-images">
            No Unsplash images for this search term.
          </span>
        );
      } else if (data.pickerSearchResults.activeTab === "bing") {
        return data.pickerSearchResults.results &&
          data.pickerSearchResults.results?.length > 0 ? (
          data.pickerSearchResults.results.map(getBingImageItem)
        ) : (
          <span className="no-pizap-images">
            No Bing images for this search term.
          </span>
        );
      }
    }
  }, [data.pickerSearchResults, props.type, data.folderItems]);

  const handleClickPixabayTab = () => {
    stockScrollRef?.current && stockScrollRef.current.scrollTo(0, 0);
    JSONUtils.getJSON(
      "https://www.pizap.com/pixabay/search?q=" +
        encodeURI(searchValue ? searchValue : selectedCategory),
      "GET",
      null,
      function (data) {
        AssetActionCreators.setSearchResults({
          activeTab: "pixabay",
          catname: searchValue ? searchValue : selectedCategory,
          results: data?.hits ? data.hits : [],
        });
      }
    );
  };

  const handleClickUnsplashTab = () => {
    stockScrollRef?.current && stockScrollRef.current.scrollTo(0, 0);
    JSONUtils.getJSON(
      `${process.env.REACT_APP_SERVER_API}/unsplash?query=${encodeURI(
        searchValue ? searchValue : selectedCategory
      )}`,
      "GET",
      null,
      function (data) {
        AssetActionCreators.setSearchResults({
          activeTab: "unsplash",
          catname: searchValue ? searchValue : selectedCategory,
          results: data.total ? data.results : [],
        });
      }
    );
  };

  const loadMoreCutouts = () => {
    const newStartIndex = startIndex + 50;
    if (data.cutouts && data.cutouts?.length > 0) {
      const newVisibleImages = data.cutouts
        .slice(0, newStartIndex)
        .map((item, idx) => {
          return {
            ...item,
            customOverlay: <FilePickerOverlay data={item} key={idx} />,
          };
        });
      setCutouts(newVisibleImages);
      setStartIndex(newStartIndex);
    }
  };

  const loadMoreUploads = () => {
    const newStartIndex = startIndex + 50;
    if (data.folderItems && data.folderItems?.length > 0) {
      const newVisibleImages = data.folderItems
        .slice(0, newStartIndex)
        .map((item, idx) => {
          return {
            ...item,
            customOverlay: <FilePickerOverlay data={item} key={idx} />,
          };
        });
      setUploads(newVisibleImages);
      setStartIndex(newStartIndex);
    }
  };

  useEffect(() => {
    if (
      featuredApp === "emoji" &&
      data.featuredBackgroundsEmoji &&
      data.featuredBackgroundsEmoji.length > 0
    ) {
      setBackgrounds(data.featuredBackgroundsEmoji);
    } else if (
      featuredApp === "meme" &&
      data.featuredBackgroundsMeme &&
      data.featuredBackgroundsMeme.length > 0
    ) {
      setBackgrounds(data.featuredBackgroundsMeme);
    } else {
      setBackgrounds(data.featuredBackgrounds);
    }
  }, [
    data.featuredBackgrounds,
    data.featuredBackgroundsEmoji,
    data.featuredBackgroundsMeme,
    featuredApp,
  ]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 300) {
      if (props.type === "cutout") {
        loadMoreCutouts();
      } else if (props.type === "projectAssets") {
        loadMoreUploads();
      }
    }
  };

  useEffect(() => {
    scrollRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [cutouts, uplods, startIndex]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleClickSuggestion = (item) => {
    setPrompt(prompt ? `${prompt} ${item.caption}` : item.caption);
  };

  const handleClickStyle = (item) => {
    if (item === currentStyle) setCurrentStyle(null);
    else setCurrentStyle(item);
  };

  const aiImageGen = async () => {
    if (props.type === "ai_image") {
      const size =
        imageSize === "square"
          ? "1024x1024"
          : imageSize === "landscape"
          ? "1792x1024"
          : imageSize === "portrait"
          ? "1024x1792"
          : imageSize === "facebookCover"
          ? "1248x480"
          : "1024x1024"; // fallback default

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${UserStore.getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: `${prompt} in the style of ${
            currentStyle ? currentStyle.caption : ""
          }`,
          model: "dall-e-3",
          n: 1,
          quality: "standard",
          size: size,
          response_format: "b64_json",
        }),
      };


      console.log('Making AI image request with options:', {
        ...options,
        body: JSON.parse(options.body)
      });

      EditorStore.setShowSpinner(true);
      fetch(`${process.env.REACT_APP_SERVER_API}/projectAssets/ai`, options)
        .then((response) => {
          // Check the status code before parsing the response body
          if (response.status === 422) {
            // If you know 422 corresponds to a restricted query,
            // handle it explicitly here
            throw new Error("Restricted query. Please adjust your prompt and try again.");
          } else if (!response.ok) {
            // Handle other non-200 responses
            throw new Error(`Error from server: ${response.statusText} (status: ${response.status})`);
          }

          // If the response is OK, parse the JSON
          return response.json();
        })
        .then((data) => {
          // Handle the successful response data
          console.log('AI image response data:', data);
          UserStore.appendProjectAssets(data);
          window.editor.addPicture(data.src);
        })
        .catch((error) => {
          // Handle the thrown errors here
          console.error('AI image error:', error.message);
          EditorStore.setShowSpinner(false);
          EditorActionCreators.ShowAIError(true);
        });
    } else if (props.type === "ai_sticker") {
      const size = "1024x1024";
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${UserStore.getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: `${prompt} in the style of ${
            currentStyle ? currentStyle.caption : "sticker"
          } style, white background, no background decor`,
          model: "dall-e-3",
          n: 1,
          quality: "standard",
          size: size,
          response_format: "b64_json",
        }),
      };

      console.log('Making AI sticker request with options:', {
        ...options,
        body: JSON.parse(options.body)
      });

      EditorStore.setShowSpinner(true);
      fetch(`${process.env.REACT_APP_SERVER_API}/projectAssets/ai`, options)
        .then((response) => {
          console.log('AI sticker response status:', response.status);
          return response.json();
        })
        .then((data) => {
          console.log('AI sticker response data:', data);
          EditorActionCreators.removeBackground(data.src);
        })
        .catch((error) => {
          console.error('AI sticker error:', error);
          EditorStore.setShowSpinner(false);
          EditorActionCreators.ShowAIError(true);
        });
    }
  };

  const handleClickCreate = (e) => {
    if (prompt === "") {
      toast.warning("Describe any prompt.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip,
      });
      return;
    }
  
    EditorActionCreators.ShowFilePicker(false);
  
    const isPro = UserStore.getUser().isPRO;
  
    // 1) If non-pro user tries to generate a sticker, show Pro popup immediately
    if (!isPro && props.type === "ai_sticker") {
      UserActionCreators.showProGet(true);
      if (!window.editor.getBaseObject()) {
        navigate("/app/start");
      }
      return;
    }
  
    // 2) Otherwise, go through the normal usage-limit check for ai_image
    const hasUsageLeft = checkUsageLimit(imageSize, isPro);
  
    if (!hasUsageLeft) {
      // Out of usage
      if (!isPro) {
        // Non-Pro user: show Pro modal if usage limit is reached (3 images)
        UserActionCreators.showProGet(true);
        // If there's no base object in the editor, redirect
        if (!window.editor.getBaseObject()) {
          navigate("/app/start");
        }
        return;
      } else {
        // Pro user: show usage-limit modal (100 images)
        EditorActionCreators.ShowUsageLimitModal(true);
        return;
      }
    }
  
    // If we still have usage left (or user is Pro for stickers), run AI generation
    aiImageGen();
  };

  const pickerContents = useMemo(() => {
    if (props.type === "cutout") {
      return (
        cutouts && (
          <div id="photopicker-container">
            <div
              id="photopicker-album-files"
              className={`photopicker-photos ${
                props.type === "cutout" ? "uploads-dialog" : ""
              }`}
              ref={scrollRef}
            >
              <div className="pw-10">
                <Gallery
                  images={cutouts}
                  enableImageSelection={false}
                  enableLightbox={false}
                  rowHeight={140}
                />
              </div>
            </div>
          </div>
        )
      );
    } else if (props.type === "background") {
      return data.pickerSearchResults.activeTab === "main" ? (
        <div className="galleries file-picker-galleries">
          {backgrounds &&
            backgrounds.length > 0 &&
            backgrounds?.map((galleries, index) => {
              const images = galleries.pizapAssets.slice(0, 14).map((i, j) => {
                if (!i.oldTags) {
                  i.oldTags = i.tags;
                }
                let isFree = true;
                if (
                  i.oldTags &&
                  i.oldTags.find(
                    (element) =>
                      typeof element.toLowerCase === "function" &&
                      element.toLowerCase() === "pizappro"
                  ) &&
                  !isPro
                ) {
                  i.thumbnailCaption = <ProCrown key={j} />;
                  isFree = false;
                }
                if (i.oldTags) {
                  i.tags = i.oldTags.map((t) => {
                    return { value: t, title: t };
                  });
                }
                i.src = i.url;
                i.customOverlay = (
                  <ThumbOverlay
                    key={index}
                    background={i}
                    hideActions={true}
                    isFree={isFree}
                    rowHeight={140}
                  />
                );
                return i;
              });
              return (
                <div className="gallery" key={`gallery-${index}`}>
                  <div className="file-picker-gallery-title">
                    <span>{galleries.name}</span>
                    <a
                      onClick={() => {
                        handleCategorySelected(galleries);
                      }}
                    >{`See all >`}</a>
                  </div>
                  <Gallery
                    featureTitle={galleries.name}
                    enableImageSelection={false}
                    enableLightbox={false}
                    images={images}
                    rowHeight={140}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <div className="image-src-tabs" style={{ marginBottom: "8px" }}>
            <a
              nohref="true"
              onClick={() => {
                setSearchValue("");
                AssetActionCreators.setSearchResults({
                  activeTab: "main",
                  catname: "",
                  results: [],
                });
              }}
              className="clickable file-picker-back"
            >
              <svg viewBox="0 0 960 560" transform="rotate(90)">
                <path
                  className="a"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </svg>
            </a>
            <div
              className={`tab-item ${
                data.pickerSearchResults.activeTab === "pizap" ||
                data.pickerSearchResults.activeTab === "category"
                  ? `selected`
                  : ``
              }`}
              onClick={handlePizapSearch}
            >
              <img
                src="https://static.pizap.com/html5/resources/editor/pizap_logo.svg"
                height={16}
              />
            </div>
            <div
              className={`tab-item ${
                data.pickerSearchResults.activeTab === "pixabay"
                  ? `selected`
                  : ``
              }`}
              onClick={handleClickPixabayTab}
            >
              <img src={PixabayImageSrc} alt="pixabay" height={16} />
            </div>
            <div
              className={`tab-item ${
                data.pickerSearchResults.activeTab === "unsplash"
                  ? `selected`
                  : ``
              }`}
              onClick={handleClickUnsplashTab}
            >
              <img src={UnsplashImageSrc} alt="unsplash" height={16} />
            </div>
          </div>
          <div id="photopicker-container">
            <div
              id="photopicker-album-files"
              className={`photopicker-photos background-picker`}
              ref={stockScrollRef}
            >
              <div className="grid">{items}</div>
            </div>
          </div>
        </>
      );
    } else if (props.type === "projectAssets") {
      return (
        <div id="photopicker-container">
          <div
            id="photopicker-album-files"
            className={`photopicker-photos ${
              props.type === "projectAssets" ? "uploads-dialog" : ""
            }`}
            ref={scrollRef}
          >
            <div className="pw-10">
              <Gallery
                images={uplods}
                enableImageSelection={false}
                enableLightbox={false}
                rowHeight={140}
              />
            </div>
          </div>
        </div>
      );
    } else if (props.type === "ai_image") {
      return (
        <div className="ai-dialog-container">
          <div className="flex-start space-between mt-25">
            <div className="ai-prompt">
              <span className="title-text">1. Describe any image</span>
              <textarea
                autoFocus
                className="text-area mt-2 mb-1 ai-prompt-text"
                id="text-area"
                onChange={handlePromptChange}
                value={prompt}
                placeholder="example: a dog in outer space"
              ></textarea>
              <span className="mb-1 title">Suggestions</span>
              <div className="suggestion-group mt-1">
                {isLoading ? (
                  <>
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                  </>
                ) : (
                  data?.aiSuggestions &&
                  data?.aiSuggestions
                    ?.sort((a, b) => {
                      return a.sequence - b.sequence;
                    })
                    .map((item, idx) => {
                      return (
                        <span
                          key={idx}
                          className="clickable suggestion-item"
                          onClick={() => handleClickSuggestion(item)}
                        >
                          {item.caption}
                        </span>
                      );
                    })
                )}
              </div>
              <div className="ai-flex-column">
                <span className="title-text mt-2">2. Choose layout</span>
                <div className="d-flex space-between mt-1">
                <div
                  onClick={() => setImageSize("square")}
                  className={`square-size ${imageSize === "square" ? `active` : ``}`}
                >
                  <span>Square</span>
                </div>
                <div
                  onClick={() => {
                    if (!UserStore.getUser().isPRO) {
                      UserActionCreators.showProGet(true);
                    } else {
                      setImageSize("landscape");
                    }
                  }}
                  className={`landscape-size  ${imageSize === "landscape" ? `active` : ``}`}
                >
                  {!UserStore.getUser().isPRO && <ProCrown />}
                  <span>Landscape</span>
                </div>
                <div
                  onClick={() => {
                    if (!UserStore.getUser().isPRO) {
                      UserActionCreators.showProGet(true);
                    } else {
                      setImageSize("portrait");
                    }
                  }}
                  className={`portrait-size  ${imageSize === "portrait" ? `active` : ``}`}
                >
                  {!UserStore.getUser().isPRO && <ProCrown />}
                  <span>Portrait</span>
                </div>
              </div>

              {/* New row for Facebook Cover */}
              <div className="d-flex justify-content-center mt-2">
                <div
                  onClick={() => {
                    if (!UserStore.getUser().isPRO) {
                      UserActionCreators.showProGet(true);
                    } else {
                      setImageSize("facebookCover");
                    }
                  }}
                  className={`facebook-cover-size ${imageSize === "facebookCover" ? `active` : ``}`}
                >
                  {!UserStore.getUser().isPRO && <ProCrown />}
                  <span>Facebook Cover</span>
                </div>
              </div>
              </div>
            </div>
            <div className="ai-style">
              <div className="d-flex space-between">
                <span className="title-text">3. Choose Style</span>
              </div>
              <div className="mt-1 mb-1 ai-style-categories">
                <span
                  className={`ai-style-category ${
                    aiStyle === "aifeatured" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aifeatured") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aifeatured");
                      setAIStyle("aifeatured");
                      aiStyleRef?.current && aiStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Featured
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aiartstyle" ? "active" : ""
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aiartstyle") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aiartstyle");
                      setAIStyle("aiartstyle");
                      aiStyleRef?.current && aiStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Art Styles
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aicartoon" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aicartoon") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aicartoon");
                      setAIStyle("aicartoon");
                      aiStyleRef?.current && aiStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Cartoon & Anime
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aistylized" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aistylized") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aistylized");
                      setAIStyle("aistylized");
                      aiStyleRef?.current && aiStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Stylized
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aiphotography" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aiphotography") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aiphotography");
                      setAIStyle("aiphotography");
                      aiStyleRef?.current && aiStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Photography
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aiunique" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aiunique") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aiunique");
                      setAIStyle("aiunique");
                      aiStyleRef?.current && aiStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Unique
                </span>
              </div>
              <div className="style-group" ref={aiStyleRef}>
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "40px 27px",
                    }}
                  >
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                  </div>
                ) : (
                  data?.aiStyles &&
                  data?.aiStyles
                    ?.sort((a, b) => (a.sequence || 9999) - (b.sequence || 9999))
                    .map((item, idx) => {
                      return (
                        <AIStyleItem
                          key={idx}
                          currentStyle={currentStyle}
                          item={item}
                          handleClickStyle={handleClickStyle}
                        />
                      );
                    })
                )}
              </div>
            </div>
          </div>
          <div className="mt-25 text-center">
            <button className="aicreate-btn my-btn" onClick={handleClickCreate}>
              Create
            </button>
          </div>
        </div>
      );
    } else if (props.type === "ai_sticker") {
      return (
        <div className="ai-dialog-container">
          <div className="flex-start space-between mt-25">
            <div className="ai-prompt">
              <span className="title-text">1. Describe any sticker</span>
              <textarea
                autoFocus
                className="text-area mt-2 mb-1 ai-prompt-text"
                id="text-area"
                onChange={handlePromptChange}
                value={prompt}
                placeholder="example: a cute cat"
              ></textarea>
              <span className="mb-1 title">Suggestions</span>
              <div className="suggestion-stickers-group mt-1">
                {isLoading ? (
                  <>
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                    <SkeletonLoading width={80} height={20} />
                  </>
                ) : (
                  data?.aiSuggestions &&
                  data?.aiSuggestions
                    ?.sort((a, b) => {
                      return a.sequence - b.sequence;
                    })
                    .map((item, idx) => {
                      return (
                        <span
                          key={idx}
                          className="clickable suggestion-item"
                          onClick={() => handleClickSuggestion(item)}
                        >
                          {item.caption}
                        </span>
                      );
                    })
                )}
              </div>
            </div>
            <div className="ai-style">
              <div className="d-flex space-between">
                <span className="title-text">2. Choose Style</span>
              </div>
              <div className="mt-1 mb-1 ai-style-categories">
                <span
                  className={`ai-style-category ${
                    aiStyle === "aistickerfeatured" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aistickerfeatured") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aistickerfeatured");
                      setAIStyle("aistickerfeatured");
                      aiStickerStyleRef?.current &&
                        aiStickerStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Featured
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aistickercartoon" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aistickercartoon") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aistickercartoon");
                      setAIStyle("aistickercartoon");
                      aiStickerStyleRef?.current &&
                        aiStickerStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Cartoon & Anime
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aistickerartstyle" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aistickerartstyle") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aistickerartstyle");
                      setAIStyle("aistickerartstyle");
                      aiStickerStyleRef?.current &&
                        aiStickerStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Art Styles
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aistickerlogo" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aistickerlogo") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aistickerlogo");
                      setAIStyle("aistickerlogo");
                      aiStickerStyleRef?.current &&
                        aiStickerStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Logo Styles
                </span>
                <span
                  className={`ai-style-category ${
                    aiStyle === "aistickerunique" ? `active` : ``
                  }`}
                  onClick={() => {
                    if (aiStyle !== "aistickerunique") {
                      setIsLoading(true);  
                      AssetActionCreators.loadAiStyles("aistickerunique");
                      setAIStyle("aistickerunique");
                      aiStickerStyleRef?.current &&
                        aiStickerStyleRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  Unique
                </span>
              </div>
              <div className="style-group" ref={aiStickerStyleRef}>
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "40px 27px",
                    }}
                  >
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                    <SkeletonLoading width={96} height={96} />
                  </div>
                ) : (
                  data?.aiStyles &&
                  data.aiStyles
                    ?.sort((a, b) => {
                      return a.sequence - b.sequence;
                    })
                    .map((item, idx) => {
                      return (
                        <AIStyleItem
                          key={idx}
                          currentStyle={currentStyle}
                          item={item}
                          handleClickStyle={handleClickStyle}
                        />
                      );
                    })
                )}
              </div>
            </div>
          </div>
          <div className="mt-25 text-center">
            <button className="aicreate-btn my-btn" onClick={handleClickCreate}>
              Create
            </button>
          </div>
        </div>
      );
    }
  }, [
    data.pickerSearchResults,
    props.type,
    backgrounds,
    cutouts,
    uplods,
    data,
    searchValue,
    prompt,
    currentStyle,
    imageSize,
    aiStyle,
    isLoading,
  ]);

  return (
    <div className="photopicker" id="photopicker">
      <div id="photopicker-dialog" className="photopicker-dialog second-modal">
        <div className="photopicker-dialog-box stock-image-dialog-box">
          <div className="photopicker-navbar text-left">
            <div className="close-btn" onClick={_cancelClick}>
              <CloseSVG height={12} />
            </div>
            <div className="photopicker-title">{title}</div>
            {props.type === "background" && (
              <div className="input-group">
                <Autosuggest
                  multiSection={true}
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={handleSearch}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                  inputProps={inputProps}
                />
                <span className="start__search__icon">
                  <IconSearch />
                </span>
                <span
                  className="start__clear__icon"
                  onClick={(e) => {
                    setSearchValue("");
                    AssetActionCreators.setSearchResults({
                      activeTab: "main",
                      catname: "",
                      results: [],
                    });
                  }}
                >
                  <IconClear />
                </span>
              </div>
            )}
          </div>
          {pickerContents}
        </div>
      </div>
    </div>
  );
};

export default FilePicker;
